import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  LoadingPage,
  StyledCard,
  StyledContainer,
  StyledContent,
  StyledHeader,
} from '../Styled'
import Header from './Header'
import { ViewRow } from '../../../components/CRUD/components/StyledViewBox'
import { Box, Divider, Typography } from '@mui/material'
import HistoryFilterDrawer from '../HistoryFilterDrawer'
import { useEffect, useState } from 'react'
import { fetchHistory } from '../Form/event'
import { getExaminationScheduleDetail } from '../../../services/eExamination/form'
import _ from 'lodash'
import { getFormatDate } from '../../../utils/lib'
import {
  E_EXAM_TYPE,
  SCHEDULE_STATUS_TEXT_TH,
} from '../../../constants/eExamination'
import { setEExamType } from 'src/redux/slices/eExamination/list'

const ExaminationView = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const {
    isOpenHistoryFilterDrawer,
    historyList,
    historyCount,
    ExamSchedulerResult,
    eExamType,
  } = useSelector(
    (state) => ({
      isOpenHistoryFilterDrawer: state.eExamList.isOpenHistoryFilterDrawer,
      historyList: state.eExamForm.historyList,
      historyCount: state.eExamForm.historyCount,
      ExamSchedulerResult: state.eExamForm.result,
      eExamType: state.eExamList.eExamType,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(fetchHistory(limit, page, uuid))
  }, [limit, page])

  useEffect(() => {
    if (uuid) {
      fetchData(uuid)
    }
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await dispatch(getExaminationScheduleDetail(uuid))
    setIsLoading(false)
  }

  useEffect(() => {
    if (ExamSchedulerResult) {
      if (ExamSchedulerResult.association) {
        dispatch(setEExamType(E_EXAM_TYPE.ASSOCIATION))
      } else if (ExamSchedulerResult.station) {
        dispatch(setEExamType(E_EXAM_TYPE.COMPANY))
      } else if (ExamSchedulerResult.other) {
        dispatch(setEExamType(E_EXAM_TYPE.OTHER))
      }
    }
  }, [ExamSchedulerResult])

  const association = ExamSchedulerResult?.other
    ? ExamSchedulerResult?.other?.association
    : ExamSchedulerResult?.association

  const examStation =
    eExamType === E_EXAM_TYPE.OTHER
      ? _.get(ExamSchedulerResult, 'other.otherName', '-')
      : _.get(ExamSchedulerResult, 'defaultScheduleName', '-')

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Header uuid={uuid} />
        </StyledHeader>

        <StyledCard sx={{ p: 3 }}>
          <ViewRow
            title="ภูมิภาค"
            variant="body1b"
            value={_.get(association, 'region.regionName', '-')}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title={eExamType === E_EXAM_TYPE.OTHER ? 'จังหวัด' : 'สนามสอบ'}
            variant="body1b"
            value={
              [E_EXAM_TYPE.OTHER, E_EXAM_TYPE.ASSOCIATION].includes(eExamType)
                ? _.get(association, 'provinceName', '-')
                : _.get(ExamSchedulerResult, 'station.academyStationName', '-')
            }
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="สถานที่สอบ"
            variant="body1b"
            value={examStation}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="วันสอบ"
            variant="body1b"
            value={getFormatDate(ExamSchedulerResult?.date, 'DD/MM/YYYY')}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="รอบสอบ"
            variant="body1b"
            value={_.get(ExamSchedulerResult, 'time.timeName', '-')}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="เวลาเริ่มสอบ-เวลาสิ้นสุดการสอบ"
            variant="body1b"
            value={`${_.get(
              ExamSchedulerResult,
              'time.startTime',
              '',
            )} - ${_.get(ExamSchedulerResult, 'time.endTime', '')}`}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="วันเวลาเปิดรับสมัคร"
            variant="body1b"
            value={`${getFormatDate(
              ExamSchedulerResult?.startDateRegister,
              'DD/MM/YYYY',
            )} ${_.get(ExamSchedulerResult, 'startTimeRegister', '-')}`}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="วันเวลาปิดรับสมัคร"
            variant="body1b"
            value={`${getFormatDate(
              ExamSchedulerResult?.endDateRegister,
              'DD/MM/YYYY',
            )} ${_.get(ExamSchedulerResult, 'endTimeRegister', '-')}`}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="จำนวนที่เปิดรับ"
            variant="body1b"
            value={_.get(ExamSchedulerResult, 'numberOfPeople', '-')}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="รายละเอียดเพิ่มเติม"
            variant="body1b"
            value={_.get(ExamSchedulerResult, 'description', '-')}
            boxSx={{ mb: 2 }}
          />
          <ViewRow
            title="สถานะ"
            variant="body1b"
            value={_.get(
              SCHEDULE_STATUS_TEXT_TH,
              ExamSchedulerResult?.status,
              '-',
            )}
            boxSx={{ mb: 2 }}
          />
          <Divider sx={{ mx: 3 }} />
          <Box
            sx={{
              mx: 3,
              mt: 2,
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'right',
            }}
          >
            <Typography variant="caption" color="text.lightGray">
              {historyList && historyList?.result?.length > 1
                ? 'แก้ไขล่าสุดโดย '
                : 'สร้างโดย '}
              {historyList &&
                historyList?.result?.length > 0 &&
                historyList?.result[0]?.author}{' '}
              {historyList &&
                historyList?.result?.length > 0 &&
                historyList?.result[0]?.createdAt}
            </Typography>
          </Box>
        </StyledCard>
      </StyledContent>
      <HistoryFilterDrawer
        open={isOpenHistoryFilterDrawer}
        drawerType="etesting"
        historyList={historyList}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        allCount={historyCount}
        isForm={true}
      />
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default ExaminationView
