import React, { useState, useEffect } from 'react'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'
import dayjs from 'dayjs'

import View from './View/Index'
import { getSubWorkType } from '../api/Index'
import DayLeave from './Leave/Index'
import Holiday from './Holiday/Index'
import AcademyActivity from './AcademyActivity/Index'
import Training from './Training/Index'
import { handleApiTraining } from './Training/Handle'
import { handleEditCalendar, handleAddCalendar } from './Handle'
import AlertDialog from '../../../components/AlertDialog'
import { requireFile, checkSpeaker } from './RequireFile'
import {
  WORK_WORK_LIST,
  MESSAGE_HOLIDAY,
  MESSAGE_LEAVE,
  MESSAGE_ACADEMY_ACTIVITY,
  SUB_WORK_TYPE_ACADEMY,
  MESSAGE_ACADEMY_TRAINING,
} from '../Text'

export const defaultStateForm = {
  leave: {
    workType: '',
    subWorkType: '',
    startDate: null,
    finishDate: null,
    remarkLeave: '',
    remark: '',
    staff: [],
    leavePeriod: {},
  },
  holiday: {
    workType: '',
    holidayType: '',
    startDate: null,
    finishDate: null,
    dayOff: '',
    remark: '',
  },
  academyActivity: {
    meetingType: undefined,
    isUseRoom: true,
    startDate: null,
    finishDate: null,
    title: '',
    description: '',
    toMeetingRoom: [],
    expandDate: [],
    attendees: [],
    remark: '',
    workPlace: undefined,
    travelType: undefined,
    course: [],
    checkAllUser: false,
  },
  training: {
    area: {
      id: 1,
      uuid: '',
      departments: '',
      station: '',
    },
    workType: {
      id: 5,
      uuid: '',
      workType: 'Training',
    },
    subject: '',
    courseOther: '',
    remarkOther: '',
    remark: '',
    startDate: null,
    finishDate: null,
    distributionChannel: {},
    trainingPlatform: [],
    isUseRoom: true,
    course: {},
    typeDate: 'DUPLICATE',
    dateBetween: [],
    dates: [
      {
        typeTime: 'FULL',
        date: '',
        startTime: '08:30',
        endTime: '17:00',
        speakers: [],
        speakerBorrow: [],
        location: {
          online: false,
          onsite: false,
          other: false,
          classRoomOther: '',
        },
      },
    ],
    expectNumberPeople: '',
    classroom: {},
    break: false,
    cost: 0,
    remarkCost: '',
  },
}

export const defaultValidateForm = {
  leave: {
    workType: '',
    subWorkType: '',
    startDate: '',
    finishDate: '',
    remarkLeave: '',
    remark: '',
    staff: '',
    leavePeriod: '',
  },
  holiday: {
    workType: '',
    holidayType: '',
    startDate: '',
    finishDate: '',
    dayOff: '',
    remark: '',
  },
  academyActivity: {
    startDate: '',
    finishDate: '',
    title: '',
    description: '',
    toMeetingRoom: '',
    expandDate: [],
    attendees: '',
    subWorkType: '',
    checkAllUser: false,
  },
  training: {
    workType: '',
    startDate: null,
    finishDate: null,
    dayOff: '',
    remark: '',
    subject: '',
  },
}

export default function DrawerAll({
  open,
  isDrawer,
  setIsDrawer,
  state,
  setState,
  newEvent,
  options,
  setOptions,
  handleFetchEvents,
  isLoading,
  setIsLoading,
  fetchApproveList,
}) {
  let dataEvent = {}
  let workType = state.work.value || ''
  const [user] = useLocalStorage('user')
  const [validateForm, setValidateForm] = useState(defaultValidateForm)
  const [optionSubWorkType, setOptionSubWorkType] = useState([])
  const [dialog, setDialog] = useState({
    variant: 'save',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    open: false,
    isError: false,
    onConfirmClick: null,
    onCancelClick: null,
  })
  const [stateForm, setStateForm] = useState(defaultStateForm)
  const [loading, setLoading] = useState(true)
  if (
    isDrawer.type === 'view' ||
    isDrawer.type === 'edit' ||
    isDrawer.type === 'approved' ||
    isDrawer.type === 'rejected' ||
    isDrawer.type === 'waiting_approve_budget' ||
    isDrawer.type === 'request_edit' ||
    isDrawer.type === 'request_delete' ||
    isDrawer.type === 'request_create'
  ) {
    if (isDrawer.work) {
      dataEvent = {
        uuid: isDrawer.work.id,
        workType: isDrawer.work.workType.workType,
        subWorkType: _.get(isDrawer, 'work.subWorkType.id', null),
      }
      workType = _.get(isDrawer, 'work.workType.workType', null)
    } else {
      dataEvent =
        state.calendarAllEvents.find((d) => d.publicId === isDrawer.publicId) ||
        {}
      workType = dataEvent.workType
    }
  }

  useEffect(() => {
    fetchInitial()
  }, [open, isDrawer.type])

  const fetchInitial = async () => {
    if (isDrawer.status)
      handleApiTraining({
        setOptions,
        area: state.area.value,
        setLoading,
        workType,
        options,
        date: state.data,
      })
    fetchSubWorkType({
      workType,
      options: options.work,
      setOptionSubWorkType,
      setLoading,
    })

    const newStartDate = !_.isNil(newEvent.start)
      ? dayjs(newEvent.start).format(window.__env__.REACT_APP_DATE_DB)
      : null

    const newEndDate = !_.isNil(newEvent.start)
      ? dayjs(newEvent.end).format(window.__env__.REACT_APP_DATE_DB)
      : null

    if (isDrawer.type === 'add') {
      let training = {}

      if (workType === WORK_WORK_LIST[3] || workType === WORK_WORK_LIST[4]) {
        const workTypeObject = options.work.filter(
          (a) => a.value === state.work.value,
        )
        const listStation = _.get(state, 'area.station', [])
        let objStation = listStation
        if (_.isArray(listStation)) {
          objStation = listStation.find((item) => {
            return item.value === _.get(state, 'area.valueStation', '')
          })
        }

        training = {
          ...defaultStateForm.training,
          area: {
            id: _.get(state, 'area.id', ''),
            uuid: _.get(state, 'area.value', ''),
            departments:
              _.get(state, 'area.department.department', '') ||
              _.get(state, 'area.department', '-'),
            station: _.get(objStation, 'station', '-'),
          },
          startDate: newStartDate,
          finishDate: newEndDate,
          workType: {
            id: _.get(workTypeObject, '[0]id', ''),
            uuid: _.get(workTypeObject, '[0]uuid', ''),
            workType: _.get(workTypeObject, '[0]workType', ''),
          },
        }
      }

      let subWorkTypeName = {}
      if (optionSubWorkType.length) {
        subWorkTypeName = optionSubWorkType.find((item) => {
          return item.uuid === _.get(state, 'activity.value', '')
        })
      }
      setStateForm((prevState) => ({
        ...prevState,
        holiday: {
          ...prevState.holiday,
          workType: workType,
          startDate: newStartDate,
          finishDate: newEndDate,
        },
        leave: {
          ...prevState.leave,
          startDate: newStartDate,
          finishDate: newEndDate,
          workType: workType,
        },
        academyActivity: {
          ...prevState.academyActivity,
          startDate: newStartDate,
          finishDate:
            _.get(subWorkTypeName, 'label', '') === 'Travelling'
              ? newStartDate
              : newEndDate,
          workType: workType,
          subWorkType: state.activity.value,
        },
        training,
        approveList: state.approveList,
      }))
    }

    if (!open) {
      setStateForm({
        holiday: {
          ...defaultStateForm.holiday,
          workType: workType,
          startDate: newStartDate,
          finishDate: newEndDate,
        },
        leave: {
          ...defaultStateForm.leave,
          startDate: newStartDate,
          finishDate: newEndDate,
          workType: workType,
        },
        academyActivity: {
          ...defaultStateForm.academyActivity,
          startDate: newStartDate,
          finishDate: newEndDate,
          workType: workType,
          subWorkType: state.activity.value,
        },
        training: {
          ...defaultStateForm.training,
          startDate: newStartDate,
          finishDate: newEndDate,
          workType: workType,
          subWorkType: state.activity.value,
        },
      })

      setValidateForm(defaultValidateForm)
    }
  }

  if (
    isDrawer.type === 'view' ||
    isDrawer.type === 'approved' ||
    isDrawer.type === 'waiting_approve_budget' ||
    isDrawer.type === 'rejected' ||
    isDrawer.type === 'request_edit' ||
    isDrawer.type === 'request_delete' ||
    isDrawer.type === 'request_create'
  ) {
    return !loading ? (
      <Drawer
        sx={{ height: '100vh' }}
        anchor={'right'}
        open={open}
        onClose={setIsDrawer}
      >
        <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
          <Box sx={{ zIndex: 1 }}>
            <View
              setStateForm={setStateForm}
              isDrawer={isDrawer}
              setIsDrawer={setIsDrawer}
              state={state}
              setState={setState}
              options={options}
              setIsLoading={setIsLoading}
              handleFetchEvents={handleFetchEvents}
              optionSubWorkType={optionSubWorkType}
              fetchApproveList={fetchApproveList}
            />
          </Box>
        </Box>
      </Drawer>
    ) : (
      <></>
    )
  } else if (isDrawer.type === 'edit' || isDrawer.type === 'add') {
    return !loading ? (
      <Drawer
        sx={{ height: '100vh' }}
        anchor={'right'}
        open={open}
        onClose={setIsDrawer}
      >
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Box>
            <Box sx={{ width: 1024 }}>
              <Box
                sx={{
                  display: 'flex',
                  position: 'sticky',
                  top: 0,
                  flexDirection: 'column',
                  backgroundColor: 'background.paper',
                  zIndex: 2,
                }}
              >
                <Typography sx={{ p: 3 }} variant="h5">
                  {isDrawer.type === 'add' && 'เพิ่มตารางงาน'}
                  {isDrawer.type === 'edit' && 'แก้ไขตารางงาน'}
                </Typography>
                <Divider />
              </Box>
              <Box sx={{ px: 3, py: 3 }}>
                <Box>
                  <>
                    {/* Holiday */}
                    {workType === WORK_WORK_LIST[0] && (
                      <Holiday
                        dataEvent={dataEvent}
                        type={isDrawer.type}
                        state={state}
                        optionsWork={options.work}
                        validateForm={validateForm}
                        setValidateForm={setValidateForm}
                        stateForm={stateForm}
                        setStateForm={setStateForm}
                        optionSubWorkType={optionSubWorkType}
                      />
                    )}
                    {/* Leave */}
                    {workType === WORK_WORK_LIST[1] && (
                      <DayLeave
                        user={user}
                        uuidStaff={_.get(newEvent, 'uuidStaff', null)}
                        dataEvent={dataEvent}
                        type={isDrawer.type}
                        state={state}
                        options={options}
                        validateForm={validateForm}
                        setValidateForm={setValidateForm}
                        stateForm={stateForm}
                        setStateForm={setStateForm}
                        optionSubWorkType={optionSubWorkType}
                      />
                    )}
                    {workType === WORK_WORK_LIST[2] && (
                      <AcademyActivity
                        user={user}
                        uuidStaff={_.get(newEvent, 'uuidStaff', null)}
                        type={isDrawer.type}
                        workType={workType}
                        state={state}
                        options={options}
                        setOptions={setOptions}
                        validateForm={validateForm}
                        setValidateForm={setValidateForm}
                        stateForm={stateForm}
                        setStateForm={setStateForm}
                        optionSubWorkType={optionSubWorkType}
                      />
                    )}
                    {(workType === WORK_WORK_LIST[3] ||
                      workType === WORK_WORK_LIST[4]) && (
                      <Training
                        type={isDrawer.type}
                        state={state}
                        workType={workType}
                        stateForm={stateForm.training}
                        setStateForm={setStateForm}
                        validateForm={validateForm.training}
                        setValidateForm={setValidateForm}
                        options={options}
                        optionSubWorkType={optionSubWorkType}
                        uuidStaff={_.get(newEvent, 'uuidStaff', null)}
                      />
                    )}
                  </>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 'auto',
            justifyContent: 'flex-end',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            position: 'sticky',
            bottom: 0,
            zIndex: 2,
            pr: 3,
            pb: 3,
            backgroundColor: 'background.paper',
            gap: 3,
          }}
        >
          <Button
            sx={{ minWidth: 100 }}
            variant="outlined"
            color={'primary'}
            onClick={() => {
              setIsDrawer({
                type: '',
                status: false,
                publicId: '',
              })
            }}
          >
            ยกเลิก
          </Button>
          <Button
            sx={{ minWidth: 100 }}
            variant="contained"
            onClick={() =>
              handleRequireFile({
                setDialog,
                stateForm,
                setValidateForm,
                workType,
                optionSubWorkType,
                masterPlanStatus: _.get(state, 'masterPlan.status', ''),
                drawerType: isDrawer.type,
              })
            }
            color={'primary'}
          >
            บันทึก
          </Button>
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={() => clearDialogAndClose({ setDialog, setStateForm })}
          title={dialog.title}
          variant={dialog.variant}
          isLoading={isLoading}
          onCancelClick={() => clearDialogAndClose({ setDialog, setStateForm })}
          onConfirmClick={() =>
            !dialog?.isError || false
              ? handleSubmit({
                  setDialog,
                  type: isDrawer.type,
                  state,
                  setIsDrawer,
                  stateForm,
                  setStateForm,
                  handleFetchEvents,
                  setIsLoading,
                  dataEvent,
                  workType,
                  options,
                  optionSubWorkType,
                  setState,
                })
              : handleCloseConfirmDialog(
                  dialog,
                  setDialog,
                  setIsDrawer,
                  setStateForm,
                  handleFetchEvents,
                )
          }
          content={dialog.content}
          maxWidth={dialog.maxWidth}
          agreeText={dialog.agreeText}
        />
      </Drawer>
    ) : (
      <></>
    )
  } else {
    return <></>
  }
}

export const fetchSubWorkType = async ({
  workType,
  options,
  setOptionSubWorkType,
  setLoading,
}) => {
  setLoading(true)

  let subWorkType = []
  if (workType !== WORK_WORK_LIST[3] && workType !== WORK_WORK_LIST[4]) {
    const objWorkType = options.find((item) => {
      return item.workType === workType
    })
    subWorkType = await getSubWorkType(_.get(objWorkType, 'uuid', ''))
  }
  setOptionSubWorkType(subWorkType)
  setLoading(false)
}

export const handleRequireFile = ({
  setDialog,
  stateForm,
  setValidateForm,
  workType,
  optionSubWorkType,
  masterPlanStatus,
  drawerType,
}) => {
  let ignoredKey = []
  let mainStatus = false
  let ignoredExpand = false

  if (workType === WORK_WORK_LIST[0]) {
    const { result, status } = requireFile(
      stateForm.holiday,
      MESSAGE_HOLIDAY,
      ignoredKey,
      ignoredExpand,
    )

    if (!status) {
      setValidateForm((prevState) => ({
        ...prevState,
        holiday: result,
      }))
    }
    mainStatus = status
  }

  if (workType === WORK_WORK_LIST[1]) {
    const subWorkType = optionSubWorkType?.find((item) => {
      return item.uuid === _.get(stateForm, 'leave.subWorkType', '')
    })

    ignoredKey = ['remarkLeave']
    if (_.get(subWorkType, 'label', '') === 'Other Leave') ignoredKey = []

    const { result, status } = requireFile(
      stateForm.leave,
      MESSAGE_LEAVE,
      ignoredKey,
      ignoredExpand,
    )

    if (!status) {
      setValidateForm((prevState) => ({
        ...prevState,
        leave: result,
      }))
    }
    mainStatus = status
  }

  if (workType === WORK_WORK_LIST[2]) {
    const meetingType = _.get(stateForm, 'academyActivity.meetingType')
    const findSubWorkType = optionSubWorkType.find((item) => {
      return item.uuid === _.get(stateForm, 'academyActivity.subWorkType', '')
    })

    const nameSubWorkType = _.get(findSubWorkType, 'subWorkType', '')

    if (
      nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1] ||
      nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2] ||
      nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]
    ) {
      ignoredKey = ['course', 'attendees']
      if (meetingType === 'onsite') {
        ignoredKey = [...ignoredKey, 'toMeetingRoom']
      }
      setValidateForm((prevState) => ({
        ...prevState,
        academyActivity: {
          speaker: false,
        },
      }))
    }

    if (
      nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6] ||
      nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]
    ) {
      ignoredKey = ['travelType', 'course']
      if (meetingType === 'onsite') {
        ignoredKey = [...ignoredKey, 'attendees', 'toMeetingRoom']
      } else {
        //online
        ignoredKey = [...ignoredKey, 'attendees']
      }
    }
    if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
      ignoredKey = [
        'description',
        'title',
        'travelType',
        'toMeetingRoom',
        'course',
      ]
    }

    if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
      ignoredKey = ['title', 'toMeetingRoom', 'course']
    }

    const { result, status } = requireFile(
      stateForm.academyActivity,
      MESSAGE_ACADEMY_ACTIVITY,
      ignoredKey,
      ignoredExpand,
      nameSubWorkType,
    )

    if (!status) {
      setValidateForm((prevState) => ({
        ...prevState,
        academyActivity: result,
      }))
    }
    mainStatus = status
  }
  if (workType === WORK_WORK_LIST[3] || workType === WORK_WORK_LIST[4]) {
    const valueCheckSpeaker = checkSpeaker({
      listValueDate: _.get(stateForm, 'training.dates', []),
      isTrining: true,
    })
    if (valueCheckSpeaker) {
      setValidateForm((prevState) => ({
        ...prevState,
        training: {
          speaker: true,
        },
      }))
      return true
    }

    if (workType === WORK_WORK_LIST[3]) {
      ignoredKey = [
        'area',
        'break',
        'courseOther',
        'dateBetween',
        'remarkOther',
        'distributionChannel',
        'trainingPlatform',
      ]
      if (!_.get(stateForm, 'training.break', false)) {
        ignoredKey = [...ignoredKey, 'cost', 'remarkCost']
      }
    } else {
      ignoredKey = [
        'area',
        'course',
        'break',
        'dateBetween',
        'remarkOther',
        'expectNumberPeople',
        'classroom',
      ]
      if (!_.get(stateForm, 'training.break', false)) {
        ignoredKey = [...ignoredKey, 'cost', 'remarkCost']
      }
    }

    const { result, status } = requireFile(
      stateForm.training,
      MESSAGE_ACADEMY_TRAINING,
      ignoredKey,
      ignoredExpand,
    )

    setValidateForm((prevState) => ({
      ...prevState,
      training: result,
    }))
    mainStatus = status
  }

  if (mainStatus) {
    setDialog({
      open: true,
      variant: 'save',
      title:
        masterPlanStatus?.includes('APPROVED') && drawerType === 'add'
          ? 'กรอกข้อมูลเรียบร้อย'
          : 'ยืนยันบันทึกข้อมูล',
      content:
        masterPlanStatus?.includes('APPROVED') && drawerType === 'add'
          ? 'ต้องการส่งข้อมูลไปขั้นตอนต่อไปหรือไม่'
          : 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      isError: false,
      agreeText:
        masterPlanStatus?.includes('APPROVED') && drawerType === 'add'
          ? 'ตกลง'
          : 'บันทึก',
    })
  }
}

export const handleRequireFileEditSpeaker = () => {}

export const handleSubmit = async ({
  setDialog,
  type,
  state,
  setIsDrawer,
  stateForm,
  setStateForm,
  handleFetchEvents,
  setIsLoading,
  dataEvent,
  workType,
  options,
  optionSubWorkType,
  setState,
}) => {
  if (type === 'add') {
    await handleAddCalendar({
      state,
      setIsDrawer,
      stateForm,
      setStateForm,
      handleFetchEvents,
      setIsLoading,
      options,
      optionSubWorkType,
      setDialog,
      setState,
    })
  }
  if (type === 'edit') {
    await handleEditCalendar({
      state,
      dataEvent,
      workType,
      setIsDrawer,
      stateForm,
      setStateForm,
      handleFetchEvents,
      setIsLoading,
      options,
      optionSubWorkType,
      setDialog,
      setState,
    })
  }
}

export const clearDialogAndClose = ({ setDialog, setStateForm }) => {
  setDialog((prev) => ({
    ...prev,
    open: false,
  }))
  setStateForm((prev) => ({
    ...prev,
    training: {
      ...prev.training,
      remarkEditDate: '',
    },
  }))
}

export const handleCloseConfirmDialog = (
  dialog,
  setDialog,
  setIsDrawer,
  setStateForm,
  handleFetchEvents,
) => {
  setDialog({ ...dialog, open: false })
  if (dialog.variant !== 'fail') {
    setIsDrawer({ type: '', status: false, publicId: '' })
    setStateForm(defaultStateForm)
    handleFetchEvents()
  }
}
