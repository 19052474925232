import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { fetchTrainingPlatform } from './../../../services/manageClass'
import { initialState } from './model'

const manageClass = createSlice({
  name: 'manageClass',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `${key}`, value)
    },
    setFetchStation: (state, { payload }) => {
      state.station = 'all'
      state.listStation = [
        {
          label: 'All',
          value: 'all',
        },
        ...payload,
      ]
    },
    setTotalAllClass: (state, { payload }) => {
      state.totalAllClass = payload
    },
    setIsOpenManageAdminDrawer: (state, { payload }) => {
      state.isOpenManageAdminDrawer = payload
    },
    startLoadingManageAdminDrawer: (state) => {
      state.isLoadingManageAdminDrawer = true
    },
    stopLoadingManageAdminDrawer: (state) => {
      state.isLoadingManageAdminDrawer = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrainingPlatform.fulfilled, (state, { payload }) => {
        state.listTrainingPlan = _.get(payload, 'data.result', []).map(
          (item) => ({
            label: item.trainingPlatform,
            name: _.camelCase(item.trainingPlatform) + 'Checked',
            value: item.uuid,
          }),
        )
      })
      .addCase(fetchTrainingPlatform.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setChange,
  setFetchStation,
  setTotalAllClass,
  setIsOpenManageAdminDrawer,
  startLoadingManageAdminDrawer,
  stopLoadingManageAdminDrawer,
} = manageClass.actions

export default manageClass.reducer
