import { Box } from '@mui/material'
import { Column, LoadingPage } from '../../Styled'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { CourseInfoWithAdminSelect } from '../CourseInfoWithAdminSelect'
import { TrainingRoomsByDate } from '../TrainingRoomsByDate'

const Content = () => {
  const { data, isLoadingManageAdminDrawer } = useSelector(
    (state) => ({
      data: state.manageClassForm.data,
      isLoadingManageAdminDrawer: state.manageClass.isLoadingManageAdminDrawer,
    }),
    shallowEqual,
  )

  const dates = _.get(data, 'monthlyPlan.dates', [])

  return (
    <Box>
      <Box
        sx={{
          overflowY: 'auto',
          opacity: isLoadingManageAdminDrawer ? 0.4 : 'unset',
          pointerEvents: isLoadingManageAdminDrawer ? 'none' : 'unset',
        }}
        height="100%"
        p={3}
      >
        <CourseInfoWithAdminSelect />
        <Column gap={1}>
          {_.map(dates, (date, idxD) => {
            return <TrainingRoomsByDate key={idxD} idxD={idxD} {...date} />
          })}
        </Column>
      </Box>
      <LoadingPage isLoading={isLoadingManageAdminDrawer} />
    </Box>
  )
}

export default Content
