import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import { StatusComponent } from './components/StatusComponent'
import { Typography, Box, Button } from '@mui/material'
import _ from 'lodash'
import { tabTypeTxtEnum, tabTypeEnum } from './enum/requisitionTypeEnum'
import { statusValueEnum } from './enum/statusEnum'
import {
  convertFormatDateTime,
  validatePermission,
} from '../../../../utils/lib'
import {
  INVENTORY_SETTING,
  RequisitionStatusColor,
  STOCK_PERMISSION,
} from '../../../../constants/stock'
import { handleSendMailReturnProduct } from './handler/handleSendMailReturnProduct'
import { useState } from 'react'
import DialogShowImages from './components/DialogShowImages'

export const ViewContent = () => {
  const { formData, isDisabledEmailButton, hasSentMailToday } = useSelector(
    (state) => state.crud,
  )
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionInventory = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  const hasPermissionAdmin = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const isSameUser = user.uuid === _.get(formData, 'createdByInfo.uuid')
  let statusDotsx = {}
  if (formData?.status && RequisitionStatusColor?.[formData?.status]) {
    statusDotsx = {
      ...statusDotsx,
      backgroundColor: RequisitionStatusColor?.[formData?.status],
    }
  }
  const responsibility = `${formData?.responsibility?.firstNameTH ?? ''} ${
    formData?.responsibility?.lastNameTH ?? ''
  }`

  const [openDialogImages, setOpenDialogImages] = useState(false)
  const [imgSelected, setImgSelected] = useState(0)

  const getAttachFiles = () => {
    const files = _.defaultTo(formData?.attachFiles, [])
    if (!files?.length) {
      return '-'
    }
    return files.map((item, index) => (
      <Box
        sx={{
          boxShadow: '1px 0px 5px 0px rgba(81, 81, 81, 0.30)',
          cursor: 'pointer',
          ':hover': {
            transform: 'scale(1.15)',
          },
        }}
        key={index}
      >
        <img
          onClick={() => {
            setOpenDialogImages(true)
            setImgSelected(index)
          }}
          src={`${window.__env__.REACT_APP_API_URL}/file/get${item.key}`}
          width={'100%'}
          height={'100%'}
        />
      </Box>
    ))
  }
  return (
    <>
      <Typography variant="h6" mt={3}>
        ข้อมูลพื้นฐาน
      </Typography>
      <Box pl={3}>
        <Box display="flex" mb={1}>
          <ViewRow
            boxSx={{ width: { sx: '100%', md: '50%' } }}
            titleSx={{ color: '#5F5F5F' }}
            variant="body1."
            title="Ticket ID"
            value={formData.id ?? '-'}
          />
          <ViewRow
            boxSx={{ width: { sx: '100%', md: '50%' } }}
            variant="body1"
            titleSx={{ color: '#5F5F5F' }}
            title="ประเภทรายการเบิก"
            value={tabTypeTxtEnum?.[formData?.requisitionType] ?? '-'}
          />
        </Box>
        {formData?.requisitionType !== tabTypeEnum.OTHER && (
          <>
            <Box display="flex" mb={1}>
              <ViewRow
                boxSx={{ width: { sx: '100%', md: '50%' } }}
                variant="body1"
                titleSx={{ color: '#5F5F5F' }}
                title="วันที่อบรม"
                value={
                  formData?.trainingPlan?.startDate
                    ? convertFormatDateTime({
                        value: formData?.trainingPlan?.startDate,
                        type: 'date',
                      })
                    : '-'
                }
              />
              <ViewRow
                boxSx={{ width: { sx: '100%', md: '50%' } }}
                variant="body1"
                titleSx={{ color: '#5F5F5F' }}
                title="ชื่อคอร์ส"
                value={
                  formData?.trainingPlan ? formData?.trainingPlan?.label : '-'
                }
              />
            </Box>
          </>
        )}

        <Box display="flex" mb={1}>
          <ViewRow
            boxSx={{ width: { sx: '100%', md: '50%' } }}
            variant="body1"
            titleSx={{ color: '#5F5F5F' }}
            title="คลังทรัพย์สิน"
            value={_.get(formData, 'warehouse.warehouseName', '-')}
          />
          {formData?.requisitionType !== tabTypeEnum.OTHER && (
            <ViewRow
              boxSx={{ width: { sx: '100%', md: '50%' } }}
              variant="body1"
              titleSx={{ color: '#5F5F5F' }}
              title="ผู้รับผิดชอบ"
              value={formData?.responsibility ? responsibility : '-'}
            />
          )}
        </Box>
        <ViewRow
          boxSx={{ display: 'flex', flexDirection: 'column', my: 2 }}
          variant="body2"
          titleSx={{ color: '#5F5F5F' }}
          title="หมายเหตุ"
          value={_.get(formData, 'annotation', '-')}
        />
        <Box display="flex" mb={1} alignItems="start" my={2}>
          <ViewRow
            variant="body1"
            title="สถานะ"
            valueComponent={StatusComponent(formData, statusDotsx)}
            boxSx={{ width: { sx: '100%', md: '50%' } }}
          />
          <Box sx={{ width: { sx: '100%', md: '50%' } }}>
            {formData?.status === statusValueEnum.WAITING_RETURN &&
              formData?.rejectReason && (
                <ViewRow
                  variant="body1"
                  titleSx={{ color: '#5F5F5F' }}
                  title="เหตุผลการปฏิเสธคืน"
                  value={formData?.rejectReason ? formData?.rejectReason : '-'}
                  customTextSx={{
                    color: (theme) => theme.palette?.text?.error,
                  }}
                />
              )}
            {formData?.lateReturnReasonTxt && (
              <ViewRow
                variant="body1"
                titleSx={{ color: '#5F5F5F' }}
                title="เหตุผลคืนทรัพย์สินเกินเวลา"
                value={
                  formData?.lateReturnReasonTxt
                    ? formData?.lateReturnReasonTxt
                    : '-'
                }
                customTextSx={{ color: (theme) => theme.palette?.text?.error }}
              />
            )}
          </Box>
        </Box>
        {hasPermissionInventory &&
          (!isSameUser || hasPermissionAdmin) &&
          formData?.status === statusValueEnum.WAITING_RETURN && (
            <Box display="flex" gap={2} alignItems={'center'} mb={2}>
              <Button
                variant="contained"
                onClick={handleSendMailReturnProduct}
                disabled={isDisabledEmailButton}
              >
                ส่ง E-mail แจ้งคืนของ
              </Button>
              {hasSentMailToday && (
                <Typography>
                  คำสั่งได้ถูกใช้แล้ว กรุณาลองใหม่วันถัดไป
                </Typography>
              )}
            </Box>
          )}
        {formData?.requisitionType === tabTypeEnum.OTHER && (
          <ViewRow
            variant="body1"
            title="รูปภาพประกอบการยืม"
            valueComponent={getAttachFiles()}
            boxSx={{ my: 2, width: { sx: '100%', md: '50%' } }}
          />
        )}
      </Box>
      <DialogShowImages
        isOpen={openDialogImages}
        setOpen={setOpenDialogImages}
        imgSelected={imgSelected}
      />
    </>
  )
}
