import React from 'react'
import Transaction from '../components/Transaction'
import AllContent from './AllContent'
import { Box } from '@mui/material'
import Ranking from './Ranking'
import WaitingTable from './WaitingTable'
import DueTable from './DueTable'
import { Row } from './Styled'

const index = () => {
  return (
    <Box sx={{ display: 'grid', gap: '24px' }}>
      <Row>
        <AllContent />
        <img
          src="/image/inventoryImage.svg"
          style={{ position: 'relative', top: '30%', left: '30%' }}
        />
      </Row>
      <Transaction />
      <Ranking />
      <WaitingTable />
      <DueTable />
    </Box>
  )
}

export default index
