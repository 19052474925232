import React from 'react'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import Truncate from '../../redux/Table/Truncate'
import ActionDetail from './Action/ActionDetail/ActionDetail'
import ActionResult from './Action/ActionResult/ActionResult'
import ActionResultExam from './Action/ActionExam/ActionResultExam'
import ActionStatusExam from './Action/ActionExam/ActionStatusExam'
import { StyledTableCell } from './Styled'
import _ from 'lodash'
import { Box, TableCell, Typography } from '@mui/material'
import {
  RequisitionStatus,
  RequisitionStatusColor,
  RequisitionStatusText,
} from '../../../constants/stock'

export const checkConditionView = (
  headCells,
  row,
  rowIndex,
  cellIndex,
  startLoading,
  stopLoading,
) => {
  const columnKey = headCells[cellIndex].id
  const colStatus = columnKey === 'status' ? 150 : columnKey === 'no' ? 55 : 100

  const width = headCells[cellIndex].width
  const minWidth = columnKey === 'name' ? 380 : width ? width : colStatus
  const sx = GetSxStyle(row, minWidth)
  if (columnKey === 'action') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionDetail row={row} condition={headCells[cellIndex]} />
      </StyledTableCell>
    )
  } else if (columnKey === 'result') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionResult
          row={row}
          condition={headCells[cellIndex]}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </StyledTableCell>
    )
  } else if (columnKey === 'examStatus') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionStatusExam
          row={row}
          condition={headCells[cellIndex]}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </StyledTableCell>
    )
  } else if (columnKey === 'examResult') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionResultExam
          row={row}
          condition={headCells[cellIndex]}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </StyledTableCell>
    )
  } else if (columnKey === 'custom') {
    return <StyledTableCell key={columnKey}>{row.custom}</StyledTableCell>
  } else if (columnKey === 'requisitionStatus') {
    return (
      <TableCell key={columnKey}>
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          color={RequisitionStatusColor?.[row?.status]}
        >
          {RequisitionStatusText?.[row?.status] ? (
            <>{' ' + RequisitionStatusText?.[row?.status]}</>
          ) : (
            row?.status
          )}
          {row?.status === RequisitionStatus.COMPLETED_WITH_CONDITION && (
            <Typography sx={{ ml: 0.5 }} color={'error'}>
              *
            </Typography>
          )}
        </Box>
      </TableCell>
    )
  } else if (columnKey === 'createdByName') {
    return (
      <StyledTableCell key={columnKey}>{row.createdBy.name}</StyledTableCell>
    )
  } else if (
    columnKey === 'returnDate' &&
    typeof _.get(row, `returnDate`) === 'object'
  ) {
    {
      return (
        <TableCell key={columnKey}>
          <Typography color={_.get(row, `returnDate`)?.returnDateColor ?? ''}>
            {_.isEmpty(_.get(row, `returnDate.returnDate`))
              ? '-'
              : _.get(row, `returnDate.returnDate`)}
          </Typography>
        </TableCell>
      )
    }
  }

  const value = isNil(row[columnKey]) ? '-' : `${get(row, columnKey, '-')}`
  return (
    <StyledTableCell key={columnKey} sx={sx}>
      <Truncate>{_.isEmpty(value) ? '-' : value}</Truncate>
    </StyledTableCell>
  )
}

export const GetSxStyle = (row, minWidth) => {
  const status = get(row, 'status', '')

  if ((status === 'DELETED' || status === 'INACTIVE') && !isEmpty()) {
    return { minWidth, color: 'text.gray' }
  }
  if (status === 'DELETED' || status === 'CANCELED') {
    return { minWidth, color: 'text.gray' }
  } else {
    return { minWidth }
  }
}
