import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/inventory/dashboard'
import { resetRowsPerPage, setSearchText } from '../../../../redux/slices/table'

export const resetDrawerCheckbox = (key, objValue) => {
  store.dispatch(
    setFieldValue({
      key: key,
      value: objValue,
    }),
  )
}

export const toggleFiltersDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `filtersDrawer.isOpen`,
      value: value,
    }),
  )
}

export const toggleFiltersReqDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `filtersReqDrawer.isOpen`,
      value: value,
    }),
  )
}

export const clearDrawerTable = () => {
  store.dispatch(setSearchText(''))
  store.dispatch(resetRowsPerPage())
}
