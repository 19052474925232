import { Box, Drawer, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root': {
    width: 636,
    height: '100vh',
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  height: 72,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& .MuiIconButton-root': { padding: 0 },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(3),
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: 144 },
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
}))

export const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
}))

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))
