import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TransactionCard from '../ModuleCard/TransactionCard'
import { transactionModel } from './overview'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import AssetCard from '../ModuleCard/AssetCard'
import { openTableDrawer } from '../../../../../redux/slices/inventory/dashboard'
import { useDispatch } from 'react-redux'

const index = () => {
  const { inventoryProperty } = useSelector(
    (state) => ({
      inventoryProperty: state.inventoryDashboard.overview.inventoryProperty,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const transaction = transactionModel()
  const transactionData = [
    {
      label: 'จำนวนทั้งหมด',
      number: inventoryProperty?.quantity ?? 0,
      unit: 'รายการ',
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        paddingRight: '8px',
        marginRight: '8px',
      },
    },
    {
      label: 'มูลค่ารวม',
      number: inventoryProperty?.quantityPrice ?? 0,
      unit: 'บาท',
      sxBox: '',
    },
  ]
  return (
    <Box height={'100%'}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">จำแนกทรัพย์สิน</Typography>
          </Box>
          <Box>
            <TransactionCard
              label={''}
              labelVariant={'body2'}
              background={''}
              dotColor={''}
              border={'none'}
              // dataSx={{}}
              dataList={transactionData}
              boxSx={{ padding: '0px' }}
            />
          </Box>
          <Box display="flex" gap={2} mt={3} alignItems="center">
            <Box
              display="flex"
              flexDirection="row"
              gap={'4px'}
              sx={{ flexWrap: 'wrap', width: '100%' }}
            >
              {transaction.map((item, index) => {
                if (item.type === 'asset') {
                  return (
                    <AssetCard
                      key={item.value}
                      label={item.label}
                      labelVariant={item.labelVariant}
                      background={item.background}
                      dotColor={item.dotColor}
                      boxSx={{ width: item.width }}
                      // dataSx={{}}
                      dataList={_.defaultTo(item.dataList, [])}
                      handleClickButton={() => {}}
                      index={index}
                    />
                  )
                } else {
                  return (
                    <TransactionCard
                      key={item.value}
                      label={item.label}
                      labelVariant={item.labelVariant}
                      background={item.background}
                      dotColor={item.dotColor}
                      boxSx={{ width: item.width }}
                      // dataSx={{}}
                      dataList={_.defaultTo(item.dataList, [])}
                      handleClickButton={() => {
                        dispatch(
                          openTableDrawer({
                            header: item.label,
                            drawerType: 'formData',
                            courseType: 'inventory',
                          }),
                        )
                      }}
                    />
                  )
                }
              })}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default index
