import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import _ from 'lodash'
import CourseDrawer from './CourseDrawer'
import SearchCustom from '../Search'
import { BoxHeder, BoxGroup } from './Styled'
import { fetchCourse } from './events'
import { onSort } from '../../../../../utils/lib'
import { headerCells } from './headerCells'
import { StyledButton } from '../Styled'
import { ChevronRight } from '@mui/icons-material'
import { CRUDListFilterPath } from '../../../../../utils/apiPath'
import { inventoryRequisitionBody } from '../TableDrawer/model'
import {
  openTableDrawer,
  setFieldValue,
} from '../../../../../redux/slices/inventory/dashboard'
import { LoadingCircular } from '../TableDrawer/Styled'
import { defaultReqFilter } from '../../model/defaultFilter'

const WaitingTable = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const { searchText, sort, order, data, isLoading } = useSelector(
    (state) => ({
      searchText: state.inventoryDashboard?.waitingTable?.searchText,
      sort: state.inventoryDashboard?.waitingTable?.sort,
      order: state.inventoryDashboard?.waitingTable?.order,
      data: state.inventoryDashboard?.waitingTable?.data,
      isLoading: state.inventoryDashboard?.waitingTable?.isLoading,
    }),
    shallowEqual,
  )

  const [head, setHead] = useState(headerCells())
  let bodyProps = {
    searchText: searchText ?? '',
    sort: sort ?? 'updatedAt',
    order: order ?? 'DESC',
    page: 1,
  }

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    let sort = _.isEmpty(order) ? 'updatedAt' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    switch (sort) {
      case 'trainingPlanCourse':
        sort = 'trainingPlan.label'
        break
      case 'trainingPlanDate':
        sort = 'trainingPlan.startDate'
        break
      case 'requisitionStatus':
        sort = 'status'
        break
      case 'createdByName':
        sort = 'createdBy'
        break
      case 'ticketType':
        sort = 'updatedAt'
        break
      default:
        break
    }
    bodyProps = { ...bodyProps, sort: sort }
    dispatch(
      setFieldValue({
        key: 'waitingTable.sort',
        value: sort,
      }),
    )
    dispatch(
      setFieldValue({
        key: 'waitingTable.order',
        value: orderBy,
      }),
    )
    dispatch(
      fetchCourse(
        CRUDListFilterPath,
        inventoryRequisitionBody(bodyProps),
        {
          'x-type': 'INVENTORY_REQUISITION',
        },
        'waitingTable.data',
      ),
    )
    setHead(head)
  }

  useEffect(() => {
    dispatch(
      fetchCourse(
        CRUDListFilterPath,
        inventoryRequisitionBody(bodyProps),
        {
          'x-type': 'INVENTORY_REQUISITION',
        },
        'waitingTable.data',
      ),
    )
  }, [searchText, sort, order])

  return (
    <>
      <Card
        sx={{
          pb: 3,
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <BoxHeder>
          <BoxGroup sx={{ alignItems: 'center' }}>
            <Typography
              variant="h6"
              fontWeight={'light'}
              sx={{ display: 'flex' }}
            >
              รายการทรัพย์สิน
              <Typography variant="h6"> รอคืน</Typography>
            </Typography>
            <StyledButton
              color="text.lightGray"
              size="small"
              background="#4976BA14"
              marginLeft={''}
              sx={{ width: '28px', height: '28px' }}
              onClick={() => {
                dispatch(
                  setFieldValue({ key: 'filterReq', value: defaultReqFilter }),
                )
                dispatch(setFieldValue({ key: 'filterReqMemo', value: {} }))
                dispatch(
                  setFieldValue({
                    key: 'filtersReqDrawer.filterTotal',
                    value: false,
                  }),
                )
                dispatch(
                  setFieldValue({
                    key: 'filtersReqDrawer.isFilterStation',
                    value: false,
                  }),
                )
                dispatch(
                  openTableDrawer({
                    header: 'รายการทรัพย์สิน รอคืน',
                    drawerType: 'table',
                    courseType: 'requisition',
                  }),
                )
              }}
            >
              <ChevronRight fontSize="small" />
            </StyledButton>
          </BoxGroup>
          <BoxGroup>
            <SearchCustom searchKey="waitingTable" placeholder="ค้นหา" />
          </BoxGroup>
        </BoxHeder>
        <NormalTable
          headCells={head}
          listRows={_.get(data, 'result', [])}
          totalRows={_.get(data, 'totalCount', 0)}
          showPagination={false}
          isHiddenOptions
          onRequestSort={onRequestSort}
          isLoading={isLoading}
        />
        <LoadingCircular isLoading={isLoading} />
      </Card>
      <CourseDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </>
  )
}

export default WaitingTable
