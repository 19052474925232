import { examinationStudentDownload } from '../../../utils/apiPath'
import { headCellsExaminer } from '../../../constants/eExamination'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import { initialState } from '../../../redux/slices/table/model'
import _ from 'lodash'
import {
  setExaminerList,
  setAnnouncement,
  setInitialExaminerList,
  setRefreshExaminerList,
  setOtherDocConfig,
} from '../../../redux/slices/eExamination/list'
import { STUDENT_STATUS_TEXT } from '../../../constants/eExamination'
import {
  checkOtherDocConfig,
  deleteExaminer,
} from '../../../services/eExamination'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import {
  STUDENT_STATUS,
  TEST_RESULT_STATUS,
  TEST_RESULT_STATUS_TEXT,
} from '../../../constants/eExamination'
import { handleEligibleExaminer } from './Header/events'
import callAxios from '../../../utils/baseService'
import { examinationStudentFilter } from '../../../utils/apiPath'
import { validatePermission } from '../../../utils/lib'
import { EXAMINATION_SETTING, PERMISSION } from '../../../constants/examination'

export const onDownload = (selected, sort, order, uuid) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    uuid: uuid,
    list: selected,
    type: 'CUSTOM',
    order: order.toUpperCase(),
    sort: sort,
  }

  await dispatch(
    downloadFile({
      url: examinationStudentDownload,
      body: body,
      fileName: `รายการผู้สอบ.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchExaminerListFilter = (props) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(setRefreshExaminerList(false))
  const {
    uuid,
    method,
    table,
    statusList,
    filterProp,
    page,
    keyword,
    examinerPermissions,
  } = props
  // const pageNumber = page === -1 ? 1 : page
  const isInitial = method == 'initial'
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial ? null : filterProp
  const search = isInitial || method == 'filter' ? '' : keyword
  const headCells = headCellsExaminer
  const checkSortValue = _.find(headCells, (h) => {
    return h.id === _.get(tableProps, 'sort')
  })
  const body = {
    uuid,
    search: _.defaultTo(search, ''), //ค้นหา
    userCode: _.get(filter, 'userCode', ''), //รหัส 6 หลัก
    firstNameTH: _.get(filter, 'firstNameTH', ''), //ชื่อ
    lastNameTH: _.get(filter, 'lastNameTH', ''), //นามสกุล
    unitName: _.get(filter, 'unitName', ''), //ชื่อหน่วยงาน
    unitCode: _.get(filter, 'unitCode', ''), //รหัสหน่วยงาน
    status: _.get(filter, 'status', []), // สถานะ WAITING_CHECK,WAITING_ANNOUNCEMENT,ANNOUNCEMENT,PERMISSION_DENIED,CANCEL
    result: _.get(filter, 'result', []), // สถานะ PASS,NOT_PASS,ABSENCE
    limit: _.get(tableProps, 'limit', '100'),
    order: _.get(tableProps, 'order', 'DESC').toString().toUpperCase(),
    page: _.defaultTo(page, 1),
    sort: _.isEmpty(checkSortValue)
      ? 'updatedAt'
      : _.get(tableProps, 'sort', 'updatedAt'),
  }

  await callAxios
    .post(examinationStudentFilter, body)
    .then(({ data }) => {
      let results = _.get(data, 'result', [])
      const totalCount = _.get(data, 'totalCount', 0)
      let startNumber = 1
      const orderId = body.sort === 'order' && body.order === 'DESC'
      if (orderId) {
        startNumber = totalCount
      }
      results = _.map(results, (item) => {
        const status = _.get(item, 'status')
        const getOrder = () => {
          if (orderId) {
            return startNumber--
          }
          return startNumber++
        }
        return {
          ...item,
          order: getOrder(),
          examinerStatus: STUDENT_STATUS_TEXT[_.get(item, 'status')],
          statusStudent: _.get(item, 'status'),
          status: [
            'CANCEL',
            'CANCEL_BY_SELF',
            'CANCEL_BY_ADMIN',
            'CANCEL_EXAMINATION',
            'CANCEL_BY_MANAGER',
          ].includes(status)
            ? 'INACTIVE'
            : 'ACTIVE',
          userCode: _.get(item, 'userCode', '-'),
          firstNameTH: _.get(item, 'firstNameTH', '-'),
          lastNameTH: _.get(item, 'lastNameTH', '-'),
          unitNumber: _.isEmpty(_.get(item, 'unitNumber'))
            ? '-'
            : _.get(item, 'unitNumber', '-'),
          mobileNo: _.get(item, 'mobileNo', '-'),
          examResult: _.isEmpty(_.get(item, 'result'))
            ? '-'
            : TEST_RESULT_STATUS_TEXT[_.get(item, 'result', '-')],
        }
      })
      dispatch(setExaminerList(results))

      dispatch(
        setAnnouncement({
          scheduleName: _.get(data, 'scheduleName', ''),
          annotation: _.get(data, 'annotation', ''),
          announce: _.get(data, 'announce', 0),
          isEnableScheduleName: _.get(data, 'isEnableScheduleName'),
          isEnableAnounce: _.get(data, 'isEnableAnounce'),
          scheduleStatus: _.get(data, 'scheduleStatus'),
          isUploadResult: _.get(data, 'isUploadResult'),
        }),
      )

      const user = JSON.parse(localStorage.getItem('user'))
      const hasViewPermission = validatePermission({
        user,
        moduleType: EXAMINATION_SETTING,
        permission: [PERMISSION.VIEW],
      })
      const hasDCPermission = validatePermission({
        user,
        moduleType: EXAMINATION_SETTING,
        permission: [PERMISSION.DC],
      })
      const actionObj = {
        hideView: hasViewPermission ? false : true,
        hideEdit: hasDCPermission ? false : true,
        hideDelete: hasDCPermission ? false : true,
        hideEligibleExam: hasDCPermission ? false : true,
      }
      const actionExamIndex = headCells.findIndex((x) => x.id === 'actionExam')
      _.assign(headCells[actionExamIndex], actionObj)
      let handleSearch = (text) =>
        dispatch(
          handleQuickSearch(
            uuid,
            tableProps,
            text,
            statusList,
            filterProp,
            examinerPermissions,
          ),
        )
      if (actionObj?.hideView) handleSearch = ''
      let onDownloadFunc = (selected, sort, order) =>
        dispatch(onDownload(selected, sort, order, uuid))
      if (actionObj?.hideView) onDownloadFunc = ''
      let onEligibleExamFunc = () => dispatch(handleEligibleExaminer())
      if (actionObj?.hideEligibleExam) onEligibleExamFunc = ''
      dispatch(
        setInitialTable({
          rows: results,
          allCount: totalCount,
          headCells,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: statusList,
          handleSearch: handleSearch,
          onDownload: onDownloadFunc,
          onEligibleExam: onEligibleExamFunc,
          onDelete: (row) =>
            dispatch(
              handleDeleteModule(uuid, row, tableProps, examinerPermissions),
            ),
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  isInitial && dispatch(setInitialExaminerList(false))
  dispatch(stopLoading())
}

export const onFilterClick =
  (uuid, table, filter, examinerPermissions) => (dispatch) => {
    dispatch(setSearchText(''))
    const statusList = []
    const testResultList = []

    if (filter.statusChecked) {
      if (filter.status.waitingCheckChecked) {
        statusList.push(STUDENT_STATUS.WAITING_CHECK)
      }
      if (filter.status.waitingAnnounceChecked) {
        statusList.push(STUDENT_STATUS.WAITING_ANNOUNCEMENT)
      }
      if (filter.status.announcementChecked) {
        statusList.push(STUDENT_STATUS.ANNOUNCEMENT)
      }
      if (filter.status.permissionDeniedChecked) {
        statusList.push(STUDENT_STATUS.PERMISSION_DENIED)
      }
      if (filter.status.cancelChecked) {
        statusList.push(STUDENT_STATUS.CANCEL)
      }
    }

    if (filter.testResultChecked) {
      if (filter.testResult.passChecked) {
        testResultList.push(TEST_RESULT_STATUS.PASS)
      }
      if (filter.testResult.notPassChecked) {
        testResultList.push(TEST_RESULT_STATUS.NOT_PASS)
      }
      if (filter.testResult.absentChecked) {
        testResultList.push(TEST_RESULT_STATUS.ABSENCE)
      }
    }
    dispatch(setStatusFilter(statusList))

    const filterProp = {
      uuid,
      search: '', //ค้นหา
      userCode: filter.agentCodeChecked ? _.get(filter, 'agentCode', '') : '', //รหัส 6 หลัก
      firstNameTH: filter.firstNameTHChecked
        ? _.get(filter, 'firstNameTH', '')
        : '', //ชื่อ
      lastNameTH: filter.lastNameTHChecked
        ? _.get(filter, 'lastNameTH', '')
        : '', //นามสกุล
      unitName: filter.unitNameChecked ? _.get(filter, 'unitName', '') : '', //ชื่อหน่วยงาน
      unitCode: filter.unitCodeChecked ? _.get(filter, 'unitCode', '') : '', //รหัสหน่วยงาน
      status: statusList, // สถานะ WAITING_CHECK,WAITING_ANNOUNCEMENT,ANNOUNCEMENT,PERMISSION_DENIED,CANCEL
      result: testResultList, // สถานะ PASS,NOT_PASS,ABSENCE
    }

    dispatch(setFilterProp(filterProp))
    dispatch(setFilterTotal(filter))
    dispatch(resetTablePage())
    dispatch(
      fetchExaminerListFilter({
        uuid,
        method: 'filter',
        table,
        page: 1,
        statusList,
        filterProp,
        examinerPermissions,
      }),
    )
  }

export const handleQuickSearch =
  (uuid, table, text, statusList, filterProp, examinerPermissions) =>
  (dispatch) => {
    dispatch(setSearchText(text))
    // dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(
      fetchExaminerListFilter({
        uuid,
        method: 'search',
        table,
        page: 1,
        statusList: statusList,
        filterProp: filterProp,
        filterText: null,
        keyword: text,
        examinerPermissions,
      }),
    )
  }

export const handleDeleteModule =
  (uuid, row, table, examinerPermissions) => async (dispatch) => {
    dispatch(
      openDialog({
        title: 'ยืนยันลบข้อมูล',
        message: 'คุณต้องการลบรายการใช่หรือไม่',
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        agreeText: 'ลบ',
        disagreeText: 'ยกเลิก',
        handleConfirm: () =>
          dispatch(
            handleConfirmDelete(uuid, row.uuid, table, examinerPermissions),
          ),
      }),
    )
  }

export const resetFilter =
  (scheduleUuid, table, examinerPermissions) => (dispatch) => {
    dispatch(
      fetchExaminerListFilter({
        uuid: scheduleUuid,
        method: 'initial',
        table,
        page: 1,
        statusList: [],
        filterText: null,
        keyword: '',
        examinerPermissions,
      }),
    )
    dispatch(stopLoadingDialog())
    dispatch(closeDialog())
  }

export const handleConfirmDelete =
  (scheduleUuid, uuid, table, examinerPermissions) => async (dispatch) => {
    dispatch(startLoading())
    dispatch(loadingDialog())
    const response = await dispatch(deleteExaminer({ uuid }))
    if (!response?.error) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(resetFilter(scheduleUuid, table, examinerPermissions))
          },
        }),
      )
    } else {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message:
            'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากพบปัญหาบางอย่าง โปรดลองใหม่อีกครั้ง',
          handleConfirm: () => {
            dispatch(resetFilter(scheduleUuid, table, examinerPermissions))
          },
        }),
      )
    }
  }

export const getOtherDocConfig = () => async (dispatch) => {
  const response = await dispatch(checkOtherDocConfig())
  if (!response?.error) {
    const result = _.get(response, 'payload.data', {})
    dispatch(setOtherDocConfig(result))
  }
}
