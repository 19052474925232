import { breadcrumbReportRawData } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'REPORT_INVENTORY_RAW_DATA_REQUISITION',
  modulePath: '/inventory/report-raw-data-requisition',
  breadcrumb: breadcrumbReportRawData(),
  downloadName: `Report Raw Data Requisition ${dayjs().format(
    'DDMMYYYY',
  )}.xlsx`,
}
