import {
  replaceFieldError,
  setFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'
import { TicketStatus } from '../../../../../constants/stock'
import { rejectValidationSchema } from '../../Form/schema/schema'
import { Box } from '@mui/material'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import { store } from '../../../../../App'
import { changeTransferStatus } from './handleChangeStatus'

export const handleRejectedClick = (uuid) => {
  openRejectDialog(uuid, TicketStatus.REJECTED)
}

export const openRejectDialog = (uuid, status) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        rejectTransferReason: '',
      },
    }),
  )
  store.dispatch(
    openDialog({
      type: 'delete',
      title: `ยืนยันการปฏิเสธ`,
      agreeText: 'ยืนยัน',
      disagreeText: 'ยกเลิก',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <CustomTextArea
            fieldName="rejectTransferReason"
            labelName="เหตุผลการปฏิเสธการโอนย้าย"
            required
          />
        </Box>
      ),
      handleConfirm: () => {
        handleSubmitReject(uuid, status)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const handleSubmitReject = async (uuid, status) => {
  const isValid = await validateData(rejectValidationSchema)
  if (isValid) {
    const { formData } = store.getState().crud
    changeTransferStatus(uuid, status, formData)
  }
}

export const validateData = async (validationSchema) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    return false
  }
}
