import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const getBodyMutation = (data) => {
  return {
    id: _.get(data, 'id', ''),
    from: _.get(data, 'from', {}),
    to: _.get(data, 'to', {}),
    productType: _.get(data, 'productType', '-'),
    remark:
      _.isEmpty(_.get(data, 'remark')) || _.isNil(_.get(data, 'remark'))
        ? '-'
        : _.get(data, 'remark', '-'),
    status: _.get(data, 'status', enumStatus.INACTIVE),
    createdAt: _.get(data, 'createdAt', null),
    createdBy: _.get(data, 'createdBy.name', null),
    updatedAt: _.get(data, 'updatedAt', null),
    updatedBy: _.get(data, 'updatedBy.name', null),
    rejectTransferReason: _.get(data, 'rejectTransferReason', null),
  }
}
