import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
  Container,
  Dot,
  Row,
  Column,
  StyledButton,
  SubLableBox,
} from '../Styled'
import { Box } from '@mui/material'
import _ from 'lodash'

const AssetCard = ({
  label,
  subLable,
  labelVariant = 'body2b',
  background,
  dotColor,
  fontColor,
  border,
  boxSx = {},
  minWidth = 200,
  padding,
  dataList = [],
  dataSx = {},
}) => {
  return (
    <Container
      background={background}
      border={!_.isEmpty(border) ? border : 'solid 1px #DBE4F1'}
      minWidth={minWidth}
      sx={boxSx}
      padding={padding}
    >
      <Box display="flex" alignItems={'center'} gap={1} mb={1}>
        {dotColor && <Dot background={dotColor} />}
        <Typography variant={labelVariant}>{label}</Typography>
      </Box>
      {subLable && (
        <SubLableBox background={dotColor} fontColor={fontColor}>
          {subLable}
        </SubLableBox>
      )}
      <Row sx={dataSx}>
        {_.defaultTo(dataList, []).map((item, index) => {
          return (
            <Row key={`asset-${index}`} sx={{ ...item.sxBox }}>
              <Column>
                <Typography
                  variant="body2b"
                  sx={{
                    lineHeight: '18px',
                    mt: 'auto',
                    pb: '8px',
                  }}
                >
                  {item.topLabel}
                </Typography>

                <Typography
                  variant="body2"
                  color="text.lightGray"
                  sx={{
                    lineHeight: '18px',
                    mt: 'auto',
                  }}
                >
                  {item.label}
                </Typography>

                <Row sx={{ gap: 1, mt: 1 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      lineHeight: '20px',
                    }}
                  >
                    {item.number}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.lightGray"
                    sx={{
                      lineHeight: '18px',
                      mt: 'auto',
                    }}
                  >
                    {item.unit}
                  </Typography>
                </Row>
                <Row sx={{ gap: 1, mt: 1 }}>
                  {item.percent && (
                    <Typography
                      variant="body2"
                      color="text.lightGray"
                      sx={{
                        lineHeight: '18px',
                      }}
                    >
                      {item.percent}
                    </Typography>
                  )}
                </Row>
              </Column>
              {item.handleClickButton ? (
                <Column ml="auto">
                  <StyledButton
                    color="text.lightGray"
                    size="small"
                    onClick={item.handleClickButton}
                  >
                    <ChevronRight fontSize="small" />
                  </StyledButton>
                </Column>
              ) : (
                ''
              )}
            </Row>
          )
        })}
      </Row>
    </Container>
  )
}

export default AssetCard
