import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import _ from 'lodash'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { useLocalStorage } from '@rehooks/local-storage'
import TextInput from '../../../../components/Input/TextInput'
import DatePicker from '../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import Autocomplete from '../../../../components/Input/Autocomplete'
import AlertDialog from '../../../../components/AlertDialog'
import SubInfoInput from '../../../../components/SubInfoInput'

import { EventExpandDate } from './Form'
import { convertTimeToApi } from '../View/Index'

import { getExpandDate, fetchAllState } from './Handle'

const Index = (props) => {
  const {
    state,
    stateForm,
    setStateForm,
    type,
    options,
    setOptions,
    validateForm,
    setValidateForm,
    loadingDrawer,
    setLoadingDrawer,
    statePermission,
  } = props
  const [user] = useLocalStorage('user')
  const [expandDate, setExpandDate] = useState(
    _.get(stateForm, 'academyActivity.expandDate.value', []),
  )
  const [course, setCourse] = useState([])
  const [staff, setStaff] = useState([])
  const [responsibility, setResponsibility] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState({
    variant: 'fail',
    title: 'ผิดพลาด',
    content: 'กรุณาระบุหน้าที่ของวิทยากร',
    open: false,
  })
  const courseDefault = course.find(
    (item) => item.value === _.get(stateForm, 'academyActivity.course', ''),
  )

  const defaultExpandDate = {
    typeTime: 'CUSTOM',
    startTime: '',
    endTime: '',
    link: '',
    meetingRoom: {},
    speakers: [],
    speakerBorrow: [],
  }

  const [listOption, setListOption] = useState({
    listAttendees: [],
    listStaffDate: [],
  })

  useEffect(() => {
    const checkDate = getExpandDate(
      stateForm,
      setExpandDate,
      setStateForm,
      defaultExpandDate,
      false,
      setLoadingDrawer,
    )

    if (checkDate) {
      fetchAllState({
        area: _.get(state, 'area.value', ''),
        setCourse,
        setStaff,
        setResponsibility,
        setLoading,
        setStateForm,
        startDate: _.get(stateForm, 'academyActivity.startDate', null),
        finishDate: _.get(stateForm, 'academyActivity.finishDate', null),
        setOptions,
        options,
        user,
      })
    }
  }, [
    _.get(stateForm, 'academyActivity.startDate', null),
    _.get(stateForm, 'academyActivity.finishDate', null),
  ])

  useEffect(() => {
    if (staff.length > 0) {
      removeStaffInUse({
        listAttendees: _.get(stateForm, 'academyActivity.attendees', []),
        listStaffDate: _.get(stateForm, 'academyActivity.expandDate', []),
        optionAttendees: options.staff,
        optionStaff: staff,
        setListOption,
        user,
        options,
      })
    }
  }, [staff, _.get(stateForm, 'academyActivity', {})])

  const onChangeForm = (
    event,
    key,
    isArray,
    date,
    listExpandDate,
    indexSpeaker,
  ) => {
    let value = ''
    let valueValidate = ''

    const listValidateExpandDate = _.get(
      validateForm,
      'academyActivity.expandDate',
      [],
    )

    if (isArray) {
      value = event
    } else {
      value = event.target.value
    }

    if (key === 'selectOnsite' || key === 'onlineLink') {
      value = listExpandDate.length
        ? listExpandDate.map((item) => {
            let result = item
            if (item.date === date) {
              if (key === 'selectOnsite') {
                result = {
                  ...item,
                  meetingRoom: _.get(options, 'meetingRoom', []).find(
                    (item) => {
                      return item.uuid === value
                    },
                  ),
                }
              } else {
                result = {
                  ...item,
                  link: value,
                }
              }
            }
            return result
          })
        : []
      valueValidate = listValidateExpandDate.map((item) => {
        let resultValidate = item
        if (item.date === date) {
          if (key === 'selectOnsite') {
            resultValidate = {
              ...item,
              meetingRoom: '',
            }
          } else {
            resultValidate = {
              ...item,
              link: '',
            }
          }
        }
        return resultValidate
      })

      key = 'expandDate'
    }

    if (key === 'startTime' || key === 'endTime') {
      value = listExpandDate.length
        ? listExpandDate.map((item) => {
            let result = item
            if (item.date === date) {
              result = {
                ...item,
                [key]: convertTimeToApi(value),
              }
            }
            return result
          })
        : []

      valueValidate = listValidateExpandDate.map((item) => {
        let result = item
        if (item.date === date) {
          result = {
            ...item,
            [key]: '',
          }
        }
        return result
      })
      key = 'expandDate'
    }

    if (
      key === 'staff' ||
      key === 'speakerRole' ||
      key === 'startTimeSK' ||
      key === 'endTimeSK'
    ) {
      value = listExpandDate.length
        ? listExpandDate.map((item) => {
            let result = item
            if (item.date === date) {
              let resultSpeaker = []
              const listSpeaker = item?.speakers || []
              if (listSpeaker.length) {
                resultSpeaker = listSpeaker.filter((itemSK, indexSK) => {
                  if (key === 'startTimeSK') {
                    key = 'startTime'
                    value = convertTimeToApi(value)
                  }
                  if (key === 'endTimeSK') {
                    key = 'endTime'
                    value = convertTimeToApi(value)
                  }

                  if (indexSK === indexSpeaker) {
                    itemSK[key] = value
                  }
                  return itemSK
                })
              }
              result = {
                ...item,
                speakers: resultSpeaker,
              }
            }
            return result
          })
        : []

      valueValidate = listValidateExpandDate.map((item) => {
        let result = item
        if (item.date === date) {
          let validateSpeaker = []
          const listSpeaker = item?.speakers || []
          if (listSpeaker.length) {
            if (key === 'startTimeSK') key = 'startTime'
            if (key === 'endTimeSK') key = 'endTime'
            validateSpeaker = listSpeaker.filter((itemSK, indexSK) => {
              if (indexSK === indexSpeaker) {
                itemSK[key] = ''
              }
              return itemSK
            })
          }
          result = {
            ...item,
            resultSpeaker: validateSpeaker,
          }
        }
        return result
      })
      key = 'expandDate'
    }

    if (key === 'isUseRoom') {
      value = event.target.checked
    }

    setStateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        [key]: value,
      },
    }))

    setValidateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        [key]: valueValidate,
      },
    }))
  }

  const nameForLearner = _.get(courseDefault, 'nameForLearner', '')

  return (
    <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Typography>สถานที่จัดประชุม</Typography>
        <RadioGroup
          sx={{ gap: 1, pl: 1, width: '160px' }}
          aria-label="meetingType"
          defaultValue={_.get(
            stateForm,
            'academyActivity.meetingType',
            'online',
          )}
          name="radio-buttons-group"
          onChange={(e) =>
            onChangeRadio(
              e,
              setStateForm,
              expandDate,
              type,
              setValidateForm,
              validateForm,
            )
          }
        >
          <FormControlLabel
            value="online"
            control={<Radio />}
            label="ประชุมแบบ Online"
          />
          <FormControlLabel
            value="onsite"
            control={<Radio />}
            label="ประชุมแบบ Onsite"
          />
        </RadioGroup>
      </Box>
      {_.get(stateForm, 'academyActivity.meetingType', 'online') ===
        'onsite' && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <FormControlLabel
            label="ใช้ห้องประชุม"
            control={
              <Checkbox
                color="primary"
                name="isUseRoom"
                checked={_.get(stateForm, 'academyActivity.isUseRoom', true)}
                onChange={(event) => onChangeForm(event, 'isUseRoom')}
              />
            }
          />
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="ตั้งแต่วันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            onChange={(value) =>
              onDateChange(value, 'startDate', setStateForm, setValidateForm)
            }
            value={
              _.get(stateForm, 'academyActivity.startDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.startDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'academyActivity.startDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.startDate', null)
            }
            isShouldDisableDate={true}
            disabledEndDate={
              _.get(stateForm, 'academyActivity.finishDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.finishDate', null)
            }
            textError={_.get(validateForm, 'academyActivity.startDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.startDate', '')
                ? false
                : true
            }
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="finishDate"
            name="finishDate"
            labelText="ถึงวันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            isShouldDisableDate={true}
            required
            onChange={(value) =>
              onDateChange(value, 'finishDate', setStateForm, setValidateForm)
            }
            value={
              _.get(stateForm, 'academyActivity.finishDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.finishDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'academyActivity.startDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.startDate', null)
            }
            disabledEndDate={
              _.get(stateForm, 'academyActivity.finishDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.finishDate', null)
            }
            textError={_.get(validateForm, 'academyActivity.finishDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.finishDate', '')
                ? false
                : true
            }
          />
        </Box>
      </Box>
      <EventExpandDate
        type={type}
        state={state}
        stateForm={stateForm}
        setStateForm={setStateForm}
        onChangeForm={onChangeForm}
        options={options}
        validateForm={validateForm}
        setValidateForm={setValidateForm}
        staff={_.get(listOption, 'listStaffDate', [])}
        responsibility={responsibility}
        loadingDrawer={loadingDrawer}
        setLoadingDrawer={setLoadingDrawer}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextInput
          id="title"
          name="title"
          type="basic"
          labelText="หัวข้อ"
          placeholder="กรุณากรอกหัวข้อ"
          required
          onChange={(value) => onChangeForm(value, 'title')}
          value={_.get(stateForm, 'academyActivity.title', '')}
          textError={_.get(validateForm, 'academyActivity.title', '')}
          isShowTextError={_.get(validateForm, 'academyActivity.title', '')}
        />
        {!loading && !loadingDrawer && (
          <Autocomplete
            id="course"
            name="course"
            labelText="หลักสูตรอบรม"
            type="basic"
            isHideSort={true}
            disabled={course.length ? false : true}
            options={
              course.length
                ? course.sort(
                    (a, b) => -b.englishName.localeCompare(a.englishName),
                  )
                : []
            }
            defaultValue={
              course.length
                ? course.find((item) => {
                    return (
                      item.value ===
                      _.get(stateForm, 'academyActivity.course', '')
                    )
                  })
                : { label: '', value: '' }
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="เลือกหลักสูตรอบรม"
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'select-course',
                }}
              />
            )}
            handleChange={(event) => {
              const uuid = _.get(event, 'target.id', '')
              onChangeForm(uuid, 'course', true)
              const courseObj = course.find((item) => item.value === uuid)
              onChangeForm(
                _.get(courseObj, 'courseCode', ''),
                'courseCode',
                true,
              )
              onChangeForm(
                _.get(courseObj, 'englishName', ''),
                'englishName',
                true,
              )
              onChangeForm(
                _.get(courseObj, 'nameForLearner', ''),
                'nameForLearner',
                true,
              )
            }}
            value={_.get(stateForm, 'academyActivity.course', '')}
            showTextError={false}
            subComponent={<SubInfoInput label={nameForLearner} />}
          />
        )}
        <TextInput
          id="description"
          name="description"
          type="basic"
          labelText="รายละเอียด"
          placeholder="รายละเอียด"
          multiline={true}
          rows={3}
          onChange={(value) => onChangeForm(value, 'description')}
          value={stateForm.academyActivity.description}
          textError={_.get(validateForm, 'academyActivity.description', '')}
        />

        <SelectGroupChip
          id="attendees"
          name="attendees"
          placeholder={'เลือกผู้เข้าร่วมประชุม'}
          labelText="ผู้เข้าร่วมประชุม"
          options={_.get(listOption, 'listAttendees', [])}
          onChange={(event) => onChangeForm(event, 'attendees', true)}
          value={_.get(stateForm, 'academyActivity.attendees', [])}
          textError={_.get(validateForm, 'academyActivity.attendees', '')}
          disabled={statePermission}
        />
      </Box>

      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={() => setDialog({ ...dialog, open: false })}
        title={dialog.title}
        variant={dialog.variant}
        onCancelClick={() => setDialog({ ...dialog, open: false })}
        onConfirmClick={() => setDialog({ ...dialog, open: false })}
        content={dialog.content}
      />
    </Box>
  )
}
export default Index

export const findUuidStaffDate = (listStaffDate) => {
  let result = []
  if (listStaffDate.length) {
    listStaffDate.map((item) => {
      let listSpeakers = _.get(item, 'speakers', [])
      if (listSpeakers.length) {
        listSpeakers.map((itemSk) => {
          if (itemSk.staff) result.push({ uuid: itemSk.staff })
        })
      }
    })
  }
  return result
}

export const findUuidAttendees = (listAttendees) => {
  let result = []
  if (listAttendees.length) {
    listAttendees.map((item) => {
      if (item.uuid) result.push({ uuid: item.uuid })
    })
  }
  return result
}

export const removeStaffInUse = ({
  listAttendees,
  listStaffDate,
  optionAttendees,
  optionStaff,
  setListOption,
  user,
  options,
}) => {
  const listUuidStaffDate = findUuidStaffDate(listStaffDate)
  const listUuidAttendees = findUuidAttendees(listAttendees)
  const allStaff = _.get(options, 'allStaffs', [])
  //find permission for check can assign all staff from all area
  const findCreateAllPermission = _.get(user, 'permission', []).find(
    (item) =>
      item.module === 'MONTHLY_PLAN' &&
      item.permission === 'CREATE_ALL' &&
      item.owner === 'ALL',
  )
  let result = {
    listAttendees: [],
    listStaffDate: [],
  }
  let listAttendeesPermission = _.map(optionAttendees, (item) => {
    return {
      ...item,
      label: _.get(item, 'firstNameTH')
        ? `${_.get(item, 'area[0].station.station', '')} ${_.get(
            item,
            'firstNameTH',
            '',
          )} ${_.get(item, 'lastNameTH', '')}`
        : _.get(item, 'label', ''),
    }
  })
  let listStaffDatePermission = optionStaff.map((item) => {
    return {
      ...item,
      label: `${_.get(item, 'area[0].station.station', '')} ${_.get(
        item,
        'firstNameTH',
        '',
      )} ${_.get(item, 'lastNameTH', '')}`,
    }
  })

  if (findCreateAllPermission) {
    const fullAllStaff = allStaff.map((item) => {
      return {
        ...item,
        label: `${_.get(item, 'area[0].station.station', '')} ${_.get(
          item,
          'firstNameTH',
          '',
        )} ${_.get(item, 'lastNameTH', '')}`,
        value: item.uuid,
      }
    })
    listAttendeesPermission = fullAllStaff
    listStaffDatePermission = fullAllStaff
  }
  if (listAttendeesPermission.length) {
    const filterAttendees = listAttendeesPermission.filter((item) => {
      const findUuid = listUuidStaffDate.find((itemList) => {
        return itemList.uuid === item.uuid
      })
      if (!findUuid) return item
    })

    result = {
      ...result,
      listAttendees: filterAttendees,
    }
  }

  if (listStaffDatePermission.length) {
    const filterStaff = listStaffDatePermission.filter((item) => {
      const findUuid = listUuidAttendees.find((itemList) => {
        return itemList.uuid === item.uuid
      })
      if (!findUuid) return item
    })

    result = {
      ...result,
      listStaffDate: filterStaff,
    }
  }

  setListOption(result)
  return result
}

export const onDateChange = (event, key, setStateForm, setValidateForm) => {
  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: dayjs(event).format(window.__env__.REACT_APP_DATE_DB),
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: '',
    },
  }))
}
export const onChangeRadio = (
  e,
  setStateForm,
  expandDate,
  type,
  setValidateForm,
  validateForm,
) => {
  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      meetingType: e.target.value,
      expandDate: expandDate.length
        ? type !== 'edit'
          ? expandDate.map((item) => {
              return {
                ...item,
                startTime: '',
                endTime: '',
                link: '',
                meetingRoom: e.target.value === 'onsite' ? null : '',
                speakers: [],
              }
            })
          : expandDate
        : [],
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      toMeetingRoom: '',
      expandDate: _.get(validateForm, 'academyActivity.expandDate', []).map(
        (item) => {
          return {
            ...item,
            startTime: '',
            endTime: '',
            link: '',
            meetingRoom: '',
            speakers: [],
          }
        },
      ),
    },
  }))
}
