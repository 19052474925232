import React, { useState, useContext, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'

import Autocomplete from '@mui/material/Autocomplete'
import { openDialog, closeDialog } from '../../../redux/slices/dialog'
import Breadcrumbs from '../../../components/Breadcrumbs'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
  StyledColumnFlex,
  StyledColumnNoGap,
  StyledRespRow,
} from '../Styled'
import { breadcrumbFormList } from '../model'
import Select from '../../../../src/components/Input/Select'
import DatePicker from '../../../../src/components/Input/DatePicker'
import TextInput from '../../../../src/components/Input/TextInput'
import TimePicker from '../../../../src/components/TimePicker/TimePicker'
import TextField from '@mui/material/TextField'
import {
  E_EXAM_TYPE,
  SCHEDULE_STATUS,
  validationExamAssociationSchema,
  validationExamCompanySchema,
  validationExamOtherSchema,
} from '../../../../src/constants/eExamination'
import Button from '@mui/material/Button'
import { DialogContext } from '../../../context/dialog-context'
import _ from 'lodash'
import {
  fetchRegionList,
  fetchExaminationAssociationList,
  getExaminationTime,
} from '../../../../src/services/eExamination/form'
import {
  getOptionsKey,
  handleNumberKeyDown,
  handlePasteFormat,
  validateEPermission,
} from '../../../utils/lib'
import { initialValues } from '../../../redux/slices/eExamination/form/model'
import {
  fetchExaminationData,
  getStateValue,
  handleChangeEvent,
  handleChange,
  submitExamField,
  onCancelClickDialog,
  fetchHistory,
  handleNumberInput,
  fetchCompanyList,
} from './event'
import { useParams, useHistory } from 'react-router-dom'
import MoreDropdownButton from './Header/DropdownButton'
import HistoryFilterDrawer from '../HistoryFilterDrawer'
import AlertDialog from '../../../components/AlertDialog'
import { setEExamType } from '../../../redux/slices/eExamination/list'
import { EXAMINATION_SETTING, PERMISSION } from '../../../constants/examination'

const Index = ({ isEditForm }) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [isDisabled, setIsDisabled] = useState(0)
  const [dateTimeInvalid, setDateTimeInvalid] = useState(false)
  const [companyLoading, setCompanyLoading] = useState(false)
  const {
    eExamType,
    isOpenHistoryFilterDrawer,
    regionList,
    examinationCompanyList,
    examinationAssociationList,
    association,
    company,
    otherName,
    date,
    time,
    startTime,
    endTime,
    startDateRegister,
    startTimeRegister,
    endDateRegister,
    endTimeRegister,
    numberOfPeople,
    description,
    region,
    historyList,
    historyCount,
    ExamSchedulerResult,
    timeList,
    loading,
    defaultScheduleName,
    status,
  } = useSelector(
    (state) => ({
      eExamType: state.eExamList.eExamType,
      isOpenHistoryFilterDrawer: state.eExamList.isOpenHistoryFilterDrawer,
      regionList: state.eExamForm.regionList,
      examinationCompanyList: state.eExamForm.examinationCompanyList,
      examinationAssociationList: state.eExamForm.examinationAssociationList,
      association: getStateValue(state, 'association'),
      company: getStateValue(state, 'company'),
      otherName: getStateValue(state, 'otherName'),
      date: getStateValue(state, 'date'),
      time: getStateValue(state, 'time'),
      startTime: getStateValue(state, 'startTime'),
      endTime: getStateValue(state, 'endTime'),
      startDateRegister: getStateValue(state, 'startDateRegister'),
      startTimeRegister: getStateValue(state, 'startTimeRegister'),
      endDateRegister: getStateValue(state, 'endDateRegister'),
      endTimeRegister: getStateValue(state, 'endTimeRegister'),
      numberOfPeople: getStateValue(state, 'numberOfPeople'),
      description: getStateValue(state, 'description'),
      region: getStateValue(state, 'region'),
      historyList: state.eExamForm.historyList,
      historyCount: state.eExamForm.historyCount,
      ExamSchedulerResult: state.eExamForm.result,
      timeList: state.eExamForm.timeList,
      loading: state.eExamForm.loading,
      defaultScheduleName: getStateValue(state, 'defaultScheduleName'),
      status: getStateValue(state, 'status'),
    }),
    shallowEqual,
  )

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search)
    const paramExamType = queryParameters.get('type')
    if (paramExamType && E_EXAM_TYPE?.[paramExamType]) {
      dispatch(setEExamType(E_EXAM_TYPE?.[paramExamType]))
    }
    if (
      eExamType === E_EXAM_TYPE.ASSOCIATION ||
      eExamType === E_EXAM_TYPE.OTHER
    ) {
      dispatch(fetchRegionList())
    } else if (eExamType === E_EXAM_TYPE.COMPANY) {
      dispatch(fetchCompanyList({ setCompanyLoading }))
    }
    dispatch(fetchExaminationData({ uuid, isEditForm, setIsLoading }))
    dispatch(getExaminationTime())
  }, [])

  const fetchExaminationAssoc = (e) => {
    setExaminationAssocOption([])
    const regUuid = e.target.value
    dispatch(fetchExaminationAssociationList(regUuid))
  }

  useEffect(() => {
    const getRegion =
      regionList &&
      getOptionsKey({
        data: regionList,
        label: 'regionName',
        key: 'uuid',
      })
    setRegionOption(getRegion)
  }, [regionList])

  useEffect(() => {
    const getExamComp =
      examinationCompanyList &&
      getOptionsKey({
        data: examinationCompanyList,
        label: 'academyStationName',
        key: 'uuid',
      })
    if (getExamComp.length > 0) {
      setExaminationCompOption(getExamComp)
    } else {
      setExaminationCompOption([])
    }
  }, [examinationCompanyList])

  useEffect(() => {
    const getExamAssoc =
      examinationAssociationList &&
      getOptionsKey({
        data: examinationAssociationList,
        label: 'provinceName',
        key: 'uuid',
      })
    if (getExamAssoc.length > 0) {
      setExaminationAssocOption(getExamAssoc)
    } else {
      setExaminationAssocOption([])
    }
  }, [examinationAssociationList])

  useEffect(() => {
    const timeOptionArr = timeList.map((item) => {
      const endTime = item.endTime
      const startTime = item.startTime
      const timeName = item.timeName
      const uuid = item.uuid
      if (time === uuid) {
        formik.setFieldValue('startTime', startTime)
        dispatch(handleChange('startTime', startTime))
        formik.setFieldValue('endTime', endTime)
        dispatch(handleChange('endTime', endTime))
      }
      return {
        value: uuid,
        label: timeName,
        startTime: startTime,
        endTime: endTime,
      }
    })
    setTimeOption(timeOptionArr)
    if (!time) {
      formik.setFieldValue('startTime', '')
      dispatch(handleChange('startTime', ''))
      formik.setFieldValue('endTime', '')
      dispatch(handleChange('endTime', ''))
    }
  }, [timeList, time])

  useEffect(() => {
    if (isEditForm) {
      dispatch(fetchHistory(limit, page, uuid))
    }
  }, [limit, page])

  useEffect(() => {
    if (isEditForm && ExamSchedulerResult) {
      if (ExamSchedulerResult.association) {
        dispatch(setEExamType(E_EXAM_TYPE.ASSOCIATION))
      }
      if (ExamSchedulerResult.station) {
        dispatch(setEExamType(E_EXAM_TYPE.COMPANY))
      } else if (ExamSchedulerResult.other) {
        dispatch(setEExamType(E_EXAM_TYPE.OTHER))
      }

      for (const key in ExamSchedulerResult) {
        const item = ExamSchedulerResult[key]
        if (key === 'association' && item) {
          const assocUuid = item.uuid
          const region = item.region
          dispatch(handleChange('region', region.uuid))
          formik.setFieldValue('region', region.uuid)
          dispatch(fetchExaminationAssociationList(region.uuid))
          setInputAssoc({ label: item.provinceName, value: assocUuid })
          dispatch(handleChange(key, assocUuid))
          formik.setFieldValue(key, assocUuid)
        } else if (key === 'station' && item) {
          setInputComp({ label: item.academyStationName, value: item.uuid })
          dispatch(handleChange('company', item.uuid))
          formik.setFieldValue('company', item.uuid)
        } else if (key === 'other' && item) {
          const other = item
          const otherName = other.otherName
          const association = other?.association
          const region = association?.region
          dispatch(handleChange('otherName', otherName))
          formik.setFieldValue('otherName', otherName)
          dispatch(handleChange('region', region.uuid))
          formik.setFieldValue('region', region.uuid)
          dispatch(fetchExaminationAssociationList(region.uuid))
          setInputAssoc({
            label: association.provinceName,
            value: association.uuid,
          })
          dispatch(handleChange('association', association.uuid))
          formik.setFieldValue('association', association.uuid)
        } else if (key === 'time' && item) {
          formik.setFieldValue('time', item.uuid)
          dispatch(handleChange('time', item.uuid))
          formik.setFieldValue('startTime', item.startTime)
          dispatch(handleChange('startTime', item.startTime))
          formik.setFieldValue('endTime', item.endTime)
          dispatch(handleChange('endTime', item.endTime))
        } else {
          formik.setFieldValue(key, item)
          dispatch(handleChange(key, item))
        }
      }
    }
  }, [ExamSchedulerResult])

  const history = useHistory()
  const [regionOption, setRegionOption] = useState([])
  const [examinationAssocOption, setExaminationAssocOption] = useState([])
  const [examinationCompOption, setExaminationCompOption] = useState([])
  const [timeOption, setTimeOption] = useState([])
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isLoading, setIsLoading] = useState(false)
  let validationSchema = {}
  let eExamTypeTitle = isEditForm ? 'แก้ไขตารางสอบ' : 'สร้างตารางสอบ'
  if (eExamType === E_EXAM_TYPE.ASSOCIATION) {
    validationSchema = validationExamAssociationSchema
    eExamTypeTitle += 'สมาคม'
  } else if (eExamType === E_EXAM_TYPE.OTHER) {
    validationSchema = validationExamOtherSchema
    eExamTypeTitle += 'อื่นๆ'
  } else if (eExamType === E_EXAM_TYPE.COMPANY) {
    validationSchema = validationExamCompanySchema
    eExamTypeTitle += 'บริษัท'
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        title: 'ยืนยันบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'saveExam',
        open: true,
        isLoading: false,
        onConfirmClick: () => {
          dispatch(
            submitExamField({
              eExamType,
              uuid,
              values,
              isEditForm,
              setDialog,
              setIsLoading,
              history,
              openDialog,
              closeDialog,
              formik,
            }),
          )
        },
        onCancelClick: () =>
          setDialog({
            variant: 'saveExam',
            content: '',
            open: false,
          }),
      })
    },
  })

  const hasCreatePermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.CREATE,
  })

  useEffect(() => {
    let isInValid = false
    if (
      !date ||
      !time ||
      !startTime ||
      !endTime ||
      !startDateRegister ||
      !startTimeRegister ||
      !endDateRegister ||
      !endTimeRegister ||
      !numberOfPeople
    ) {
      isInValid = true
    } else if (
      (eExamType === E_EXAM_TYPE.ASSOCIATION &&
        (!association ||
          association === '' ||
          !defaultScheduleName ||
          defaultScheduleName.length > 255)) ||
      (eExamType === E_EXAM_TYPE.OTHER &&
        (!association ||
          association === '' ||
          !otherName ||
          otherName.length > 255)) ||
      (eExamType === E_EXAM_TYPE.COMPANY &&
        (!company ||
          company === '' ||
          !defaultScheduleName ||
          defaultScheduleName.length > 255))
    ) {
      isInValid = true
    } else if (
      (description && description.length > 3000) ||
      (numberOfPeople && numberOfPeople.length > 4)
    ) {
      isInValid = true
    } else if (numberOfPeople && numberOfPeople <= 0) {
      isInValid = true
    }

    if (!hasCreatePermission) {
      isInValid = true
    }

    setIsDisabled(isInValid)
  }, [formik])

  useEffect(() => {
    validateExamDatetime()
  }, [
    date,
    time,
    startTime,
    endTime,
    startDateRegister,
    startTimeRegister,
    endDateRegister,
    endTimeRegister,
  ])

  const validateExamDatetime = () => {
    if (checkDatetimeInput()) {
      let isDateInValid = checkDateTimeValid('isDateInValid')
      let isEndTimeInValid = checkDateTimeValid('isEndTimeInValid')
      if (isDateInValid || isEndTimeInValid) {
        formik.setErrors({
          ...formik.errors,
          date: isDateInValid
            ? 'กรุณากรอกวันเวลาให้มากกว่าวันเวลาปิดรับสมัคร'
            : '',
          endTimeRegister:
            isEndTimeInValid && !isDateInValid
              ? 'กรุณากรอกวันเวลาให้มากกว่าวันเวลาเปิดรับสมัคร'
              : '',
        })
        setDateTimeInvalid(true)
      } else {
        formik.setErrors({ ...formik.errors, date: '', endTimeRegister: '' })
        setDateTimeInvalid(false)
      }
    } else {
      formik.setErrors({ ...formik.errors, date: '', endTimeRegister: '' })
      setDateTimeInvalid(false)
    }
  }

  const checkDatetimeInput = () => {
    return (
      date &&
      time != '' &&
      startTime != '' &&
      endTime != '' &&
      startDateRegister &&
      startTimeRegister != '' &&
      endDateRegister &&
      endTimeRegister != ''
    )
  }

  const checkDateTimeValid = (type) => {
    const dateFormat = dayjs(date).format('YYYY-MM-DD')
    const startDateFormat = dayjs(startDateRegister).format('YYYY-MM-DD')
    const endDateFormat = dayjs(endDateRegister).format('YYYY-MM-DD')
    if (type === 'isDateInValid') {
      return (
        dateFormat < endDateFormat ||
        (dateFormat === endDateFormat && startTime < endTimeRegister)
      )
    } else if (type === 'isEndTimeInValid') {
      return (
        startDateFormat > endDateFormat ||
        startDateFormat > dateFormat ||
        (startDateFormat === endDateFormat &&
          startTimeRegister >= endTimeRegister)
      )
    }
  }

  const handleSelectAssoc = (element) => {
    if (element) {
      setInputAssoc(element)
      formik.setFieldValue('association', element?.value)
      dispatch(handleChange('association', element?.value))
    } else {
      setInputAssoc(null)
      formik.setFieldValue('association', '')
      dispatch(handleChange('association', ''))
    }
  }
  const [inputAssoc, setInputAssoc] = useState(null)

  const handleSelectComp = (element) => {
    if (element) {
      setInputComp(element)
      formik.setFieldValue('company', element?.value)
      dispatch(handleChange('company', element?.value))
    } else {
      setInputComp(null)
      formik.setFieldValue('company', '')
      dispatch(handleChange('company', ''))
    }
  }
  const [inputComp, setInputComp] = useState(null)

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">{eExamTypeTitle}</Typography>
            </Box>
            <Breadcrumbs
              menuList={breadcrumbFormList({ titleText: eExamTypeTitle })}
            />
          </Box>
          <MoreDropdownButton />
        </StyledHeader>
        <form onSubmit={formik.handleSubmit}>
          <StyledCard sx={{ p: 3 }}>
            <StyledColumnFlex>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  {(eExamType === E_EXAM_TYPE.ASSOCIATION ||
                    eExamType === E_EXAM_TYPE.OTHER) && (
                    <>
                      <Select
                        boxSx={{ width: '50%' }}
                        name="region"
                        labelText="ภูมิภาค"
                        type="basic"
                        required
                        placeholder={'เลือก'}
                        options={!_.isNil(regionOption) ? regionOption : []}
                        value={region}
                        handleChange={(e) => {
                          formik.setFieldValue('region', e.target.value)
                          dispatch(handleChangeEvent(e))
                          handleSelectAssoc(null)
                          fetchExaminationAssoc(e)
                        }}
                        textError={formik?.errors?.region}
                      />
                      <AutocompleteCustom
                        boxSx={{ width: '50%' }}
                        name="association"
                        id="association"
                        value={inputAssoc}
                        onChange={(event, newValue) => {
                          handleSelectAssoc(newValue)
                        }}
                        options={
                          loading
                            ? [{ label: '', name: '' }]
                            : examinationAssocOption
                        }
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField placeholder={'กรุณาเลือก'} {...params} />
                        )}
                        disabled={!region}
                        required
                        noOptionsText={`ไม่พบคำที่ค้นหาในรายการ`}
                        labelText={
                          eExamType === E_EXAM_TYPE.ASSOCIATION
                            ? 'สนามสอบ'
                            : 'จังหวัด'
                        }
                        textError={formik?.errors?.association}
                        isLoading={loading}
                      />
                    </>
                  )}
                  {eExamType === E_EXAM_TYPE.COMPANY && (
                    <Box
                      sx={{
                        width: '50%',
                      }}
                    >
                      <AutocompleteCustom
                        // boxSx={{ width: '50%' }}
                        name="company"
                        id="company"
                        value={inputComp}
                        onChange={(event, newValue) => {
                          handleSelectComp(newValue)
                        }}
                        options={
                          companyLoading
                            ? [{ label: '', name: '' }]
                            : examinationCompOption
                        }
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField placeholder={'กรุณาเลือก'} {...params} />
                        )}
                        required
                        noOptionsText={`ไม่พบคำที่ค้นหาในรายการ`}
                        labelText={'สนามสอบ'}
                        textError={formik?.errors?.company}
                        isLoading={companyLoading}
                      />
                    </Box>
                  )}
                </StyledRespRow>
              </StyledColumnNoGap>
              {eExamType === E_EXAM_TYPE.OTHER && (
                <StyledColumnNoGap noGap>
                  <StyledRespRow isInput>
                    <TextInput
                      name="otherName"
                      type="basic"
                      labelText="สถานที่สอบ"
                      id="input-otherName"
                      required
                      value={otherName}
                      onChange={(e) => {
                        formik.setErrors({ ...formik.errors, otherName: '' })
                        formik.setFieldValue('otherName', e.target.value)
                        dispatch(handleChangeEvent(e))
                      }}
                      textError={
                        formik?.errors?.otherName
                          ? formik?.errors?.otherName
                          : String(otherName).length > 255
                          ? 'กรอกได้ไม่เกิน 255 ตัวอักษร'
                          : ''
                      }
                    />
                  </StyledRespRow>
                </StyledColumnNoGap>
              )}
              {[E_EXAM_TYPE.ASSOCIATION, E_EXAM_TYPE.COMPANY].includes(
                eExamType,
              ) && (
                <StyledColumnNoGap noGap>
                  <StyledRespRow isInput>
                    <TextInput
                      name="defaultScheduleName"
                      type="basic"
                      labelText="สถานที่สอบ"
                      id="input-defaultScheduleName"
                      required
                      value={defaultScheduleName}
                      onChange={(e) => {
                        formik.setErrors({
                          ...formik.errors,
                          defaultScheduleName: '',
                        })
                        formik.setFieldValue(
                          'defaultScheduleName',
                          e.target.value,
                        )
                        dispatch(handleChangeEvent(e))
                      }}
                      textError={
                        formik?.errors?.defaultScheduleName
                          ? formik?.errors?.defaultScheduleName
                          : String(defaultScheduleName).length > 255
                          ? 'กรอกได้ไม่เกิน 255 ตัวอักษร'
                          : ''
                      }
                    />
                  </StyledRespRow>
                </StyledColumnNoGap>
              )}
            </StyledColumnFlex>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body1b" color="text.secondary">
                วันสอบ
              </Typography>
            </Box>
            <StyledColumnFlex>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  <DatePicker
                    id="date"
                    name="date"
                    labelText="วันที่สอบ"
                    required
                    placeholder="เลือกวันที่"
                    hideTextError={false}
                    disabledStartDate={'1900-01-01'}
                    disabledEndDate={dayjs(new Date()).format(
                      window.__env__.REACT_APP_DATE_DB,
                    )}
                    hideDisableDateRange={true}
                    value={date}
                    onChange={(e) => {
                      formik.setFieldValue('date', e)
                      dispatch(handleChange('date', e))
                    }}
                    textError={formik?.errors?.date}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Select
                          name="time"
                          labelText="ช่วงเวลา"
                          type="basic"
                          required
                          placeholder={'เลือก'}
                          options={!_.isNil(timeOption) ? timeOption : []}
                          value={time}
                          handleChange={(e) => {
                            formik.setFieldValue('time', e.target.value)
                            dispatch(handleChangeEvent(e))
                          }}
                          textError={formik?.errors?.time}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography variant="body2" color={'text.secondary'}>
                            เวลาเริ่มสอบ
                          </Typography>
                          <Typography
                            sx={{ lineHeight: 1, ml: 0.5 }}
                            color={'error'}
                          >
                            *
                          </Typography>
                        </Box>
                        <TimePicker
                          id="startTime"
                          name="startTime"
                          disabledStartTime={''}
                          disabledEndTime={''}
                          onChange={(e) => {
                            formik.setFieldValue('startTime', e.target.value)
                            dispatch(handleChange('startTime', e.target.value))
                          }}
                          placeholder={'เริ่มต้น'}
                          value={startTime}
                          helperText={formik?.errors?.startTime}
                          disabled={true}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography variant="body2" color={'text.secondary'}>
                            เวลาสิ้นสุดการสอบ
                          </Typography>
                          <Typography
                            sx={{ lineHeight: 1, ml: 0.5 }}
                            color={'error'}
                          >
                            *
                          </Typography>
                        </Box>
                        <TimePicker
                          id="endTime"
                          name="endTime"
                          disabledStartTime={''}
                          disabledEndTime={''}
                          onChange={(e) => {
                            formik.setFieldValue('endTime', e.target.value)
                            dispatch(handleChange('endTime', e.target.value))
                          }}
                          placeholder={'เริ่มต้น'}
                          value={endTime}
                          helperText={formik?.errors?.endTime}
                          disabled={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
            <Box sx={{ my: 2 }}>
              <Typography variant="body1b" color="text.secondary">
                วันรับสมัคร
              </Typography>
            </Box>
            <StyledColumnFlex sx={{ mb: 3 }}>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  <DatePicker
                    id="startDateRegister"
                    name="startDateRegister"
                    labelText="วันที่เปิดรับสมัครสอบ"
                    required
                    placeholder="เลือกวันที่"
                    hideTextError={false}
                    disabledStartDate={'1900-01-01'}
                    disabledEndDate={dayjs(new Date()).format(
                      window.__env__.REACT_APP_DATE_DB,
                    )}
                    hideDisableDateRange={true}
                    value={startDateRegister}
                    onChange={(e) => {
                      formik.setFieldValue('startDateRegister', e)
                      dispatch(handleChange('startDateRegister', e))
                    }}
                    textError={formik?.errors?.startDateRegister}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="body2" color={'text.secondary'}>
                          เวลาเริ่ม
                        </Typography>
                        <Typography
                          sx={{ lineHeight: 1, ml: 0.5 }}
                          color={'error'}
                        >
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <TimePicker
                      id="startTimeRegister"
                      name="startTimeRegister"
                      disabledStartTime={''}
                      disabledEndTime={''}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'startTimeRegister',
                          e.target.value,
                        )
                        dispatch(
                          handleChange('startTimeRegister', e.target.value),
                        )
                      }}
                      placeholder={'เริ่มต้น'}
                      value={startTimeRegister}
                      helperText={formik?.errors?.startTimeRegister}
                    />
                  </Box>
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
            <StyledColumnFlex sx={{ mb: 3 }}>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  <DatePicker
                    id="endDateRegister"
                    name="endDateRegister"
                    labelText="วันที่ปิดรับสมัครสอบ"
                    required
                    placeholder="เลือกวันที่"
                    hideTextError={false}
                    disabledStartDate={'1900-01-01'}
                    disabledEndDate={dayjs(new Date()).format(
                      window.__env__.REACT_APP_DATE_DB,
                    )}
                    hideDisableDateRange={true}
                    value={endDateRegister}
                    onChange={(e) => {
                      formik.setFieldValue('endDateRegister', e)
                      dispatch(handleChange('endDateRegister', e))
                    }}
                    textError={formik?.errors?.endDateRegister}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="body2" color={'text.secondary'}>
                          เวลาสิ้นสุด
                        </Typography>
                        <Typography
                          sx={{ lineHeight: 1, ml: 0.5 }}
                          color={'error'}
                        >
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <TimePicker
                      id="endTimeRegister"
                      name="endTimeRegister"
                      disabledStartTime={''}
                      disabledEndTime={''}
                      onChange={(e) => {
                        formik.setFieldValue('endTimeRegister', e.target.value)
                        dispatch(
                          handleChange('endTimeRegister', e.target.value),
                        )
                      }}
                      placeholder={'เริ่มต้น'}
                      value={endTimeRegister}
                      helperText={formik?.errors?.endTimeRegister}
                    />
                  </Box>
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
            <StyledColumnFlex>
              <StyledColumnNoGap noGap>
                <StyledRespRow sx={{ width: '50%' }} isInput>
                  <TextInput
                    id="numberOfPeople"
                    name="numberOfPeople"
                    labelText="จำนวนที่เปิดรับ"
                    type="basic"
                    inputProps={{
                      onInput: handleNumberInput,
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    textError={
                      formik?.errors?.numberOfPeople
                        ? formik?.errors?.numberOfPeople
                        : numberOfPeople && numberOfPeople <= 0
                        ? 'กรุณากรอกจำนวนมากกว่า 0'
                        : String(numberOfPeople).length > 4
                        ? 'กรอกได้ไม่เกิน 4 ตัวอักษร'
                        : ''
                    }
                    required
                    value={numberOfPeople}
                    onChange={(e) => {
                      formik.setErrors({ ...formik.errors, numberOfPeople: '' })
                      formik.setFieldValue('numberOfPeople', e.target.value)
                      dispatch(handleChangeEvent(e))
                    }}
                    onBlur={formik.handleBlur}
                    onWheel={(e) =>
                      e.target instanceof HTMLElement && e.target.blur()
                    }
                  />
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
            <StyledColumnFlex sx={{ mb: 3 }}>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  <TextInput
                    id="description"
                    name="description"
                    type="basic"
                    labelText="รายละเอียดเพิ่มเติม"
                    multiline={true}
                    rows={3}
                    value={description}
                    onChange={(e) => {
                      formik.setErrors({ ...formik.errors, description: '' })
                      formik.setFieldValue('description', e.target.value)
                      dispatch(handleChangeEvent(e))
                    }}
                    textError={
                      formik?.errors?.description
                        ? formik?.errors?.description
                        : String(description).length > 3000
                        ? 'กรอกได้ไม่เกิน 3000 ตัวอักษร'
                        : ''
                    }
                    onBlur={formik.handleBlur}
                  />
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                data-testid="btn-save"
                variant="contained"
                name="submit"
                type="submit"
                size="m"
                disabled={
                  isDisabled ||
                  dateTimeInvalid ||
                  status === SCHEDULE_STATUS.COMPLETED ||
                  status === SCHEDULE_STATUS.CANCEL
                }
              >
                บันทึก
              </Button>
            </Box>
          </StyledCard>
          <AlertDialog
            open={dialog.open}
            setOpen={setDialog}
            handleClose={onCancelClickDialog}
            title={dialog.title}
            content={dialog.content}
            variant={'saveExam'}
            onConfirmClick={dialog.onConfirmClick}
            onCancelClick={dialog.onCancelClick}
            isLoading={dialog.isLoading}
          />
        </form>
      </StyledContent>

      <HistoryFilterDrawer
        open={isOpenHistoryFilterDrawer}
        drawerType="etesting"
        historyList={historyList}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        allCount={historyCount}
        isForm={true}
      />

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Index

export const AutocompleteCustom = (props) => {
  const {
    boxSx,
    labelText,
    id,
    defaultProps,
    value,
    disabled,
    noOptionsText,
    renderInput,
    onChange,
    required,
    options,
    getOptionLabel,
    textError,
    showTextError = true,
    errorSx = { mb: 3 },
    isLoading,
  } = props
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <Autocomplete
        {...defaultProps}
        options={options}
        getOptionLabel={getOptionLabel}
        id={id}
        value={value}
        onChange={(event, newValue) => {
          onChange(event, newValue)
        }}
        renderInput={renderInput}
        renderOption={(propsOption, option) => {
          if (isLoading) return <LoadingOption />
          return (
            <Box {...propsOption} id={option.value}>
              <Typography variant="body1" id={option.value}>
                {option.label}
              </Typography>
            </Box>
          )
        }}
        required={required ? true : false}
        disabled={disabled}
        noOptionsText={noOptionsText}
      />
      {showTextError && (
        <Box sx={errorSx}>
          {textError && textError != '' && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

const LoadingOption = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 50,
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
