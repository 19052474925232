import { breadcrumbReportRawData } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'REPORT_INVENTORY_RAW_DATA_ASSET_LICENSE',
  modulePath: '/inventory/report-raw-data-asset-license',
  breadcrumb: breadcrumbReportRawData(),
  downloadName: `Report Raw Data Asset&License ${dayjs().format(
    'DDMMYYYY',
  )}.xlsx`,
}
