import dayjs from 'dayjs'

export const filtersDrawerEnum = {
  isOpen: false,
  dateFilterStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  dateFilterFinishDate: dayjs().format('YYYY-MM-DD'),
  ram: [],
  sram: [],
  station: [],
  skill: [],
  distribution: [],
  filterTotal: 0,
}

export const detailDrawerEnum = {
  isOpen: false,
}

export const drawerType = {
  ALL_CLASS: 'ALL_CLASS',
  TOP_LIST: 'TOP_LIST',
  TOP_EXPESNE_LIST: 'TOP_EXPESNE_LIST',
}

export const drawerDataType = {
  ALL: 'ALL',
  AGENCY: 'AGENCY',
  BANCA: 'BANCA',
  KTB: 'KTB',
  OTHER: 'OTHER',
}
