import _ from 'lodash'
import { store } from '../../../../../App'
import { openTableDrawer } from '../../../../../redux/slices/inventory/dashboard'

const prepareDataList = (quantity = 0, percent = 0, price = 0) => {
  return [
    {
      label: 'จำนวน',
      number: quantity,
      unit: 'รายการ',
      percent: `${percent}`,
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        paddingRight: '8px',
        width: '50%',
      },
    },
    {
      label: 'มูลค่า',
      number: price,
      unit: 'บาท',
      percent: ' ',
      sxBox: { width: '50%' },
    },
  ]
}
const prepareAssetDataList = (propertyAsset) => {
  const noQuantity = _.get(propertyAsset, 'noHolder.quantity', 0)
  const noPercent = _.get(propertyAsset, 'noHolder.percent', 0)
  const noPrice = _.get(propertyAsset, 'noHolder.price', 0)
  const holdQuantity = _.get(propertyAsset, 'inHolder.quantity', 0)
  const holdPercent = _.get(propertyAsset, 'inHolder.percent', 0)
  const holdPrice = _.get(propertyAsset, 'inHolder.price', 0)
  return [
    {
      topLabel: 'ที่ “ไม่มีผู้ถือครอง”',
      label: 'จำนวน',
      number: noQuantity,
      unit: 'รายการ',
      percent: `${noPercent}`,
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        width: 'calc(25% - 40px)',
        ml: '16px',
      },
    },
    {
      topLabel: ' ',
      label: 'มูลค่า',
      number: noPrice,
      unit: 'บาท',
      percent: ' ',
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        paddingRight: '18px',
        width: 'calc(25% + 11px)',
        ml: '16px',
      },
      handleClickButton: () => {
        store.dispatch(
          openTableDrawer({
            header: 'Asset ที่ “ไม่มีผู้ถือครอง”',
            drawerType: 'formData',
            courseType: 'inventory',
          }),
        )
      },
    },
    {
      topLabel: 'ที่ “มีผู้ถือครอง”',
      label: 'จำนวน',
      number: holdQuantity,
      unit: 'รายการ',
      percent: `${holdPercent}`,
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        paddingRight: '8px',
        width: 'calc(25% - 42px)',
        ml: '32px',
      },
    },
    {
      topLabel: ' ',
      label: 'มูลค่า',
      number: holdPrice,
      unit: 'บาท',
      percent: ' ',
      sxBox: {
        width: 'calc(25% - 6px)',
        ml: '16px',
      },
      handleClickButton: () => {
        store.dispatch(
          openTableDrawer({
            header: 'Asset ที่ “มีผู้ถือครอง”',
            drawerType: 'formData',
            courseType: 'inventory',
          }),
        )
      },
    },
  ]
}

export const transactionModel = () => {
  const { inventoryProperty } = store.getState().inventoryDashboard.overview
  const propertyStock = _.get(inventoryProperty, 'propertyStock', {})
  const propertySKU = _.get(inventoryProperty, 'propertySKU', {})
  const propertyAsset = _.get(inventoryProperty, 'propertyAsset', {})
  return [
    {
      label: 'Stock',
      labelVariant: 'body2b',
      background: '#B1DFD2',
      dotColor: '#32A287',
      value: 'stock',
      width: 'calc(50% - 2px)',
      dataList: prepareDataList(
        _.get(propertyStock, 'quantity', 0),
        _.get(propertyStock, 'percent', 0),
        _.get(propertyStock, 'price', 0),
      ),
    },
    {
      label: 'Stock (SKU)',
      labelVariant: 'body2b',
      background: '#BED0ED',
      dotColor: '#4976BA',
      value: 'stockSKU',
      width: 'calc(50% - 2px)',
      dataList: prepareDataList(
        _.get(propertySKU, 'quantity', 0),
        _.get(propertySKU, 'percent', 0),
        _.get(propertySKU, 'price', 0),
      ),
    },
    {
      label: 'Asset',
      labelVariant: 'body2b',
      background: '#FFDACA',
      dotColor: '#E99E7D',
      value: 'Asset',
      width: '100%',
      type: 'asset',
      dataList: prepareAssetDataList(propertyAsset),
    },
  ]
}
