export const ROOM_FUNCTION = {
  SINGLE_ROOM: 'SINGLE_ROOM',
  CONNECTING_ROOM: 'CONNECTING_ROOM',
  OTHER: 'OTHER',
}

export const ROOM_FUNCTION_TEXT = {
  [ROOM_FUNCTION.SINGLE_ROOM]: 'Single Room',
  [ROOM_FUNCTION.CONNECTING_ROOM]: 'Connecting Room',
  [ROOM_FUNCTION.OTHER]: 'Other',
}
