import * as service from '../../../../../services/manageClass'
import { fetchDataList } from '../../../../../modules/ManageClass/List/Table/event'
import { openDialog, closeDialog } from '../../../../../redux/slices/dialog'
import _ from 'lodash'
import { store } from '../../../../../App'
import {
  setIsOpenManageAdminDrawer,
  startLoadingManageAdminDrawer,
  stopLoadingManageAdminDrawer,
} from '../../../../../redux/slices/manageClass'
import {
  getAdminStaff,
  getManageClassByUuid,
} from '../../../../../services/manageClass/form'
import { validatePermission } from 'src/utils/lib'

export const checkStyledLeft = (indexImage) => {
  switch (indexImage) {
    case 0:
      return 0
    case 1:
      return -6
    case 2:
      return -12
    default:
      return -18
  }
}
export const checkStyledZIndex = (indexImage) => {
  switch (indexImage) {
    case 0:
      return 100
    case 1:
      return 80
    case 2:
      return 60
    default:
      return 40
  }
}

export const onAdminChange =
  ({ newValue, row, dialog, table }) =>
  (dispatch) => {
    dispatch(
      openDialog({
        ...dialog,
        handleConfirm: async () =>
          dispatch(onAdminChangeSuccess(newValue, row, table)),
        disableButton: _.isEmpty(_.get(newValue, 'value', '')),
      }),
    )
  }

export const onAdminChangeSuccess =
  (newValue, row, table) => async (dispatch) => {
    const result = await dispatch(
      service.updateAdmin({
        staffUuid: _.get(newValue, 'value', ''),
        trainingPlanUuid: row.uuid,
      }),
    )
    const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
    if (requestStatus === 'fulfilled') {
      dispatch(
        openDialog({
          title: 'Success',
          message: 'เพิ่มแอดมินหลักสำเร็จ',
          type: 'success',
          handleConfirm: () => dispatch(closeDialog()),
        }),
      )
      dispatch(fetchDataList(table, ''))
    }
  }

export const handleManageAdminDrawerWithData = async ({ uuid }) => {
  store.dispatch(startLoadingManageAdminDrawer())
  store.dispatch(setIsOpenManageAdminDrawer(true))
  const manageClassData = await store
    .dispatch(getManageClassByUuid(uuid))
    .then((res) => _.get(res, 'payload.data'))

  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionEditAll = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['EDIT_ALL_CLASS'],
  })
  const station = _.get(manageClassData, 'monthlyPlan.area.station', null)

  await store.dispatch(getAdminStaff({ station, hasPermissionEditAll }))
  store.dispatch(stopLoadingManageAdminDrawer())
}
