import { breadcrumbReportEEXamResult } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'REPORT_E_EXAM_RESULT',
  modulePath: '/e-exam/schedule', // ใช้ api เส้นเดียวกับ exam schedule
  breadcrumb: breadcrumbReportEEXamResult(),
  downloadName: `รายงาน ผลสอบใบอนุญาต รายสนามสอบ ประจำวันที่ ${dayjs().format(
    'DDMMYYYY',
  )}.xlsx`,
}
