export const defaultFilter = {
  nameChecked: false,
  name: '',
  categoryChecked: false,
  categoryName: '',
  createDateChecked: false,
  createStartDate: '',
  createFinishDate: '',
  updateDateChecked: false,
  updateStartDate: '',
  updateFinishDate: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
