import _ from 'lodash'

export const handleScale = (props) => {
  const { width, length, height } = props
  let scale = []
  if (!_.isNull(width)) scale.push(`กว้าง ${width}`)
  if (!_.isNull(length)) scale.push(`ยาว ${length}`)
  if (!_.isNull(height)) scale.push(`สูง ${height}`)
  let scaleLabel = ''
  for (let el of scale) {
    el = el + ' x '
    scaleLabel += el
  }
  if (!_.isEmpty(scale)) {
    scaleLabel = `( ${scaleLabel.slice(0, -3)} )`
  }
  return scaleLabel
}

export const handleCloseDialogShowImages = (setOpen) => {
  setOpen(false)
}
