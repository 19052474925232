import { CPageContainer } from '../../../../../components/CRUD/components/PageContainer'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import { breadcrumb } from './model/breadcrumb'
import { Content } from './Content'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  fetchDetail,
  fetchMasterDataDropdown,
} from '../../../../../components/CRUD/handler/fetchDetail'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { getBodyMutation } from './mutation/get'
import { getModuleTitle } from '../../utils'
import { Button } from '@mui/material'
import { handleSubmitClick } from '../../../../../components/CRUD/handler/handleSubmitClick'
import { postBodyMutation } from '../Form/mutation/post'
import { validationSchema } from '../Form/schema/schema'
import { validatePermission } from '../../../../../utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import { setFieldUploadValue } from '../../../../../redux/slices/uploadFile'

export const InventoryProductStockForm = () => {
  const { id: uuid } = useParams()
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const dispatch = useDispatch()

  const initializeCrud = async () => {
    initialConfig(initialCrudConfig)
    dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
    dispatch(
      setFieldValue({
        key: 'masterData',
        value: {
          category: {
            isLoading: false,
            options: [],
          },
          subCategory: {
            isLoading: false,
            options: [],
          },
          costCenter: {
            isLoading: false,
            options: [],
          },
        },
      }),
    )
    await fetchMasterDataDropdown().then()
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation).then((detail) => {
        if (detail.productImage && !_.isEmpty(detail.productImage)) {
          dispatch(
            setFieldUploadValue({
              key: `formData.productImage`,
              value: {
                files: [
                  {
                    id: 1,
                    key: detail.productImage?.key,
                    displayName: detail.productImage?.fileName,
                    fileSize: detail.productImage?.fileSize,
                    fileType: detail.productImage?.fileType,
                    percent: 100,
                  },
                ],
                isUploading: false,
              },
            }),
          )
        }
      })
    }
  }

  useEffect(() => {
    initializeCrud()
  }, [])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CPageContainer
      gap="3"
      header={
        <CHeader
          title={getModuleTitle(uuid, crudConfig.modulesTitleShort)}
          breadcrumbs={breadcrumb(uuid)}
          action={
            <Button
              disabled={!hasPermissionCreate}
              variant="contained"
              onClick={() =>
                handleSubmitClick(uuid, postBodyMutation, validationSchema)
              }
            >
              บันทึกและเผยแพร่
            </Button>
          }
        />
      }
      content={<Content uuid={uuid} />}
    />
  )
}
