import {
  startLoading,
  stopLoading,
  setReduxValue,
  setDownloadName,
} from '../../../redux/slices/reportComp'
import { store } from '../../../App'
import _ from 'lodash'
import { filterReport } from '../../../services/reportComp'

export const fetchReports = async () => {
  store.dispatch(startLoading())
  const {
    columns,
    bodyFilters,
    hasSortedColumns,
    rowsPerPage,
    tablePage,
    isFilter,
    columnName,
    tab,
    year,
    reportConfig: { modulePath, moduleId },
  } = store.getState().reportComp

  if (columns?.length === 0) return

  const body = {
    columns,
    filters: bodyFilters,
    hasSortedColumns,
    limit: isFilter ? 100 : rowsPerPage.value,
    page: isFilter ? 0 : tablePage,
    moduleId,
    tab,
    year,
  }
  const { payload: response } = await store.dispatch(
    filterReport({
      modulePath: modulePath,
      body,
    }),
  )

  let newColumns = _.filter(columns, (col) => {
    if (modulePath === '/course-setting')
      return (
        col.value !== columnName.STAFF_ID_LIST &&
        col.value !== columnName.STAFF_NAME_LIST
      )
    else return true
  })
  const reports = _.get(response, 'results', [])
  const allCount = _.get(response, 'count', 0)

  if (['/e-exam/enroll', '/e-exam/schedule'].includes(modulePath)) {
    const minDate = _.get(response, 'minDate', '')
    const maxDate = _.get(response, 'maxDate', '')
    let name =
      moduleId === 'REPORT_E_EXAM_RESULT'
        ? 'รายงาน ผลสอบใบอนุญาต รายสนามสอบ ประจำวันที่ '
        : moduleId === 'REPORT_E_EXAM_SCHEDULE'
        ? 'Report รอบสอบ '
        : 'Report รับสมัครสอบ '
    name = name + `${minDate}-${maxDate}.xlsx`
    await store.dispatch(setDownloadName(name))
  }

  let filteredColumns = []
  let differenceColumns = []
  let columnsList = newColumns

  if (!hasSortedColumns) {
    filteredColumns = _.filter(
      newColumns,
      (col) =>
        col.value === columnName.CREATED_AT ||
        col.value === columnName.CREATED_BY ||
        col.value === columnName.UPDATED_AT ||
        col.value === columnName.UPDATED_BY,
    )
    differenceColumns = _.difference(newColumns, filteredColumns)
    columnsList = [...differenceColumns, ...filteredColumns]
  }
  await store.dispatch(setReduxValue({ key: 'allCount', value: allCount }))
  await store.dispatch(setReduxValue({ key: 'isInitial', value: false }))
  await store.dispatch(setReduxValue({ key: 'columns', value: columnsList }))
  await store.dispatch(setReduxValue({ key: 'reportsData', value: reports }))
  await store.dispatch(setReduxValue({ key: 'allCount', value: allCount }))
  await store.dispatch(stopLoading())
}
