import { Button, Divider, IconButton } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from './Styled'
import { Close } from '@mui/icons-material'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { setIsOpenManageAdminDrawer } from '../../../../redux/slices/manageClass'
import Content from './components/Content'
import { handleUpdateManageClass } from './events'

const ManageAdminDrawer = () => {
  const dispatch = useDispatch()
  const { isOpenManageAdminDrawer } = useSelector(
    (state) => ({
      isOpenManageAdminDrawer: state.manageClass.isOpenManageAdminDrawer,
    }),
    shallowEqual,
  )
  return (
    <StyledDrawer
      sx={{ position: 'relative' }}
      open={isOpenManageAdminDrawer}
      onClose={() => dispatch(setIsOpenManageAdminDrawer(false))}
    >
      <StyledHeadLabel>
        <IconButton
          data-testid="btn-close-drawer"
          color="action.active"
          component="span"
          onClick={() => dispatch(setIsOpenManageAdminDrawer(false))}
        >
          <Close />
        </IconButton>
      </StyledHeadLabel>
      <Divider />
      <Content />

      <StyledFooter>
        <Button
          sx={{ width: 210, height: 40 }}
          data-testid="btn-cancel"
          variant="outlined"
          onClick={() => dispatch(setIsOpenManageAdminDrawer(false))}
        >
          ยกเลิก
        </Button>
        <Button
          sx={{ width: 210, height: 40 }}
          data-testid="btn-submit"
          variant="contained"
          onClick={() => {
            handleUpdateManageClass()
          }}
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default ManageAdminDrawer
