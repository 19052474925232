import React from 'react'
import styled from '@mui/system/styled'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'

export const shouldForwardProp = {
  shouldForwardProp: (props) =>
    props !== 'customWidth' &&
    props !== 'isLoading' &&
    props !== 'isEdit' &&
    props !== 'isError' &&
    props !== 'isUploadLoading' &&
    props !== 'isScrolled' &&
    props !== 'isDraft',
}

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ customWidth }) => ({
  '& .MuiDrawer-paper': {
    width: customWidth ? customWidth : '75%',
    height: '100vh',
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(1.25),
  zIndex: 100,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  position: 'sticky',
  bottom: 0,
  zIndex: 99,
  backgroundColor: theme?.palette?.background?.paper,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(7)}`,
  },
}))

export const StyledCard = styled(
  (props) => <Card {...props} />,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  minWidth: 275,
  margin: `0 ${theme.spacing(2.75)}`,
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  overflowY: 'auto',
  [theme.breakpoints.down('lg')]: {
    '&#view': { marginTop: theme.spacing(5) },
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(#versionTable)': { marginTop: theme.spacing(5) },
  },
}))

export const LoadingCircular = styled(
  CircularProgress,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  right: '37.5%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette?.primary?.main,
}))

export const StyledGroupHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
  lineHeight: '20px',
  fontSize: '18px',
  color: theme.palette?.text?.lightGray,
  background: theme.palette?.background?.paper,
}))

export const StyledGroupItems = styled('ul')({
  padding: 0,
})
