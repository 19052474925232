import React, { useEffect, useState } from 'react'
import {
  // Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { shallowEqual } from 'react-redux'
import Carousel from '../../../../../../components/Carousel'
import { isMediaWidthMoreThan } from '../../../../../../utils/lib'
import { handleCloseDialogShowImages } from './event'
import { Close } from '@mui/icons-material'
import useTheme from '@mui/system/useTheme'
// import ChevronRightIcon from '@mui/icons-material/ChevronRight'
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import './customCarousel.css'

const DialogShowImages = ({ isOpen, setOpen, imgSelected }) => {
  const { attachFiles } = useSelector(
    (state) => ({
      attachFiles: state.crud.formData?.attachFiles,
    }),
    shallowEqual,
  )
  const isNotSm = isMediaWidthMoreThan('sm')
  const theme = useTheme()

  const [selectedItem, setSelectedItem] = useState(imgSelected)

  // const handleNext = () => {
  //   setSelectedItem((prev) => (prev + 1) % attachFiles.length)
  // }

  // const handlePrev = () => {
  //   setSelectedItem(
  //     (prev) => (prev - 1 + attachFiles.length) % attachFiles.length,
  //   )
  // }

  useEffect(() => {
    setSelectedItem(imgSelected)
  }, [imgSelected, isOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleCloseDialogShowImages(setOpen)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        รูปภาพประกอบการยืม
        <IconButton
          sx={{ color: theme?.palette?.common?.black }}
          data-testid="btn-close-drawer"
          component="span"
          onClick={() => handleCloseDialogShowImages(setOpen)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ display: 'flex', gap: '3px' }}>
        {/* <Box
          display="flex"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={handlePrev}
        >
          <ChevronLeftIcon />
        </Box> */}
        <Carousel
          autoPlay={false}
          selectedItem={selectedItem}
          showArrows={false}
          showIndicators={false}
          onChange={(index) => setSelectedItem(index)}
        >
          {_.map(attachFiles, (img, idx) => (
            <img
              style={{
                height: isNotSm ? 450 : 200,
                width: '100%',
                objectFit: 'cover',
              }}
              key={idx}
              src={`${window.__env__.REACT_APP_API_URL}/file/get${img.key}`}
            />
          ))}
        </Carousel>
        {/* <Box
          display="flex"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={handleNext}
        >
          <ChevronRightIcon />
        </Box> */}
      </DialogContent>
    </Dialog>
  )
}

export default DialogShowImages
