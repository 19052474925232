import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledClearIcon } from '../Styled'
import { Box, Typography } from '@mui/material'
import { handleClearFilterReq } from '../handler/handleClearFilterReq'

export const StationFilterBox = () => {
  const { filterReq, filtersReqDrawer } = useSelector(
    (state) => ({
      filterReq: state.inventoryDashboard.filterReq,
      filtersReqDrawer: state.inventoryDashboard.filtersReqDrawer,
    }),
    shallowEqual,
  )
  const isFilterStation = filtersReqDrawer?.isFilterStation ?? false
  const stationFilter = filterReq?.station ?? []

  return (
    <>
      {isFilterStation ? (
        <Box
          sx={{
            height: '34px',
            backgroundColor: '#F1F4FA',
            borderRadius: '16px',
            padding: '2px',
            width: `fit-content`,
            marginRight: `auto`,
          }}
        >
          {stationFilter?.length > 0 && (
            <Typography
              variant="body2b"
              fontSize="18px"
              sx={{
                padding: '2px 6px',
                color: '#00008F',
                display: `flex`,
                alignItems: `center`,
              }}
            >
              Station:{' '}
              <strong>
                {stationFilter.map((item) => item.label).join(', ')}
              </strong>
              {isFilterStation && (
                <StyledClearIcon
                  onClick={() => handleClearFilterReq('stationFilter')}
                />
              )}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            height: '34px',
            backgroundColor: '#F1F4FA',
            borderRadius: '16px',
            padding: '2px',
            width: `fit-content`,
            marginRight: `auto`,
          }}
        >
          <Typography
            variant="body2b"
            fontSize="18px"
            sx={{ padding: '2px 6px', color: '#00008F' }}
          >
            ทุก Station
          </Typography>
        </Box>
      )}
    </>
  )
}
