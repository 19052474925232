import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import HeaderList from './Header'
import { fetchExamListFilter } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from '../Styled'
import FilterDrawer from '../FilterDrawer'
import { breadcrumbList } from '../model'
import { validateEPermission } from '../../../utils/lib'
import Tabs from '@mui/material/Tabs'
import { StyledTab } from '../Styled'
import { E_EXAM_TYPE } from '../../../constants/eExamination'
import { handleChangeExamType } from '../../../redux/slices/eExamination/list/event'
import { fetchRegionsFilter } from '../FilterDrawer/event'
import { EXAMINATION_SETTING, PERMISSION } from '../../../constants/examination'
import {
  setExaminationPermissions,
  setEExamType,
  setInitialExamList,
} from '../../../redux/slices/eExamination/list'
import { getExaminationTime } from '../../../services/eExamination'
import { setChangeRowsPerPage } from '../../../redux/slices/table'
import { MANAGE_CLASS_ROW_PAGE_PER_LIST } from '../../../constants/table'

const Index = () => {
  const dispatch = useDispatch()
  const { table, search, filter } = useSelector((state) => state.table)
  const { eExamType, initialExamList, examinationPermissions } = useSelector(
    (state) => ({
      eExamType: state.eExamList.eExamType,
      initialExamList: state.eExamList.initialExamList,
      examinationPermissions: state.eExamList.examinationPermissions,
    }),
    shallowEqual,
  )

  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText, searchDate } = search
  const hasCreatePermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.CREATE,
  })
  const hasViewPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.VIEW,
  })
  const hasDeletePermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.DELETE,
  })

  useEffect(() => {
    dispatch(setInitialExamList(true))
    dispatch(
      setChangeRowsPerPage({
        defaultRowsPerPage: 20,
        limit: 20,
        rowsPerPage: { label: '20', value: 20 },
      }),
    )
    dispatch(
      setExaminationPermissions({
        hideView: hasViewPermission ? false : true,
        hideEdit: hasCreatePermission ? false : true,
        hideDelete: hasDeletePermission ? false : true,
      }),
    )
    const queryParameters = new URLSearchParams(location.search)
    const paramExamType = queryParameters.get('type')
    if (paramExamType && E_EXAM_TYPE?.[paramExamType]) {
      dispatch(setEExamType(E_EXAM_TYPE?.[paramExamType]))
    } else {
      dispatch(setEExamType(E_EXAM_TYPE.COMPANY))
    }
    dispatch(
      fetchExamListFilter({
        method: 'initial',
        table,
        page,
        statusList: status,
        filterProp,
        keyword: searchText,
        examinationPermissions,
      }),
    )
    dispatch(fetchRegionsFilter())
    dispatch(getExaminationTime())
  }, [])

  useEffect(() => {
    if (!initialExamList) {
      dispatch(
        fetchExamListFilter({
          method: 'fetch',
          table,
          page,
          statusList: status,
          filterProp,
          keyword: searchText,
          dateKeyword: searchDate,
          examinationPermissions,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">ตารางสอบ</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>
        </StyledHeader>

        <StyledHeader sx={{ mb: 3, position: 'relative' }}>
          <Tabs
            variant="scrollable"
            value={eExamType}
            indicatorColor="primary"
            onChange={handleChangeExamType}
          >
            <StyledTab value={E_EXAM_TYPE.COMPANY} label="ตารางสอบบริษัท" />
            <StyledTab value={E_EXAM_TYPE.ASSOCIATION} label="ตารางสอบสมาคม" />
            <StyledTab value={E_EXAM_TYPE.OTHER} label="ตารางสอบอื่นๆ" />
          </Tabs>
          {hasCreatePermission && <HeaderList />}
        </StyledHeader>

        <StyledCard id="listTable">
          <Table rowsPerPageOptions={MANAGE_CLASS_ROW_PAGE_PER_LIST} />
        </StyledCard>

        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
