import { Typography, IconButton } from '@mui/material'
import { Column, Row } from '../Styled'
import { useDispatch } from 'react-redux'
import { DeleteTwoTone } from '@mui/icons-material'
import { setDeleteOnlineRoom } from 'src/redux/slices/manageClassForm'

export const RoomAdminSelector = ({ idxR, idxD, setNewOnlineRoom }) => {
  const dispatch = useDispatch()

  return (
    <Column>
      <Row alignItems="baseline" justifyContent="space-between">
        <Typography mx={1} variant="body1b">
          ห้องที่ {idxR + 1}
        </Typography>
        <IconButton
          data-testid="btn-delete"
          onClick={() => {
            setNewOnlineRoom((roomVal) => {
              const newRoom = [...roomVal]
              newRoom.splice(idxR, 1)
              return newRoom
            })

            dispatch(setDeleteOnlineRoom({ oIndex: idxR, dIndex: idxD }))
          }}
        >
          <DeleteTwoTone />
        </IconButton>
      </Row>
    </Column>
  )
}
