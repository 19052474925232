import { breadcrumbAuditLog } from './model'
import dayjs from 'dayjs'

export const reportConfigBackend = {
  moduleId: 'AUDIT_LOG_BACKEND',
  modulePath: '/audit-log',
  breadcrumb: breadcrumbAuditLog(),
  downloadName: `Audit Log-Backend ${dayjs().format('DDMMYYYY')}.xlsx`,
}

export const reportConfigLearner = {
  moduleId: 'AUDIT_LOG_LEARNER',
  modulePath: '/audit-log',
  breadcrumb: breadcrumbAuditLog(),
  downloadName: `Audit Log-Learner ${dayjs().format('DDMMYYYY')}.xlsx`,
}
