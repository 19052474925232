import { Column, Row } from '../Styled'
import { getMonthlyPlanDateText } from '../../Table/event'
import _ from 'lodash'
import { TextField, Typography } from '@mui/material'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import {
  checkAssignNee,
  handleSelectChange,
} from 'src/modules/ManageClass/Setting/Form/events'

export const CourseInfoWithAdminSelect = () => {
  const dispatch = useDispatch()
  const { data, adminStaffOption } = useSelector(
    (state) => ({
      data: state.manageClassForm.data,
      adminStaffOption: state.manageClassForm.adminStaffOption,
    }),
    shallowEqual,
  )

  const defaultAssignNee = checkAssignNee(
    _.get(data, 'assignNee', ''),
    adminStaffOption,
    _.get(data, 'assignNeeObj', {}),
  )
  const dates = _.get(data, 'monthlyPlan.dates', [])
  const status = _.get(data, 'status')
  const disableClearable = status !== 'UNASSIGN' && status !== 'TODO'

  return (
    <Column gap={2}>
      <Typography variant="h5">{_.get(data, 'course')}</Typography>
      <Row gap={3}>
        <Typography variant="body1">วันที่จัดอบรม</Typography>
        <Typography sx={{ width: 350, ml: 'auto', mr: 2 }}>
          {getMonthlyPlanDateText(data)}
        </Typography>
      </Row>
      <Row gap={3}>
        <Typography variant="body1">แอดมินหลัก</Typography>
        <Autocomplete
          boxSx={{ width: 350, ml: 'auto', mr: 2 }}
          name="assignNee"
          options={[...adminStaffOption]}
          defaultValue={defaultAssignNee}
          getOptionLabel={(option) => option.label}
          disableClearable={disableClearable}
          renderInput={(params) => (
            <TextField
              placeholder="กรุณาเลือก"
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'select-main-admin',
              }}
            />
          )}
          handleChange={(e) => {
            const id = e.target.id || ''
            const assignNeeUuid = id.replace(/^\d+-/, '')

            dispatch(
              handleSelectChange({
                dates,
                keyField: 'assignNee',
                value: assignNeeUuid,
              }),
            )
          }}
          handleInputChange={(e) => {
            if (_.isNull(e)) {
              dispatch(
                handleSelectChange({
                  keyField: 'assignNeeObj',
                  value: null,
                }),
              )
            }
          }}
        />
      </Row>
    </Column>
  )
}
