import { E_EXAM_TYPE } from '../../../../constants/eExamination'

export const initialValues = {
  association: '',
  company: '',
  otherName: '',
  date: '',
  time: '',
  startTime: '',
  endTime: '',
  startDateRegister: '',
  startTimeRegister: '10:00',
  endDateRegister: '',
  endTimeRegister: '16:00',
  numberOfPeople: '',
  description: '',
  region: '',
  defaultScheduleName: '',
}

export const initialState = {
  errors: [],
  formError: [],
  isLoadingDrawer: false,
  eExamType: E_EXAM_TYPE.ASSOCIATION,
  regionList: [],
  examinationCompanyList: [],
  examinationAssociationList: [],
  loading: false,
  body: initialValues,
  result: null,
  historyList: [],
  historyCount: 0,
  timeList: [],
}
