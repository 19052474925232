export const arrayStatus = [
  {
    label: 'รอเปิดรับสมัคร',
    name: 'inProgressChecked',
  },
  {
    label: 'ยกเลิกการสอบ',
    name: 'cancelChecked',
  },
  {
    label: 'เปิดรับสมัคร',
    name: 'openRegisterChecked',
  },
  {
    label: 'ปิดรับสมัคร',
    name: 'closedRegisterChecked',
  },
  {
    label: 'ระหว่างสอบ',
    name: 'inExamChecked',
  },
  {
    label: 'จบการสอบ',
    name: 'successChecked',
  },
  {
    label: 'เสร็จสมบูรณ์',
    name: 'completedChecked',
  },
]

export const arrayCheckbox = [
  {
    label: 'วันที่สอบ',
    nameCheckBox: 'examDateChecked',
    nameInput: 'examDate',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'รอบสอบ',
    nameCheckBox: 'timeChecked',
    nameInput: 'time',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'สนามสอบ',
    nameCheckBox: 'examinationFieldChecked',
    nameInput: 'examinationField',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานที่สอบ',
    nameCheckBox: 'scheduleNameChecked',
    nameInput: 'scheduleName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'ภาค',
    nameCheckBox: 'regionChecked',
    nameInput: 'region',
    type: 'region',
    list: [],
  },
  {
    label: 'จำนวน',
    nameCheckBox: 'peopleNumberChecked',
    nameInput: 'peopleNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'วันที่เปิดรับสมัคร',
    nameCheckBox: 'startDateRegisterChecked',
    nameInput: 'startDateRegister',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'วันที่ปิดรับสมัคร',
    nameCheckBox: 'endDateRegisterChecked',
    nameInput: 'endDateRegister',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
]

export const arrayCheckboxOther = [
  {
    label: 'วันที่สอบ',
    nameCheckBox: 'examDateChecked',
    nameInput: 'examDate',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'รอบสอบ',
    nameCheckBox: 'timeChecked',
    nameInput: 'time',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'สถานที่สอบ',
    nameCheckBox: 'examinationFieldChecked',
    nameInput: 'examinationField',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'ภาค',
    nameCheckBox: 'regionChecked',
    nameInput: 'region',
    type: 'region',
    list: [],
  },
  {
    label: 'จังหวัด',
    nameCheckBox: 'provinceFieldChecked',
    nameInput: 'provinceField',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'จำนวน',
    nameCheckBox: 'peopleNumberChecked',
    nameInput: 'peopleNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'วันที่เปิดรับสมัคร',
    nameCheckBox: 'startDateRegisterChecked',
    nameInput: 'startDateRegister',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'วันที่ปิดรับสมัคร',
    nameCheckBox: 'endDateRegisterChecked',
    nameInput: 'endDateRegister',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
]

export const defaultFilter = {
  regionChecked: false,
  provinecFieldChecked: false,
  provinceField: '',
  examinationFieldChecked: false,
  examinationField: '',
  examDateChecked: false,
  examDate: '',
  startDateRegisterChecked: false,
  startDateRegister: '',
  peopleNumberChecked: false,
  peopleNumber: '',
  endDateRegisterChecked: false,
  endDateRegister: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    inProgressChecked: false,
    cancelChecked: false,
    openRegisterChecked: false,
    closedRegisterChecked: false,
    inExamChecked: false,
    successChecked: false,
    completedChecked: false,
  },
  region: [],
  timeChecked: false,
  time: [],
  scheduleNameChecked: false,
  scheduleName: '',
}
