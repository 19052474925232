import React from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { themeColor } from '../../../utils/themeData'
import { WORK_WORK_LIST, LEAVE_LIST } from '../Text'
import { findWorkTypeAndSubWorkType } from '../api/Index'
import { createTrainingAll } from '../api/Training'
import { SUB_WORK_TYPE_ACADEMY } from '../Text'
import {
  monthlyPlanTraining,
  monthlyPlanTrainingOther,
} from '../../../utils/apiPath'
import {
  createHoliday,
  createLeave,
  updateMonthlyPlan,
  createAcademyActivity,
} from '../api/Index'
import TextInput from '../../../components/Input/TextInput'
import {
  StyledTable,
  StyledTableCell,
  StyledTableData,
  StyledTableHead,
} from './styled'

export const checkChangeDate = (startDate, finishDate) => {
  if (_.isEmpty(startDate) || _.isEmpty(finishDate)) return false
  if (startDate.length != 10 || finishDate.length != 10) {
    return false
  }
  let newStartDate = new Date(startDate).getTime()
  let newFinishDate = new Date(finishDate).getTime()
  if (newStartDate > newFinishDate && newFinishDate < newStartDate) {
    return false
  }

  return true
}

export const handleEditCalendar = async ({
  state,
  dataEvent,
  workType,
  stateForm,
  setIsLoading,
  options,
  optionSubWorkType,
  setDialog,
  setState,
}) => {
  setIsLoading(true)

  const { area } = state
  let result = {
    status: true,
    value: [],
  }
  let body = {}

  const { holiday, leave, academyActivity } = stateForm

  const findObjectHoliday = findWorkTypeAndSubWorkType({
    optionWorkType: options.work,
    optionSubWorkType,
    workType,
    subWorkType: _.get(holiday, 'holidayType', ''),
  })

  const findObject = findWorkTypeAndSubWorkType({
    optionWorkType: options.work,
    optionSubWorkType,
    workType,
    subWorkType: _.get(leave, 'subWorkType', ''),
  })

  const { objWorkType, objSubWorkType } = findWorkTypeAndSubWorkType({
    optionWorkType: options.work,
    optionSubWorkType,
    workType: workType,
    subWorkType: _.get(academyActivity, 'subWorkType', ''),
  })
  const nameSubWorkType = _.get(objSubWorkType, 'subWorkType', '')

  let listDates = []
  let staffArea = {}
  let staffs = []
  const bodyTraining = convertBody({ body: _.get(stateForm, 'training', []) })
  switch (workType) {
    case WORK_WORK_LIST[0]:
      //?    วันหยุด

      body = {
        ...dataEvent,
        startDate: dayjs(_.get(holiday, 'startDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        ),
        finishDate: dayjs(_.get(holiday, 'finishDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        ),
        workType: _.get(findObjectHoliday, 'objWorkType', {}),
        subWorkType: _.get(findObjectHoliday, 'objSubWorkType', {}),
        title: _.get(holiday, 'dayOff', ''),
        remark: _.get(holiday, 'remark', ''),
        subject: '',
        plans: _.get(holiday, 'plans', []),
      }
      delete body.id
      result = await updateMonthlyPlan({
        workType,
        body,
      })

      break
    case WORK_WORK_LIST[1]:
      //?    วันลา

      listDates = _.get(leave, 'leavePeriod.value', []).length
        ? _.get(leave, 'leavePeriod.value', []).map((item) => {
            return {
              date: dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB),
              typeTime: item.type.value,
            }
          })
        : []
      body = {
        ...dataEvent,
        startDate: dayjs(_.get(leave, 'startDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        ),
        finishDate: dayjs(_.get(leave, 'finishDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        ),
        workType: _.get(findObject, 'objWorkType', {}),
        subWorkType: _.get(findObject, 'objSubWorkType', {}),
        description: _.get(leave, 'remarkLeave', ''),
        remark: _.get(leave, 'remark', ''),
        staffs: _.get(leave, 'staff', []).length
          ? _.get(leave, 'staff', [])
          : _.get(leave, 'staff.label', []),
        dates: listDates,
        period: _.get(leave, 'leavePeriod.type', []),
        plans: _.get(leave, 'plans', []),
      }
      delete body.id
      result = await updateMonthlyPlan({
        workType,
        body,
      })
      break
    case WORK_WORK_LIST[2]:
      //?    Academy Activity

      staffArea = options.monthlyPlanStaff.find((item) => {
        return item.uuid === _.get(academyActivity, 'toMeetingRoom', '')
      })

      staffs = _.get(academyActivity, 'attendees', [])

      if (_.size(staffs) > 0) {
        staffs = _.map(staffs, (item) => {
          return {
            id: _.get(item, 'id'),
            label: _.get(item, 'label'),
            uuid: _.get(item, 'uuid'),
            value: _.get(item, 'value'),
          }
        })
      }

      body = {
        uuid: _.get(dataEvent, 'uuid', ''),
        workType: objWorkType,
        subWorkType: objSubWorkType,
        startDate: dayjs(_.get(academyActivity, 'startDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        ),
        finishDate: dayjs(_.get(academyActivity, 'finishDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        ),
        dates: _.get(academyActivity, 'expandDate', []),
        staffs,
        plans: [],
        status: 'ACTIVE',
        staffProfile:
          _.get(academyActivity, 'meetingType', 'ONLINE').toUpperCase() !==
          'ONLINE'
            ? staffArea
            : {},
        checkAllUser: _.get(academyActivity, 'checkAllUser', false),
        area: {
          id: area.id,
          uuid: area.value,
        },
      }
      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
        body = {
          ...body,
          description: _.get(academyActivity, 'remark', ''),
          workPlace: _.get(
            academyActivity,
            'workPlace',
            'OFFICE',
          ).toUpperCase(),
        }
      }
      if (
        nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6] ||
        nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]
      ) {
        body = {
          ...body,
          title: _.get(academyActivity, 'title', ''),
          description: _.get(academyActivity, 'description', ''),
          meetingType: _.get(
            academyActivity,
            'meetingType',
            'ONLINE',
          ).toUpperCase(),
          isUseRoom:
            _.get(academyActivity, 'meetingType', 'ONLINE').toUpperCase() ===
            'ONLINE'
              ? false
              : _.get(academyActivity, 'isUseRoom', true),
        }
      }

      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
        body = {
          ...body,
          description: _.get(academyActivity, 'description', ''),
          travelType: _.get(
            academyActivity,
            'travelType',
            'FORWARD',
          ).toUpperCase(),
        }
      }

      if (
        nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1] ||
        nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2] ||
        nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]
      ) {
        const { course, speakers, responsibility } = options

        const objCourse = course?.find((itemCourse) => {
          return itemCourse.value === _.get(academyActivity, 'course', '')
        })

        body = {
          ...body,
          title: _.get(academyActivity, 'title', ''),
          description: _.get(academyActivity, 'description', ''),
          meetingType: _.get(
            academyActivity,
            'meetingType',
            'ONLINE',
          ).toUpperCase(),
          isUseRoom:
            _.get(academyActivity, 'meetingType', 'ONLINE').toUpperCase() ===
            'ONLINE'
              ? false
              : _.get(academyActivity, 'isUseRoom', true),
          staffProfile:
            _.get(academyActivity, 'meetingType', 'ONLINE').toUpperCase() !==
            'ONLINE'
              ? staffArea
              : {},
          course: !_.isEmpty(objCourse)
            ? {
                id: _.get(objCourse, 'id', ''),
                uuid: _.get(objCourse, 'value', ''),
              }
            : {},

          dates: _.get(academyActivity, 'expandDate', []).map((item) => {
            return {
              ...item,
              speakers: item?.speakers?.length
                ? item.speakers.map((itemSK) => {
                    let objSpeaker = speakers?.find((itemStaff) => {
                      return itemStaff.value === itemSK.staff
                    })
                    if (!objSpeaker) objSpeaker = itemSK.listStaff
                    const objResponsibility = responsibility?.find((itemRB) => {
                      return itemRB.value === itemSK.speakerRole
                    })

                    return {
                      startTime: itemSK.startTime,
                      endTime: itemSK.endTime,
                      staff: !_.isEmpty(objSpeaker)
                        ? {
                            id: _.get(objSpeaker, 'id', ''),
                            uuid: _.get(objSpeaker, 'uuid', ''),
                          }
                        : {},
                      responsibility: !_.isEmpty(objResponsibility)
                        ? {
                            id: objResponsibility?.id || '',
                            uuid: objResponsibility?.value || '',
                          }
                        : {},
                    }
                  })
                : [],
              speakerBorrow: _.get(item, 'speakerBorrow', []).map((data) => {
                if (
                  _.isNil(data.department) &&
                  _.isNil(data.station) &&
                  data.approverDepartment &&
                  data.approverStation
                ) {
                  const approverDepartment = _.get(
                    data,
                    'approverDepartment',
                    {},
                  )
                  const department = {
                    ...approverDepartment,
                    value: _.get(approverDepartment, 'uuid', ''),
                  }
                  const station = _.get(data, 'approverStation.uuid', '')
                  return { ...data, department, station }
                }
                return data
              }),
            }
          }),
        }
      }

      result = await updateMonthlyPlan({
        workType,
        body: body,
        nameSubWorkType,
      })

      break
    case WORK_WORK_LIST[3]:
      //?    Training
      body = {
        ...bodyTraining,
        dates: _.get(bodyTraining, 'dates', []).map((date) => ({
          ...date,
          speakerBorrow: _.get(date, 'speakerBorrow', []).map((data) => {
            if (
              _.isNil(data.department) &&
              _.isNil(data.station) &&
              data.approverDepartment &&
              data.approverStation
            ) {
              const approverDepartment = _.get(data, 'approverDepartment', {})
              const department = {
                ...approverDepartment,
                value: _.get(approverDepartment, 'uuid', ''),
              }
              const station = _.get(data, 'approverStation.uuid', '')
              return { ...data, department, station }
            }
            return data
          }),
        })),
      }

      result = await updateMonthlyPlan({
        workType,
        body: body,
      })
      break
    case WORK_WORK_LIST[4]:
      //?    Activity Support

      result = await updateMonthlyPlan({
        workType,
        body: bodyTraining,
      })
      break

    default:
      break
  }

  const { status, value, text } = result
  let newStatus = status

  setDialog({
    open: false,
    isLoading: false,
    variant: '',
  })
  if (newStatus) {
    setState((prev) => ({
      ...prev,
      isUpdateSuccess: true,
      handleType: 'actions',
    }))
    setDialog((prevState) => ({
      ...prevState,
      open: true,
      variant: 'success',
      title: 'สำเร็จ',
      content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      isError: true,
    }))
  } else {
    const constraintsKey = _.get(value, 'constraints.key', '')
    const titleText = _.get(value, 'constraints.title', 'Request failed')

    if (constraintsKey === 'IsRequestSendRemarkEditDateLate') {
      //remark edit date
      const error = { reason: null }
      let value = { reason: null }
      const clearInput = true
      stateForm['training']['remarkEditDate'] = ''

      const getActionPlanContentDialog = (
        clearInput,
        value,
        error,
        stateForm,
      ) => {
        return (
          <TextInput
            id="reason"
            name="reason"
            labelText="เหตุผลการแก้ไขวันที่อบรม"
            type="basic"
            required
            textError={_.get(error, 'reason', '')}
            isShowTextError={true}
            onChange={(e) => {
              if (e.target.value !== '') {
                error.reason = ''
              }
              if (clearInput) {
                e.target.value = ''
                clearInput = false
              } else {
                stateForm['training']['remarkEditDate'] = e.target.value
              }

              value.reason = e.target.value
              setDialog((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }}
            multiline={true}
            rows={2}
          ></TextInput>
        )
      }

      setDialog({
        open: true,
        variant: 'save',
        title: titleText,
        content: getActionPlanContentDialog(
          clearInput,
          value,
          error,
          stateForm,
        ),
        value: value,
        agreeText: 'ยืนยัน',
        error: { reason: '' },
      })
    } else if (constraintsKey === 'IsDateAvailableConstraint') {
      const errorDialog = {
        variant: 'fail',
        open: true,
        title: titleText,
        content: handleMessage({
          listError: _.get(value, 'constraints.value', []),
          text: text,
        }),
        isError: true,
        maxWidth: 'sm',
      }

      setDialog(errorDialog)
    } else if (constraintsKey === 'IsOverlappingClasses') {
      const errorDialog = {
        variant: 'fail overlapping',
        open: true,
        title: titleText,

        content: handleOverlappingMessage(
          _.get(value, 'constraints.value', []),
        ),
        isError: true,
        maxWidth: 'sm',
      }

      setDialog(errorDialog)
    }
  }

  setIsLoading(false)
}

const convertBody = ({ body }) => {
  const { dates, dateBetween, typeDate } = body
  const countDateBetween = dateBetween?.length || 0
  const lastDateBetweenIndex = countDateBetween === 0 ? 0 : countDateBetween - 1
  const convertDates = dates?.map((item) => {
    return {
      ...item,
      speakerBorrow: item.speakerBorrow.map((borrowItem) => {
        const newBorrowItem =
          typeDate === 'DUPLICATE'
            ? {
                ...borrowItem,
                finishDate: dateBetween[lastDateBetweenIndex],
                startDate: dateBetween[0],
              }
            : { ...borrowItem }

        if (newBorrowItem.requester) {
          newBorrowItem['requester'] = {
            ...newBorrowItem.requester,
            roleAreaInfo: {
              ...newBorrowItem.requester.roleAreaInfo,
              area: undefined,
            },
          }
        }
        if (newBorrowItem.approver) {
          newBorrowItem['approver'] = {
            ...newBorrowItem.approver,
            roleAreaInfo: {
              ...newBorrowItem.approver.roleAreaInfo,
              area: undefined,
            },
          }
        }

        if (
          _.isNil(newBorrowItem.department) &&
          _.isNil(newBorrowItem.station) &&
          newBorrowItem.approverDepartment &&
          newBorrowItem.approverStation
        ) {
          const approverDepartment = _.get(
            newBorrowItem,
            'approverDepartment',
            {},
          )
          const department = {
            ...approverDepartment,
            value: _.get(approverDepartment, 'uuid', ''),
          }
          const station = _.get(newBorrowItem, 'approverStation.uuid', '')
          return { ...newBorrowItem, department, station }
        }
        return newBorrowItem
      }),
    }
  })

  body.dates = convertDates
  delete body.defaultDates
  return body
}

const removeApprover = ({ stateForm }) => {
  const stateFormDeleteBorrowApprover = stateForm.training.dates.map((item) => {
    item.speakerBorrow.map((speaker) => {
      const newSpeaker = { ...speaker }
      if (newSpeaker.approver) delete newSpeaker.approver
      return newSpeaker
    })
    return item
  })

  stateForm.training.dates = stateFormDeleteBorrowApprover
}

export const handleAddCalendar = async ({
  state,
  stateForm,
  setIsLoading,
  options,
  optionSubWorkType,
  setDialog,
  setState,
}) => {
  setIsLoading(true)

  let result = {
    status: false,
    value: [],
  }

  switch (state.work.value) {
    case WORK_WORK_LIST[0]:
      //?    วันหยุด
      result = await createHoliday({
        optionWorkType: options.work,
        stateForm: stateForm.holiday,
        optionSubWorkType,
      })
      break
    case WORK_WORK_LIST[1]:
      //?    วันลา
      result = await createLeave({
        state,
        stateForm: stateForm.leave,
        optionWorkType: options.work,
        optionSubWorkType,
      })
      break
    case WORK_WORK_LIST[2]:
      //?    Academy Activity
      result = await createAcademyActivity({
        state,
        stateForm: stateForm.academyActivity,
        optionWorkType: options.work,
        optionSubWorkType,
        optionMonthlyPlanStaff: options.monthlyPlanStaff,
        options,
      })
      break
    case WORK_WORK_LIST[3]:
      //?    Training
      removeApprover({ stateForm })
      result = await createTrainingAll({
        url: monthlyPlanTraining,
        body: stateForm.training,
      })

      break
    case WORK_WORK_LIST[4]:
      //?    Activity Support
      removeApprover({ stateForm })
      result = await createTrainingAll({
        url: monthlyPlanTrainingOther,
        body: stateForm.training,
      })
      break

    default:
      break
  }
  const { status, value, text } = result
  let newStatus = status
  if (_.get(value, 'isAxiosError', false)) newStatus = false

  setDialog({
    open: false,
    isLoading: false,
    variant: '',
  })

  if (newStatus) {
    setState((prev) => ({
      ...prev,
      isUpdateSuccess: true,
      handleType: 'actions',
    }))
    setDialog((prevState) => ({
      ...prevState,
      open: true,
      variant: 'success',
      title: 'สำเร็จ',
      content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      isError: true,
    }))
  } else {
    const constraintsKey = _.get(value, 'constraints.key', '')
    if (
      constraintsKey === 'IsRequestSendRemarkEditDateLate' ||
      constraintsKey === 'IsDateAvailableConstraint' ||
      constraintsKey === 'IsOverlappingClasses'
    ) {
      const titleText = _.get(value, 'constraints.title', 'Request failed')
      const errorDialog = {
        variant:
          constraintsKey === 'IsOverlappingClasses'
            ? 'fail overlapping'
            : 'fail',
        open: true,
        title: titleText,
        content:
          constraintsKey === 'IsDateAvailableConstraint'
            ? handleMessage({
                listError: _.get(value, 'constraints.value', []),
                text: text,
              })
            : constraintsKey === 'IsOverlappingClasses'
            ? handleOverlappingMessage(_.get(value, 'constraints.value', []))
            : _.get(value, 'constraints.value', ''),
        isError: true,
        maxWidth: 'sm',
      }

      setDialog(errorDialog)
    }
  }

  setIsLoading(false)
}

export const handleStyleEvent = ({ work, leave }) => {
  let styleEvent = {}
  switch (work) {
    case WORK_WORK_LIST[0]:
      //?   วันหยุด
      styleEvent = {
        color: themeColor.text.primary,
        backgroundColor: themeColor.common.white,
        borderColor: themeColor.monthlyPlan.holidayBorder,
        eventColor: themeColor.monthlyPlan.holidayBorder,
      }
      break
    case WORK_WORK_LIST[1]:
      //?   วันลา
      switch (leave) {
        case LEAVE_LIST[0]:
          //!  Annual Leave
          styleEvent = {
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.annualLeaveBorder,
            eventColor: themeColor.monthlyPlan.annualLeaveBorder,
            border: `1px solid ${themeColor.monthlyPlan.annualLeaveBorder}`,
          }
          break
        case LEAVE_LIST[1]:
          //!    Birthday Leave
          styleEvent = {
            textColor: themeColor.text.primary,
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.birthdayLeaveBorder,
            eventColor: themeColor.monthlyPlan.birthdayLeaveBorder,
            border: `1px solid ${themeColor.monthlyPlan.birthdayLeaveBorder}`,
          }
          break
        case LEAVE_LIST[2]:
          //!    Family Care Leave
          styleEvent = {
            textColor: themeColor.text.primary,
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.familyCareLeaveBorder,
            eventColor: themeColor.monthlyPlan.familyCareLeaveBorder,
            border: `1px solid ${themeColor.monthlyPlan.familyCareLeaveBorder}`,
          }
          break
        case LEAVE_LIST[3]:
          //!    Personal Leave
          styleEvent = {
            textColor: themeColor.text.primary,
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.personalLeaveBorder,
            eventColor: themeColor.monthlyPlan.personalLeaveBorder,
            border: `1px solid ${themeColor.monthlyPlan.personalLeaveBorder}`,
          }
          break
        case LEAVE_LIST[4]:
          //!    Sick Leave
          styleEvent = {
            textColor: themeColor.text.primary,
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.sickLeaveBorder,
            eventColor: themeColor.monthlyPlan.sickLeaveBorder,
            border: `1px solid ${themeColor.monthlyPlan.sickLeaveBorder}`,
          }
          break
        case LEAVE_LIST[5]:
          //!    Other Leave
          styleEvent = {
            textColor: themeColor.text.primary,
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.otherLeaveBorder,
            eventColor: themeColor.monthlyPlan.otherLeaveBorder,
            border: `1px solid ${themeColor.monthlyPlan.otherLeaveBorder}`,
          }
          break
        case LEAVE_LIST[6]:
          //!    Day Off
          styleEvent = {
            textColor: themeColor.text.primary,
            color: themeColor.text.primary,
            borderColor: themeColor.monthlyPlan.dayOffBorder,
            eventColor: themeColor.monthlyPlan.dayOffBorder,
            border: `1px solid ${themeColor.monthlyPlan.dayOffBorder}`,
          }
          break

        default:
          styleEvent = {
            color: themeColor.text.primary,
            backgroundColor: themeColor.common.white,
            border: `1px solid ${themeColor.monthlyPlan.otherLeaveBorder}`,
          }

          break
      }
      break
    case WORK_WORK_LIST[2]:
      //?   Academy Activity
      styleEvent = {
        color: themeColor.text.primary,
        backgroundColor: themeColor.monthlyPlan.academyActivity,
        eventColor: themeColor.monthlyPlan.academyActivity,
      }
      break
    case WORK_WORK_LIST[5]:
      //?   Office Work
      styleEvent = {
        color: themeColor.text.primary,
        backgroundColor: themeColor.monthlyPlan.academyActivity,
        eventColor: themeColor.monthlyPlan.academyActivity,
      }
      break
    case WORK_WORK_LIST[3]:
      //?   Training
      styleEvent = {
        textColor: themeColor.text.primary,
        backgroundColor: themeColor.monthlyPlan.training,
        eventColor: themeColor.monthlyPlan.training,
      }
      break
    case WORK_WORK_LIST[4]:
      //?   Activity Support
      styleEvent = {
        textColor: themeColor.text.primary,
        backgroundColor: themeColor.monthlyPlan.training,
        eventColor: themeColor.monthlyPlan.training,
      }
      break
    default:
      break
  }
  return styleEvent
}

export const handleStyleBorrow = ({ isBorrow }) => {
  return isBorrow
    ? {
        border: `1px dashed ${themeColor.text.gray}`,
        backgroundColor: themeColor.grey.grey200,
        color: themeColor.text.lightGray,
        borderRadius: '4px',
      }
    : {}
}

export const handleStyleStatus = ({ status }) => {
  const style = {
    REQUEST_EDIT: {
      border: `1px solid ${themeColor.info.main}`,
      borderLeft: `4px solid ${themeColor.info.main}`,
      borderRadius: '4px',
    },
    REQUEST_CREATE: {
      border: `1px solid ${themeColor.success.main}`,
      borderLeft: `4px solid ${themeColor.success.main}`,
      borderRadius: '4px',
    },
    REQUEST_DELETE: {
      color: themeColor.text.primary,
      border: `1px solid ${themeColor.error.main}`,
      borderLeft: `4px solid ${themeColor.error.main}`,
      backgroundColor: themeColor.monthlyPlan.training,
      borderRadius: '4px',
    },
    WAITING_APPROVE_BORROW: {
      border: `1px solid ${themeColor.warning.main}`,
      borderLeft: `4px solid ${themeColor.warning.main}`,
      borderRadius: '4px',
    },
    DELETED: {
      color: themeColor.text.lightGray,
      backgroundColor: themeColor.grey.grey200,
      border: `1px dashed ${themeColor.error.main} !important`,
      borderRadius: '4px',
    },
    CANCEL: {
      color: themeColor.text.lightGray,
      backgroundColor: themeColor.grey.grey200,
      border: `1px dashed ${themeColor.error.main} !important`,
      borderRadius: '4px',
    },
  }
  return style[status] || {}
}
export const FormMessage = ({ listError }) => {
  let result = []
  if (!_.isEmpty(listError)) {
    let index = 0
    for (let name in listError) {
      const listValue = listError[name]
      result.push(
        <Box sx={{ mb: 2, mt: index === 0 ? 2 : 0 }}>
          <Typography variant="body1">กิจกรรมอื่นๆของ {name} :</Typography>
          {listValue.length ? (
            listValue.map((item, index) => (
              <Typography key={index} variant="body1">
                {item.date} เวลา {item.time + ' ' + item.title}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>,
      )
      index++
    }
  } else {
    result.push(<></>)
  }

  return result
}

export const handleMessage = ({ listError, text = '' }) => {
  const newObj = listError.length ? _.groupBy(listError, 'name') : []
  return (
    <Box sx={{ ml: 6, mr: 2 }}>
      <Box>
        <Typography variant="body1">
          เนื่องจาก{text} มีกิจกรรมอื่นในช่วงเวลาเดียวกัน
          หากต้องการเพิ่มตารางงาน
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">กรุณาเลื่อนเวลาของกิจกรรม</Typography>
      </Box>
      <FormMessage listError={newObj} />
    </Box>
  )
}

export const handleOverlappingMessage = (listError) => {
  return (
    <Box sx={{ ml: 2 }}>
      <Typography
        variant="body1"
        sx={{ paddingLeft: '16px', paddingBottom: '24px' }}
      >
        เนื่องจากวันที่เลือกมีผู้เรียนที่อยู่ในคลาสนี้มีคลาสเรียนอื่นแล้ว
      </Typography>
      <StyledTable>
        <StyledTableHead>
          <Typography>No.</Typography>
          <Typography>Name</Typography>
          <Typography>Class</Typography>
        </StyledTableHead>
        {listError.map((item, index) => (
          <StyledTableData key={index}>
            <StyledTableCell>{handleNumber(index + 1)}</StyledTableCell>
            <StyledTableCell>{item.name}</StyledTableCell>
            <StyledTableCell color="#C91432">
              {item.course} {item.date} {item.time}
            </StyledTableCell>
          </StyledTableData>
        ))}
      </StyledTable>
    </Box>
  )
}

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}
