import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportRequisitionConst = {
  COLUMN_NAME: {
    NO: 'no',
    TICKET_ID: 'ticket_id',
    CREATE_DATE_TIME_TICKET: 'createDateTimeTicket',
    CREATE_BY: 'createdBy',
    UPDATE_DATE: 'updatedDate',
    UPDATE_BY: 'updatedBy',
    STAFF_ID: 'staffId',
    STAFF_NAME_TH: 'staffNameTH',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_LEARNER: 'courseNameLearner',
    TRAINING_DATE_START: 'trainingDateStart',
    TRAINING_DATE_FINISH: 'trainingDateFinish',
    STATION: 'station',
    COURSE_PLATFORM: 'coursePlatform',
    DISTRIBUTION_CHANNELS: 'distributionChannels',
    MONTHLY_PLAN_TYPE: 'monthlyPlanType',
    WITHDRAWAL_TYPE: 'withdrawalType',
    WAREHOUSE: 'warehouse',
    STATION_ASSET_WAREHOUSE: 'stationAssetWarehouse',
    RETURN_DUE_DATE: 'returnDueDate',
    RETURN_DATE: 'returnDate',
    RETURN_OVE_DUE_DATE: 'returnOveDueDate',
    PRODUCT_NAME_1: 'productName_1',
    TOTAL_1: 'total_1',
    TOTAL_PRICE_1: 'totalPrice_1',
    PRODUCT_CODE_1: 'productCode_1',
    TOTAL_RETURN_1: 'totalReturn_1',
    PRODUCT_NAME_2: 'productName_2',
    TOTAL_2: 'total_2',
    TOTAL_PRICE_2: 'totalPrice_2',
    PRODUCT_CODE_2: 'productCode_2',
    TOTAL_RETURN_2: 'totalReturn_2',
    PRODUCT_NAME_3: 'productName_3',
    TOTAL_3: 'total_3',
    TOTAL_PRICE_3: 'totalPrice_3',
    PRODUCT_CODE_3: 'productCode_3',
    TOTAL_RETURN_3: 'totalReturn_3',
    PRODUCT_NAME_4: 'productName_4',
    TOTAL_4: 'total_4',
    TOTAL_PRICE_4: 'totalPrice_4',
    PRODUCT_CODE_4: 'productCode_4',
    TOTAL_RETURN_4: 'totalReturn_4',
    PRODUCT_NAME_5: 'productName_5',
    TOTAL_5: 'total_5',
    TOTAL_PRICE_5: 'totalPrice_5',
    PRODUCT_CODE_5: 'productCode_5',
    TOTAL_RETURN_5: 'totalReturn_5',
    PRODUCT_NAME_6: 'productName_6',
    TOTAL_6: 'total_6',
    TOTAL_PRICE_6: 'totalPrice_6',
    PRODUCT_CODE_6: 'productCode_6',
    TOTAL_RETURN_6: 'totalReturn_6',
    PRODUCT_NAME_7: 'productName_7',
    TOTAL_7: 'total_7',
    TOTAL_PRICE_7: 'totalPrice_7',
    PRODUCT_CODE_7: 'productCode_7',
    TOTAL_RETURN_7: 'totalReturn_7',
    PRODUCT_NAME_8: 'productName_8',
    TOTAL_8: 'total_8',
    TOTAL_PRICE_8: 'totalPrice_8',
    PRODUCT_CODE_8: 'productCode_8',
    TOTAL_RETURN_8: 'totalReturn_8',
    PRODUCT_NAME_9: 'productName_9',
    TOTAL_9: 'total_9',
    TOTAL_PRICE_9: 'totalPrice_9',
    PRODUCT_CODE_9: 'productCode_9',
    TOTAL_RETURN_9: 'totalReturn_9',
    PRODUCT_NAME_10: 'productName_10',
    TOTAL_10: 'total_10',
    TOTAL_PRICE_10: 'totalPrice_10',
    PRODUCT_CODE_10: 'productCode_10',
    TOTAL_RETURN_10: 'totalReturn_10',
    PRODUCT_NAME_11: 'productName_11',
    TOTAL_11: 'total_11',
    TOTAL_PRICE_11: 'totalPrice_11',
    PRODUCT_CODE_11: 'productCode_11',
    TOTAL_RETURN_11: 'totalReturn_11',
    PRODUCT_NAME_12: 'productName_12',
    TOTAL_12: 'total_12',
    TOTAL_PRICE_12: 'totalPrice_12',
    PRODUCT_CODE_12: 'productCode_12',
    TOTAL_RETURN_12: 'totalReturn_12',
    PRODUCT_NAME_13: 'productName_13',
    TOTAL_13: 'total_13',
    TOTAL_PRICE_13: 'totalPrice_13',
    PRODUCT_CODE_13: 'productCode_13',
    TOTAL_RETURN_13: 'totalReturn_13',
    PRODUCT_NAME_14: 'productName_14',
    TOTAL_14: 'total_14',
    TOTAL_PRICE_14: 'totalPrice_14',
    PRODUCT_CODE_14: 'productCode_14',
    TOTAL_RETURN_14: 'totalReturn_14',
    PRODUCT_NAME_15: 'productName_15',
    TOTAL_15: 'total_15',
    TOTAL_PRICE_15: 'totalPrice_15',
    PRODUCT_CODE_15: 'productCode_15',
    TOTAL_RETURN_15: 'totalReturn_15',
    PRODUCT_NAME_16: 'productName_16',
    TOTAL_16: 'total_16',
    TOTAL_PRICE_16: 'totalPrice_16',
    PRODUCT_CODE_16: 'productCode_16',
    TOTAL_RETURN_16: 'totalReturn_16',
    PRODUCT_NAME_17: 'productName_17',
    TOTAL_17: 'total_17',
    TOTAL_PRICE_17: 'totalPrice_17',
    PRODUCT_CODE_17: 'productCode_17',
    TOTAL_RETURN_17: 'totalReturn_17',
    PRODUCT_NAME_18: 'productName_18',
    TOTAL_18: 'total_18',
    TOTAL_PRICE_18: 'totalPrice_18',
    PRODUCT_CODE_18: 'productCode_18',
    TOTAL_RETURN_18: 'totalReturn_18',
    PRODUCT_NAME_19: 'productName_19',
    TOTAL_19: 'total_19',
    TOTAL_PRICE_19: 'totalPrice_19',
    PRODUCT_CODE_19: 'productCode_19',
    TOTAL_RETURN_19: 'totalReturn_19',
    PRODUCT_NAME_20: 'productName_20',
    TOTAL_20: 'total_20',
    TOTAL_PRICE_20: 'totalPrice_20',
    PRODUCT_CODE_20: 'productCode_20',
    TOTAL_RETURN_20: 'totalReturn_20',
    PRODUCT_NAME_21: 'productName_21',
    TOTAL_21: 'total_21',
    TOTAL_PRICE_21: 'totalPrice_21',
    PRODUCT_CODE_21: 'productCode_21',
    TOTAL_RETURN_21: 'totalReturn_21',
    PRODUCT_NAME_22: 'productName_22',
    TOTAL_22: 'total_22',
    TOTAL_PRICE_22: 'totalPrice_22',
    PRODUCT_CODE_22: 'productCode_22',
    TOTAL_RETURN_22: 'totalReturn_22',
    PRODUCT_NAME_23: 'productName_23',
    TOTAL_23: 'total_23',
    TOTAL_PRICE_23: 'totalPrice_23',
    PRODUCT_CODE_23: 'productCode_23',
    TOTAL_RETURN_23: 'totalReturn_23',
    PRODUCT_NAME_24: 'productName_24',
    TOTAL_24: 'total_24',
    TOTAL_PRICE_24: 'totalPrice_24',
    PRODUCT_CODE_24: 'productCode_24',
    TOTAL_RETURN_24: 'totalReturn_24',
    PRODUCT_NAME_25: 'productName_25',
    TOTAL_25: 'total_25',
    TOTAL_PRICE_25: 'totalPrice_25',
    PRODUCT_CODE_25: 'productCode_25',
    TOTAL_RETURN_25: 'totalReturn_25',
    PRODUCT_NAME_26: 'productName_26',
    TOTAL_26: 'total_26',
    TOTAL_PRICE_26: 'totalPrice_26',
    PRODUCT_CODE_26: 'productCode_26',
    TOTAL_RETURN_26: 'totalReturn_26',
    PRODUCT_NAME_27: 'productName_27',
    TOTAL_27: 'total_27',
    TOTAL_PRICE_27: 'totalPrice_27',
    PRODUCT_CODE_27: 'productCode_27',
    TOTAL_RETURN_27: 'totalReturn_27',
    PRODUCT_NAME_28: 'productName_28',
    TOTAL_28: 'total_28',
    TOTAL_PRICE_28: 'totalPrice_28',
    PRODUCT_CODE_28: 'productCode_28',
    TOTAL_RETURN_28: 'totalReturn_28',
    PRODUCT_NAME_29: 'productName_29',
    TOTAL_29: 'total_29',
    TOTAL_PRICE_29: 'totalPrice_29',
    PRODUCT_CODE_29: 'productCode_29',
    TOTAL_RETURN_29: 'totalReturn_29',
    PRODUCT_NAME_30: 'productName_30',
    TOTAL_30: 'total_30',
    TOTAL_PRICE_30: 'totalPrice_30',
    PRODUCT_CODE_30: 'productCode_30',
    TOTAL_RETURN_30: 'totalReturn_30',
    STATUS: 'status',
    REMARK: 'remark',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    TICKET_ID: 'Ticket ID',
    CREATE_DATE_TIME_TICKET: 'Create DateTime Ticket',
    CREATE_BY: 'Create by',
    UPDATE_DATE: 'Update date',
    UPDATE_BY: 'Update by',
    STAFF_ID: 'Staff ID ผู้รับผิดชอบ',
    STAFF_NAME_TH: 'Staff Name TH ผู้รับผิดชอบ',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
    TRAINING_DATE_START: 'วันเริ่มต้น (Training Date)',
    TRAINING_DATE_FINISH: 'วันสิ้นสุด  (Training Date)',
    STATION: 'Station',
    COURSE_PLATFORM: 'รูปแบบของการสอน',
    DISTRIBUTION_CHANNELS: 'ช่องทางการจำหน่าย',
    MONTHLY_PLAN_TYPE: 'Monthly Plan Type',
    WITHDRAWAL_TYPE: 'ประเภทรายการเบิก',
    WAREHOUSE: 'คลังทรัพย์สิน',
    STATION_ASSET_WAREHOUSE: 'Station คลังทรัพย์สิน',
    RETURN_DUE_DATE: 'วันที่กำหนดคืนของ',
    RETURN_DATE: 'วันที่คืนของจริง',
    RETURN_OVE_DUE_DATE: 'เกินกำหนดคืน',
    PRODUCT_NAME_1: 'ชื่อทรัพย์สิน_1',
    TOTAL_1: 'Total (จำนวนที่เบิก)_1',
    TOTAL_PRICE_1: 'Total_Price',
    PRODUCT_CODE_1: 'รหัสทรัพย์สิน_1',
    TOTAL_RETURN_1: 'Total (จำนวนคืน)_1',
    PRODUCT_NAME_2: 'ชื่อทรัพย์สิน_2',
    TOTAL_2: 'Total (จำนวนที่เบิก)_2',
    TOTAL_PRICE_2: 'Total_Price',
    PRODUCT_CODE_2: 'รหัสทรัพย์สิน_2',
    TOTAL_RETURN_2: 'Total (จำนวนคืน)_2',
    PRODUCT_NAME_3: 'ชื่อทรัพย์สิน_3',
    TOTAL_3: 'Total (จำนวนที่เบิก)_3',
    TOTAL_PRICE_3: 'Total_Price',
    PRODUCT_CODE_3: 'รหัสทรัพย์สิน_3',
    TOTAL_RETURN_3: 'Total (จำนวนคืน)_3',
    PRODUCT_NAME_4: 'ชื่อทรัพย์สิน_4',
    TOTAL_4: 'Total (จำนวนที่เบิก)_4',
    TOTAL_PRICE_4: 'Total_Price',
    PRODUCT_CODE_4: 'รหัสทรัพย์สิน_4',
    TOTAL_RETURN_4: 'Total (จำนวนคืน)_4',
    PRODUCT_NAME_5: 'ชื่อทรัพย์สิน_5',
    TOTAL_5: 'Total (จำนวนที่เบิก)_5',
    TOTAL_PRICE_5: 'Total_Price',
    PRODUCT_CODE_5: 'รหัสทรัพย์สิน_5',
    TOTAL_RETURN_5: 'Total (จำนวนคืน)_5',
    PRODUCT_NAME_6: 'ชื่อทรัพย์สิน_6',
    TOTAL_6: 'Total (จำนวนที่เบิก)_6',
    TOTAL_PRICE_6: 'Total_Price',
    PRODUCT_CODE_6: 'รหัสทรัพย์สิน_6',
    TOTAL_RETURN_6: 'Total (จำนวนคืน)_6',
    PRODUCT_NAME_7: 'ชื่อทรัพย์สิน_7',
    TOTAL_7: 'Total (จำนวนที่เบิก)_7',
    TOTAL_PRICE_7: 'Total_Price',
    PRODUCT_CODE_7: 'รหัสทรัพย์สิน_7',
    TOTAL_RETURN_7: 'Total (จำนวนคืน)_7',
    PRODUCT_NAME_8: 'ชื่อทรัพย์สิน_8',
    TOTAL_8: 'Total (จำนวนที่เบิก)_8',
    TOTAL_PRICE_8: 'Total_Price',
    PRODUCT_CODE_8: 'รหัสทรัพย์สิน_8',
    TOTAL_RETURN_8: 'Total (จำนวนคืน)_8',
    PRODUCT_NAME_9: 'ชื่อทรัพย์สิน_9',
    TOTAL_9: 'Total (จำนวนที่เบิก)_9',
    TOTAL_PRICE_9: 'Total_Price',
    PRODUCT_CODE_9: 'รหัสทรัพย์สิน_9',
    TOTAL_RETURN_9: 'Total (จำนวนคืน)_9',
    PRODUCT_NAME_10: 'ชื่อทรัพย์สิน_10',
    TOTAL_10: 'Total (จำนวนที่เบิก)_10',
    TOTAL_PRICE_10: 'Total_Price',
    PRODUCT_CODE_10: 'รหัสทรัพย์สิน_10',
    TOTAL_RETURN_10: 'Total (จำนวนคืน)_10',
    PRODUCT_NAME_11: 'ชื่อทรัพย์สิน_11',
    TOTAL_11: 'Total (จำนวนที่เบิก)_11',
    TOTAL_PRICE_11: 'Total_Price',
    PRODUCT_CODE_11: 'รหัสทรัพย์สิน_11',
    TOTAL_RETURN_11: 'Total (จำนวนคืน)_11',
    PRODUCT_NAME_12: 'ชื่อทรัพย์สิน_12',
    TOTAL_12: 'Total (จำนวนที่เบิก)_12',
    TOTAL_PRICE_12: 'Total_Price',
    PRODUCT_CODE_12: 'รหัสทรัพย์สิน_12',
    TOTAL_RETURN_12: 'Total (จำนวนคืน)_12',
    PRODUCT_NAME_13: 'ชื่อทรัพย์สิน_13',
    TOTAL_13: 'Total (จำนวนที่เบิก)_13',
    TOTAL_PRICE_13: 'Total_Price',
    PRODUCT_CODE_13: 'รหัสทรัพย์สิน_13',
    TOTAL_RETURN_13: 'Total (จำนวนคืน)_13',
    PRODUCT_NAME_14: 'ชื่อทรัพย์สิน_14',
    TOTAL_14: 'Total (จำนวนที่เบิก)_14',
    TOTAL_PRICE_14: 'Total_Price',
    PRODUCT_CODE_14: 'รหัสทรัพย์สิน_14',
    TOTAL_RETURN_14: 'Total (จำนวนคืน)_14',
    PRODUCT_NAME_15: 'ชื่อทรัพย์สิน_15',
    TOTAL_15: 'Total (จำนวนที่เบิก)_15',
    TOTAL_PRICE_15: 'Total_Price',
    PRODUCT_CODE_15: 'รหัสทรัพย์สิน_15',
    TOTAL_RETURN_15: 'Total (จำนวนคืน)_15',
    PRODUCT_NAME_16: 'ชื่อทรัพย์สิน_16',
    TOTAL_16: 'Total (จำนวนที่เบิก)_16',
    TOTAL_PRICE_16: 'Total_Price',
    PRODUCT_CODE_16: 'รหัสทรัพย์สิน_16',
    TOTAL_RETURN_16: 'Total (จำนวนคืน)_16',
    PRODUCT_NAME_17: 'ชื่อทรัพย์สิน_17',
    TOTAL_17: 'Total (จำนวนที่เบิก)_17',
    TOTAL_PRICE_17: 'Total_Price',
    PRODUCT_CODE_17: 'รหัสทรัพย์สิน_17',
    TOTAL_RETURN_17: 'Total (จำนวนคืน)_17',
    PRODUCT_NAME_18: 'ชื่อทรัพย์สิน_18',
    TOTAL_18: 'Total (จำนวนที่เบิก)_18',
    TOTAL_PRICE_18: 'Total_Price',
    PRODUCT_CODE_18: 'รหัสทรัพย์สิน_18',
    TOTAL_RETURN_18: 'Total (จำนวนคืน)_18',
    PRODUCT_NAME_19: 'ชื่อทรัพย์สิน_19',
    TOTAL_19: 'Total (จำนวนที่เบิก)_19',
    TOTAL_PRICE_19: 'Total_Price',
    PRODUCT_CODE_19: 'รหัสทรัพย์สิน_19',
    TOTAL_RETURN_19: 'Total (จำนวนคืน)_19',
    PRODUCT_NAME_20: 'ชื่อทรัพย์สิน_20',
    TOTAL_20: 'Total (จำนวนที่เบิก)_20',
    TOTAL_PRICE_20: 'Total_Price',
    PRODUCT_CODE_20: 'รหัสทรัพย์สิน_20',
    TOTAL_RETURN_20: 'Total (จำนวนคืน)_20',
    PRODUCT_NAME_21: 'ชื่อทรัพย์สิน_21',
    TOTAL_21: 'Total (จำนวนที่เบิก)_21',
    TOTAL_PRICE_21: 'Total_Price',
    PRODUCT_CODE_21: 'รหัสทรัพย์สิน_21',
    TOTAL_RETURN_21: 'Total (จำนวนคืน)_21',
    PRODUCT_NAME_22: 'ชื่อทรัพย์สิน_22',
    TOTAL_22: 'Total (จำนวนที่เบิก)_22',
    TOTAL_PRICE_22: 'Total_Price',
    PRODUCT_CODE_22: 'รหัสทรัพย์สิน_22',
    TOTAL_RETURN_22: 'Total (จำนวนคืน)_22',
    PRODUCT_NAME_23: 'ชื่อทรัพย์สิน_23',
    TOTAL_23: 'Total (จำนวนที่เบิก)_23',
    TOTAL_PRICE_23: 'Total_Price',
    PRODUCT_CODE_23: 'รหัสทรัพย์สิน_23',
    TOTAL_RETURN_23: 'Total (จำนวนคืน)_23',
    PRODUCT_NAME_24: 'ชื่อทรัพย์สิน_24',
    TOTAL_24: 'Total (จำนวนที่เบิก)_24',
    TOTAL_PRICE_24: 'Total_Price',
    PRODUCT_CODE_24: 'รหัสทรัพย์สิน_24',
    TOTAL_RETURN_24: 'Total (จำนวนคืน)_24',
    PRODUCT_NAME_25: 'ชื่อทรัพย์สิน_25',
    TOTAL_25: 'Total (จำนวนที่เบิก)_25',
    TOTAL_PRICE_25: 'Total_Price',
    PRODUCT_CODE_25: 'รหัสทรัพย์สิน_25',
    TOTAL_RETURN_25: 'Total (จำนวนคืน)_25',
    PRODUCT_NAME_26: 'ชื่อทรัพย์สิน_26',
    TOTAL_26: 'Total (จำนวนที่เบิก)_26',
    TOTAL_PRICE_26: 'Total_Price',
    PRODUCT_CODE_26: 'รหัสทรัพย์สิน_26',
    TOTAL_RETURN_26: 'Total (จำนวนคืน)_26',
    PRODUCT_NAME_27: 'ชื่อทรัพย์สิน_27',
    TOTAL_27: 'Total (จำนวนที่เบิก)_27',
    TOTAL_PRICE_27: 'Total_Price',
    PRODUCT_CODE_27: 'รหัสทรัพย์สิน_27',
    TOTAL_RETURN_27: 'Total (จำนวนคืน)_27',
    PRODUCT_NAME_28: 'ชื่อทรัพย์สิน_28',
    TOTAL_28: 'Total (จำนวนที่เบิก)_28',
    TOTAL_PRICE_28: 'Total_Price',
    PRODUCT_CODE_28: 'รหัสทรัพย์สิน_28',
    TOTAL_RETURN_28: 'Total (จำนวนคืน)_28',
    PRODUCT_NAME_29: 'ชื่อทรัพย์สิน_29',
    TOTAL_29: 'Total (จำนวนที่เบิก)_29',
    TOTAL_PRICE_29: 'Total_Price',
    PRODUCT_CODE_29: 'รหัสทรัพย์สิน_29',
    TOTAL_RETURN_29: 'Total (จำนวนคืน)_29',
    PRODUCT_NAME_30: 'ชื่อทรัพย์สิน_30',
    TOTAL_30: 'Total (จำนวนที่เบิก)_30',
    TOTAL_PRICE_30: 'Total_Price',
    PRODUCT_CODE_30: 'รหัสทรัพย์สิน_30',
    TOTAL_RETURN_30: 'Total (จำนวนคืน)_30',
    STATUS: 'Status',
    REMARK: 'หมายเหตุ',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
    INPUT_TEXT_SEARCH: 'INPUT_TEXT_SEARCH',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportRequisitionDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportRequisitionConst.COLUMN_NAME.NO,
      value: reportRequisitionConst.COLUMN_NAME.NO,
      label: reportRequisitionConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportRequisitionConst.COLUMN_NAME.TICKET_ID,
      value: reportRequisitionConst.COLUMN_NAME.TICKET_ID,
      label: reportRequisitionConst.COLUMN_NAME_TH.TICKET_ID,
      minWidth: 100,
    },
    {
      id: 3,
      name: reportRequisitionConst.COLUMN_NAME.CREATE_DATE_TIME_TICKET,
      value: reportRequisitionConst.COLUMN_NAME.CREATE_DATE_TIME_TICKET,
      label: reportRequisitionConst.COLUMN_NAME_TH.CREATE_DATE_TIME_TICKET,
      minWidth: 100,
    },
    {
      id: 4,
      name: reportRequisitionConst.COLUMN_NAME.CREATE_BY,
      value: reportRequisitionConst.COLUMN_NAME.CREATE_BY,
      label: reportRequisitionConst.COLUMN_NAME_TH.CREATE_BY,
      minWidth: 100,
    },
    {
      id: 5,
      name: reportRequisitionConst.COLUMN_NAME.UPDATE_DATE,
      value: reportRequisitionConst.COLUMN_NAME.UPDATE_DATE,
      label: reportRequisitionConst.COLUMN_NAME_TH.UPDATE_DATE,
      minWidth: 100,
    },
    {
      id: 6,
      name: reportRequisitionConst.COLUMN_NAME.UPDATE_BY,
      value: reportRequisitionConst.COLUMN_NAME.UPDATE_BY,
      label: reportRequisitionConst.COLUMN_NAME_TH.UPDATE_BY,
      minWidth: 100,
    },
    {
      id: 7,
      name: reportRequisitionConst.COLUMN_NAME.STAFF_ID,
      value: reportRequisitionConst.COLUMN_NAME.STAFF_ID,
      label: reportRequisitionConst.COLUMN_NAME_TH.STAFF_ID,
      minWidth: 100,
    },
    {
      id: 8,
      name: reportRequisitionConst.COLUMN_NAME.STAFF_NAME_TH,
      value: reportRequisitionConst.COLUMN_NAME.STAFF_NAME_TH,
      label: reportRequisitionConst.COLUMN_NAME_TH.STAFF_NAME_TH,
      minWidth: 100,
    },
    {
      id: 9,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_CODE,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_CODE,
      label: reportRequisitionConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 100,
    },
    {
      id: 10,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportRequisitionConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 100,
    },
    {
      id: 11,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportRequisitionConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 100,
    },
    {
      id: 12,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      label: reportRequisitionConst.COLUMN_NAME_TH.COURSE_NAME_LEARNER,
      minWidth: 100,
    },
    {
      id: 13,
      name: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_START,
      value: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_START,
      label: reportRequisitionConst.COLUMN_NAME_TH.TRAINING_DATE_START,
      minWidth: 100,
    },
    {
      id: 14,
      name: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_FINISH,
      value: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_FINISH,
      label: reportRequisitionConst.COLUMN_NAME_TH.TRAINING_DATE_FINISH,
      minWidth: 100,
    },
    {
      id: 15,
      name: reportRequisitionConst.COLUMN_NAME.STATION,
      value: reportRequisitionConst.COLUMN_NAME.STATION,
      label: reportRequisitionConst.COLUMN_NAME_TH.STATION,
      minWidth: 100,
    },
    {
      id: 16,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_PLATFORM,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_PLATFORM,
      label: reportRequisitionConst.COLUMN_NAME_TH.COURSE_PLATFORM,
      minWidth: 100,
    },
    {
      id: 17,
      name: reportRequisitionConst.COLUMN_NAME.DISTRIBUTION_CHANNELS,
      value: reportRequisitionConst.COLUMN_NAME.DISTRIBUTION_CHANNELS,
      label: reportRequisitionConst.COLUMN_NAME_TH.DISTRIBUTION_CHANNELS,
      minWidth: 100,
    },
    {
      id: 18,
      name: reportRequisitionConst.COLUMN_NAME.MONTHLY_PLAN_TYPE,
      value: reportRequisitionConst.COLUMN_NAME.MONTHLY_PLAN_TYPE,
      label: reportRequisitionConst.COLUMN_NAME_TH.MONTHLY_PLAN_TYPE,
      minWidth: 100,
    },
    {
      id: 19,
      name: reportRequisitionConst.COLUMN_NAME.WITHDRAWAL_TYPE,
      value: reportRequisitionConst.COLUMN_NAME.WITHDRAWAL_TYPE,
      label: reportRequisitionConst.COLUMN_NAME_TH.WITHDRAWAL_TYPE,
      minWidth: 100,
    },
    {
      id: 20,
      name: reportRequisitionConst.COLUMN_NAME.WAREHOUSE,
      value: reportRequisitionConst.COLUMN_NAME.WAREHOUSE,
      label: reportRequisitionConst.COLUMN_NAME_TH.WAREHOUSE,
      minWidth: 100,
    },
    {
      id: 21,
      name: reportRequisitionConst.COLUMN_NAME.STATION_ASSET_WAREHOUSE,
      value: reportRequisitionConst.COLUMN_NAME.STATION_ASSET_WAREHOUSE,
      label: reportRequisitionConst.COLUMN_NAME_TH.STATION_ASSET_WAREHOUSE,
      minWidth: 100,
    },
    {
      id: 21,
      name: reportRequisitionConst.COLUMN_NAME.RETURN_DUE_DATE,
      value: reportRequisitionConst.COLUMN_NAME.RETURN_DUE_DATE,
      label: reportRequisitionConst.COLUMN_NAME_TH.RETURN_DUE_DATE,
      minWidth: 100,
    },
    {
      id: 22,
      name: reportRequisitionConst.COLUMN_NAME.RETURN_DATE,
      value: reportRequisitionConst.COLUMN_NAME.RETURN_DATE,
      label: reportRequisitionConst.COLUMN_NAME_TH.RETURN_DATE,
      minWidth: 100,
    },
    {
      id: 23,
      name: reportRequisitionConst.COLUMN_NAME.RETURN_OVE_DUE_DATE,
      value: reportRequisitionConst.COLUMN_NAME.RETURN_OVE_DUE_DATE,
      label: reportRequisitionConst.COLUMN_NAME_TH.RETURN_OVE_DUE_DATE,
      minWidth: 100,
    },
    {
      id: 24,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_1,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_1,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_1,
      minWidth: 100,
    },
    {
      id: 25,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_1,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_1,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_1,
      minWidth: 100,
    },
    {
      id: 26,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_1,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_1,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_1,
      minWidth: 100,
    },
    {
      id: 27,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_1,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_1,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_1,
      minWidth: 100,
    },
    {
      id: 28,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_1,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_1,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_1,
      minWidth: 100,
    },

    {
      id: 29,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_2,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_2,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_2,
      minWidth: 100,
    },
    {
      id: 30,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_2,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_2,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_2,
      minWidth: 100,
    },
    {
      id: 31,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_2,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_2,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_2,
      minWidth: 100,
    },
    {
      id: 32,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_2,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_2,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_2,
      minWidth: 100,
    },
    {
      id: 33,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_2,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_2,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_2,
      minWidth: 100,
    },
    {
      id: 34,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_3,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_3,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_3,
      minWidth: 100,
    },
    {
      id: 35,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_3,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_3,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_3,
      minWidth: 100,
    },
    {
      id: 36,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_3,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_3,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_3,
      minWidth: 100,
    },
    {
      id: 37,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_3,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_3,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_3,
      minWidth: 100,
    },
    {
      id: 38,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_3,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_3,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_3,
      minWidth: 100,
    },
    {
      id: 39,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_4,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_4,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_4,
      minWidth: 100,
    },
    {
      id: 40,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_4,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_4,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_4,
      minWidth: 100,
    },
    {
      id: 41,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_4,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_4,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_4,
      minWidth: 100,
    },
    {
      id: 42,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_4,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_4,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_4,
      minWidth: 100,
    },
    {
      id: 43,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_4,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_4,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_4,
      minWidth: 100,
    },
    {
      id: 44,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_5,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_5,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_5,
      minWidth: 100,
    },
    {
      id: 45,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_5,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_5,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_5,
      minWidth: 100,
    },
    {
      id: 46,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_5,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_5,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_5,
      minWidth: 100,
    },
    {
      id: 47,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_5,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_5,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_5,
      minWidth: 100,
    },
    {
      id: 48,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_5,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_5,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_5,
      minWidth: 100,
    },
    {
      id: 49,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_6,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_6,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_6,
      minWidth: 100,
    },
    {
      id: 50,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_6,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_6,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_6,
      minWidth: 100,
    },
    {
      id: 51,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_6,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_6,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_6,
      minWidth: 100,
    },
    {
      id: 52,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_6,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_6,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_6,
      minWidth: 100,
    },
    {
      id: 53,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_6,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_6,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_6,
      minWidth: 100,
    },
    {
      id: 54,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_7,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_7,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_7,
      minWidth: 100,
    },
    {
      id: 55,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_7,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_7,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_7,
      minWidth: 100,
    },
    {
      id: 56,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_7,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_7,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_7,
      minWidth: 100,
    },
    {
      id: 57,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_7,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_7,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_7,
      minWidth: 100,
    },
    {
      id: 58,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_7,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_7,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_7,
      minWidth: 100,
    },
    {
      id: 59,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_8,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_8,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_8,
      minWidth: 100,
    },
    {
      id: 60,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_8,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_8,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_8,
      minWidth: 100,
    },
    {
      id: 61,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_8,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_8,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_8,
      minWidth: 100,
    },
    {
      id: 62,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_8,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_8,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_8,
      minWidth: 100,
    },
    {
      id: 63,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_8,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_8,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_8,
      minWidth: 100,
    },
    {
      id: 64,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_9,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_9,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_9,
      minWidth: 100,
    },
    {
      id: 65,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_9,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_9,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_9,
      minWidth: 100,
    },
    {
      id: 66,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_9,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_9,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_9,
      minWidth: 100,
    },
    {
      id: 67,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_9,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_9,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_9,
      minWidth: 100,
    },
    {
      id: 68,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_9,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_9,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_9,
      minWidth: 100,
    },
    {
      id: 69,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_10,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_10,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_10,
      minWidth: 100,
    },
    {
      id: 70,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_10,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_10,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_10,
      minWidth: 100,
    },
    {
      id: 71,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_10,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_10,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_10,
      minWidth: 100,
    },
    {
      id: 72,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_10,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_10,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_10,
      minWidth: 100,
    },
    {
      id: 73,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_10,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_10,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_10,
      minWidth: 100,
    },
    {
      id: 74,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_11,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_11,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_11,
      minWidth: 100,
    },
    {
      id: 75,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_11,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_11,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_11,
      minWidth: 100,
    },
    {
      id: 76,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_11,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_11,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_11,
      minWidth: 100,
    },
    {
      id: 77,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_11,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_11,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_11,
      minWidth: 100,
    },
    {
      id: 78,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_11,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_11,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_11,
      minWidth: 100,
    },
    {
      id: 79,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_12,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_12,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_12,
      minWidth: 100,
    },
    {
      id: 80,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_12,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_12,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_12,
      minWidth: 100,
    },
    {
      id: 81,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_12,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_12,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_12,
      minWidth: 100,
    },
    {
      id: 82,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_12,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_12,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_12,
      minWidth: 100,
    },
    {
      id: 83,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_12,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_12,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_12,
      minWidth: 100,
    },
    {
      id: 84,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_13,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_13,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_13,
      minWidth: 100,
    },
    {
      id: 85,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_13,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_13,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_13,
      minWidth: 100,
    },
    {
      id: 86,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_13,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_13,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_13,
      minWidth: 100,
    },
    {
      id: 87,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_13,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_13,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_13,
      minWidth: 100,
    },
    {
      id: 88,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_13,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_13,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_13,
      minWidth: 100,
    },
    {
      id: 89,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_14,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_14,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_14,
      minWidth: 100,
    },
    {
      id: 90,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_14,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_14,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_14,
      minWidth: 100,
    },
    {
      id: 91,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_14,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_14,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_14,
      minWidth: 100,
    },
    {
      id: 92,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_14,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_14,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_14,
      minWidth: 100,
    },
    {
      id: 93,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_14,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_14,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_14,
      minWidth: 100,
    },
    {
      id: 94,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_15,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_15,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_15,
      minWidth: 100,
    },
    {
      id: 95,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_15,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_15,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_15,
      minWidth: 100,
    },
    {
      id: 96,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_15,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_15,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_15,
      minWidth: 100,
    },
    {
      id: 97,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_15,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_15,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_15,
      minWidth: 100,
    },
    {
      id: 98,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_15,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_15,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_15,
      minWidth: 100,
    },
    {
      id: 99,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_16,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_16,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_16,
      minWidth: 100,
    },
    {
      id: 100,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_16,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_16,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_16,
      minWidth: 100,
    },
    {
      id: 101,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_16,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_16,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_16,
      minWidth: 100,
    },
    {
      id: 102,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_16,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_16,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_16,
      minWidth: 100,
    },
    {
      id: 103,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_16,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_16,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_16,
      minWidth: 100,
    },
    {
      id: 104,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_17,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_17,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_17,
      minWidth: 100,
    },
    {
      id: 105,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_17,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_17,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_17,
      minWidth: 100,
    },
    {
      id: 106,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_17,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_17,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_17,
      minWidth: 100,
    },
    {
      id: 107,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_17,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_17,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_17,
      minWidth: 100,
    },
    {
      id: 108,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_17,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_17,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_17,
      minWidth: 100,
    },
    {
      id: 109,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_18,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_18,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_18,
      minWidth: 100,
    },
    {
      id: 110,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_18,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_18,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_18,
      minWidth: 100,
    },
    {
      id: 111,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_18,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_18,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_18,
      minWidth: 100,
    },
    {
      id: 112,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_18,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_18,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_18,
      minWidth: 100,
    },
    {
      id: 113,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_18,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_18,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_18,
      minWidth: 100,
    },
    {
      id: 114,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_19,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_19,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_19,
      minWidth: 100,
    },
    {
      id: 115,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_19,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_19,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_19,
      minWidth: 100,
    },
    {
      id: 116,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_19,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_19,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_19,
      minWidth: 100,
    },
    {
      id: 117,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_19,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_19,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_19,
      minWidth: 100,
    },
    {
      id: 118,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_19,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_19,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_19,
      minWidth: 100,
    },
    {
      id: 119,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_20,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_20,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_20,
      minWidth: 100,
    },
    {
      id: 120,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_20,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_20,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_20,
      minWidth: 100,
    },
    {
      id: 121,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_20,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_20,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_20,
      minWidth: 100,
    },
    {
      id: 122,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_20,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_20,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_20,
      minWidth: 100,
    },
    {
      id: 123,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_20,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_20,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_20,
      minWidth: 100,
    },
    {
      id: 124,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_21,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_21,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_21,
      minWidth: 100,
    },
    {
      id: 125,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_21,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_21,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_21,
      minWidth: 100,
    },
    {
      id: 126,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_21,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_21,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_21,
      minWidth: 100,
    },
    {
      id: 127,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_21,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_21,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_21,
      minWidth: 100,
    },
    {
      id: 128,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_21,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_21,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_21,
      minWidth: 100,
    },
    {
      id: 129,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_22,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_22,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_22,
      minWidth: 100,
    },
    {
      id: 130,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_22,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_22,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_22,
      minWidth: 100,
    },
    {
      id: 131,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_22,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_22,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_22,
      minWidth: 100,
    },
    {
      id: 132,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_22,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_22,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_22,
      minWidth: 100,
    },
    {
      id: 133,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_22,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_22,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_22,
      minWidth: 100,
    },
    {
      id: 134,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_23,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_23,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_23,
      minWidth: 100,
    },
    {
      id: 135,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_23,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_23,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_23,
      minWidth: 100,
    },
    {
      id: 136,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_23,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_23,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_23,
      minWidth: 100,
    },
    {
      id: 137,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_23,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_23,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_23,
      minWidth: 100,
    },
    {
      id: 138,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_23,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_23,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_23,
      minWidth: 100,
    },
    {
      id: 139,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_24,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_24,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_24,
      minWidth: 100,
    },
    {
      id: 140,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_24,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_24,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_24,
      minWidth: 100,
    },
    {
      id: 141,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_24,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_24,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_24,
      minWidth: 100,
    },
    {
      id: 142,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_24,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_24,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_24,
      minWidth: 100,
    },
    {
      id: 143,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_24,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_24,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_24,
      minWidth: 100,
    },
    {
      id: 144,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_25,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_25,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_25,
      minWidth: 100,
    },
    {
      id: 145,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_25,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_25,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_25,
      minWidth: 100,
    },
    {
      id: 146,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_25,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_25,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_25,
      minWidth: 100,
    },
    {
      id: 147,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_25,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_25,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_25,
      minWidth: 100,
    },
    {
      id: 148,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_25,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_25,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_25,
      minWidth: 100,
    },
    {
      id: 149,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_26,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_26,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_26,
      minWidth: 100,
    },
    {
      id: 150,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_26,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_26,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_26,
      minWidth: 100,
    },
    {
      id: 151,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_26,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_26,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_26,
      minWidth: 100,
    },
    {
      id: 152,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_26,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_26,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_26,
      minWidth: 100,
    },
    {
      id: 153,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_26,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_26,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_26,
      minWidth: 100,
    },
    {
      id: 154,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_27,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_27,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_27,
      minWidth: 100,
    },
    {
      id: 155,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_27,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_27,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_27,
      minWidth: 100,
    },
    {
      id: 156,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_27,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_27,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_27,
      minWidth: 100,
    },
    {
      id: 157,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_27,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_27,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_27,
      minWidth: 100,
    },
    {
      id: 158,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_27,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_27,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_27,
      minWidth: 100,
    },
    {
      id: 159,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_28,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_28,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_28,
      minWidth: 100,
    },
    {
      id: 160,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_28,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_28,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_28,
      minWidth: 100,
    },
    {
      id: 161,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_28,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_28,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_28,
      minWidth: 100,
    },
    {
      id: 162,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_28,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_28,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_28,
      minWidth: 100,
    },
    {
      id: 163,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_28,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_28,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_28,
      minWidth: 100,
    },
    {
      id: 164,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_29,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_29,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_29,
      minWidth: 100,
    },
    {
      id: 165,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_29,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_29,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_29,
      minWidth: 100,
    },
    {
      id: 166,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_29,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_29,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_29,
      minWidth: 100,
    },
    {
      id: 167,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_29,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_29,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_29,
      minWidth: 100,
    },
    {
      id: 168,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_29,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_29,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_29,
      minWidth: 100,
    },
    {
      id: 169,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_30,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_NAME_30,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_NAME_30,
      minWidth: 100,
    },
    {
      id: 170,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_30,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_30,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_30,
      minWidth: 100,
    },
    {
      id: 171,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_30,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_PRICE_30,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_PRICE_30,
      minWidth: 100,
    },
    {
      id: 172,
      name: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_30,
      value: reportRequisitionConst.COLUMN_NAME.PRODUCT_CODE_30,
      label: reportRequisitionConst.COLUMN_NAME_TH.PRODUCT_CODE_30,
      minWidth: 100,
    },
    {
      id: 173,
      name: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_30,
      value: reportRequisitionConst.COLUMN_NAME.TOTAL_RETURN_30,
      label: reportRequisitionConst.COLUMN_NAME_TH.TOTAL_RETURN_30,
      minWidth: 100,
    },
    {
      id: 174,
      name: reportRequisitionConst.COLUMN_NAME.STATUS,
      value: reportRequisitionConst.COLUMN_NAME.STATUS,
      label: reportRequisitionConst.COLUMN_NAME_TH.STATUS,
      minWidth: 100,
    },
    {
      id: 175,
      name: reportRequisitionConst.COLUMN_NAME.REMARK,
      value: reportRequisitionConst.COLUMN_NAME.REMARK,
      label: reportRequisitionConst.COLUMN_NAME_TH.REMARK,
      minWidth: 100,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportRequisitionConst.COLUMN_NAME.CREATE_DATE_TIME_TICKET,
      value: reportRequisitionConst.COLUMN_NAME.CREATE_DATE_TIME_TICKET,
      label: 'Start-End Date',
      minWidth: 160,
      type: reportRequisitionConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 2,
      name: reportRequisitionConst.COLUMN_NAME.STAFF_ID,
      value: reportRequisitionConst.COLUMN_NAME.STAFF_ID,
      label: 'Staff ID',
      minWidth: 160,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRequisitionConst.COLUMN_NAME.STAFF_ID,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportRequisitionConst.COLUMN_NAME.STAFF_NAME_TH,
      value: reportRequisitionConst.COLUMN_NAME.STAFF_NAME_TH,
      label: 'Staff Name TH',
      minWidth: 210,
      align: 'center',
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRequisitionConst.COLUMN_NAME.STAFF_NAME_TH,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_CODE,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_CODE,
      label: 'รหัสหลักสูตร',
      minWidth: 210,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRequisitionConst.COLUMN_NAME.COURSE_CODE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: 'VERSION_NUMBER',
      value: 'VERSION_NUMBER',
      label: 'เวอร์ชั่น',
      minWidth: 210,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: 'VERSION_NUMBER',
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_EN,
      label: 'ชื่อหลักสูตร EN',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_EN,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_TH,
      label: 'ชื่อหลักสูตร TH',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_TH,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      label: 'ชื่อหลักสูตร (สำหรับ Learner)',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_START,
      value: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_START,
      label: 'วันเริ่มต้น (Training Date)',
      minWidth: 160,
      type: reportRequisitionConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 10,
      name: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_FINISH,
      value: reportRequisitionConst.COLUMN_NAME.TRAINING_DATE_FINISH,
      label: 'วันสิ้นสุด  (Training Date)',
      minWidth: 160,
      type: reportRequisitionConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 11,
      name: reportRequisitionConst.COLUMN_NAME.STATION,
      value: reportRequisitionConst.COLUMN_NAME.STATION,
      label: 'Station',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.STATION,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportRequisitionConst.COLUMN_NAME.COURSE_PLATFORM,
      value: reportRequisitionConst.COLUMN_NAME.COURSE_PLATFORM,
      label: 'รูปแบบของการสอน',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.COURSE_PLATFORM,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportRequisitionConst.COLUMN_NAME.DISTRIBUTION_CHANNELS,
      value: reportRequisitionConst.COLUMN_NAME.DISTRIBUTION_CHANNELS,
      label: 'ช่องทางการจำหน่าย',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.DISTRIBUTION_CHANNELS,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: 'STOCK_NAME',
      value: 'STOCK_NAME',
      label: 'Stock Name',
      minWidth: 210,
      masterDataName: 'STOCK_NAME',
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportRequisitionConst.COLUMN_NAME.STATUS,
      value: reportRequisitionConst.COLUMN_NAME.STATUS,
      label: 'Status',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.STATUS,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportRequisitionConst.COLUMN_NAME.WITHDRAWAL_TYPE,
      value: reportRequisitionConst.COLUMN_NAME.WITHDRAWAL_TYPE,
      label: 'ประเภทรายการเบิก',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.WITHDRAWAL_TYPE,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportRequisitionConst.COLUMN_NAME.TICKET_ID,
      value: reportRequisitionConst.COLUMN_NAME.TICKET_ID,
      label: 'Ticket No.',
      minWidth: 210,
      masterDataName: reportRequisitionConst.COLUMN_NAME.TICKET_ID,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}

export const reportAssetLicenseConst = {
  COLUMN_NAME: {
    NO: 'no',
    ASSET_TYPE: 'assetType',
    ASSET_CODE: 'assetCode',
    ASSET_NAME: 'assetName',
    VENDOR: 'vendor',
    CATEGORY: 'category',
    SUB_CATEGORY: 'subCategory',
    ASSET_WAREHOUSE_TYPE: 'assetWarehouseType',
    ASSET_WAREHOUSE_NAME: 'assetWarehouseName',
    STATION_ASSIGNED: 'stationAssigned',
    TOTAL_VALUE: 'totalValue',
    ASSET_STATUS: 'assetStatus',
    COST_CENTER_ID: 'costCenterId',
    COST_CENTER_NAME: 'costCenterName',
    HOLDER_STAFF_ID: 'holderStaffId',
    HOLDER_NAME: 'holderName',
    CONTRACT_PERIOD_START_DATE: 'contractPeriodStartDate',
    CONTRACT_PERIOD_END_DATE: 'contractPeriodEndDate',
    CONTRACT_PERIOD_RENEWAL_DATE: 'contractPeriodRenewalDate',
    PAYMENT: 'payment',
    PAYER: 'payer',
    CREATE_DATE: 'createDate',
    CREATED_BY: 'createdBy',
    UPDATE_DATE: 'updateDate',
    UPDATED_BY: 'updatedBy',
  },

  COLUMN_NAME_TH: {
    NO: 'No',
    ASSET_TYPE: 'ประเภททรัพย์สิน',
    ASSET_CODE: 'รหัสทรัพย์สิน',
    ASSET_NAME: 'ชื่อทรัพย์สิน',
    VENDOR: 'Vendor',
    CATEGORY: 'หมวดหมู่ (Category)',
    SUB_CATEGORY: 'หมวดหมู่ย่อย (Sub-Category)',
    ASSET_WAREHOUSE_TYPE: 'ประเภท คลังทรัพย์สิน',
    ASSET_WAREHOUSE_NAME: 'ชื่อคลังทรัพย์สิน',
    STATION_ASSIGNED: 'Station ประจำ',
    TOTAL_VALUE: 'มูลค่ารวม (฿)',
    ASSET_STATUS: 'สถานะทรัพย์สิน',
    COST_CENTER_ID: 'Cost center ID',
    COST_CENTER_NAME: 'Cost center Name',
    HOLDER_STAFF_ID: 'ผู้ถือครอง (Staff ID)',
    HOLDER_NAME: 'ชื่อผู้ถือครอง',
    CONTRACT_PERIOD_START_DATE: 'ระยะสัญญา(วันที่เริ่มต้น)',
    CONTRACT_PERIOD_END_DATE: 'ระยะสัญญา(วันที่สิ้นสุด)',
    CONTRACT_PERIOD_RENEWAL_DATE: 'ระยะสัญญา(วันที่ต่อระยะ)',
    PAYMENT: 'การชำระเงิน',
    PAYER: 'ผู้ชำระเงิน',
    CREATE_DATE: 'Create Date',
    CREATED_BY: 'Create by',
    UPDATE_DATE: 'Update Date',
    UPDATED_BY: 'Update by',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
    INPUT_TEXT_SEARCH: 'INPUT_TEXT_SEARCH',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportAssetLicenseDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportAssetLicenseConst.COLUMN_NAME.NO,
      value: reportAssetLicenseConst.COLUMN_NAME.NO,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
    },
    {
      id: 2,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_TYPE,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_TYPE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.ASSET_TYPE,
      minWidth: 60,
    },
    {
      id: 3,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_CODE,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_CODE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.ASSET_CODE,
      minWidth: 60,
    },
    {
      id: 4,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_NAME,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.ASSET_NAME,
      minWidth: 60,
    },
    {
      id: 5,
      name: reportAssetLicenseConst.COLUMN_NAME.VENDOR,
      value: reportAssetLicenseConst.COLUMN_NAME.VENDOR,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.VENDOR,
      minWidth: 60,
    },
    {
      id: 6,
      name: reportAssetLicenseConst.COLUMN_NAME.CATEGORY,
      value: reportAssetLicenseConst.COLUMN_NAME.CATEGORY,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.CATEGORY,
      minWidth: 60,
    },
    {
      id: 7,
      name: reportAssetLicenseConst.COLUMN_NAME.SUB_CATEGORY,
      value: reportAssetLicenseConst.COLUMN_NAME.SUB_CATEGORY,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.SUB_CATEGORY,
      minWidth: 60,
    },
    {
      id: 8,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.ASSET_WAREHOUSE_TYPE,
      minWidth: 60,
    },
    {
      id: 9,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.ASSET_WAREHOUSE_NAME,
      minWidth: 60,
    },
    {
      id: 10,
      name: reportAssetLicenseConst.COLUMN_NAME.STATION_ASSIGNED,
      value: reportAssetLicenseConst.COLUMN_NAME.STATION_ASSIGNED,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.STATION_ASSIGNED,
      minWidth: 60,
    },
    {
      id: 11,
      name: reportAssetLicenseConst.COLUMN_NAME.TOTAL_VALUE,
      value: reportAssetLicenseConst.COLUMN_NAME.TOTAL_VALUE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.TOTAL_VALUE,
      minWidth: 60,
    },
    {
      id: 12,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_STATUS,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_STATUS,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.ASSET_STATUS,
      minWidth: 60,
    },
    {
      id: 13,
      name: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_ID,
      value: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_ID,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.COST_CENTER_ID,
      minWidth: 60,
    },
    {
      id: 14,
      name: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_NAME,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.COST_CENTER_NAME,
      minWidth: 60,
    },
    {
      id: 15,
      name: reportAssetLicenseConst.COLUMN_NAME.HOLDER_STAFF_ID,
      value: reportAssetLicenseConst.COLUMN_NAME.HOLDER_STAFF_ID,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.HOLDER_STAFF_ID,
      minWidth: 60,
    },
    {
      id: 16,
      name: reportAssetLicenseConst.COLUMN_NAME.HOLDER_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.HOLDER_NAME,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.HOLDER_NAME,
      minWidth: 60,
    },
    {
      id: 17,
      name: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_START_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_START_DATE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.CONTRACT_PERIOD_START_DATE,
      minWidth: 60,
    },
    {
      id: 18,
      name: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_END_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_END_DATE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.CONTRACT_PERIOD_END_DATE,
      minWidth: 60,
    },
    {
      id: 19,
      name: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_RENEWAL_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_RENEWAL_DATE,
      label:
        reportAssetLicenseConst.COLUMN_NAME_TH.CONTRACT_PERIOD_RENEWAL_DATE,
      minWidth: 60,
    },
    {
      id: 20,
      name: reportAssetLicenseConst.COLUMN_NAME.PAYMENT,
      value: reportAssetLicenseConst.COLUMN_NAME.PAYMENT,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.PAYMENT,
      minWidth: 60,
    },
    {
      id: 21,
      name: reportAssetLicenseConst.COLUMN_NAME.PAYER,
      value: reportAssetLicenseConst.COLUMN_NAME.PAYER,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.PAYER,
      minWidth: 60,
    },
    {
      id: 22,
      name: reportAssetLicenseConst.COLUMN_NAME.CREATE_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CREATE_DATE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.CREATE_DATE,
      minWidth: 60,
    },
    {
      id: 23,
      name: reportAssetLicenseConst.COLUMN_NAME.CREATED_BY,
      value: reportAssetLicenseConst.COLUMN_NAME.CREATED_BY,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.CREATED_BY,
      minWidth: 60,
    },
    {
      id: 24,
      name: reportAssetLicenseConst.COLUMN_NAME.UPDATE_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.UPDATE_DATE,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.UPDATE_DATE,
      minWidth: 60,
    },
    {
      id: 25,
      name: reportAssetLicenseConst.COLUMN_NAME.UPDATED_BY,
      value: reportAssetLicenseConst.COLUMN_NAME.UPDATED_BY,
      label: reportAssetLicenseConst.COLUMN_NAME_TH.UPDATED_BY,
      minWidth: 60,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      label: 'ประเภทคลังทรัพย์สิน',
      minWidth: 160,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRequisitionConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportAssetLicenseConst.COLUMN_NAME.STATION_ASSIGNED,
      value: reportAssetLicenseConst.COLUMN_NAME.STATION_ASSIGNED,
      label: 'Station',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.STATION_ASSIGNED,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportAssetLicenseConst.COLUMN_NAME.CREATE_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CREATE_DATE,
      label: 'Create Date',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportAssetLicenseConst.COLUMN_NAME.CREATED_BY,
      value: reportAssetLicenseConst.COLUMN_NAME.CREATED_BY,
      label: 'Create by',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.CREATED_BY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportAssetLicenseConst.COLUMN_NAME.UPDATE_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.UPDATE_DATE,
      label: 'Update Date',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportAssetLicenseConst.COLUMN_NAME.UPDATED_BY,
      value: reportAssetLicenseConst.COLUMN_NAME.UPDATED_BY,
      label: 'Update by',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.UPDATED_BY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_TYPE,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_TYPE,
      label: 'ประเภททรัพย์สิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_TYPE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_CODE,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_CODE,
      label: 'รหัสทรัพย์สิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_CODE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_NAME,
      label: 'ชื่อทรัพย์สิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportAssetLicenseConst.COLUMN_NAME.VENDOR,
      value: reportAssetLicenseConst.COLUMN_NAME.VENDOR,
      label: 'Vendor',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.VENDOR,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportAssetLicenseConst.COLUMN_NAME.CATEGORY,
      value: reportAssetLicenseConst.COLUMN_NAME.CATEGORY,
      label: 'หมวดหมู่ (Category)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.CATEGORY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportAssetLicenseConst.COLUMN_NAME.SUB_CATEGORY,
      value: reportAssetLicenseConst.COLUMN_NAME.SUB_CATEGORY,
      label: 'หมวดหมู่ย่อย (Sub-Category)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.SUB_CATEGORY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      label: 'ชื่อคลังทรัพย์สิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      label: 'ชื่อคลังทรัพย์สิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportAssetLicenseConst.COLUMN_NAME.TOTAL_VALUE,
      value: reportAssetLicenseConst.COLUMN_NAME.TOTAL_VALUE,
      label: 'มูลค่ารวม (฿)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.TOTAL_VALUE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportAssetLicenseConst.COLUMN_NAME.ASSET_STATUS,
      value: reportAssetLicenseConst.COLUMN_NAME.ASSET_STATUS,
      label: 'สถานะทรัพย์สิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_STATUS,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_ID,
      value: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_ID,
      label: 'Cost center ID',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_ID,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_NAME,
      label: 'Cost center Name',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportAssetLicenseConst.COLUMN_NAME.HOLDER_STAFF_ID,
      value: reportAssetLicenseConst.COLUMN_NAME.HOLDER_STAFF_ID,
      label: 'ผู้ถือครอง (Staff ID)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.HOLDER_STAFF_ID,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportAssetLicenseConst.COLUMN_NAME.HOLDER_NAME,
      value: reportAssetLicenseConst.COLUMN_NAME.HOLDER_NAME,
      label: 'ชื่อผู้ถือครอง',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.HOLDER_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_START_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_START_DATE,
      label: 'ระยะสัญญา(วันที่เริ่มต้น)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.CONTRACT_PERIOD_START_DATE,
    },
    {
      id: 16,
      name: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_END_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_END_DATE,
      label: 'ระยะสัญญา(วันที่สิ้นสุด)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.CONTRACT_PERIOD_END_DATE,
    },
    {
      id: 17,
      name: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_RENEWAL_DATE,
      value: reportAssetLicenseConst.COLUMN_NAME.CONTRACT_PERIOD_RENEWAL_DATE,
      label: 'ระยะสัญญา(วันที่ต่อระยะ)',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.CONTRACT_PERIOD_RENEWAL_DATE,
    },
    {
      id: 18,
      name: reportAssetLicenseConst.COLUMN_NAME.PAYMENT,
      value: reportAssetLicenseConst.COLUMN_NAME.PAYMENT,
      label: 'การชำระเงิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.PAYMENT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportAssetLicenseConst.COLUMN_NAME.PAYER,
      value: reportAssetLicenseConst.COLUMN_NAME.PAYER,
      label: 'ผู้ชำระเงิน',
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.PAYER,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}

export const reportStockConst = {
  COLUMN_NAME: {
    NO: 'no',
    ASSET_TYPE: 'assetType',
    ASSET_NAME: 'assetName',
    CATEGORY: 'category',
    SUB_CATEGORY: 'subCategory',
    MINIMUM_NOTIFICATION_REMAINING: 'minimumNotificationRemaining',
    COST_CENTER_ID: 'costCenterId',
    COST_CENTER_NAME: 'costCenterName',
    ASSET_STATUS: 'assetStatus',
    SYSTEM_ASSET_CODE: 'systemAssetCode',
    FLAG_RETURN_ASSET: 'flagReturnAsset',
    RETURN_ASSET_BY: 'returnAssetBy',
    ASSET_CODE: 'assetCode',
    UNIT_VALUE_AVERAGE_PER_ITEM: 'unitValueAveragePerItem',
    TOTAL_VALUE: 'totalValue',
    ASSET_WAREHOUSE_TYPE: 'assetWarehouseType',
    ASSET_WAREHOUSE_NAME: 'assetWarehouseName',
    ID: 'id',
    STATION_ASSIGNED: 'stationAssigned',
    REMAINING: 'remaining',
    AVAILABLE: 'available',
    ASSET_CODE_DUPLICATE: 'assetCodeDuplicate',
    CREATE_DATE: 'createDate',
    CREATED_BY: 'createdBy',
    UPDATE_DATE: 'updateDate',
    UPDATED_BY: 'updatedBy',
  },

  COLUMN_NAME_TH: {
    NO: 'No',
    ASSET_TYPE: 'ประเภททรัพย์สิน',
    ASSET_NAME: 'ชื่อทรัพย์สิน',
    CATEGORY: 'หมวดหมู่',
    SUB_CATEGORY: 'หมวดหมู่ย่อย',
    MINIMUM_NOTIFICATION_REMAINING: 'จำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย',
    COST_CENTER_ID: 'Cost center ID',
    COST_CENTER_NAME: 'Cost center Name',
    ASSET_STATUS: 'สถานะทรัพย์สิน',
    SYSTEM_ASSET_CODE: 'ระบบ รหัสทรัพย์สิน',
    FLAG_RETURN_ASSET: 'Flag คืนทรัพย์สิน',
    RETURN_ASSET_BY: 'คืนทรัพย์สินภายใน',
    ASSET_CODE: 'รหัสทรัพย์สิน',
    UNIT_VALUE_AVERAGE_PER_ITEM: 'มูลค่าต่อหน่วย เฉลี่ยต่อชิ้น',
    TOTAL_VALUE: 'มูลค่ารวม',
    ASSET_WAREHOUSE_TYPE: 'ประเภท คลังทรัพย์สิน',
    ASSET_WAREHOUSE_NAME: 'ชื่อคลังทรัพย์สิน',
    ID: 'ID',
    STATION_ASSIGNED: 'Station ประจำ',
    REMAINING: 'คงเหลือ',
    AVAILABLE: 'พร้อมใช้งาน',
    ASSET_CODE_DUPLICATE: 'รหัสทรัพย์สิน',
    CREATE_DATE: 'Create Date',
    CREATED_BY: 'Create by',
    UPDATE_DATE: 'Update Date',
    UPDATED_BY: 'Update by',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
    INPUT_TEXT_SEARCH: 'INPUT_TEXT_SEARCH',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportStockDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportStockConst.COLUMN_NAME.NO,
      value: reportStockConst.COLUMN_NAME.NO,
      label: reportStockConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
    },
    {
      id: 2,
      name: reportStockConst.COLUMN_NAME.ASSET_TYPE,
      value: reportStockConst.COLUMN_NAME.ASSET_TYPE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_TYPE,
      minWidth: 60,
    },
    {
      id: 3,
      name: reportStockConst.COLUMN_NAME.ASSET_NAME,
      value: reportStockConst.COLUMN_NAME.ASSET_NAME,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_NAME,
      minWidth: 60,
    },
    {
      id: 4,
      name: reportStockConst.COLUMN_NAME.CATEGORY,
      value: reportStockConst.COLUMN_NAME.CATEGORY,
      label: reportStockConst.COLUMN_NAME_TH.CATEGORY,
      minWidth: 60,
    },
    {
      id: 5,
      name: reportStockConst.COLUMN_NAME.SUB_CATEGORY,
      value: reportStockConst.COLUMN_NAME.SUB_CATEGORY,
      label: reportStockConst.COLUMN_NAME_TH.SUB_CATEGORY,
      minWidth: 60,
    },
    {
      id: 6,
      name: reportStockConst.COLUMN_NAME.MINIMUM_NOTIFICATION_REMAINING,
      value: reportStockConst.COLUMN_NAME.MINIMUM_NOTIFICATION_REMAINING,
      label: reportStockConst.COLUMN_NAME_TH.MINIMUM_NOTIFICATION_REMAINING,
      minWidth: 60,
    },
    {
      id: 7,
      name: reportStockConst.COLUMN_NAME.COST_CENTER_ID,
      value: reportStockConst.COLUMN_NAME.COST_CENTER_ID,
      label: reportStockConst.COLUMN_NAME_TH.COST_CENTER_ID,
      minWidth: 60,
    },
    {
      id: 8,
      name: reportStockConst.COLUMN_NAME.COST_CENTER_NAME,
      value: reportStockConst.COLUMN_NAME.COST_CENTER_NAME,
      label: reportStockConst.COLUMN_NAME_TH.COST_CENTER_NAME,
      minWidth: 60,
    },
    {
      id: 9,
      name: reportStockConst.COLUMN_NAME.ASSET_STATUS,
      value: reportStockConst.COLUMN_NAME.ASSET_STATUS,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_STATUS,
      minWidth: 60,
    },
    {
      id: 10,
      name: reportStockConst.COLUMN_NAME.SYSTEM_ASSET_CODE,
      value: reportStockConst.COLUMN_NAME.SYSTEM_ASSET_CODE,
      label: reportStockConst.COLUMN_NAME_TH.SYSTEM_ASSET_CODE,
      minWidth: 60,
    },
    {
      id: 11,
      name: reportStockConst.COLUMN_NAME.FLAG_RETURN_ASSET,
      value: reportStockConst.COLUMN_NAME.FLAG_RETURN_ASSET,
      label: reportStockConst.COLUMN_NAME_TH.FLAG_RETURN_ASSET,
      minWidth: 60,
    },
    {
      id: 12,
      name: reportStockConst.COLUMN_NAME.RETURN_ASSET_BY,
      value: reportStockConst.COLUMN_NAME.RETURN_ASSET_BY,
      label: reportStockConst.COLUMN_NAME_TH.RETURN_ASSET_BY,
      minWidth: 60,
    },
    {
      id: 13,
      name: reportStockConst.COLUMN_NAME.ASSET_CODE,
      value: reportStockConst.COLUMN_NAME.ASSET_CODE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_CODE,
      minWidth: 60,
    },
    {
      id: 14,
      name: reportStockConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      value: reportStockConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      label: reportStockConst.COLUMN_NAME_TH.UNIT_VALUE_AVERAGE_PER_ITEM,
      minWidth: 60,
    },
    {
      id: 15,
      name: reportStockConst.COLUMN_NAME.TOTAL_VALUE,
      value: reportStockConst.COLUMN_NAME.TOTAL_VALUE,
      label: reportStockConst.COLUMN_NAME_TH.TOTAL_VALUE,
      minWidth: 60,
    },
    {
      id: 16,
      name: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      value: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_WAREHOUSE_TYPE,
      minWidth: 60,
    },
    {
      id: 17,
      name: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      value: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_WAREHOUSE_NAME,
      minWidth: 60,
    },
    {
      id: 18,
      name: reportStockConst.COLUMN_NAME.ID,
      value: reportStockConst.COLUMN_NAME.ID,
      label: reportStockConst.COLUMN_NAME_TH.ID,
      minWidth: 60,
    },
    {
      id: 19,
      name: reportStockConst.COLUMN_NAME.STATION_ASSIGNED,
      value: reportStockConst.COLUMN_NAME.STATION_ASSIGNED,
      label: reportStockConst.COLUMN_NAME_TH.STATION_ASSIGNED,
      minWidth: 60,
    },
    {
      id: 20,
      name: reportStockConst.COLUMN_NAME.REMAINING,
      value: reportStockConst.COLUMN_NAME.REMAINING,
      label: reportStockConst.COLUMN_NAME_TH.REMAINING,
      minWidth: 60,
    },
    {
      id: 21,
      name: reportStockConst.COLUMN_NAME.AVAILABLE,
      value: reportStockConst.COLUMN_NAME.AVAILABLE,
      label: reportStockConst.COLUMN_NAME_TH.AVAILABLE,
      minWidth: 60,
    },
    {
      id: 22,
      name: reportStockConst.COLUMN_NAME.ASSET_CODE_DUPLICATE,
      value: reportStockConst.COLUMN_NAME.ASSET_CODE_DUPLICATE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_CODE_DUPLICATE,
      minWidth: 60,
    },
    {
      id: 23,
      name: reportStockConst.COLUMN_NAME.CREATE_DATE,
      value: reportStockConst.COLUMN_NAME.CREATE_DATE,
      label: reportStockConst.COLUMN_NAME_TH.CREATE_DATE,
      minWidth: 60,
    },
    {
      id: 24,
      name: reportStockConst.COLUMN_NAME.CREATED_BY,
      value: reportStockConst.COLUMN_NAME.CREATED_BY,
      label: reportStockConst.COLUMN_NAME_TH.CREATED_BY,
      minWidth: 60,
    },
    {
      id: 25,
      name: reportStockConst.COLUMN_NAME.UPDATE_DATE,
      value: reportStockConst.COLUMN_NAME.UPDATE_DATE,
      label: reportStockConst.COLUMN_NAME_TH.UPDATE_DATE,
      minWidth: 60,
    },
    {
      id: 26,
      name: reportStockConst.COLUMN_NAME.UPDATED_BY,
      value: reportStockConst.COLUMN_NAME.UPDATED_BY,
      label: reportStockConst.COLUMN_NAME_TH.UPDATED_BY,
      minWidth: 60,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportStockConst.COLUMN_NAME.ASSET_TYPE,
      value: reportStockConst.COLUMN_NAME.ASSET_TYPE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_TYPE,
      minWidth: 160,
      type: reportRequisitionConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRequisitionConst.COLUMN_NAME.ASSET_TYPE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportStockConst.COLUMN_NAME.ASSET_NAME,
      value: reportStockConst.COLUMN_NAME.ASSET_NAME,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_NAME,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportStockConst.COLUMN_NAME.CATEGORY,
      value: reportStockConst.COLUMN_NAME.CATEGORY,
      label: reportStockConst.COLUMN_NAME_TH.CATEGORY,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.CATEGORY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportStockConst.COLUMN_NAME.SUB_CATEGORY,
      value: reportStockConst.COLUMN_NAME.SUB_CATEGORY,
      label: reportStockConst.COLUMN_NAME_TH.SUB_CATEGORY,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.SUB_CATEGORY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportStockConst.COLUMN_NAME.MINIMUM_NOTIFICATION_REMAINING,
      value: reportStockConst.COLUMN_NAME.MINIMUM_NOTIFICATION_REMAINING,
      label: reportStockConst.COLUMN_NAME_TH.MINIMUM_NOTIFICATION_REMAINING,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportAssetLicenseConst.COLUMN_NAME.MINIMUM_NOTIFICATION_REMAINING,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportStockConst.COLUMN_NAME.COST_CENTER_ID,
      value: reportStockConst.COLUMN_NAME.COST_CENTER_ID,
      label: reportStockConst.COLUMN_NAME_TH.COST_CENTER_ID,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_ID,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportStockConst.COLUMN_NAME.COST_CENTER_NAME,
      value: reportStockConst.COLUMN_NAME.COST_CENTER_NAME,
      label: reportStockConst.COLUMN_NAME_TH.COST_CENTER_NAME,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.COST_CENTER_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportStockConst.COLUMN_NAME.ASSET_STATUS,
      value: reportStockConst.COLUMN_NAME.ASSET_STATUS,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_STATUS,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_STATUS,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportStockConst.COLUMN_NAME.SYSTEM_ASSET_CODE,
      value: reportStockConst.COLUMN_NAME.SYSTEM_ASSET_CODE,
      label: reportStockConst.COLUMN_NAME_TH.SYSTEM_ASSET_CODE,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.SYSTEM_ASSET_CODE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportStockConst.COLUMN_NAME.FLAG_RETURN_ASSET,
      value: reportStockConst.COLUMN_NAME.FLAG_RETURN_ASSET,
      label: reportStockConst.COLUMN_NAME_TH.FLAG_RETURN_ASSET,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.FLAG_RETURN_ASSET,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportStockConst.COLUMN_NAME.RETURN_ASSET_BY,
      value: reportStockConst.COLUMN_NAME.RETURN_ASSET_BY,
      label: reportStockConst.COLUMN_NAME_TH.RETURN_ASSET_BY,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.RETURN_ASSET_BY,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportStockConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      value: reportStockConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      label: reportStockConst.COLUMN_NAME_TH.UNIT_VALUE_AVERAGE_PER_ITEM,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportAssetLicenseConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportStockConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      value: reportStockConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      label: reportStockConst.COLUMN_NAME_TH.UNIT_VALUE_AVERAGE_PER_ITEM,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportAssetLicenseConst.COLUMN_NAME.UNIT_VALUE_AVERAGE_PER_ITEM,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      value: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_WAREHOUSE_TYPE,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_TYPE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      value: reportStockConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_WAREHOUSE_NAME,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_WAREHOUSE_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportStockConst.COLUMN_NAME.ID,
      value: reportStockConst.COLUMN_NAME.ID,
      label: reportStockConst.COLUMN_NAME_TH.ID,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ID,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: reportStockConst.COLUMN_NAME.STATION_ASSIGNED,
      value: reportStockConst.COLUMN_NAME.STATION_ASSIGNED,
      label: reportStockConst.COLUMN_NAME_TH.STATION_ASSIGNED,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.STATION_ASSIGNED,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportStockConst.COLUMN_NAME.REMAINING,
      value: reportStockConst.COLUMN_NAME.REMAINING,
      label: reportStockConst.COLUMN_NAME_TH.REMAINING,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.REMAINING,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportStockConst.COLUMN_NAME.AVAILABLE,
      value: reportStockConst.COLUMN_NAME.AVAILABLE,
      label: reportStockConst.COLUMN_NAME_TH.AVAILABLE,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.AVAILABLE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportStockConst.COLUMN_NAME.TOTAL_VALUE,
      value: reportStockConst.COLUMN_NAME.TOTAL_VALUE,
      label: reportStockConst.COLUMN_NAME_TH.TOTAL_VALUE,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.TOTAL_VALUE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportStockConst.COLUMN_NAME.ASSET_CODE_DUPLICATE,
      value: reportStockConst.COLUMN_NAME.ASSET_CODE_DUPLICATE,
      label: reportStockConst.COLUMN_NAME_TH.ASSET_CODE_DUPLICATE,
      minWidth: 160,
      type: reportAssetLicenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportAssetLicenseConst.COLUMN_NAME.ASSET_CODE_DUPLICATE,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}
