import localforage from 'localforage'
import { downloadTemplate } from '../../../../services/eTesting/form'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'
import AnnounceExaminerDialog from '../AnnounceExaminerDialog'
import EligibleExaminerDialog from '../EligibleExaminerDialog'
import { examinationSchedule } from '../../../../utils/apiPath'
import { SCHEDULE_STATUS } from '../../../../constants/eExamination'
import { setRefreshExaminerList } from '../../../../redux/slices/eExamination/list'
import { callAPI } from '../../../../utils/lib'
import { startLoading, stopLoading } from '../../../../redux/slices/table'

export const onClickAddTest = (option, history) => {
  localforage.removeItem('persist:root')
  history.push(option.link)
}

export const onDownloadTemplate = () => async (dispatch) => {
  await dispatch(downloadTemplate())
}

export const handleAnnounceExaminer = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'mini',
      disableButton: true,
      content: <AnnounceExaminerDialog />,
    }),
  )
}

export const handleEligibleExaminer = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'mini',
      disableButton: true,
      content: <EligibleExaminerDialog />,
    }),
  )
}

export const handleCloseSchedule = (examUuid) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันปิดรอบสอบ',
      message: 'คุณต้องการปิดรอบสอบใช่หรือไม่',
      handleConfirm: () => dispatch(handleConfirmClose(examUuid)),
    }),
  )
}

export const handleConfirmClose = (examUuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())
  const url = `${examinationSchedule}/status`
  callAPI({
    method: 'PUT',
    url: url,
    body: {
      scheduleUuid: examUuid,
      status: SCHEDULE_STATUS.COMPLETED,
    },
    onSuccess: () => {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(closeDialog())
            dispatch(setRefreshExaminerList(true))
          },
        }),
      )
    },
  })
  dispatch(stopLoading())
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
}
