export const initialState = {
  isLoading: false,
  uuidAdminSelected: '',
  defaultMenu: 'list', // calendar,
  station: '',
  listStation: [],
  listTrainingPlan: [],
  totalAllClass: 0,
  isOpenManageAdminDrawer: false,
  isLoadingManageAdminDrawer: false,
}
