import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onEnterSearch } from './events'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
const SearchCustom = ({ searchKey, placeholder = 'ค้นหาจากชื่อคอร์ส' }) => {
  const dispatch = useDispatch()
  const [searchTable, setSearchTable] = useState('')
  const key =
    searchKey === 'dueTable'
      ? 'dueTable.searchText'
      : searchKey === 'waitingTable'
      ? 'waitingTable.searchText'
      : 'table2.search.searchText'

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          setFieldValue({
            key: key,
            value: searchTable,
          }),
        ),
      500,
    )
    return () => clearTimeout(timeOutId)
  }, [searchTable])

  return (
    <BoxSearch>
      <SearchInput
        data-testid="search-input"
        placeholder={placeholder}
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          setSearchTable(value)
        }}
        onKeyPress={(e) => {
          dispatch(onEnterSearch(e, key))
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const value = _.get(e, 'target.value', '')
            dispatch(
              setFieldValue({
                key: key,
                value: value,
              }),
            )
          }
        }}
        value={searchTable}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={() => {
          dispatch(setFieldValue({ key: key, value: searchTable }))
        }}
      />
    </BoxSearch>
  )
}

export default SearchCustom
