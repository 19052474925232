import _ from 'lodash'
import {
  setEditDateDetail,
  setEditRoomDetail,
  addRoom,
  deleteRoom,
  setMeetingRoomByDate,
  setEditRoomDetailSelect,
} from '../../../../../redux/slices/manageClassForm'
import {
  handleStartTimeRoom,
  handleEndTimeRoom,
} from '../../../../RoomManagement/BookingDrawer/FormBooking/DetailsBookingCard/event'
import { store } from '../../../../../App'
import { overTimeOptions } from '../../../../../constants/roomManagement'
import Typography from '@mui/material/Typography'
import { getManageClassRoomAvailable } from '../../../../../services/manageClass/form'

export const handleMultiSelectChange =
  (list, keyField, index) => (dispatch) => {
    const value = list.map((item) => ({ uuid: item.value, ...item }))
    dispatch(setEditDateDetail({ keyField, index, value }))
  }

export const handleInputChange = (e, keyField, index) => (dispatch) => {
  const value = _.get(e.target, 'value', '')
  dispatch(setEditDateDetail({ keyField, index, value }))
}

export const handleInputRoomChange =
  (e, keyField, index, bookingDate) => (dispatch) => {
    const value = _.get(e.target, 'value', '')
    const roomIndex = getIndexByDateCards(index, bookingDate)
    dispatch(setEditRoomDetail({ keyField, index: roomIndex, value }))
  }

export const handleSelectRoomChange =
  (e, index, roomAvailableOptions, bookingDate, cardIndex) => (dispatch) => {
    const value = _.get(e.target, 'value', '')
    let findMeetingRoom = _.find(roomAvailableOptions, (room) => {
      return room.uuid == value
    })

    if (value === 'OTHERS') {
      findMeetingRoom = {
        uuid: value,
        value,
      }
    }

    const roomIndex = getIndexByDateCards(index, bookingDate)
    dispatch(
      setEditRoomDetailSelect({ index: roomIndex, value: findMeetingRoom }),
    )
    dispatch(fetchRoomAvailable(cardIndex, bookingDate))
  }

export const handleChangeTrainingPeriodChange =
  (e, keyField, index, time, date, bookingDate) => (dispatch) => {
    const value = _.get(e.target, 'value', '')
    const state = store.getState()
    const { roomManagementMeetingRoom } =
      state.manageClassForm.data.roomManagement
    const roomByBookingDate = _.filter(roomManagementMeetingRoom, (room) => {
      return _.get(room, 'bookingDate') === bookingDate
    })
    let timeSelect = time
    if (keyField === 'beforeTrainingTime') {
      timeSelect = handleStartTimeRoom(value, time, date)
      dispatch(setEditDateDetail({ keyField: 'beforeTraining', index, value }))
    } else {
      timeSelect = handleEndTimeRoom(value, time, date)
      dispatch(setEditDateDetail({ keyField: 'afterTraining', index, value }))
    }
    const periodSelect =
      keyField === 'beforeTrainingTime' ? 'beforeTraining' : 'afterTraining'
    _.map(roomByBookingDate, (item) => {
      const roomIndex = _.findIndex(roomManagementMeetingRoom, (room) => {
        return room === item
      })
      dispatch(
        setEditRoomDetail({
          keyField: periodSelect,
          index: roomIndex,
          value,
        }),
      )
    })

    dispatch(setEditDateDetail({ keyField, index, value: timeSelect }))
  }

export const handleFetchRoomAvailable =
  (value, keyField, index) => (dispatch) => {
    dispatch(setEditDateDetail({ keyField, index, value }))
  }

export const handleDefaultMeetingRoom = (data) => {
  const meetingRooms = _.get(data, 'meetingRooms', [])
  return meetingRooms.map((item) => ({
    ...item,
    label:
      item.label == 'Others' || item.value == 'OTHERS'
        ? 'Others'
        : `${_.get(item, 'station.station', '')} ${item.roomName} (ชั้น ${
            item.floor
          })`,
    value: _.get(item, 'uuid', ''),
  }))
}

export const handleDefaultAdminStaff = (data, listValueAdmin) => {
  const adminStaffPlan = _.get(data, 'adminStaffPlan', [])
  let result = []
  adminStaffPlan.forEach((item) => {
    const value = _.get(item, 'value', '') || _.get(item, 'uuid', '')
    const defaultArea = _.get(item, 'staff.defaultArea', '')
    const findArea = _.get(item, 'staff.area', []).find(
      (itemArea) => itemArea.uuid === defaultArea,
    )
    const textStation = _.get(findArea, 'station.station')
    const objItem = {
      label: item.staff
        ? `${textStation} - ${item.staff.firstNameTH} ${item.staff.lastNameTH}`
        : item.label,
      value: item.staff ? _.get(item.staff, 'uuid', item.uuid) : item.uuid,
    }
    if (value !== _.get(listValueAdmin, 'value', '')) result.push(objItem)
  })

  return result
}

export const filterAdminStaffOption = (listStaffOption, listValueAdmin) => {
  if (!listStaffOption.length) return []
  const adminValue = _.get(listValueAdmin, 'value', '')
  if (_.isEmpty(adminValue)) return listStaffOption
  let result = []
  listStaffOption.forEach((item) => {
    const value = _.get(item, 'value', '')
    if (value !== adminValue) result.push(item)
  })

  return result
}

export const handleAddRoom =
  (monthlyPlan, index, isEmpty = false) =>
  (dispatch) => {
    const date = _.get(monthlyPlan, `dates[${index}].date`, '')
    const startTime = _.get(monthlyPlan, `dates[${index}].startTime`, '')
    const endTime = _.get(monthlyPlan, `dates[${index}].endTime`, '')
    const value = {
      startTimeTraining: _.get(monthlyPlan, `dates[${index}].startTime`, ''),
      endTimeTraining: _.get(monthlyPlan, `dates[${index}].endTime`, ''),
      afterTrainingTime: _.get(
        monthlyPlan,
        `dates[${index}].afterTrainingTime`,
        handleEndTimeRoom(
          _.get(
            monthlyPlan,
            `dates[${index}].afterTraining`,
            overTimeOptions[0].value,
          ),
          endTime,
          date,
        ),
      ),
      beforeTrainingTime: _.get(
        monthlyPlan,
        `dates[${index}].beforeTrainingTime`,
        handleStartTimeRoom(
          _.get(
            monthlyPlan,
            `dates[${index}].beforeTraining`,
            overTimeOptions[0].value,
          ),
          startTime,
          date,
        ),
      ),
      afterTraining: _.get(monthlyPlan, `dates[${index}].afterTraining`, ''),
      beforeTraining: _.get(monthlyPlan, `dates[${index}].beforeTraining`, ''),
      bookingDate: _.get(monthlyPlan, `dates[${index}].date`, ''),
      meetingRoom:
        isEmpty &&
        !_.isEmpty(_.get(monthlyPlan, `dates[${index}].meetingRoomOther`))
          ? { uuid: 'OTHERS', value: 'OTHERS' }
          : null,
      meetingRoomOther: isEmpty
        ? _.get(monthlyPlan, `dates[${index}].meetingRoomOther`)
        : null,
      numberOfAttendees: null,
      roomLayout: '',
      additionalEquipment: '',
      numberOfGroup: null,
      attendeeInGroup: null,
    }

    dispatch(addRoom({ value }))
  }

export const handleDeleteRoom = (idx, bookingDate) => (dispatch) => {
  const state = store.getState()
  const roomManagementMeetingRoomData =
    state.manageClassForm.data.roomManagement.roomManagementMeetingRoom
  const roomIndex = getIndexByDateCards(idx, bookingDate)
  const roomManagementMeetingRoom = _.filter(
    roomManagementMeetingRoomData,
    (item, index) => {
      return index !== roomIndex
    },
  )

  dispatch(deleteRoom({ roomManagementMeetingRoom }))
}

export const getIndexByDateCards = (idx, bookingDate) => {
  const state = store.getState()
  const { roomManagementMeetingRoom } =
    state.manageClassForm.data.roomManagement
  const roomByBookingDate = _.filter(roomManagementMeetingRoom, (room) => {
    return _.get(room, 'bookingDate') === bookingDate
  })

  const roomIndex = _.findIndex(roomManagementMeetingRoom, (room) => {
    return room === roomByBookingDate[idx]
  })

  return roomIndex
}

export const filterRoomManagementMeetingRoom =
  (roomManagement, monthlyPlan, index) => (dispatch) => {
    const date = _.get(monthlyPlan, `dates[${index}].date`, '')
    const startTime = _.get(monthlyPlan, `dates[${index}].startTime`, '')
    const endTime = _.get(monthlyPlan, `dates[${index}].endTime`, '')
    let value = _.filter(
      _.get(roomManagement, 'roomManagementMeetingRoom', []),
      (item) => {
        return (
          _.get(item, 'startTimeTraining') ==
            _.get(monthlyPlan, `dates[${index}].startTime`, '') &&
          _.get(item, 'endTimeTraining') ==
            _.get(monthlyPlan, `dates[${index}].endTime`, '') &&
          _.get(item, 'bookingDate') ==
            _.get(monthlyPlan, `dates[${index}].date`, '')
        )
      },
    )

    value = _.map(value, (item) => {
      return {
        ...item,
        afterTrainingTime: _.get(
          monthlyPlan,
          `dates[${index}].afterTrainingTime`,
          handleEndTimeRoom(
            _.get(
              monthlyPlan,
              `dates[${index}].afterTraining`,
              overTimeOptions[0].value,
            ),
            endTime,
            date,
          ),
        ),
        beforeTrainingTime: _.get(
          monthlyPlan,
          `dates[${index}].beforeTrainingTime`,
          handleStartTimeRoom(
            _.get(
              monthlyPlan,
              `dates[${index}].beforeTraining`,
              overTimeOptions[0].value,
            ),
            startTime,
            date,
          ),
        ),
        afterTraining: _.get(monthlyPlan, `dates[${index}].afterTraining`, ''),
        beforeTraining: _.get(
          monthlyPlan,
          `dates[${index}].beforeTraining`,
          '',
        ),
      }
    })

    dispatch(setMeetingRoomByDate({ value }))
  }

export const handleFetchRoomForm = (index) => {
  const state = store.getState()
  const { roomManagementMeetingRoom } =
    state.manageClassForm.data.roomManagement
  const monthlyPlan = state.manageClassForm.data.monthlyPlan
  const roomFilter = _.filter(
    _.defaultTo(roomManagementMeetingRoom, []),
    (rooms) => {
      return (
        _.get(rooms, 'bookingDate') ===
        _.get(monthlyPlan, `dates[${index}].date`, '')
      )
    },
  )
  return roomFilter
}

export const fetchRoomAvailable = (index, bookingDate) => async (dispatch) => {
  const state = store.getState()
  const monthlyPlan = state.manageClassForm.data.monthlyPlan
  const { roomManagementMeetingRoom } =
    state.manageClassForm.data.roomManagement
  const roomManagementMeetingRoomDefault =
    state.manageClassForm.data.roomManagementMeetingRoomDefault
  const date = _.get(monthlyPlan, `dates[${index}].date`, '')
  const startTime = _.get(monthlyPlan, `dates[${index}].startTime`, '')
  const endTime = _.get(monthlyPlan, `dates[${index}].endTime`, '')

  const body = {
    endDate: date,
    endTime: _.get(
      monthlyPlan,
      `dates[${index}].afterTrainingTime`,
      handleEndTimeRoom(
        _.get(
          monthlyPlan,
          `dates[${index}].afterTraining`,
          overTimeOptions[0].value,
        ),
        endTime,
        date,
      ),
    ),
    startDate: date,
    startTime: _.get(
      monthlyPlan,
      `dates[${index}].beforeTrainingTime`,
      handleStartTimeRoom(
        _.get(
          monthlyPlan,
          `dates[${index}].beforeTraining`,
          overTimeOptions[0].value,
        ),
        startTime,
        date,
      ),
    ),
    stationUuid: _.get(monthlyPlan, `area.station.uuid`),
  }
  const respone = await dispatch(getManageClassRoomAvailable(body))
  const roomResult = _.get(respone, 'payload.data.roomResult', [])

  let result = []
  roomResult.forEach((item) => {
    const roomUuid = _.get(item, 'roomUuid', '')
    const station = _.get(item, 'station', '')
    const stationUuid = _.get(item, 'stationUuid', '')
    const roomName = _.get(item, 'roomName', '')
    const available = _.get(item, 'available', false)
    const textAvailable = available ? (
      <Typography variant="body2" color="success.main" component="span">
        ว่าง
      </Typography>
    ) : (
      <Typography variant="body2" component="span">
        ไม่ว่าง
      </Typography>
    )

    const findExistRoomSelect = _.find(roomManagementMeetingRoom, (room) => {
      return (
        _.get(room, 'bookingDate') === bookingDate &&
        _.get(room, 'meetingRoom.uuid') === roomUuid
      )
    })

    const findExistRoomDefault = _.find(
      roomManagementMeetingRoomDefault,
      (room) => {
        return (
          _.get(room, 'bookingDate') === bookingDate &&
          _.get(room, 'meetingRoom.uuid') === roomUuid
        )
      },
    )

    const roomOption = {
      ...item,
      value: roomUuid,
      uuid: roomUuid,
      label: `${station} - ${roomName}`,
      labelText: (
        <Typography variant="body2b">
          {station} - {roomName} : {textAvailable}
        </Typography>
      ),
      station: {
        uuid: stationUuid,
        station,
      },
      disabled: !_.isEmpty(findExistRoomSelect)
        ? true
        : !_.isEmpty(findExistRoomDefault)
        ? false
        : !available,
      capacityClassroom: _.get(item, 'capacityClassroom'),
      capacityGroup: _.get(item, 'capacityGroup'),
      capacityTheater: _.get(item, 'capacityTheater'),
      capacityUShape: _.get(item, 'capacityUShape'),
    }

    result.push(roomOption)
  })
  result.push({
    value: 'OTHERS',
    uuid: 'others',
    label: 'Others',
    labelText: <Typography variant="body2b">Others</Typography>,
  })

  dispatch(handleFetchRoomAvailable(result, 'roomAvailableOptions', index))
}
