export const defaultFilter = {
  startDateChecked: false,
  startDate: '',
  endDate: '',
  displayDate: false,
  inventoryChecked: false,
  inventory: '',
  stationChecked: false,
  station: '',
  courseChecked: false,
  course: '',
}

export const defaultReqFilter = {
  startDateChecked: false,
  startDate: '',
  endDate: '',
  displayDate: false,
  inventoryChecked: false,
  inventory: '',
  stationChecked: false,
  station: [],
  courseChecked: false,
  course: '',
  statusChecked: false,
  status: [],
  ticketTypeChecked: false,
  ticketType: [],
  returnDateChecked: false,
  returnDateFrom: '',
  returnDateTo: '',
}
