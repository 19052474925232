import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from './Styled'
import { handleClearFilter } from './handleClearFilter'
import _ from 'lodash'

export const FilterBox = () => {
  const { filtersDrawer, filterTotal } = useSelector(
    (state) => ({
      filtersDrawer: state.crud?.eExamDashboard?.filtersDrawer,
      filterTotal: state.crud?.eExamDashboard?.filtersDrawer.filterTotal,
    }),
    shallowEqual,
  )

  const isFilterDate = filtersDrawer?.isFilterDate
  const startDateFilter = filtersDrawer?.startDate
  const endDateFilter = filtersDrawer?.endDate
  const stationFilter = filtersDrawer?.station
  const associationRegionFilter = filtersDrawer?.associationRegion
  const associationProvinceFilter = filtersDrawer?.associationProvince
  const otherRegionFilter = filtersDrawer?.otherRegion
  const otherProvinceFilter = filtersDrawer?.otherProvince

  return (
    <>
      {
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่สอบ:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => {
                    handleClearFilter('filterDate', filtersDrawer, filterTotal)
                  }}
                />
              )}
            </FilterStyledBox>
          )}
          {!_.isEmpty(stationFilter) && (
            <FilterStyledBox>
              สนามสอบบริษัท:{' '}
              {stationFilter.map((item, i) => {
                return (
                  <strong key={i}>
                    {i !== 0 && ' , '}
                    {item.label}
                  </strong>
                )
              })}
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter('stationFilter', filtersDrawer, filterTotal)
                }}
              />
            </FilterStyledBox>
          )}

          {!_.isEmpty(associationRegionFilter) && (
            <FilterStyledBox>
              สนามสอบสมาคม ภาค:{' '}
              {associationRegionFilter.map((item, i) => {
                return (
                  <strong key={i}>
                    {i !== 0 && ' , '}
                    {item.label}
                  </strong>
                )
              })}
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    'associationRegionFilter',
                    filtersDrawer,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {!_.isEmpty(associationProvinceFilter) && (
            <FilterStyledBox>
              สนามสอบสมาคม จังหวัด:{' '}
              {associationProvinceFilter.map((item, i) => {
                return (
                  <strong key={i}>
                    {i !== 0 && ' , '}
                    {item.label}
                  </strong>
                )
              })}{' '}
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    'associationProvinceFilter',
                    filtersDrawer,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {!_.isEmpty(otherRegionFilter) && (
            <FilterStyledBox>
              สนามสอบอื่นๆ ภาค:{' '}
              {otherRegionFilter.map((item, i) => {
                return (
                  <strong key={i}>
                    {i !== 0 && ' , '}
                    {item.label}
                  </strong>
                )
              })}
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    'otherRegionFilter',
                    filtersDrawer,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {!_.isEmpty(otherProvinceFilter) && (
            <FilterStyledBox>
              สนามสอบอื่นๆ จังหวัด:{' '}
              {otherProvinceFilter.map((item, i) => {
                return (
                  <strong key={i}>
                    {i !== 0 && ' , '}
                    {item.label}
                  </strong>
                )
              })}
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    'otherProvinceFilter',
                    filtersDrawer,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
        </BoxWrapper>
      }
    </>
  )
}
