import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import ClearIcon from '@mui/icons-material/Clear'
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import useLocalStorage from '@rehooks/local-storage'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import useTheme from '@mui/system/useTheme'
import FormView from './FormView'
import AlertStatus from './AlertStatus'
import { defaultStateForm } from '../Index'
import { handleStyleEvent } from '../../Drawer/Handle'
import { WORK_WORK_LIST, LIST_TYPE_DATE } from '../../Text'
import { fetchEventAll, deleteEvent } from '../../api/Index'
import {
  validateLeavePermission,
  validatePermission,
  convertFormatDateTime,
} from '../../../../utils/lib'
import { handleTrainingActionClick } from './Training/DeleteDialog'
import TextInput from '../../../../components/Input/TextInput'
import AlertDialog from '../../../../components/AlertDialog'
import { getStyleStatus } from './AlertStatus/handle'
import * as API from '../../api/Training'
import { Expense } from '../../../Expense'

export default function View({
  setStateForm,
  isDrawer,
  setIsDrawer,
  state,
  setState,
  setIsLoading,
  handleFetchEvents,
  optionSubWorkType,
  options,
  fetchApproveList,
}) {
  const [user] = useLocalStorage('user')
  let dataFind =
    state.calendarAllEvents.find((d) => d.publicId === isDrawer.publicId) || {}
  const [styleEvent, setStyleEvent] = useState({})
  const [data, setData] = useState(
    { ...dataFind, uuid: dataFind.publicId } || {
      borderColor: '',
      title: '',
      uuid: '',
      workType: '',
      subWorkType: '',
      data: {},
    },
  )
  const [dialog, setDialog] = useState({
    open: false,
    variant: 'delete',
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    value: { reason: '', cost: 0 },
    error: { reason: '', cost: 0 },
    onConfirmClick: null,
    onCancelClick: null,
    isLoading: false,
  })

  useEffect(() => {
    fetchEvent({ data, setData, setIsLoading, optionSubWorkType, isDrawer })

    setStyleEvent(
      handleStyleEvent({
        work: data.workType,
        leave: data?.title || '',
      }),
    )
  }, [data.workType])

  const status = _.get(data, 'data.status', '')
  const manageClassStatus = _.get(data, 'data.manageClassStatus', '')
  const masterPlanStatus = _.get(state, 'masterPlan.status', '')
  const statusApprove = _.get(data, 'data.statusApprove', '')
  const statusInfo = _.get(data, 'data.statusInfo', '')
  const getHeaderStyle = getStyleStatus(data)
  const rejectReason = _.get(data, 'data.rejectReason', '')
  const workType = _.get(data, 'workType', '')
  const manageClassUuid = _.get(data, 'data.manageClassUuid', '')
  const masterPlanRejectReason = _.get(
    state,
    'masterPlan.masterPlan.rejectReason',
    '',
  )
  const allowEditPlan = _.get(state, 'canEditPreviousPlan.allowEdit', '')
  const isStatusWaitingBorrow =
    _.get(data, 'data.statusBorrow', '') === 'WAITING_APPROVE_BORROW'
  const registerDateTime =
    !_.get(data, 'data.endRegisterDateTime', null) ||
    !_.get(data, 'data.startRegisterDateTime', null)
      ? '-'
      : `${convertFormatDateTime({
          value: _.get(data, 'data.startRegisterDateTime', null),
          type: 'dateTime',
        })} - ${convertFormatDateTime({
          value: _.get(data, 'data.endRegisterDateTime', null),
          type: 'dateTime',
        })}`
  const remarkRequest = _.get(data, 'data.remarkRequest', '')
  const remarkRequestTime = _.get(data, 'data.remarkRequestTime', null)
  const updatedAt = _.get(data, 'data.masterPlanUpdatedAt', null)

  return (
    <Box
      sx={{
        width: 576,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'sticky',
          top: 0,
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            m: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <FiberManualRecordRoundedIcon
              fontSize="small"
              sx={{ color: `${styleEvent.eventColor}`, mr: 1 }}
            />
            <Typography variant="body1b">{data.workType}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            {isDrawer.type !== 'approve' &&
              !state.isAllStation &&
              status !== 'DELETED' &&
              status !== 'CANCEL' &&
              manageClassStatus !== 'CLOSED' &&
              !(isDrawer?.work?.borrow || data?.borrow) && (
                <>
                  {getEditPermission(data, user, state) && (
                    <IconButton
                      data-testid="btn-edit"
                      component="span"
                      onClick={() =>
                        handleEditClick(
                          data,
                          setDialog,
                          allowEditPlan,
                          setIsDrawer,
                          setStateForm,
                          masterPlanStatus,
                        )
                      }
                    >
                      <CreateTwoToneIcon />
                    </IconButton>
                  )}
                  {getDeletePermission(data, user, state) && (
                    <IconButton
                      data-testid="btn-delete"
                      component="span"
                      onClick={() =>
                        handleDeleteClick(
                          user,
                          data,
                          state,
                          masterPlanStatus,
                          allowEditPlan,
                          setDialog,
                          setIsDrawer,
                          handleFetchEvents,
                          optionSubWorkType,
                          setState,
                        )
                      }
                    >
                      <DeleteTwoToneIcon />
                    </IconButton>
                  )}
                </>
              )}
            <IconButton
              data-testid="btn-close"
              component="span"
              onClick={() => handleClose({ setIsDrawer })}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
      </Box>
      {(statusApprove === 'REJECTED' || masterPlanStatus === 'REJECTED') && (
        <Box
          sx={{
            px: 3,
            pt: 3,
            wordBreak: 'break-word',
          }}
        >
          <Alert severity="error">
            <AlertTitle sx={{ mb: 0 }}>
              {/* .utc because api return real time dont need +7 */}
              HF ไม่อนุมัติ ({dayjs(updatedAt).utc().format('DD/MM/YYYY HH:mm')}
              )
            </AlertTitle>
            เหตุผล:{' '}
            {rejectReason !== '' ? rejectReason : masterPlanRejectReason}
          </Alert>
        </Box>
      )}
      {(status === 'DELETED' || status === 'CANCEL') &&
        (masterPlanStatus === 'APPROVED' ||
          masterPlanStatus === 'PATIAL_APPROVED') && (
          <Box
            sx={{
              px: 3,
              pt: 3,
              wordBreak: 'break-word',
            }}
          >
            <Alert severity="error">
              <AlertTitle sx={{ mb: 0 }}>
                {/* .utc because api return real time dont need +7 */}
                HZ ขอยกเลิกคลาส (
                {dayjs(remarkRequestTime).utc().format('DD/MM/YYYY HH:mm')})
              </AlertTitle>
              เหตุผล: {remarkRequest}
            </Alert>
          </Box>
        )}
      <Box
        sx={{
          ...getHeaderStyle.stlyeBackgroundBox,
          p: 3,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          pb: 0,
        }}
      >
        <Box sx={{ width: 384 }}>
          <Typography variant="h5" sx={{ wordWrap: 'break-word' }}>
            {data?.data?.workType?.workType === 'Training'
              ? _.get(data, 'data.course.englishName', '')
              : _.get(data, 'data.title', '')}
          </Typography>
        </Box>
        <AlertStatus data={data}></AlertStatus>
      </Box>
      <Box
        sx={{
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {workType === 'Training' || workType === 'Activity Support' ? (
          <>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
                วันที่จัดอบรม
              </Typography>
              <Typography variant="body1">{`${convertFormatDateTime({
                value: _.get(data, 'data.startDate', null),
                type: 'date',
              })} - ${convertFormatDateTime({
                value: _.get(data, 'data.finishDate', null),
                type: 'date',
              })}`}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
                วันรับสมัคร
              </Typography>
              <Typography variant="body1">{registerDateTime}</Typography>
            </Box>
          </>
        ) : (
          <Typography variant="body1">
            {`${convertFormatDateTime({
              value: _.get(data, 'data.startDate', null),
              type: 'date',
            })} - ${convertFormatDateTime({
              value: _.get(data, 'data.finishDate', null),
              type: 'date',
            })}`}{' '}
            {_.get(data, 'data.title', '') === 'Travelling'
              ? `${convertTime(
                  _.get(data, 'data.dates[0].startTime', '00:00'),
                )} - ${convertTime(
                  _.get(data, 'data.dates[0].endTime', '00:00'),
                )}`
              : ''}
          </Typography>
        )}
      </Box>
      {status === 'REQUEST_DELETE' &&
        (masterPlanStatus === 'APPROVED' ||
          masterPlanStatus === 'PATIAL_APPROVED') && (
          <Box
            sx={{
              px: 3,
              pt: 3,
              wordBreak: 'break-word',
            }}
          >
            <Alert severity="error">
              <AlertTitle sx={{ mb: 0 }}>
                HZ ขอยกเลิกคลาส (
                {dayjs(remarkRequestTime).utc().format('DD/MM/YYYY HH:mm')})
              </AlertTitle>
              เหตุผล: {remarkRequest}
            </Alert>
          </Box>
        )}
      <Box sx={{ m: 3 }}>
        {/* body  */}
        <FormView
          dataList={_.get(data, 'data', {})}
          convertTime={convertTime}
          options={options}
          state={state}
          setDialog={setDialog}
          setIsDrawer={setIsDrawer}
          fetchEvent={() => {
            fetchEvent({
              data,
              setData,
              setIsLoading,
              optionSubWorkType,
              isDrawer,
            })
            fetchApproveList()
          }}
          onClose={() => handleClose({ setIsDrawer })}
        />

        {/* footer */}
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
            วันที่สร้าง
          </Typography>
          <Typography variant="body1">
            {_.get(data, 'data.createdAt', null)
              ? convertFormatDateTime({
                  value: _.get(data, 'data.createdAt', null),
                  type: 'date',
                })
              : '-'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 0.3 }}>
          <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
            สร้างโดย
          </Typography>
          <Typography variant="body1">
            {_.get(data, 'data.createdBy', '')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 0.3 }}>
          <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
            วันที่แก้ไข
          </Typography>
          <Typography variant="body1">
            {_.get(data, 'data.updatedAt', null)
              ? convertFormatDateTime({
                  value: _.get(data, 'data.updatedAt', null),
                  type: 'date',
                })
              : '-'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 0.3 }}>
          <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
            แก้ไขโดย
          </Typography>
          <Typography variant="body1">
            {_.isEmpty(_.get(data, 'data.updatedBy', '-'))
              ? '-'
              : _.get(data, 'data.updatedBy', '-')}
          </Typography>
        </Box>
        {getPermissionExpense(
          user,
          _.get(data, 'data.area.station.uuid', ''),
        ) && <Expense monthlyPlan={_.get(data, 'data', {})} />}
      </Box>
      {(statusApprove === 'WAITING' || statusApprove === 'APPROVED') &&
        (statusInfo === 'WAITING_APPROVE_BUDGET' ||
          status === 'REQUEST_EDIT' ||
          status === 'REQUEST_CREATE' ||
          status === 'REQUEST_DELETE') &&
        validatePermission({
          user: user,
          moduleType: 'MONTHLY_PLAN',
          permission: ['APPROVE_MP'],
        }) &&
        (masterPlanStatus === 'WAITING' ||
          masterPlanStatus === 'APPROVED' ||
          masterPlanStatus === 'PATIAL_APPROVED') &&
        !isStatusWaitingBorrow && (
          <Box
            sx={{
              display: 'flex',
              position: 'sticky',
              bottom: 0,
              zIndex: 2,
              width: '100%',
              backgroundColor: 'background.paper',
              gap: 3,
              pl: 3,
              pb: 3,
              pr: 6,
            }}
          >
            <Button
              sx={{ minWidth: '50%' }}
              variant="outlined"
              color={'primary'}
              onClick={() =>
                handleStatusActionClick(
                  'REJECTED',
                  data,
                  setData,
                  setDialog,
                  setIsLoading,
                  optionSubWorkType,
                  isDrawer,
                  setIsDrawer,
                  handleFetchEvents,
                  fetchApproveList,
                )
              }
            >
              ไม่อนุมัติ
            </Button>
            <Button
              sx={{ minWidth: '50%' }}
              variant="contained"
              color={'primary'}
              onClick={() =>
                handleStatusActionClick(
                  'APPROVED',
                  data,
                  setData,
                  setDialog,
                  setIsLoading,
                  optionSubWorkType,
                  isDrawer,
                  setIsDrawer,
                  handleFetchEvents,
                  fetchApproveList,
                )
              }
            >
              อนุมัติ
            </Button>
          </Box>
        )}
      {getManageClassPermission(user, state, data, manageClassUuid) && (
        <Box
          sx={{
            display: 'flex',
            position: 'sticky',
            bottom: 0,
            zIndex: 2,
            width: '100%',
            backgroundColor: 'background.paper',
            gap: 3,
            px: 3,
            pb: 3,
          }}
        >
          <Button
            sx={{ minWidth: '100%' }}
            variant="contained"
            color={'primary'}
            onClick={() => {
              window.location.href = `/manage-class/detail/${manageClassUuid}`
            }}
          >
            ไปหน้าจัดการคลาส
          </Button>
        </Box>
      )}
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={() => setDialog({ ...dialog, open: false })}
        title={dialog.title}
        variant={dialog.variant}
        content={dialog.content}
        onCancelClick={() => setDialog({ ...dialog, open: false })}
        onConfirmClick={dialog.onConfirmClick}
        agreeText={dialog.agreeText}
        isLoading={dialog.isLoading}
      />
    </Box>
  )
}

export const fetchEvent = async ({
  data,
  setData,
  optionSubWorkType,
  isDrawer,
}) => {
  let result = {}

  const workType = isDrawer.work
    ? isDrawer.work.workType.workType
    : _.get(data, 'workType', '')

  try {
    const realSubWorkType = isDrawer.work
      ? _.get(isDrawer, 'work.subWorkType.id', null)
      : data.subWorkType
    const subWorkType = optionSubWorkType.find((item) => {
      return item.id === realSubWorkType
    })

    const uuid = isDrawer.work ? isDrawer.work.id : _.get(data, 'uuid', '')
    result = await fetchEventAll({
      workType,
      uuid,
      subWorkType,
    })
  } catch (error) {
    console.log('fetchDataAll error => ', error)
  }
  setData((prevState) => ({
    ...prevState,
    data: result,
    workType,
  }))
  return result
}

export const handleConfirmDelete = async ({
  setDialog,
  data,
  setIsDrawer,
  handleFetchEvents,
}) => {
  await handleDelete({ setDialog, data, setIsDrawer, handleFetchEvents })
  setDialog((prevState) => ({
    ...prevState,
    open: false,
  }))
}

export const handleDelete = async ({
  setDialog,
  data,
  setIsDrawer,
  handleFetchEvents,
  optionSubWorkType,
}) => {
  try {
    setDialog((prev) => ({
      ...prev,
      isLoading: true,
    }))
    const subWorkType = optionSubWorkType.find((item) => {
      return item.id === data.subWorkType
    })

    const body = {
      workType: data.workType,
      uuid: data.uuid,
      subWorkType: subWorkType,
      remarkRequest: '',
    }
    if (data?.remarkRequest) {
      body.remarkRequest = data?.remarkRequest
    }
    const { status, value } = await deleteEvent(body)

    const titleText = _.get(value, 'constraints.title', 'Request failed')

    if (!status) {
      const errorDialog = {
        variant: 'fail',
        open: true,
        title: titleText,
        content:
          _.get(value, 'constraints.value', '') ||
          _.get(value, 'message', 'Request failed'),
        isError: true,
        onConfirmClick: () => {
          setDialog({
            open: false,
            variant: 'delete',
            content: 'คุณต้องการลบรายการนี้หรือไม่',
            value: { reason: '', cost: 0 },
            error: { reason: '', cost: 0 },
            isLoading: false,
          })
        },
        isLoading: false,
      }
      setDialog(errorDialog)
    } else {
      await handleFetchEvents()
      setIsDrawer({
        type: '',
        status: false,
        publicId: '',
      })
      setDialog((prev) => ({
        ...prev,
        isLoading: false,
      }))
    }
  } catch (error) {
    console.log('handleDelete error => ', error)
  }
}
export const handleClose = ({ setIsDrawer }) => {
  setIsDrawer({
    type: '',
    status: false,
    publicId: '',
  })
}

export const convertTime = (time) => {
  const hoursMinutes = String(time)
  return hoursMinutes
}
export const convertTimeToApi = (time) => {
  const hoursMinutes = String(time)
  return hoursMinutes
}

export const handleEdit = ({
  setIsDrawer,
  setStateForm,
  data,
  masterPlanStatus,
  setDialog,
}) => {
  const { data: dataEvent } = data
  if (masterPlanStatus === 'WAITING') {
    setDialog({
      open: true,
      variant: 'fail',
      title: 'ยังไม่สามารถแก้ไขได้',
      content:
        'เนื่องจากอยู่ระหว่างการส่งตรวจสอบแผนรายเดือนนี้ หากต้องการแก้ไข \n กรุณายกเลิกการส่งแผนรายเดือนนี้ก่อน \nหรือรอจนกว่าจะตรวจสอบเรียบร้อย',
      onConfirmClick: () => {
        setDialog((prev) => ({ ...prev, open: false }))
      },
    })
    return
  }
  let form = defaultStateForm
  let staff = []
  let listDates = []
  let attendees = []
  let expandDate = []

  const listMeetingRoom = _.get(dataEvent, `staffProfile`, {})
  const toMeetingRoom = _.get(listMeetingRoom, 'uuid', '')

  switch (dataEvent?.workType?.workType) {
    case WORK_WORK_LIST[0]:
      //?    วันหยุด
      form = {
        ...form,
        holiday: {
          ...dataEvent,
          startDate: dayjs(_.get(dataEvent, 'startDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          finishDate: dayjs(_.get(dataEvent, 'finishDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          workType: _.get(dataEvent, 'workType', ''),
          holidayType: _.get(dataEvent, 'subWorkType.uuid', ''),
          dayOff: _.get(dataEvent, 'title', ''),
          remark: _.get(dataEvent, 'remark', ''),
        },
      }
      break
    case WORK_WORK_LIST[1]:
      //?    วันลา
      _.get(dataEvent, 'staffs', []).forEach((d) => {
        staff = [
          ...staff,
          {
            label:
              _.get(d, 'area[0].station.station', '') +
              ' ' +
              d.firstNameTH +
              ' ' +
              d.lastNameTH,
            value: d.uuid,
            title:
              _.get(d, 'area[0].station.station', '') +
              ' ' +
              d.firstNameTH +
              ' ' +
              d.lastNameTH,
          },
        ]
      })

      _.get(dataEvent, 'dates', []).forEach((d) => {
        const type = LIST_TYPE_DATE.find((type) => type.value === d.typeTime)
        listDates = [...listDates, { date: d.date, type }]
      })

      form = {
        ...form,
        leave: {
          ...dataEvent,
          startDate: dayjs(_.get(dataEvent, 'startDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          finishDate: dayjs(_.get(dataEvent, 'finishDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          workType: _.get(dataEvent, 'workType', ''),
          subWorkType: _.get(dataEvent, 'subWorkType.uuid', ''),
          remarkLeave: _.get(dataEvent, 'description', ''),
          remark: _.get(dataEvent, 'remark', ''),
          staff: staff,
          leavePeriod: {
            type: _.get(dataEvent, 'period', 'FULLDAY'),
            value: listDates,
          },
        },
      }
      break
    case WORK_WORK_LIST[2]:
      //?    Academy Activity

      attendees = _.get(dataEvent, 'staffs', []).map((d) => {
        return {
          ...d,
          label:
            _.get(d, 'area[0].station.station', '') +
            ' ' +
            d.firstNameTH +
            ' ' +
            d.lastNameTH,
          value: d.uuid,
          title:
            _.get(d, 'area[0].station.station', '') +
            ' ' +
            d.firstNameTH +
            ' ' +
            d.lastNameTH,
        }
      })

      expandDate = _.get(dataEvent, 'dates', []).map((d) => {
        const listMeetingRoom = _.get(d, 'meetingRoom', {})
        const listSpeaker = _.get(d, 'speakers', [])

        const meetingRoom = !_.isEmpty(listMeetingRoom)
          ? {
              ...listMeetingRoom,
              label: _.get(listMeetingRoom, 'station.station', ''),
              value: _.get(listMeetingRoom, 'uuid', ''),
            }
          : {}

        const speaker = listSpeaker.length
          ? listSpeaker.map((itemSK) => {
              return {
                startTime: itemSK.startTime,
                endTime: itemSK.endTime,
                staff: _.get(itemSK, 'staff.uuid'),
                listStaff: _.get(itemSK, 'staff', {}),
                speakerRole: _.get(itemSK, 'responsibility.uuid'),
              }
            })
          : {}

        return {
          ...d,
          startTime: d.startTime,
          endTime: d.endTime,
          meetingRoom: meetingRoom,
          speakers: listSpeaker.length ? speaker : [],
        }
      })

      form = {
        ...form,
        academyActivity: {
          ...dataEvent,
          meetingType: dataEvent?.meetingType?.toLowerCase() || undefined,
          isUseRoom: _.get(dataEvent, 'isUseRoom', true),
          startDate: dayjs(_.get(dataEvent, 'startDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          finishDate: dayjs(_.get(dataEvent, 'finishDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          title: dataEvent.title,
          description: dataEvent.description,
          toMeetingRoom:
            dataEvent?.meetingType?.toLowerCase() === 'onsite'
              ? toMeetingRoom
              : {},
          expandDate: expandDate || [],
          attendees: attendees,
          subWorkType: _.get(dataEvent, 'subWorkType.uuid', ''),
          workPlace: dataEvent?.workPlace?.toLowerCase() || undefined,
          remark: dataEvent.description,
          travelType: dataEvent?.travelType || undefined,
          course: _.get(dataEvent, 'course.uuid', ''),
          courseCode: _.get(dataEvent, 'course.courseCode', ''),
          englishName: _.get(dataEvent, 'course.englishName', ''),
          nameForLearner: _.get(dataEvent, 'course.nameForLearner', ''),
          createdAt: _.get(dataEvent, 'createdAt', null),
          createdBy: _.get(dataEvent, 'createdBy', ''),
          updatedAt: _.get(dataEvent, 'updatedAt', null),
          updatedBy: _.get(dataEvent, 'updatedBy', ''),
        },
      }

      break
    case WORK_WORK_LIST[3]:
      //?    Training

      form = {
        ...form,
        training: {
          ...dataEvent,
          area: {
            id: _.get(dataEvent, 'area.id', 1),
            uuid: _.get(dataEvent, 'area.uuid', 0),
            departments: _.get(dataEvent, 'area.department.department', ''),
            station: _.get(dataEvent, 'area.station.station', 1),
          },
          workType: _.get(dataEvent, 'workType', ''),
          startDate: dayjs(_.get(dataEvent, 'startDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          finishDate: dayjs(_.get(dataEvent, 'finishDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          course: {
            uuid: _.get(dataEvent, 'course.uuid', ''),
            trainingPlatform: _.get(dataEvent, 'course.trainingPlatform', []),
            breakMealBudget: _.get(dataEvent, 'course.breakMealBudget', false),
            courseCode: _.get(dataEvent, 'course.courseCode', ''),
            englishName: _.get(dataEvent, 'course.englishName', ''),
            nameForLearner: _.get(dataEvent, 'course.nameForLearner', ''),
          },
          typeDate: _.get(dataEvent, 'typeDate', ''),
          dateBetween: [],
          dates: _.get(dataEvent, 'dates', []),
          defaultDates: _.get(dataEvent, 'dates', []),
          expectNumberPeople: _.get(dataEvent, 'expectNumberPeople', false),
          classroom: _.get(dataEvent, 'classroom', ''),
          break: _.get(dataEvent, 'break', false),
          cost: _.get(dataEvent, 'cost', 0),
          remarkCost: _.get(dataEvent, 'remarkCost', null),
          plans: _.get(dataEvent, 'plans', []),
        },
      }

      break
    case WORK_WORK_LIST[4]:
      //?    Activity Support
      form = {
        ...form,
        training: {
          ...dataEvent,
          area: {
            id: _.get(dataEvent, 'area.id', 1),
            uuid: _.get(dataEvent, 'area.uuid', 0),
            departments: _.get(dataEvent, 'area.department.department', ''),
            station: _.get(dataEvent, 'area.station.station', 1),
          },
          workType: _.get(dataEvent, 'workType', ''),
          startDate: dayjs(_.get(dataEvent, 'startDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          finishDate: dayjs(_.get(dataEvent, 'finishDate', null)).format(
            window.__env__.REACT_APP_DATE_DB,
          ),
          typeDate: _.get(dataEvent, 'typeDate', ''),
          distributionChannel: {
            uuid: _.get(dataEvent, 'distributionChannel.uuid', ''),
            id: _.get(dataEvent, 'distributionChannel.id', ''),
          },
          dateBetween: [],
          dates: _.get(dataEvent, 'dates', []),
          defaultDates: _.get(dataEvent, 'dates', []),
          expectNumberPeople: _.get(dataEvent, 'expectNumberPeople', false),
          classroom: _.get(dataEvent, 'classroom', ''),
          break: _.get(dataEvent, 'break', false),
          cost: _.get(dataEvent, 'cost', null),
          remarkCost: _.get(dataEvent, 'remarkCost', null),
          plans: _.get(dataEvent, 'plans', []),
          trainingPlatform: _.get(dataEvent, 'trainingPlatform', []).length
            ? _.get(dataEvent, 'trainingPlatform', []).map((item) => {
                return {
                  ...item,
                  title: _.get(item, 'trainingPlatform', ''),
                  label: _.get(item, 'trainingPlatform', ''),
                  value: _.get(item, 'uuid', ''),
                }
              })
            : [],
        },
      }

      break

    default:
      break
  }
  setStateForm(form)
  setIsDrawer((prevState) => ({
    ...prevState,
    type: 'edit',
  }))
}

export const GetActionPlanContentDialog = ({
  status,
  value,
  error,
  data,
  setDialog,
}) => {
  const theme = useTheme()

  const {
    data: { cost, course },
  } = data
  const breakMealBudget = _.get(course, 'breakMealBudget', 0)
  return status === 'REJECTED' ? (
    <TextInput
      id="reason"
      name="reason"
      labelText="เหตุผล"
      type="basic"
      required
      textError={_.get(error, 'reason', '')}
      isShowTextError={true}
      onChange={(e) => {
        if (e.target.value !== '') {
          error.reason = ''
        }

        value.reason = e.target.value
        setDialog((prev) => ({
          ...prev,
          value: { reason: e.target.value },
        }))
      }}
      multiline={true}
      rows={2}
    ></TextInput>
  ) : (
    <Box>
      <Typography>
        คุณยืนยันอนุมัติคลาส และค่าใช้จ่ายต่อคนของหลักสูตรนี้ตามจำนวนที่ขอมา
      </Typography>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Typography sx={{ width: 70 }}>งบเดิม</Typography>
        <Typography>{breakMealBudget} บาท</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Typography
          sx={{
            width: 70,
            fontWeight: 'bold',
            color: theme?.palette?.success?.dark,
          }}
        >
          งบที่ขอ
        </Typography>
        <Typography
          sx={{ fontWeight: 'bold', color: theme?.palette?.success?.dark }}
        >
          {cost} บาท
        </Typography>
      </Box>
    </Box>
  )
}

export const handleSubmitSuccess = (
  data,
  setData,
  setIsLoading,
  optionSubWorkType,
  isDrawer,
  setDialog,
  setIsDrawer,
  handleFetchEvents,
  fetchApproveList,
) => {
  setDialog({
    open: true,
    variant: 'success',
    title: 'สำเร็จ',
    isLoading: false,
    onConfirmClick: () => {
      handleFetchEvents()
      fetchEvent({ data, setData, setIsLoading, optionSubWorkType, isDrawer })
      fetchApproveList()
      setDialog((prev) => ({ ...prev, open: false }))
      setIsDrawer((prev) => ({ ...prev, status: false }))
    },
  })
  return true
}

export const handleActionPlanDialog = ({
  status,
  body,
  error,
  value,
  setDialog,
  data,
  setData,
  setIsLoading,
  optionSubWorkType,
  isDrawer,
  setIsDrawer,
  handleFetchEvents,
  fetchApproveList,
}) => {
  setDialog((prev) => ({
    ...prev,
    isLoading: true,
  }))

  if (
    status === 'REJECTED' &&
    (error.reason !== '' || value.reason === null || value.reason === '') &&
    _.get(value, 'reason', '') <= 3000
  ) {
    error.reason = 'กรุณากรอกข้อมูล'
    setDialog((prev) => ({
      ...prev,
      isLoading: false,
      content: (
        <GetActionPlanContentDialog
          data={data}
          status={status}
          value={value}
          error={error}
        />
      ),
      error: error,
    }))
  } else if (status === 'REJECTED' && value.reason.length > 3000) {
    error.reason = 'กรุณากรอกเหตุผลน้อยกว่า 3000 ตัวอักษร'
    setDialog((prev) => ({
      ...prev,
      isLoading: false,
      content: (
        <GetActionPlanContentDialog
          data={data}
          status={status}
          value={value}
          error={error}
        />
      ),
      error: error,
    }))
  } else {
    API.submitApproveAction({
      body,
      value,
      onSuccess: handleSubmitSuccess(
        data,
        setData,
        setIsLoading,
        optionSubWorkType,
        isDrawer,
        setDialog,
        setIsDrawer,
        handleFetchEvents,
        fetchApproveList,
      ),
    })
  }
}

export const handleStatusActionClick = (
  status,
  data,
  setData,
  setDialog,
  setIsLoading,
  optionSubWorkType,
  isDrawer,
  setIsDrawer,
  handleFetchEvents,
  fetchApproveList,
) => {
  const error = { reason: null }
  let value = { reason: null }
  let title =
    status === 'REJECTED' ? 'ไม่อนุมัติคลาส' : 'อนุมัติคลาส และค่าใช้จ่าย'
  if (status === 'REJECTED' && value.reason === '') {
    error.reason = 'กรุณากรอกข้อมูล'
  } else if (
    status === 'REJECTED' &&
    value.reason != null &&
    value.reason.length > 3000
  ) {
    error.reason = 'กรุณากรอกข้อมูลไม่เกิน 3,000 ตัวอักษร'
  }

  let body = {
    monthlyPlan: {
      id: data?.id,
      uuid: data?.uuid,
    },
    status: status,
    rejectReason: value.reason,
  }

  setDialog({
    open: true,
    variant: status === 'APPROVED' ? 'save' : 'delete',
    title: title,
    content: (
      <GetActionPlanContentDialog
        data={data}
        status={status}
        value={value}
        error={error}
      />
    ),
    value: value,
    agreeText: 'ยืนยัน',
    error: { reason: '' },
    onConfirmClick: () =>
      handleActionPlanDialog({
        status,
        body,
        error,
        value,
        setDialog,
        data,
        setData,
        setIsLoading,
        optionSubWorkType,
        isDrawer,
        setIsDrawer,
        handleFetchEvents,
        fetchApproveList,
      }),
  })
}

export const alertCanNotEditPlan = ({ title, content, setDialog }) => {
  setDialog((prev) => ({
    ...prev,
    open: true,
    variant: 'fail',
    title: title,
    content: content,
    onConfirmClick: () => {
      setDialog((prevDialog) => ({ ...prevDialog, open: false }))
    },
    agreeText: 'ตกลง',
  }))
}

export const handleDeleteClick = (
  user,
  data,
  state,
  masterPlanStatus,
  allowEditPlan,
  setDialog,
  setIsDrawer,
  handleFetchEvents,
  optionSubWorkType,
  setState,
) => {
  const permissionDeleteAll = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['DELETE_ALL'],
    data: state,
  })

  const permissionDeleteLeave = getPermissionDeleteLeave(data, user, state)
  const permissionDeleteAllExcHol = getPermissionDeleteAllExcHol(
    data,
    user,
    state,
  )
  const permissionDelete = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['DELETE_ALL_EXC_HOLIDAY', 'DELETE_ALL', 'DELETE_LEAVE'],
    data: state,
  })

  if (!permissionDeleteAll) {
    if (!permissionDelete) {
      alertCanNotEditPlan({
        title: 'ไม่สามารถแก้ไขได้',
        content: 'เนื่องจากไม่มีสิทธิ์การใช้งานในส่วนนี้',
        setDialog,
      })
      return
    } else if (!permissionDeleteLeave) {
      alertCanNotEditPlan({
        title: 'ไม่สามารถแก้ไขได้',
        content: 'เนื่องจากไม่มีสิทธิ์การใช้งานในส่วนนี้',
        setDialog,
      })
      return
    } else if (!permissionDeleteAllExcHol) {
      alertCanNotEditPlan({
        title: 'ไม่สามารถแก้ไขได้',
        content: 'เนื่องจากไม่มีสิทธิ์การใช้งานในส่วนนี้',
        setDialog,
      })
      return
    }
  }

  if (!allowEditPlan) {
    alertCanNotEditPlan({
      title: 'ไม่สามารถแก้ไขได้',
      content: 'เนื่องจากแผนได้ถูกปิดการแก้ไขแล้ว',
      setDialog,
    })
    return
  }

  if (masterPlanStatus === 'WAITING') {
    setDialog({
      open: true,
      variant: 'fail',
      title: 'ยังไม่สามารถลบได้',
      content:
        'เนื่องจากอยู่ระหว่างการส่งตรวจสอบแผนรายเดือนนี้ หากต้องการลบ \n กรุณายกเลิกการส่งแผนรายเดือนนี้ก่อน \nหรือรอจนกว่าจะตรวจสอบเรียบร้อย',
      onConfirmClick: () => {
        setDialog((prev) => ({ ...prev, open: false }))
      },
    })
    return
  }
  if (
    masterPlanStatus === 'PATIAL_APPROVED' ||
    masterPlanStatus === 'APPROVED'
  ) {
    handleTrainingActionClick(
      {
        data,
        setDialog,
        setIsDrawer,
        optionSubWorkType,
        handleFetchEvents,
        handleDelete: handleDelete,
      },
      // fetchEvent
    )
  } else {
    setDialog((prev) => ({
      ...prev,
      variant: 'delete',
      title: 'ยืนยันลบข้อมูล',
      content: 'คุณต้องการลบรายการนี้หรือไม่',
      open: true,
      onConfirmClick: () =>
        handleDelete({
          setDialog,
          data,
          setIsDrawer,
          handleFetchEvents,
          optionSubWorkType,
        }),
    }))
  }
  setState((prev) => ({
    ...prev,
    handleType: 'actions',
  }))
}

export const getDeletePermission = (data, user, state) => {
  const workType = data.workType
  const deleteAll = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['DELETE_ALL'],
    data: state,
  })
  if (deleteAll) return true
  const deleteAllExcHoliday = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['DELETE_ALL_EXC_HOLIDAY'],
    data: state,
  })
  if (deleteAllExcHoliday && workType !== 'Holiday') return true
  const deleteLeave = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['DELETE_LEAVE'],
    data: state,
  })
  if (deleteLeave && workType === 'Leave') return true
  return false
}

export const getManageClassPermission = (
  user,
  state,
  data,
  manageClassUuid,
) => {
  if (manageClassUuid === '' || !manageClassUuid) {
    return false
  }

  const editAllClass = validatePermission({
    user: user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['EDIT_ALL_CLASS'],
    data: {
      ...state,
      staffProfile: _.get(data, 'staffs', []),
    },
  })

  if (editAllClass) return true
  return false
}

export const getEditPermission = (data, user, state) => {
  const workType = data.workType
  const createAll = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['CREATE_ALL'],
    data: state,
  })
  if (createAll) return true
  const createAllExcHoliday = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['CREATE_ALL_EXC_HOLIDAY'],
    data: state,
  })
  if (createAllExcHoliday && workType !== 'Holiday') return true
  const createLeave = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['CREATE_LEAVE'],
    data: state,
  })
  if (createLeave && workType === 'Leave') return true
  return false
}

export const getPermissionDeleteLeave = (data, user, state) => {
  let editLeave = false
  if (data.workType === 'Leave') {
    editLeave =
      validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: ['DELETE_LEAVE', 'DELETE_ALL_EXC_HOLIDAY', 'DELETE_ALL'],
        data: state,
      }) && validateLeavePermission({ user, data, action: 'DELETE' })
  } else {
    editLeave = validatePermission({
      user: user,
      moduleType: 'MONTHLY_PLAN',
      permission: ['DELETE_ALL_EXC_HOLIDAY', 'DELETE_ALL'],
      data: state,
    })
  }
  return editLeave
}

export const getPermissionDeleteAllExcHol = (data, user, state) => {
  let editHoliday = false
  if (data.workType === 'Holiday') {
    editHoliday = !validatePermission({
      user: user,
      moduleType: 'MONTHLY_PLAN',
      permission: ['DELETE_ALL_EXC_HOLIDAY'],
      data: state,
    })
  } else {
    editHoliday = true
  }
  return editHoliday
}
export const handleEditClick = (
  data,
  setDialog,
  allowEditPlan,
  setIsDrawer,
  setStateForm,
  masterPlanStatus,
) => {
  if (!allowEditPlan) {
    alertCanNotEditPlan({
      title: 'ไม่สามารถแก้ไขได้',
      content: 'เนื่องจากแผนได้ถูกปิดการแก้ไขแล้ว',
      setDialog,
    })
    return
  }
  handleEdit({
    setIsDrawer,
    setStateForm,
    data,
    masterPlanStatus,
    setDialog,
  })
}

export const getPermissionEditLeave = (data, user, state) => {
  let editLeave = false
  if (data.workType === 'Leave') {
    editLeave =
      validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: ['CREATE_LEAVE', 'CREATE_ALL_EXC_HOLIDAY', 'CREATE_ALL'],
        data: state,
      }) && validateLeavePermission({ user, data, action: 'CREATE' })
  } else {
    editLeave = validatePermission({
      user: user,
      moduleType: 'MONTHLY_PLAN',
      permission: ['CREATE_ALL_EXC_HOLIDAY', 'CREATE_ALL'],
      data: state,
    })
  }
  return editLeave
}

export const getPermissionEditAllExcHol = (data, user, state) => {
  let editHoliday = false
  if (data.workType === 'Holiday') {
    editHoliday = !validatePermission({
      user: user,
      moduleType: 'MONTHLY_PLAN',
      permission: ['CREATE_ALL_EXC_HOLIDAY'],
      data: state,
    })
  } else {
    editHoliday = true
  }
  return editHoliday
}

export const getPermissionExpense = (user, stationUuid) => {
  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'CREATE' &&
      item.module === 'EXPENSE'
    )
  })
  const hasPermissionPIC = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'CREATE' &&
      item.module === 'EXPENSE'
    )
  })
  const isUserStation = !_.isEmpty(
    _.find(_.get(user, 'area', []), (item) => {
      return item.station.uuid === stationUuid
    }),
  )
  return hasPermissionALL || (hasPermissionPIC && isUserStation)
}
