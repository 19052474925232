import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/inventory/dashboard'
import { fetchData } from '../components/TableDrawer/events'
//import _ from 'lodash'

export const handleClearFilterReq = (key) => {
  const { filtersReqDrawer, filterReq, tableDrawer } =
    store.getState().inventoryDashboard
  const { courseType, header } = tableDrawer
  const countFilterTotal = filtersReqDrawer.filterTotal
  let newFilter = { ...filterReq }

  if (key === 'stationFilter') {
    // const user = JSON.parse(localStorage.getItem('user'))
    // const userStation = _.get(user, 'area[0].station')
    newFilter = {
      ...filterReq,
      stationChecked: false,
      station: [
        // {
        //   label: userStation.station,
        //   name: `${userStation.station.toLowerCase()}Checked`,
        //   uuid: userStation.uuid,
        // },
      ],
    }
    store.dispatch(
      setFieldValue({
        key: 'filterReq',
        value: newFilter,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filtersReqDrawer.isFilterStation',
        value: false,
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: 'filterReqMemo',
      value: newFilter,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'filtersReqDrawer.filterTotal',
      value: countFilterTotal - 1,
    }),
  )
  store.dispatch(fetchData(courseType, header))
}
