import React from 'react'
import _ from 'lodash'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../../redux/slices/dialog'
import { Box } from '@mui/material'
import { store } from '../../../../../../App'
import { ViewRow } from '../../../../../../components/CRUD/components/StyledViewBox'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../../../components/CRUD/components/CustomTextArea'
import {
  setFieldValue,
  replaceFieldError,
  setFieldError,
} from '../../../../../../redux/slices/crud'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import {
  productReduce,
  productWarehouseQuantityAlert,
} from '../../../../../../utils/apiPath'
import callAxios from '../../../../../../utils/baseService'
import * as yup from 'yup'

export const openReduceDialog = (row) => {
  const { formData } = store.getState().crud
  let product = _.get(row, 'product', '')
  if (row?.productWarehouse) {
    product = _.get(row, 'productWarehouse.product', '')
  }
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        productReduceQty: '',
        productReduceRemark: '',
        available: row?.available,
      },
    }),
  )
  store.dispatch(
    openDialog({
      type: 'content',
      title: 'ปรับลด',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="ชื่อทรัพย์สิน"
            value={_.get(product, 'productName', '-')}
          />
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="ชื่อคลังทรัพย์สิน"
            value={_.get(row, 'warehouseName', '-')}
            required
          />
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="จำนวนคงเหลือปัจจุบัน"
            value={_.get(row, 'available', '-')}
            required
          />
          <CustomInput
            boxSx={{ my: 2 }}
            fieldName="productReduceQty"
            labelName="จำนวนที่ปรับลด"
            required
            inputProps={{
              'data-testid': 'input-productReduceQty',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
          />
          <CustomTextArea
            fieldName="productReduceRemark"
            labelName="สาเหตุ"
            required
          />
        </Box>
      ),
      handleConfirm: () => {
        handleSubmitReduce(row)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const validateData = async (validationSchema) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    return false
  }
}

export const openAssetListDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetDrawer',
      value: true,
    }),
  )
  setAssetDrawerInfo(row)
}

export const openHistoryDrawer = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenHistoryDrawer',
      value: true,
    }),
  )
  setAssetDrawerInfo(row)
}

export const setAssetDrawerInfo = (row) => {
  store.dispatch(
    setFieldValue({
      key: 'assetDrawerInfo',
      value: row,
    }),
  )
}

export const handleSubmitReduce = async (row) => {
  const isValid = await validateData(formValidationSchema)
  if (isValid) {
    const { formData } = store.getState().crud
    const body = {
      uuid: _.get(row, 'uuid', ''),
      quantity: _.get(formData, 'productReduceQty', ''),
      remark: _.get(formData, 'productReduceRemark', ''),
    }
    store.dispatch(loadingDialog())
    await callAxios
      .post(productReduce, body)
      .then(() => {
        store.dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              store.dispatch(closeDialog())
              store.dispatch(
                setFieldValue({
                  key: 'isRefreshTable',
                  value: true,
                }),
              )
            },
          }),
        )
      })
      .catch(() => {
        store.dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: 'ระบบขัดข้อง',
          }),
        )
      })
  }
  // if (!isCancel) store.dispatch(closeDialog())
}

export const formValidationSchema = yup.object({
  available: yup.string().nullable(),
  productReduceQty: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('กรุณาระบุจำนวน')
    .when('available', (available) => {
      let availableNumber = available
      if (typeof availableNumber === 'string') {
        availableNumber = availableNumber
          .replace(/-/g, '')
          .replace(/,/g, '')
          .trim()
      }
      if (available) {
        return yup
          .number()
          .max(
            parseInt(availableNumber),
            'กรุณาระบุจำนวน ในค่าที่น้อยกว่าจำนวนคงเหลือปัจจุบัน',
          )
          .typeError('กรุณาระบุจำนวน')
      }
    }),
  productReduceRemark: yup
    .string()
    .nullable()
    .max(3000, 'กรุณาระบุภายใน 3,000 ตัวอักษร')
    .required('กรุณาระบุสาเหตุ'),
})

export const openQuantityAlertDialog = (row) => {
  const { formData } = store.getState().crud
  let product = _.get(row, 'product', '')
  if (row?.productWarehouse) {
    product = _.get(row, 'productWarehouse.product', '')
  }
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        quantityAlert: row?.quantityAlert ?? '',
      },
    }),
  )
  store.dispatch(
    openDialog({
      type: 'content',
      title: 'ปรับลด',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="ชื่อทรัพย์สิน"
            value={_.get(product, 'productName', '-')}
          />
          <ViewRow
            titleSx={{ minWidth: '200px' }}
            variant="body1"
            title="ชื่อคลังทรัพย์สิน"
            value={_.get(row, 'warehouseName', '-')}
            required
          />
          <CustomInput
            boxSx={{ my: 2 }}
            fieldName="quantityAlert"
            labelName="จำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย"
            required
            inputProps={{
              'data-testid': 'input-quantityAlert',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
          />
        </Box>
      ),
      handleConfirm: () => {
        handleSubmitQuantityAlert(row)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const handleSubmitQuantityAlert = async (row) => {
  const isValid = await validateData(formValidationQtySchema)
  if (isValid) {
    const { formData } = store.getState().crud
    const body = {
      uuid: _.get(row, 'uuid', ''),
      quantityAlert: _.get(formData, 'quantityAlert', ''),
    }
    store.dispatch(loadingDialog())
    await callAxios
      .post(productWarehouseQuantityAlert, body)
      .then(() => {
        store.dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              store.dispatch(closeDialog())
              store.dispatch(
                setFieldValue({
                  key: 'isRefreshTable',
                  value: true,
                }),
              )
            },
          }),
        )
      })
      .catch(() => {
        store.dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: 'ระบบขัดข้อง',
          }),
        )
      })
  }
  // if (!isCancel) store.dispatch(closeDialog())
}

export const formValidationQtySchema = yup.object({
  quantityAlert: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .test(
      'quantityAlert',
      'กรุณาระบุจำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย ภายใน 20 หลัก',
      (val) => (val || val === 0) && val.toString().length <= 20,
    )
    .required('กรุณาระบุจำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย ภายใน 20 หลัก'),
})
