import { store } from '../../../../../App'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../redux/slices/dialog'
import { transferTicketStatus } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { fetchDataList } from '../../../../../components/CRUD/handler/fetchDataList'
import { mutateFilterPropToBody } from '../../../../../modules/Inventory/Transfer/Listing/mutation/filterPropToBody'
import { TicketStatus, TicketStatusText } from '../../../../../constants/stock'
import { rejectValidationSchema } from '../../../../../modules/Inventory/Transfer/Form/schema/schema'
import {
  replaceFieldError,
  setFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'
import { Box } from '@mui/material'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'

export const onCancel = async (row, status) => {
  confirmDialog(row.uuid, status)
}

function confirmDialog(uuid, status) {
  let DIALOG_TITLE = `ยืนยันลบข้อมูล`
  let agreeText = 'บันทึก'
  let disagreeText = 'ยกเลิก'
  if (TicketStatus.REJECTED === status) {
    openRejectDialog(uuid, status)
  } else {
    if (TicketStatus.CANCELED === status) {
      DIALOG_TITLE = `ยืนยันการยกเลิก`
      agreeText = 'ยืนยัน'
      disagreeText = 'ปฏิเสธ'
    } else if (TicketStatus.REJECTED === status) {
      DIALOG_TITLE = `ยืนยันการปฏิเสธ`
      agreeText = 'ยืนยัน'
      disagreeText = 'ยกเลิก'
    }
    store.dispatch(
      openDialog({
        type: 'delete',
        title: DIALOG_TITLE,
        message: `คุณต้องการ${TicketStatusText?.[status]}รายการนี้ใช่หรือไม่`,
        agreeText: agreeText,
        disagreeText: disagreeText,
        handleConfirm: () => {
          changeTransferStatus(uuid, status)
        },
        isCloseDialog: false,
      }),
    )
  }
}

const changeTransferStatus = async (uuid, status, formData = null) => {
  const body = {
    uuid,
    status,
    rejectTransferReason:
      status === TicketStatus.REJECTED
        ? formData?.rejectTransferReason
        : undefined,
  }
  store.dispatch(loadingDialog())
  await callAxios
    .put(transferTicketStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            fetchDataList(mutateFilterPropToBody).then()
          },
        }),
      )
    })
    .catch(() => {
      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
        }),
      )
    })
}

export const openRejectDialog = (uuid, status) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        rejectTransferReason: '',
      },
    }),
  )
  store.dispatch(
    openDialog({
      type: 'delete',
      title: `ยืนยันการปฏิเสธ`,
      agreeText: 'ยืนยัน',
      disagreeText: 'ยกเลิก',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          <CustomTextArea
            fieldName="rejectTransferReason"
            labelName="เหตุผลการปฏิเสธการโอนย้าย"
            required
          />
        </Box>
      ),
      handleConfirm: () => {
        handleSubmitReject(uuid, status)
      },
      handleCancel: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

export const handleSubmitReject = async (uuid, status) => {
  const isValid = await validateData(rejectValidationSchema)
  if (isValid) {
    const { formData } = store.getState().crud
    changeTransferStatus(uuid, status, formData)
  }
}

export const validateData = async (validationSchema) => {
  const { formData } = store.getState().crud
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    return false
  }
}
