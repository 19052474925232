import dayjs from 'dayjs'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { fetchOverview } from '../../handler/fetchOverview'

export const handleClearFilter = async (key, _, filterTotal) => {
  if (key === 'filterDate') {
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.startDate',
        value: dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.endDate',
        value: dayjs().format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.filterTotal',
        value: filterTotal - 1,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.clearFilter',
        value: 'date',
      }),
    )
  }
  if (key === 'stationFilter') {
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.station',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.filterTotal',
        value: filterTotal - 1,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.clearFilter',
        value: 'station',
      }),
    )
  }
  if (key === 'associationRegionFilter') {
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.associationRegion',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.associationProvince',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.filterTotal',
        value: filterTotal - 1,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.clearFilter',
        value: 'associationRegion',
      }),
    )
  }
  if (key === 'associationProvinceFilter') {
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.associationProvince',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.clearFilter',
        value: 'associationProvince',
      }),
    )
  }
  if (key === 'otherRegionFilter') {
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.otherRegion',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.otherProvince',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.filterTotal',
        value: filterTotal - 1,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.clearFilter',
        value: 'otherRegion',
      }),
    )
  }
  if (key === 'otherProvinceFilter') {
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.otherProvince',
        value: [],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eExamDashboard.filtersDrawer.clearFilter',
        value: 'otherProvince',
      }),
    )
  }
  fetchOverview()
}
