import _ from 'lodash'
import { callAPI } from '../../../../../utils/lib'
import {
  examinationAssociationFilter,
  examinationRegionList,
  stationFilter,
} from '../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enums/drawerEnum'
import { fetchOverview } from '../../handler/fetchOverview'
import { defaultFilter } from './model'

const checkIsFilter = (key, filter) => {
  if (key === 'association' || key === 'otherFilter') {
    return (
      filter?.[`${key}Checked`] &&
      filter?.[`${key}RegionChecked`] &&
      filter?.[`${key}RegionList`].length > 0
    )
  } else if (key === 'station') {
    return filter?.[`${key}Checked`] && filter?.[`${key}List`].length > 0
  }
}

export const handleFiltering = (filter) => (dispatch) => {
  const isFilterDate = filter.dateChecked && filter.startDate && filter.endDate
  const isFilterStation = checkIsFilter('station', filter)
  const isFilterAssoc = checkIsFilter('association', filter)
  const isFilterotherFilter = checkIsFilter('otherFilter', filter)
  const isFilterAssocRegion = filter?.associationRegionChecked
  const isFilterAssocProvince = filter?.associationProvinceChecked
  const isFilterOtherRegion = filter?.otherFilterRegionChecked
  const isFilterOtherProvince = filter?.otherFilterProvinceChecked

  let countFilterTotal = 0
  if (isFilterStation) ++countFilterTotal
  if (isFilterDate) ++countFilterTotal
  if (isFilterAssoc) ++countFilterTotal
  if (isFilterotherFilter) ++countFilterTotal
  dispatch(
    setFieldValue({
      key: 'eExamDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        startDate: isFilterDate
          ? dayjs(filter.startDate).format('YYYY-MM-DD')
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: isFilterDate
          ? dayjs(filter.endDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        station: isFilterStation ? filter.stationList : [],
        associationRegion: isFilterAssocRegion
          ? filter.associationRegionList
          : [],
        associationProvince: isFilterAssocProvince
          ? filter.associationProvinceList
          : [],
        otherRegion: isFilterOtherRegion ? filter.otherFilterRegionList : [],
        otherProvince: isFilterOtherProvince
          ? filter.otherFilterProvinceList
          : [],
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const handleFetchStation = async (setStation) => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }
  await callAPI({
    method: 'post',
    url: stationFilter,
    body,
    onSuccess: (data) => {
      const stationList = _.get(data, 'result', []).map((item) => ({
        label: `${item.academyStationName} (${item.locationCode})`,
        name: _.lowerCase(item.academyStationName).replace(' ', '') + 'Checked',
        uuid: item.uuid,
        value: item.uuid,
      }))
      setStation(stationList)
    },
  })
}

export const fetchRegion = async (setRegion) => {
  await callAPI({
    method: 'post',
    url: examinationRegionList,
    body: {
      regionName: '',
      createdBy: '',
      updatedBy: '',
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE'],
    },
    onSuccess: (data) => {
      const regionList = _.get(data, 'result', []).map((item) => ({
        label: item.regionName,
        name: _.lowerCase(item.regionName).replace(' ', '') + 'Checked',
        uuid: item.uuid,
        value: item.uuid,
      }))
      setRegion(regionList)
    },
  })
}

export const fetchAssociation = async (setAssociation) => {
  await callAPI({
    method: 'post',
    url: examinationAssociationFilter,
    body: {
      search: '',
      region: [],
      provinceName: '',
      locationCode: '',
      createdBy: '',
      updatedBy: '',
      status: ['ACTIVE'],
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'updatedAt',
    },
    onSuccess: (data) => {
      const associationList = _.get(data, 'result', []).map((item) => ({
        label: `${item.provinceName} (${item.locationCode})`,
        name: _.lowerCase(item.provinceName).replace(' ', '') + 'Checked',
        regionUuid: item.regionUuid,
        uuid: item.uuid,
        value: item.uuid,
      }))
      setAssociation(associationList)
    },
  })
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleSelectMultiple = (list, filter, setFilter, nameInput) => {
  let objState = {
    ...filter,
    [`${nameInput}List`]: list,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateChecked: false,
      startDate: '',
      endDate: '',
      displayDate: false,
    }
  } else if (key === 'station') {
    objState = {
      ...objState,
      stationChecked: false,
      station: undefined,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}
