import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'

const eExamList = createSlice({
  name: 'eExamList',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoading: (state) => {
      state.isLoadingDrawer = false
    },
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setEExamType: (state, { payload }) => {
      state.eExamType = payload
    },
    setImportTemplateDialog: (state, { payload }) => {
      const { keyField, value } = payload
      state.importTemplateDialog = {
        ...state.importTemplateDialog,
        [keyField]: value,
      }
    },
    setExamList: (state, { payload }) => {
      state.examList = payload
    },
    setExaminerList: (state, { payload }) => {
      state.examinerList = payload
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenExaminerFilterDrawer: (state, { payload }) => {
      state.isOpenExaminerFilterDrawer = payload
    },
    setIsOpenHistoryFilterDrawer: (state, { payload }) => {
      state.isOpenHistoryFilterDrawer = payload
    },
    setRegionsList: (state, { payload }) => {
      state.regionsList = payload
    },
    setImportFile: (state, { payload }) => {
      const file = payload.target.files[0]
      const fileName = _.get(payload, 'target.files[0].name', 'No File Chosen')
      state.importFile.fileName = fileName
      state.importFile.file = file
      state.importFile.uploadResult = []
    },
    resetFile: (state) => {
      state.importFile.file = null
      state.importFile.fileName = 'No File Chosen'
    },
    setFileUploadResult: (state, { payload }) => {
      state.importFile.uploadResult = payload
    },
    setExaminationTime: (state, { payload }) => {
      state.timeList = payload
    },
    setAssociationList: (state, { payload }) => {
      state.associationList = payload
    },
    setCompanyList: (state, { payload }) => {
      state.companyList = payload
    },
    setImportExamierType: (state, { payload }) => {
      state.importExaminerType = payload
    },
    setImportExamierFile: (state, { payload }) => {
      const file = payload.target.files[0]
      const fileName = _.get(
        payload,
        'target.files[0].name',
        'นามสกุลไฟล์ที่รองรับ .xlsx ขนาดไฟล์ที่แนะนำไม่เกิน 2 MB',
      )
      state.importExaminerFile.fileName = fileName
      state.importExaminerFile.file = file
      state.importExaminerFile.uploadResult = []
    },
    resetExamierFile: (state) => {
      state.importExaminerFile.file = null
      state.importExaminerFile.fileName =
        'นามสกุลไฟล์ที่รองรับ .xlsx ขนาดไฟล์ที่แนะนำไม่เกิน 2 MB'
    },
    setExamierFileUploadResult: (state, { payload }) => {
      state.importExaminerFile.uploadResult = payload
    },
    setSelectedExaminer: (state, { payload }) => {
      state.selectedExaminer = payload
    },
    setStudentHistoryList: (state, { payload }) => {
      state.studentHistoryList = payload
    },
    setAnnouncement: (state, { payload }) => {
      const {
        scheduleName,
        announce,
        annotation,
        isEnableScheduleName,
        isEnableAnounce,
        scheduleStatus,
        isUploadResult,
      } = payload
      state.announcement.scheduleName = scheduleName
      state.announcement.announce = announce
      state.announcement.annotation = annotation
      state.announcement.isEnableScheduleName = isEnableScheduleName
      state.announcement.isEnableAnounce = isEnableAnounce
      state.announcement.scheduleStatus = scheduleStatus
      state.announcement.isUploadResult = isUploadResult
    },
    setAnnouncementForm: (state, { payload }) => {
      const { scheduleNameForm, annotationForm } = payload
      state.announcement.scheduleNameForm = scheduleNameForm
      state.announcement.annotationForm = annotationForm
    },
    setInitialExamList: (state, { payload }) => {
      state.initialExamList = payload
    },
    setInitialExaminerList: (state, { payload }) => {
      state.initialExaminerList = payload
    },
    setRefreshExaminerList: (state, { payload }) => {
      state.refreshExaminerList = payload
    },
    setOtherDocConfig: (state, { payload }) => {
      state.otherDocConfig = payload
    },
    setExaminationPermissions: (state, { payload }) => {
      state.examinationPermissions = payload
    },
    setExaminerPermissions: (state, { payload }) => {
      state.examinerPermissions = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
  setEExamType,
  setImportTemplateDialog,
  setExamList,
  setIsOpenFilterDrawer,
  setRegionsList,
  setExaminerList,
  setIsOpenExaminerFilterDrawer,
  setIsOpenHistoryFilterDrawer,
  setImportFile,
  resetFile,
  setFileUploadResult,
  setExaminationTime,
  setAssociationList,
  setCompanyList,
  setImportExamierFile,
  resetExamierFile,
  setExamierFileUploadResult,
  setSelectedExaminer,
  setStudentHistoryList,
  setImportExamierType,
  setAnnouncement,
  setAnnouncementForm,
  setInitialExamList,
  setInitialExaminerList,
  setRefreshExaminerList,
  setOtherDocConfig,
  setExaminationPermissions,
  setExaminerPermissions,
} = eExamList.actions
export default eExamList.reducer
