export const productListBody = (productType, bodyProps) => {
  return {
    limit: '100',
    page: bodyProps.page,
    order: bodyProps.order,
    sort: bodyProps.sort,
    quickSearch: bodyProps.searchText,
    name: '',
    status: [],
    search: '',
    availableFrom: '',
    availableTo: '',
    productType: [productType],
    category: '',
    subCategory: '',
    vendor: '',
    stockValueFrom: '',
    stockValueTo: '',
    createdBy: '',
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    updatedBy: '',
    station: '',
    stationList: [],
    warehouse: '',
    costCenter: '',
    description: '',
    returnYear: '',
    returnMonth: '',
    returnDay: '',
    totalFrom: '',
    totalTo: '',
    quantityAlertFrom: '',
    quantityAlertTo: '',
    unitPriceFrom: '',
    unitPriceTo: '',
    holder: '',
    openDateFinish: '',
    openDateStart: '',
  }
}

export const assetProductListBody = (holder, bodyProps) => {
  return {
    limit: '100',
    page: bodyProps.page,
    order: bodyProps.order,
    sort: bodyProps.sort,
    quickSearch: bodyProps.searchText,
    name: '',
    status: [],
    search: '',
    availableFrom: '',
    availableTo: '',
    productType: ['ASSET'],
    category: '',
    subCategory: '',
    vendor: '',
    stockValueFrom: '',
    stockValueTo: '',
    createdBy: '',
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    updatedBy: '',
    station: '',
    stationList: [],
    warehouse: '',
    costCenter: '',
    description: '',
    returnYear: '',
    returnMonth: '',
    returnDay: '',
    totalFrom: '',
    totalTo: '',
    quantityAlertFrom: '',
    quantityAlertTo: '',
    unitPriceFrom: '',
    unitPriceTo: '',
    holder: holder,
    openDateFinish: '',
    openDateStart: '',
  }
}

export const inventoryRequisitionBody = (bodyProps, filterProps, newLimit) => {
  return {
    limit: newLimit ?? '5',
    page: bodyProps.page ?? 1,
    order: bodyProps.order,
    sort: bodyProps.sort,
    quickSearch: bodyProps.searchText,
    name: '',
    search: '',
    status: filterProps?.status ?? [],
    transferType: ['REQUISITION'],
    transferBy: ['ALL'],
    requisitionType: filterProps?.ticketType ?? [],
    station: filterProps?.station ?? [],
    returnDateFrom: filterProps?.returnDateFrom ?? '',
    returnDateTo: filterProps?.returnDateTo ?? '',
    startCreatedDate: filterProps?.startDate ?? '',
    endCreatedDate: filterProps?.endDate ?? '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    product: '',
    qtyFrom: '',
    qtyTo: '',
    category: '',
    subCategory: '',
    createdBy: '',
    updatedBy: '',
    openDateFinish: '',
    openDateStart: '',
  }
}
