import { CustomTextArea } from '../../../../components/CRUD/components/CustomTextArea'
import { Box, Grid, Typography } from '@mui/material'
import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector, shallowEqual } from 'react-redux'
import { tabTypeEnum, tabTypeTxtEnum } from './enum/requisitionTypeEnum'
import { MonthlyForm } from './components/MonthlyForm'
import { CustomDropdown } from '../../../../components/CRUD/components/CustomDropdown'
import { handleSelectWarehouse } from './handler/handleSelectWarehouse'
import _ from 'lodash'
import UploadFileComp from '../../../../components/UploadFile'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../redux/slices/crud'
import { useEffect } from 'react'

export const Content = ({ uuid }) => {
  const dispatch = useDispatch()
  const { requisitionType, warehouse, attachFiles } = useSelector(
    (state) => ({
      requisitionType: state.crud.formData.requisitionType,
      warehouse: state.crud.masterData.warehouse,
      attachFiles: state.uploadFile.formData.attachFiles,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (attachFiles && !attachFiles.isUploading) {
      if (attachFiles && attachFiles?.files?.length > 0) {
        let files = []
        for (const file of attachFiles.files) {
          const key = file.key
          const displayName = file.displayName
          const fileSize = file.fileSize
          const fileType = file.fileType
          files.push({
            cardImage: key,
            imageName: displayName,
            documentName: 'รูปภาพประกอบการยืม',
            key,
            fileName: displayName,
            fileSize,
            fileType,
          })
        }
        dispatch(
          setFieldValue({
            key: 'formData.attachFiles',
            value: files,
          }),
        )
      } else {
        dispatch(
          setFieldValue({
            key: 'formData.attachFiles',
            value: [],
          }),
        )
      }
    }
  }, [attachFiles])

  const warehouseOptions = _.defaultTo(warehouse?.options, []).filter(
    (item) => item?.warehouseType !== 'PROVIDER',
  )
  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <Box>
        <ViewRow
          titleSx={{ minWidth: 150 }}
          title="ประเภทรายการเบิก"
          value={tabTypeTxtEnum?.[requisitionType]}
          boxSx={{ mb: 2 }}
        />
        {requisitionType === tabTypeEnum.MONTHLY && (
          <MonthlyForm warehouseOptions={warehouseOptions} uuid={uuid} />
        )}

        {requisitionType === tabTypeEnum.OTHER && (
          <Box mb={2}>
            <CustomDropdown
              required={true}
              fieldName="warehouse"
              labelName="คลังทรัพย์สิน"
              placeholder="เลือก คลังทรัพย์สิน"
              isCustomOption={true}
              customOptions={{ isLoading: false, options: warehouseOptions }}
              handleExtraChange={(event, value) => {
                handleSelectWarehouse(value, uuid)
              }}
            />
          </Box>
        )}
        <CustomTextArea
          fieldName="annotation"
          placeholder="กรอกหมายเหตุ"
          labelName="หมายเหตุ"
        />
        {requisitionType === tabTypeEnum.OTHER && (
          <Grid item sm={12} xs={12} width={'100%'} mt={2}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2" color="text.secondary">
                {'รูปภาพประกอบการยืม'}
              </Typography>
              {/* <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
                *
              </Typography> */}
            </Box>
            <UploadFileComp
              sx={{
                boxShadow: 'none',
                border: 'none',
                width: '100%',
                p: 0,
              }}
              isMultiple={true}
              isShowDownloadable={false}
              isForImage={true}
              folder="inventory-requisition"
              maxFileSize={{ size: 2, sizeType: 'MB' }}
              maxLength={1}
              accept={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
                'image/jpg': ['.jpg'],
              }}
              acceptLabel=".jpg, .jpeg, .png"
              fieldName="attachFiles"
              label=""
              popupLabel={'รูปภาพประกอบการยืม'}
              disabled={false}
              // disabledRemove={!isShow}
              // hideUpload={}
            />
          </Grid>
        )}
      </Box>
    </>
  )
}
