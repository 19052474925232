import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
import { toggleFiltersReqDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'

// import { defaultFilter } from './model'
import { callAPI } from '../../../../../utils/lib'
import { stationFilter } from '../../../../../utils/apiPath'
import {
  statusEnum,
  statusValueEnum,
} from '../../../Requisition/View/enum/statusEnum'
import { tabTypeEnum } from '../../../Requisition/Listing/enum/tabTypeEnum'
import { fetchData } from '../TableDrawer/events'
import { store } from '../../../../../App'

export const handleReqFiltering = (filter, station) => (dispatch) => {
  const { courseType, header } = store.getState().inventoryDashboard.tableDrawer
  const stationList = []
  const statusList = []
  const ticketTypeList = []
  if (filter.stationChecked) {
    for (const key in filter.station) {
      if (!filter.station[key]) continue
      const item = station.find((e) => e.name === key)
      stationList.push(item)
    }
  }
  if (filter.statusChecked) {
    for (const key in filter.status) {
      if (!filter.status[key]) continue
      if (key === 'waitingReturn' && filter?.status[key] === true) {
        statusList.push(statusValueEnum.WAITING_RETURN)
      }
      if (key === 'approve' && filter?.status[key] === true) {
        statusList.push(statusValueEnum.WAITING_RETURN_APPROVE)
      }
    }
  }
  if (filter.ticketTypeChecked) {
    for (const key in filter.ticketType) {
      if (!filter.ticketType[key]) continue
      if (key === 'monthlyPlan' && filter?.ticketType[key] === true) {
        ticketTypeList.push(tabTypeEnum.MONTHLY)
      }
      if (key === 'other' && filter?.ticketType[key] === true) {
        ticketTypeList.push(tabTypeEnum.OTHER)
      }
    }
  }
  const user = JSON.parse(localStorage.getItem('user'))
  const stationUser = _.get(user, 'area[0].station')
  const isFilterDate =
    filter.startDateChecked && filter.startDate && filter.endDate ? true : false
  const isFilterCourse = filter.courseChecked && filter.course
  const isFilterStation = filter.stationChecked && stationList.length > 0
  const isFilterReturnDate =
    filter.returnDateChecked && (filter.returnDateFrom || filter.returnDateTo)
  const isFilterStatus = filter.statusChecked && statusList.length > 0
  const isFilterTicketType =
    filter.ticketTypeChecked && ticketTypeList.length > 0
  let countFilterTotal = 0
  if (isFilterDate) ++countFilterTotal
  if (isFilterCourse) ++countFilterTotal
  if (isFilterStation) ++countFilterTotal
  if (isFilterReturnDate) ++countFilterTotal
  if (isFilterStatus) ++countFilterTotal
  if (isFilterTicketType) ++countFilterTotal

  dispatch(
    setFieldValue({
      key: 'filtersReqDrawer',
      value: {
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
        isFilterStation: isFilterStation,
      },
    }),
  )
  dispatch(
    setFieldValue({
      key: 'filterReq',
      value: {
        ...filter,
        ticketType: ticketTypeList,
        status: statusList,
        station: isFilterStation
          ? stationList
          : [
              {
                label: stationUser.station,
                name: `${stationUser.station.toLowerCase()}Checked`,
                uuid: stationUser.uuid,
              },
            ],
      },
    }),
  )
  toggleFiltersReqDrawer(false)
  dispatch(fetchData(courseType, header))
}

// export const handleChange = ({
//   key,
//   value,
//   filter,
//   setFilter,
//   listKey,
//   isCheck,
// }) => {
//   let objState = {}
//   if (!_.isNil(listKey)) {
//     objState = {
//       ...filter,
//       [listKey]: {
//         ...filter[listKey],
//         [key]: value,
//       },
//     }
//   } else if (isCheck) {
//     const subKey = key.replace('Checked', '')
//     objState = {
//       ...filter,
//       [key]: value,
//       [subKey]: defaultFilter[subKey],
//     }
//   } else {
//     objState = {
//       ...filter,
//       [key]: value,
//     }
//   }
//   setFilter(objState)
// }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleFetchStation = async (setStation) => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }
  await callAPI({
    method: 'post',
    url: stationFilter,
    body,
    onSuccess: (data) => {
      const stationList = _.get(data, 'result', []).map((item) => ({
        label: item.station,
        name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
        uuid: item.uuid,
      }))
      setStation(stationList)
    },
  })
}

export const mutateFilterStateToProp = (filterState) => {
  const status = []
  const ticketType = []
  if (filterState.statuschecked) {
    if (filterState.waitingReturnChecked === true) {
      status.push(statusEnum.WAITING_RETURN)
    }
    if (filterState.approveChecked === true) {
      status.push(statusEnum.WAITING_RETURN_APPROVE)
    }
  }
  if (filterState.ticketTypechecked) {
    if (filterState.monthlyPlanChecked === true) {
      ticketType.push(tabTypeEnum.MONTHLY)
    }
    if (filterState.otherChecked === true) {
      ticketType.push(tabTypeEnum.OTHER)
    }
  }
  return {
    startDate: filterState.startDateChecked
      ? filterState.startDate
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: filterState.startDateChecked
      ? filterState.endDate
      : dayjs(new Date()).utc(true),
    inventory: filterState.inventoryChecked ? filterState.inventory : '',
    station: filterState.stationChecked ? filterState.station : '',
    course: filterState.courseChecked ? filterState.course : '',
    status: status,
    ticketType: ticketType,
    returnDateFrom: filterState.returnDateChecked
      ? filterState.returnDateFrom
      : '',
    returnDateTo: filterState.returnDateChecked ? filterState.returnDateto : '',
  }
}

export const mutateFilterPropToBody = (filterProp) => {
  const stationBody = _.get(filterProp, 'station', []).map((item) => item.uuid)
  return {
    startDate: _.isDate(filterProp?.startDate)
      ? dayjs(filterProp?.startDate).utc(true).toDate()
      : '',
    endDate: _.isDate(filterProp?.endDate)
      ? dayjs(filterProp?.endDate).utc(true).toDate()
      : '',
    inventory: _.get(filterProp, 'inventory', ''),
    station: stationBody,
    course: _.get(filterProp, 'course', ''),
    status: _.get(filterProp, 'status', []),
    ticketType: _.get(filterProp, 'ticketType', []),
    returnDateFrom: _.get(filterProp, 'returnDateFrom', ''),
    returnDateTo: _.get(filterProp, 'returnDateTo', ''),
  }
}
