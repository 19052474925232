import _ from 'lodash'
import dayjs from 'dayjs'

import { callAPI, setMapListOptions } from '../../../utils/lib'
import {
  academyActivity,
  academyCertified,
  academyMeeting,
  academyOneOnOne,
  academyStaffTraining,
  academyTrainTheTrainer,
  academyTravelling,
  academyWorkOffice,
  department,
  filterMeetingRoom,
  filterStaffProfile,
  filterVersion,
  leaveType,
  monthlyPlanArea,
  monthlyPlanCostApprove,
  monthlyPlanFilter,
  monthlyPlanFilterStaff,
  monthlyPlanHeadZone,
  monthlyPlanHoliday,
  monthlyPlanLeave,
  monthlyPlanMasterPlanStatus,
  monthlyPlanPersonal,
  monthlyPlanSpeakerBorrowCancel,
  monthlyPlanTraining,
  monthlyPlanTrainingOther,
  monthlyPlanWorkload,
  staffProfileInArea,
  subWorkType,
  workType,
  monthlyPlanCostApproveDownload,
  monthlyPlanCostApproveHistory,
  trainingPlanCalendar,
  trainingPlanArea,
  staffProfileInAreaDetail,
} from '../../../utils/apiPath'

import { SUB_WORK_TYPE_ACADEMY, WORK_WORK_LIST } from '../Text'

export const fetchWorkType = async () => {
  const body = {
    workType: '',
    limit: 100,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  let optionsList = []

  try {
    const result = await callAPI({ url: workType, method: 'POST', body: body })
    _.get(result, 'result', []).forEach((d) => {
      if (d.status === 'ACTIVE') {
        optionsList = [
          ...optionsList,
          { ...d, label: d.workType, value: d.workType, uuid: d.uuid },
        ]
      }
    })
  } catch (error) {
    console.log('fetchWorkType : error => ', error.response)
  }
  return optionsList
}
export const fetchArea = async (user) => {
  let areaList = []
  try {
    const result = await callAPI({
      url: `${monthlyPlanArea}/${_.get(user, 'uuid', '')}?beta=${true}`,
      method: 'get',
    })
    if (result.length) {
      areaList = result.map((item) => {
        return findValueArea(item, 'monthlyPlanArea')
      })
    }
  } catch (error) {
    console.log('fetchArea : error => ', error.response)
  }
  return areaList
}

export const findValueArea = (obj, key) => {
  let result = {}
  if (obj) {
    let areaUuid = _.get(obj, 'areaUuid', '')
    let departmentUuid = _.get(obj, 'station[0].department', '')
    let uuidStation = _.get(obj, 'station[0].uuid', '')
    if (key === 'monthlyPlanArea') {
      _.get(obj, 'station', []).find((itemStation) => {
        if (itemStation.department === _.get(obj, 'uuid', '')) {
          uuidStation = _.get(itemStation, 'uuid', '')
          areaUuid = _.get(itemStation, 'areaUuid', '')
          departmentUuid = _.get(itemStation, 'department', '')
        }
      })
    }

    result = {
      ...obj,
      area: {
        id: _.get(obj, 'areaId', ''),
        uuid: areaUuid,
        value: areaUuid,
      },
      id: _.get(obj, 'areaId', ''),
      label: `(${_.get(obj, 'initial')}) ${_.get(obj, 'department')}`,

      value: areaUuid,
      valueArea: departmentUuid,
      valueStation: uuidStation,

      station: _.get(obj, 'station', []).length
        ? _.get(obj, 'station', []).map((objStation) => {
            return {
              ...objStation,
              label: _.get(objStation, 'station'),
              value: _.get(objStation, 'uuid', ''),
            }
          })
        : [],
    }
  }
  return result
}

export const fetchTrainingPlanArea = async () => {
  let areaList = []
  try {
    const result = await callAPI({
      url: trainingPlanArea,
      method: 'get',
    })
    if (result.length) {
      areaList = result.map((item) => {
        return findValueArea(item, 'trainingPlanArea')
      })
    }
  } catch (error) {
    console.log('fetchArea : error => ', error.response)
  }

  return areaList
}

export const fetchAcademyActivity = async () => {
  const body = {
    activity: '',
    limit: 100,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  let optionsList = []

  try {
    const result = await callAPI({
      body: body,
      method: 'post',
      url: academyActivity,
    })
    _.get(result, `result`, []).forEach((d) => {
      if (d.status === 'ACTIVE') {
        optionsList = [
          ...optionsList,
          { ...d, label: d.activity, value: d.activity },
        ]
      }
    })
  } catch (error) {
    console.log('fetchAcademyActivity : error => ', error.response)
  }
  return optionsList
}
export const fetchLeaveType = async () => {
  const body = {
    leaveType: '',
    limit: 100,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  let optionsList = []

  try {
    const result = await callAPI({ url: leaveType, method: 'POST', body: body })

    _.get(result, `result`, []).forEach((d) => {
      if (d.status === 'ACTIVE') {
        optionsList = [
          ...optionsList,
          { ...d, label: d.leaveType, value: d.leaveType },
        ]
      }
    })
  } catch (error) {
    console.log('fetchLeaveType : error => ', error.response)
  }
  return optionsList
}
export const createHoliday = async ({
  stateForm,
  optionWorkType,
  optionSubWorkType,
}) => {
  const { workType, dayOff, startDate, finishDate, holidayType, remark } =
    stateForm

  const { objWorkType, objSubWorkType } = findWorkTypeAndSubWorkType({
    optionWorkType,
    optionSubWorkType,
    workType,
    subWorkType: holidayType,
  })

  let result = {
    status: false,
    value: [],
    text: 'วันหยุด',
  }

  const body = {
    title: dayOff,
    workType: objWorkType,
    subWorkType: objSubWorkType,
    remark: remark,
    startDate: dayjs(startDate).format(window.__env__.REACT_APP_DATE_DB),
    finishDate: dayjs(finishDate).format(window.__env__.REACT_APP_DATE_DB),
    status: 'ACTIVE',
  }
  try {
    const resultValue = await callAPI({
      url: monthlyPlanHoliday,
      method: 'POST',
      body: body,
    })

    result = {
      ...result,
      status: true,
      value: resultValue,
    }
  } catch (error) {
    console.log('fetchLeaveType : error => ', error.response)
    result = {
      ...result,
      status: false,
      value: _.get(error, 'response.data', {}),
    }
  }
  return result
}
export const updateMonthlyPlan = async ({
  workType,
  body,
  nameSubWorkType = '',
}) => {
  let result = {
    status: true,
    value: [],
    text: '',
  }
  let text = ''

  let url = ''
  switch (workType) {
    case WORK_WORK_LIST[0]:
      //?    วันหยุด
      text = 'วันหยุด'
      url = monthlyPlanHoliday
      break
    case WORK_WORK_LIST[1]:
      //?    วันลา
      text = 'ผู้ลา'
      url = monthlyPlanLeave
      break
    case WORK_WORK_LIST[2]:
      //?    Academy Activity
      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
        text = 'ผู้รับผิดชอบงาน'
        url = academyWorkOffice
      }

      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]) {
        text = 'ผู้เข้าร่วมประชุม'
        url = academyMeeting
      }

      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6]) {
        text = 'ผู้เข้าร่วมประชุม'
        url = academyOneOnOne
      }

      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
        text = 'ผู้รับผิดชอบงาน'
        url = academyTravelling
      }

      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1]) {
        text = 'วิทยากรและผู้เข้าร่วมประชุม'
        url = academyStaffTraining
      }

      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2]) {
        text = 'วิทยากรและผู้เข้าร่วมประชุม'
        url = academyTrainTheTrainer
      }
      if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]) {
        text = 'วิทยากรและผู้เข้าร่วมประชุม'
        url = academyCertified
      }

      break
    case WORK_WORK_LIST[3]:
      //?    Training
      text = 'วิทยากรและผู้เข้าร่วมประชุม'
      url = monthlyPlanTraining

      break
    case WORK_WORK_LIST[4]:
      //?    Activity Support
      text = 'วิทยากรและผู้เข้าร่วมประชุม'
      url = monthlyPlanTrainingOther
      break

    default:
      break
  }

  await callAPI({
    url: url,
    method: 'PUT',
    body: body,
    onSuccess: (data) => {
      result = {
        ...result,
        text: text,
        value: data,
      }
    },
    onError: (error) => {
      result = {
        status: false,
        text: text,
        value: _.get(error, 'response.data', {}),
      }
    },
  })
  return result
}

export const fetchMonthlyPlan = async ({
  state,
  isAllPlan,
  options,
  courses,
  staffs,
  calendarType,
}) => {
  let fullCourseDiff =
    state.isFirstInitial || isAllPlan ? courses : state.filter.courses
  let fullStaffsDiff =
    state.isFirstInitial || isAllPlan ? staffs : state.filter.staffs
  const optionsWorkUuid = options.work.map((item) => item.uuid)
  let fullWorkTypes = isAllPlan ? optionsWorkUuid : state.filter.workTypes

  if (!isAllPlan && !state.isFirstInitial && state.isUpdateSuccess) {
    const courseDiff = _.difference(courses, state.filter.courses)
    const staffsDiff = _.difference(staffs, state.filter.staffs)
    fullCourseDiff = [...state.filter.courses, ...courseDiff]
    fullStaffsDiff = [...state.filter.staffs, ...staffsDiff]
  }
  let eventsList = []
  if (
    calendarType === 'PERSONAL' &&
    (state.handleType === 'actions' || state.handleType === 'filter')
  ) {
    fullCourseDiff = _.union(courses, state.filter.courses)
    fullStaffsDiff = _.union(staffs, state.filter.staffs)
  }
  if (isAllPlan) {
    fullCourseDiff = []
    fullStaffsDiff = []
  }

  const courseFilter = fullCourseDiff
  const staffFilter = fullStaffsDiff

  const body = {
    area: state.isAllStation ? '' : state.area.value,
    year: state.data.year,
    month: state.data.month + 1,
    courses: state.isAllStation ? [] : courseFilter,
    staffs: state.isAllStation ? [] : staffFilter,
    workTypes: fullWorkTypes,
  }

  try {
    eventsList = await callAPI({
      url: monthlyPlanFilter,
      method: 'POST',
      body: body,
    })
  } catch (error) {
    console.log('fetchMonthlyPlan : error => ', error.response)
  }
  return eventsList
}

export const getStaffProfile = async () => {
  const body = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    agentCode: '',
    firstNameTH: '',
    lastNameTH: '',
    jobTitle: '',
    department: '',
    station: '',
    reportTo: '',
    position: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  let resultStaffProfile = []
  try {
    const result = await callAPI({
      url: filterStaffProfile,
      method: 'POST',
      body: body,
    })
    resultStaffProfile = _.get(result, 'result', []).length
      ? setMapListOptions(_.get(result, 'result', []), 'fullnameTH')
      : []
  } catch (error) {
    console.log('getStaffProfile : error => ', error.response)
  }
  return resultStaffProfile
}

export const getStaffInArea = async (uuid) => {
  let resultStaffProfile = []
  try {
    const result = await callAPI({
      url: `${staffProfileInArea}/${uuid}`,
      method: 'get',
    })

    resultStaffProfile = _.get(result, 'result', []).length
      ? _.get(result, 'result', []).map((item) => {
          return {
            ...item,
            label: `${_.get(item, 'firstNameTH', '')} ${_.get(
              item,
              'lastNameTH',
              '',
            )}`,
            value: item.uuid,
          }
        })
      : []
  } catch (error) {
    console.log('getStaffInArea : error => ', error.response)
  }
  return resultStaffProfile
}

export const getStaffInAreaActivity = async (uuid, date) => {
  let resultStaffProfile = []
  const chars = date.split('-')
  const yaer = _.get(chars, '[0]', '')
  const month = _.get(chars, '[1]', '0')
  const monthInt = parseInt(month) - 1

  try {
    const result = await callAPI({
      url: `${staffProfileInAreaDetail}/${uuid}/${monthInt.toString()}/${yaer}`,
      method: 'get',
      onError: (er) => {
        console.log('fetch Staff : error => ', er)
      },
    })

    resultStaffProfile = _.get(result, 'result', []).length
      ? _.get(result, 'result', []).map((item) => {
          return {
            ...item,
            label: `${_.get(item, 'firstNameTH', '')} ${_.get(
              item,
              'lastNameTH',
              '',
            )}`,
            value: item.uuid,
          }
        })
      : []
  } catch (error) {
    console.log('getStaffInArea : error => ', error.response)
  }
  return resultStaffProfile
}

export const getStaffInAreaOther = async (uuid, yaer, month) => {
  let resultStaffProfile = []
  try {
    const result = await callAPI({
      url: `${staffProfileInAreaDetail}/${uuid}/${month.toString()}/${yaer}`,
      method: 'get',
      onError: (er) => {
        console.log('fetch Staff : error => ', er)
      },
    })

    resultStaffProfile = _.get(result, 'result', []).length
      ? _.get(result, 'result', []).map((item) => {
          return {
            ...item,
            label: `${_.get(item, 'firstNameTH', '')} ${_.get(
              item,
              'lastNameTH',
              '',
            )}`,
            value: item.uuid,
          }
        })
      : []
  } catch (error) {
    console.log('getStaffInArea : error => ', error.response)
  }
  return resultStaffProfile
}

export const findWorkTypeAndSubWorkType = ({
  optionWorkType,
  optionSubWorkType,
  workType,
  subWorkType,
}) => {
  const objWorkType = optionWorkType.find((item) => {
    return item.workType === workType
  })
  const objSubWorkType = optionSubWorkType.find((item) => {
    return item.uuid === subWorkType
  })

  return { objWorkType, objSubWorkType }
}

export const createLeave = async ({
  state,
  stateForm,
  optionWorkType,
  optionSubWorkType,
}) => {
  const { area } = state
  const {
    workType,
    subWorkType,
    startDate,
    finishDate,
    remarkLeave,
    remark,
    staff,
    leavePeriod,
  } = stateForm

  const listDates = _.get(leavePeriod, 'value', []).length
    ? leavePeriod?.value?.map((item) => {
        return {
          date: dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB),
          typeTime: item.type.value,
        }
      })
    : []

  const { objWorkType, objSubWorkType } = findWorkTypeAndSubWorkType({
    optionWorkType,
    optionSubWorkType,
    workType,
    subWorkType,
  })

  const body = {
    area: {
      id: area.id,
      uuid: area.value,
    },
    workType: objWorkType,
    subWorkType: objSubWorkType,
    description: remarkLeave,
    remark: remark,
    startDate: dayjs(startDate).format(window.__env__.REACT_APP_DATE_DB),
    finishDate: dayjs(finishDate).format(window.__env__.REACT_APP_DATE_DB),
    period: leavePeriod.type || 'FULLDAY',
    dates: listDates,
    staffs: staff || [],
    status: 'ACTIVE',
  }

  let result = {
    status: true,
    value: [],
    text: 'ผู้ลา',
  }

  await callAPI({
    url: monthlyPlanLeave,
    method: 'POST',
    body: body,
    onSuccess: (data) => {
      result = {
        ...result,
        value: data,
      }
    },
    onError: (error) => {
      console.log('fetchLeaveType : error => ', error.response)
      result = {
        ...result,
        status: false,
        value: _.get(error, 'response.data', {}),
      }
    },
  })

  return result
}

export const fetchEventAll = async ({ workType, subWorkType, uuid }) => {
  let areaList = {}
  try {
    let url = ''
    const nameSubWorkType = _.get(subWorkType, 'subWorkType', '')

    switch (workType) {
      case WORK_WORK_LIST[0]:
        //?    วันหยุด
        url = monthlyPlanHoliday
        break
      case WORK_WORK_LIST[1]:
        //?    วันลา
        url = monthlyPlanLeave
        break
      case WORK_WORK_LIST[2]:
        //?    Academy Activity

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
          url = academyWorkOffice
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]) {
          url = academyMeeting
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6]) {
          url = academyOneOnOne
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
          url = academyTravelling
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1]) {
          url = academyStaffTraining
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2]) {
          url = academyTrainTheTrainer
        }
        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]) {
          url = academyCertified
        }

        break
      case WORK_WORK_LIST[3]:
        //?    Training
        url = monthlyPlanTraining
        break
      case WORK_WORK_LIST[4]:
        //?    Activity Support
        url = monthlyPlanTrainingOther
        break

      default:
        break
    }

    const result = await callAPI({ url: `${url}/${uuid}`, method: 'GET' })

    areaList = result || {}
  } catch (error) {
    console.log('fetchEventAll : error => ', error.response)
  }
  return areaList
}

export const deleteEvent = async ({
  workType,
  uuid,
  subWorkType,
  remarkRequest,
}) => {
  let result = {
    status: true,
    value: [],
  }
  try {
    let url = ''
    const nameSubWorkType = _.get(subWorkType, 'subWorkType', '')

    switch (workType) {
      case WORK_WORK_LIST[0]:
        //?    วันหยุด
        url = monthlyPlanHoliday
        break
      case WORK_WORK_LIST[1]:
        //?    วันลา
        url = monthlyPlanLeave
        break
      case WORK_WORK_LIST[2]:
        //?    Academy Activity

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
          url = academyWorkOffice
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]) {
          url = academyMeeting
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6]) {
          url = academyOneOnOne
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
          url = academyTravelling
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1]) {
          url = academyStaffTraining
        }

        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2]) {
          url = academyTrainTheTrainer
        }
        if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]) {
          url = academyCertified
        }

        break
      case WORK_WORK_LIST[3]:
        //?    Training
        url = monthlyPlanTraining
        break
      case WORK_WORK_LIST[4]:
        //?    Activity Support
        url = monthlyPlanTrainingOther

        break

      default:
        break
    }
    const callBody = { method: 'DELETE' }
    if (workType !== WORK_WORK_LIST[0]) {
      callBody.url = `${url}`
      callBody.body = { uuid: uuid, remarkRequest: remarkRequest }
    } else {
      callBody.url = `${url}/${uuid}`
    }

    await callAPI(callBody)
  } catch (error) {
    result = {
      status: false,
      value: error?.response?.data,
    }
  }
  return result
}

export const getMeetingRoom = async () => {
  const body = {
    stationUuid: [],
    roomName: '',
    roomFunction: [],
    roomType: [],
    roomLayout: [],
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  let resultMeetingRoom = []
  try {
    const result = await callAPI({
      url: filterMeetingRoom,
      method: 'POST',
      body: body,
    })
    const list = _.get(result, 'result', [])
    resultMeetingRoom = list.length
      ? list.map((item) => {
          return {
            ...item,
            label: `${item.station.station} : ${item.roomName} (ชั้น ${item.floor})`,
            value: item.uuid,
          }
        })
      : []
  } catch (error) {
    console.log('getMeetingRoom  : error => ', error.response)
  }
  return resultMeetingRoom
}

export const createAcademyActivity = async ({
  state,
  stateForm,
  optionWorkType,
  optionSubWorkType,
  optionMonthlyPlanStaff,
  options,
}) => {
  const { area } = state
  const {
    description,
    startDate,
    finishDate,
    meetingType,
    isUseRoom,
    expandDate,
    title,
    toMeetingRoom,
    workType,
    subWorkType,
    remark,
    workPlace,
    travelType,
    descrattendeesiption,
    attendees,
    checkAllUser,
  } = stateForm

  const { objWorkType, objSubWorkType } = findWorkTypeAndSubWorkType({
    optionWorkType,
    optionSubWorkType,
    workType,
    subWorkType,
  })
  const staffArea = optionMonthlyPlanStaff.find((item) => {
    return item.value === toMeetingRoom
  })
  const nameSubWorkType = _.get(objSubWorkType, 'subWorkType', '')

  let url = ''
  let text = ''
  let staffs =
    _.get(stateForm, 'descrattendeesiption', []).length > 0
      ? descrattendeesiption
      : attendees

  if (_.size(staffs) > 0) {
    staffs = _.map(staffs, (item) => {
      return {
        id: _.get(item, 'id'),
        label: _.get(item, 'label'),
        uuid: _.get(item, 'uuid'),
        value: _.get(item, 'value'),
      }
    })
  }
  let body = {
    area: {
      id: area.id,
      uuid: area.value,
    },
    workType: objWorkType,
    subWorkType: objSubWorkType,
    startDate: dayjs(startDate).format(window.__env__.REACT_APP_DATE_DB),
    finishDate: dayjs(finishDate).format(window.__env__.REACT_APP_DATE_DB),
    staffs: staffs,
    checkAllUser: checkAllUser,
  }

  if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
    url = academyWorkOffice
    text = 'ผู้รับผิดชอบงาน'
    body = {
      ...body,
      dates: expandDate,
      description: remark,
      workPlace: workPlace ? workPlace.toUpperCase() : 'OFFICE',
    }
  }
  if (
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6] ||
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]
  ) {
    url = academyOneOnOne
    text = 'ผู้เข้าร่วมประชุม'
    if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]) {
      url = academyMeeting
    }
    body = {
      ...body,
      title: title,
      dates: expandDate,
      description: description,
      meetingType: meetingType ? meetingType.toUpperCase() : 'ONLINE',
      isUseRoom:
        meetingType?.toUpperCase() === 'ONLINE' || !meetingType
          ? false
          : isUseRoom,
      staffProfile: meetingType === 'onsite' ? staffArea : {},
    }
  }

  if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
    url = academyTravelling
    text = 'ผู้รับผิดชอบงาน'
    body = {
      ...body,
      dates: expandDate,
      description: description,
      travelType: travelType ? travelType.toUpperCase() : 'THISDAY',
    }
  }

  if (
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1] ||
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2] ||
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]
  ) {
    const { course, allStaffs, responsibility } = options

    url = academyStaffTraining
    text = 'วิทยากรและผู้เข้าร่วมประชุม'

    if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2])
      url = academyTrainTheTrainer
    if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]) url = academyCertified

    const objCourse = course?.find((itemCourse) => {
      return itemCourse.value === stateForm.course
    })

    body = {
      ...body,
      title: title,
      description: description,
      dates: expandDate.map((item) => {
        return {
          ...item,
          speakers: item?.speakers?.length
            ? item.speakers.map((itemSK) => {
                const objSpeaker = allStaffs?.find((itemStaff) => {
                  return itemStaff.uuid === itemSK.staff
                })

                const objResponsibility = responsibility?.find((itemRB) => {
                  return itemRB.value === itemSK.speakerRole
                })

                return {
                  startTime: itemSK.startTime,
                  endTime: itemSK.endTime,
                  staff: !_.isEmpty(objSpeaker)
                    ? {
                        id: objSpeaker?.id || '',
                        uuid: objSpeaker?.uuid || '',
                      }
                    : {},
                  responsibility: !_.isEmpty(objResponsibility)
                    ? {
                        id: objResponsibility?.id || '',
                        uuid: objResponsibility?.value || '',
                      }
                    : {},
                }
              })
            : [],
        }
      }),
      meetingType: meetingType ? meetingType.toUpperCase() : 'ONLINE',
      isUseRoom:
        meetingType?.toUpperCase() === 'ONLINE' || !meetingType
          ? false
          : isUseRoom,
      staffProfile: meetingType === 'onsite' ? staffArea : {},
      staffs,
      course: !_.isEmpty(objCourse)
        ? {
            id: _.get(objCourse, 'id', ''),
            uuid: _.get(objCourse, 'value', ''),
          }
        : {},
    }
  }

  let result = {
    status: true,
    value: [],
    text: text,
  }
  await callAPI({
    url: url,
    method: 'POST',
    body: body,
    onSuccess: (data) => {
      result = {
        ...result,
        value: data,
      }
    },
    onError: (error) => {
      result = {
        ...result,
        status: false,
        value: _.get(error, 'response.data', {}),
      }
    },
  })

  return result
}

export const getSubWorkType = async (uuid) => {
  let resultSubWorkType = []
  if (_.isEmpty(uuid)) {
    return resultSubWorkType
  }
  try {
    const url = `${subWorkType}/${uuid}`
    const result = await callAPI({ url: url, method: 'GET' })
    resultSubWorkType =
      result.length > 0
        ? result.map((d) => ({ ...d, label: d.subWorkType, value: d.uuid }))
        : []
  } catch (error) {
    console.log('getSubWorkType : error => ', error.response)
  }
  return resultSubWorkType
}

export const getMonthlyPlanFilterStaff = async ({ area }) => {
  const body = {
    area,
    position: 'AXALFTH - Training Operations',
    trainerType: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
  }
  let resultMonthlyPlanFilterStaff = []
  try {
    const result = await callAPI({
      url: monthlyPlanFilterStaff,
      method: 'POST',
      body: body,
    })

    resultMonthlyPlanFilterStaff = _.get(result, `user`, []).map((d) => {
      const name = `${_.get(d, 'firstNameTH', '')} ${_.get(
        d,
        'lastNameTH',
        '',
      )}`

      return {
        id: d.id,
        label: `${_.get(result, `department.initial`, '')} - ${name}`,
        value: _.get(d, 'uuid', ''),
        uuid: _.get(d, 'uuid', ''),
        statusTO: _.get(d, 'statusTO', 'INACTIVE'),
      }
    })
  } catch (error) {
    console.log('getMonthlyPlanFilterStaff  : error => ', error.response)
  }
  return resultMonthlyPlanFilterStaff
}

export const fetchData = async (
  body,
  type,
  method,
  isFirstInitial,
  filterWorkload,
  isAllStation,
  area,
) => {
  const apiPath = {
    personal: monthlyPlanPersonal,
    workload: monthlyPlanWorkload,
    masterPlanStatus: monthlyPlanMasterPlanStatus,
  }
  body.staffs = isAllStation ? [] : filterWorkload.staffs
  body.workTypes = filterWorkload.workTypes
  if (type === 'personal') {
    body.isAll = isAllStation
    body.area = area
  }
  const objAPI = { url: apiPath[type], method: method, body: body }
  const fetch = await callAPI(objAPI)
  //this filter because API cant filter course (API to slow) now filter by front
  const newFilter =
    filterWorkload.courses.length > 0
      ? fetch.personalPlan.map((item) => {
          const mapStaff = item.staffs.map((staff) => {
            const newMapCourses = filterWorkload.courses.map((course) => {
              return { course: { courseCode: course } }
            })
            const newMapWorkType = filterWorkload.workTypes.map((uuid) => {
              return { workType: { uuid: uuid } }
            })
            //intersec for filter courseCode with filter drawer
            const intersec = _.intersectionBy(
              staff.workTypes,
              newMapCourses,
              'course.courseCode',
            )
            const intersecWorkType = _.intersectionBy(
              staff.workTypes,
              newMapWorkType,
              'workType.uuid',
            )
            //filter subworktype !== training will merge with intersec
            const filterNotTraining = staff.workTypes.filter(
              (workType) => workType.workType.workType !== 'Training',
            )
            const mergeWorktype = [...filterNotTraining, ...intersec]
            const conditionNotIntersecCourse =
              intersec.length === 0 && intersecWorkType.length > 0

            return {
              ...staff,
              workTypes: conditionNotIntersecCourse
                ? mergeWorktype
                : staff.workTypes,
            }
          })
          return { ...item, staffs: mapStaff }
        })
      : fetch.personalPlan
  const newFetch = isFirstInitial
    ? fetch
    : { ...fetch, personalPlan: newFilter }
  return newFetch
}

export const fetchMasterPlan = async (body) => {
  const { area, month, year, staffId } = body.params
  const objAPI = {
    url: `${monthlyPlanMasterPlanStatus}?area=${area}&month=${month}&year=${year}&staffId=${staffId}`,
    method: 'get',
    body: body,
  }
  const fetch = await callAPI(objAPI)
  return fetch
}

export const fetchStaffList = async ({ isYear, isMonth, area, type }) => {
  if (isMonth === '') return []
  let result = []
  const calendarType =
    type === undefined || (type && type === 'ALL') ? `calendar` : `workload`
  const url = `${monthlyPlanFilterStaff}?area=${area}&month=${isMonth}&year=${isYear}&type=${calendarType}`
  const objAPI = {
    url: url,
    method: 'get',
  }
  const fetch = await callAPI(objAPI)
  if (fetch.length) {
    result = fetch.map((item) => {
      return {
        label: `${item.title} ( ${_.get(item, 'department', '')} )`,
        nameCheckBox: item.title,
        value: item.uuid,
      }
    })
  }
  return result
}

export const fetchCourseSettingList = async ({ isYear, isMonth, area }) => {
  const startDate = dayjs(`${isYear}-${isMonth}-01`)
    .startOf('month')
    .format(window.__env__.REACT_APP_DATE_SHOW)
  const toDate = dayjs(`${isYear}-${isMonth}-01`)
    .endOf('month')
    .format(window.__env__.REACT_APP_DATE_SHOW)

  let newValueArea = area

  if (_.isArray(area)) newValueArea = _.get(area, '[0]uuid', '')

  const body = {
    calendar: true,
    area: newValueArea,
    version: 1,
    englishName: '',
    nameForLearner: '',
    courseCode: '',
    startDate,
    toDate,
    updatedBy: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE', 'WAITING_FOR_ACTIVE'],
    createdBy: '',
    assignee: '',
  }

  let resultCourse = []
  try {
    const objAPI = { url: filterVersion, method: 'post', body: body }
    const fetch = await callAPI(objAPI)

    _.get(fetch, `result`, []).forEach((d) => {
      resultCourse = [
        ...resultCourse,
        {
          ...d,
          label: d.englishName.substring(0, 50),
          nameCheckBox: d.englishName,
          code: d.courseCode,
          trainingPlatform: d.trainingPlatform,
          value: d.uuid,
        },
      ]
    })
  } catch (error) {
    console.log('getSubWorkType : error => ', error.response)
  }
  return resultCourse
}

export const fetchHeadZone = async (uuid) => {
  let result = []

  try {
    result = await callAPI({
      url: `${monthlyPlanHeadZone}/${uuid}`,
      method: 'get',
    })
  } catch (error) {
    console.log('fetchHeadZone : error => ', error.response)
  }

  return result
}

export const cancelSpeakerBorrow = async (uuid) => {
  let result = []

  try {
    result = await callAPI({
      url: `${monthlyPlanSpeakerBorrowCancel}/${uuid}`,
      method: 'get',
    })
  } catch (error) {
    console.log('fetchHeadZone : error => ', error.response)
  }

  return result
}

export const getDepartment = async () => {
  let result = []

  try {
    const objAPI = {
      url: department,
      method: 'post',
      body: {
        department: '',
        limit: 100,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      },
    }
    const fetch = await callAPI(objAPI)
    result = fetch.map((item) => {
      return {
        ...item,
        label: `(${_.get(item, 'initial')}) ${_.get(item, 'department')}`,
        value: _.get(item, 'uuid', ''),
        valueStation: '',
        station: _.get(item, 'station', []).length
          ? _.get(item, 'station', []).map((itemStation) => {
              return {
                ...itemStation,
                label: _.get(itemStation, 'station'),
                value: _.get(itemStation, 'uuid', ''),
              }
            })
          : [],
      }
    })
  } catch (error) {
    console.log('fetchHeadZone : error => ', error.response)
  }

  return result
}

export const fetchApproveList = async ({ state, approveDrawer }) => {
  if (_.get(state, 'area.value', '') === '') {
    return
  }
  const realPage = approveDrawer.page + 1
  const body = {
    area: state.area.value,
    month: state.data.month + 1,
    year: state.data.year,
    limit: approveDrawer.limit,
    page: realPage,
  }
  const result = callAPI({
    method: 'post',
    body: body,
    url: monthlyPlanCostApprove,
  })
  return result
}

export const fetchTrainingPlan = async ({ state }) => {
  let eventsList = []

  const body = {
    area: state.area.value,
    year: state.data.year,
    month: state.data.month + 1,
    courses: state.filter.courses,
    staffs: state.filter.staffs,
    workTypes: state.filter.workTypes,
  }

  try {
    eventsList = await callAPI({
      url: trainingPlanCalendar,
      method: 'POST',
      body: body,
    })
  } catch (error) {
    console.log('fetchTrainingPlan : error => ', error.response)
  }
  return eventsList
}

export const fetchHistory = async ({
  state,
  historyDrawer,
  setHistoryList,
  setHistoryDrawer,
}) => {
  if (_.isEmpty(_.get(state, 'area.value', ''))) {
    return false
  }
  callAPI({
    method: 'post',
    url: monthlyPlanCostApproveHistory,
    body: {
      area: state.area.value,
      startDate: historyDrawer.startDate,
      finishDate: historyDrawer.finishDate,
      limit: historyDrawer.limit,
      order: 'ASC',
      page: historyDrawer.page + 1,
      sort: 'updatedAt',
    },
    onSuccess: (data) => {
      setHistoryList(data?.result || [])
      setHistoryDrawer((prev) => ({
        ...prev,
        count: data?.totalCount || 0,
      }))
    },
  })
}

export const fetchDownload = async ({ state, historyDrawer }) => {
  if (_.isEmpty(_.get(state, 'area.value', ''))) {
    return false
  }

  callAPI({
    method: 'post',
    url: monthlyPlanCostApproveDownload,
    responseType: 'blob',
    body: {
      area: state.area.value,
      startDate: historyDrawer.startDate,
      finishDate: historyDrawer.finishDate,
      limit: historyDrawer.limit || -1,
      order: 'ASC',
      page: historyDrawer.page || 1,
      sort: 'updatedAt',
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `ประวัติการจัดทำแผน.xlsx`)
      document.body.appendChild(link)
      link.click()
    },
  })
}

export const fetchStaffProfile = async () => {
  const bodyStaff = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    agentCode: '',
    firstNameTH: '',
    lastNameTH: '',
    jobTitle: '',
    department: '',
    station: '',
    reportTo: '',
    position: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  let result = []
  result = await callAPI({
    method: 'post',
    url: filterStaffProfile,
    body: bodyStaff,
  })
  return _.get(result, 'result', [])
}
