import {
  removeValidateError,
  setBookingInformation,
  setBookingUuid,
  setChangeBookingInformation,
  setOverTimeOption,
  setRoomBookingListByDate,
  setRoomSelected,
  setValidateError,
  startLoadingDrawer,
  stopLoadingDrawer,
} from '../../../redux/slices/roomManagement/Drawer'
import _ from 'lodash'
import { isValidateDate } from '../../../utils/lib'
import {
  generateRooms,
  handleEndTimeRoom,
  handleOverTimeOptions,
  handleStartTimeRoom,
} from './FormBooking/DetailsBookingCard/event'
import { setIsShowPreview } from '../../../redux/slices/roomManagement/Dashboard'
import {
  BOOKING_PERIOD_TYPE,
  OVERTIME,
  overTimeOptions,
  PERIOD_TYPE,
  STATUS_TYPE,
} from '../../../constants/roomManagement'
import { getBookingRoomById } from '../../../services/roomManagement'
import {
  fetchCalendar,
  fetchMeetingRoomFilter,
  fetchRoomRequestFilter,
} from '../BookingRoomSection/event'
import { store } from '../../../App'
import { fetchDataRoomManagement } from '../DashboardSection/event'
import { fetchBookingRoomWeekly } from '../DashboardSection/WeeklyBookingsCard/event'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'
import { FetchMasterRoomsByDay } from '../../../redux/slices/roomManagement/Drawer/event'
import dayjs from 'dayjs'
import { fetchStaffList } from './FormBooking/event'
import { fetchRoomDetailCalendar, fetchRoomDetails } from '../View/event'

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeBookingInformation(props))
}

export const handleOverTime = (time) => {
  switch (time) {
    case 0:
      return OVERTIME.AT_TRAINING_TIME
    case 30:
      return OVERTIME.HALF_HOUR
    case 60:
      return OVERTIME.ONE_HOUR
    case 90:
      return OVERTIME.ONE_HALF_HOURS
    case 120:
      return OVERTIME.TWO_HOURS
    default:
      return OVERTIME.AT_TRAINING_TIME
  }
}

export const handleResetValue = () => (dispatch) => {
  const bookingInformation = {
    roomManagementMeetingRoom: [],
    academy: 'ACADEMY',
    bookingTopic: null,
    bookForUuid: null,
    mobileNoOther: null,
    emailOther: null,
    startDate: null,
    endDate: null,
    step: 0,
    remark: null,
    reason: null,
    uuid: null,
    bookingPeriod: BOOKING_PERIOD_TYPE.SAME_TIME,
  }
  dispatch(setBookingUuid(null))
  dispatch(setBookingInformation(bookingInformation))
  dispatch(setRoomSelected([]))
  dispatch(setIsShowPreview(false))
}

export function validatePeriodDate(state, listenerApi) {
  if (
    isValidateDate(state.bookingInformation.startDate) &&
    isValidateDate(state.bookingInformation.endDate)
  ) {
    if (
      dayjs(state.bookingInformation.startDate).isAfter(
        dayjs(state.bookingInformation.endDate),
      )
    ) {
      listenerApi.dispatch(
        setValidateError({
          key: 'startDate',
          message: 'ช่วงวันที่ไม่ถูกต้อง',
        }),
      )
      listenerApi.dispatch(
        setValidateError({
          key: 'endDate',
          message: 'ช่วงวันที่ไม่ถูกต้อง',
        }),
      )
    } else {
      listenerApi.dispatch(
        removeValidateError({
          key: 'startDate',
          message: 'ช่วงวันที่ไม่ถูกต้อง',
        }),
      )
      listenerApi.dispatch(
        removeValidateError({
          key: 'endDate',
          message: 'ช่วงวันที่ไม่ถูกต้อง',
        }),
      )
      setTimeout(
        () =>
          document
            .getElementById('BookingDrawer')
            .scroll({ top: 380, left: 0, behavior: 'smooth' }),
        100,
      )
    }
  }
}

export function validateTimeTraining(state, listenerApi, idx) {
  const { roomBookingList } = state.roomManagementDrawer.bookingInformation
  const startTimeTraining =
    _.get(roomBookingList[idx], 'startTimeTraining', '') || '__:__'
  const endTimeTraining =
    _.get(roomBookingList[idx], 'endTimeTraining', '') || '__:__'
  const bookingDate = _.get(roomBookingList[idx], 'bookingDate', '__:__')

  if (!startTimeTraining.includes('_') && !endTimeTraining.includes('_')) {
    if (startTimeTraining > endTimeTraining) {
      listenerApi.dispatch(
        setValidateError({
          key: `startTimeTraining${bookingDate}`,
          message: 'ช่วงเวลาไม่ถูกต้อง',
        }),
      )
      listenerApi.dispatch(
        setValidateError({
          key: `endTimeTraining${bookingDate}`,
          message: 'ช่วงเวลาไม่ถูกต้อง',
        }),
      )
    } else {
      listenerApi.dispatch(
        removeValidateError({
          key: `startTimeTraining${bookingDate}`,
          message: 'ช่วงเวลาไม่ถูกต้อง',
        }),
      )
      listenerApi.dispatch(
        removeValidateError({
          key: `endTimeTraining${bookingDate}`,
          message: 'ช่วงเวลาไม่ถูกต้อง',
        }),
      )
    }
  }
}

async function handleFetchMasterRooms(
  bookingPeriod,
  roomBookingList,
  bookingInformation,
) {
  let resultMasterRooms = []

  if (bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM) {
    resultMasterRooms = await Promise.all(
      roomBookingList.map((item) =>
        FetchMasterRoomsByDay({
          bookingInformation: bookingInformation,
          stationUuid: bookingInformation.stationUuid,
          roomBookingItem: item,
          startTimeRoom: item.startTimeRoom,
          endTimeRoom: item.endTimeRoom,
        }),
      ),
    )
  }
  if (bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
    resultMasterRooms = await FetchMasterRoomsByDay({
      bookingInformation: bookingInformation,
      stationUuid: bookingInformation.stationUuid,
      roomBookingItem: roomBookingList[0],
      startTimeRoom: roomBookingList[0].startTimeRoom,
      endTimeRoom: roomBookingList[0].endTimeRoom,
    })
  }
  return resultMasterRooms
}

export async function reBuildDefaultBookingItem(state, bookingPeriod) {
  const { roomBookingList } = state.bookingInformation
  const resultMasterRooms = await handleFetchMasterRooms(
    bookingPeriod,
    roomBookingList,
    state.bookingInformation,
  )

  return roomBookingList.map((item, index) => {
    const startDate = new Date(`${item.bookingDate} 00:00`)
    const startTraining = new Date(
      `${item.bookingDate} ${item.startTimeTraining}`,
    )

    const endDate = new Date(`${item.bookingDate} 23:59`)
    const endTraining = new Date(`${item.bookingDate} ${item.endTimeTraining}`)

    return {
      ...item,
      overTimeBeforeOptions: handleOverTimeOptions(startDate, startTraining),
      overTimeAfterOptions: handleOverTimeOptions(endDate, endTraining),
      masterRooms:
        bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM
          ? resultMasterRooms[index]
          : resultMasterRooms,
      rooms: (bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM
        ? item
        : roomBookingList[0]
      ).rooms.map((d) => ({
        ...d,
        meetingRoomUuid: '',
        meetingRoom: null,
      })),
    }
  })
}

export const buildDefaultBookingItem = async ({ bookingInformation }) => {
  const { startDate, endDate, bookingPeriod, roomBookingList } =
    bookingInformation

  if (isValidateDate(startDate) && isValidateDate(endDate)) {
    return generateRooms({
      bookingPeriod,
      startDate,
      endDate,
      roomBookingList,
    })
  }

  return []
}

export const fetchBookingRoomById = (uuid) => async (dispatch) => {
  const { payload: response } = await dispatch(getBookingRoomById({ uuid }))

  const userData = localStorage.getItem('user')
  const userObj = JSON.parse(userData)
  const bookingListGroup = _.groupBy(
    response.roomManagementMeetingRoom,
    'bookingDate',
  )
  const roomBookingList = Object.keys(bookingListGroup).map((key) => {
    const booking = bookingListGroup[key][0]

    let startDateTraining
    let endDateTraining
    let startTime
    let endTime
    if (response.bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
      startDateTraining = new Date(`${response.startDate} 00:00`)
      endDateTraining = new Date(`${response.endDate} 23:59`)
      startTime = new Date(`${response.startDate} ${booking.startTimeRoom}`)
      endTime = new Date(`${response.endDate} ${booking.endTimeRoom}`)
    } else {
      startDateTraining = new Date(`${booking.bookingDate} 00:00`)
      endDateTraining = new Date(`${booking.bookingDate} 23:59`)
      startTime = new Date(`${booking.bookingDate} ${booking.startTimeRoom}`)
      endTime = new Date(`${booking.bookingDate} ${booking.endTimeRoom}`)
    }

    const startTraining = new Date(
      `${response.startDate} ${booking.startTimeTraining}`,
    )

    const endTraining = new Date(
      `${response.endDate} ${booking.endTimeTraining}`,
    )
    const startTimeReal = startTime.getTime()
    const startTimeTrain = startTraining.getTime()
    const endTimeReal = endTime.getTime()
    const endTimeTrain = endTraining.getTime()

    const diffStartTime = Math.abs(startTimeReal - startTimeTrain) / (1000 * 60)
    const diffEndTime = Math.abs(endTimeReal - endTimeTrain) / (1000 * 60)

    return {
      bookingDate: key,
      period: booking.period,
      startTimeTraining: booking.startTimeTraining,
      endTimeTraining: booking.endTimeTraining,
      startTimeRoom: booking.startTimeRoom,
      endTimeRoom: booking.endTimeRoom,
      beforeTraining: handleOverTime(diffStartTime),
      afterTraining: handleOverTime(diffEndTime),
      overTimeBeforeOptions: handleOverTimeOptions(
        startDateTraining,
        startTime,
      ),
      overTimeAfterOptions: handleOverTimeOptions(endDateTraining, endTime),

      rooms: bookingListGroup[key].map((item) => {
        const isOnline = _.get(item, 'isOnline', false)
        return {
          uuid: item.uuid,
          isOnline: isOnline,
          meetingRoomUuid: isOnline
            ? 'ONLINE'
            : _.isEmpty(item.meetingRoomUuid) &&
              !_.isNull(item.meetingRoomOther)
            ? 'OTHER'
            : item.meetingRoomUuid,
          meetingRoomOther: item.meetingRoomOther,
          numberOfAttendees: item.numberOfAttendees || null,
          roomLayout: item.roomLayout,
          additionalEquipment: item.additionalEquipment,
          numberOfGroup: item.numberOfGroup || null,
          attendeeInGroup: item.attendeeInGroup || null,
          isLoading: false,
          meetingRoom: item.meetingRoom,
        }
      }),
    }
  })
  const defaultAreaUuid = _.get(userObj, 'defaultArea', '')
  const defaultArea = _.find(
    _.get(userObj, 'area', []),
    (ele) => ele?.uuid === defaultAreaUuid,
  )
  const defaultStation = _.get(defaultArea, 'station.station', '')
  const bookByName =
    _.get(response, 'requestType', '') === 'MONTHLY_PLAN' &&
    _.get(response, 'status', STATUS_TYPE.COMPLETED) === STATUS_TYPE.IN_PROGRESS
      ? `${defaultStation} - ${_.get(userObj, 'firstNameTH', '')} ${_.get(
          userObj,
          'lastNameTH',
          '',
        )}`
      : _.get(response, 'bookByName', '')

  const bookingInformation = {
    startDate: response.startDate,
    endDate: response.endDate,
    bookingPeriod: response.bookingPeriod,
    academy: response.academy,
    bookingUuid: response.uuid,
    isDeleted: false,
    reason: '',
    bookFor: null,
    bookingTopic: response.bookingTopic,
    status: response.status,
    canceledReason: response.canceledReason,
    requestType: response.requestType,
    courseName: response.courseName,
    stationName: _.get(
      response,
      'roomManagementMeetingRoom[0].meetingRoom.station.station',
      _.get(response, 'roomManagementMeetingRoom[0].station.station', ''),
    ),
    stationUuid: _.get(
      response,
      'roomManagementMeetingRoom[0].meetingRoom.station.uuid',
      _.get(response, 'roomManagementMeetingRoom[0].station.uuid', ''),
    ),
    createdAt: response.createdAt,
    createdBy: response.createdBy,
    updatedAt: _.get(response, 'updatedAt'),
    updatedBy: response.updatedBy,
    bookForName: response.bookForName,
    bookForUuid: response.bookForUuid,
    bookForOther: response.academy === 'ACADEMY' ? '' : response.bookForName,
    mobileNoOther: response.mobileNo,
    emailOther: response.email,
    bookByName: bookByName,
    bookByUuid: response.bookForUuid,
    remark: response.remark,
    isUserStation: _.get(response, 'isUserStation', false),
    roomBookingList,
  }

  const resultMasterRooms = await handleFetchMasterRooms(
    response.bookingPeriod,
    roomBookingList,
    bookingInformation,
  )

  bookingInformation.roomBookingList = bookingInformation.roomBookingList.map(
    (item, index) => {
      const rooms = _.get(item, 'rooms', [])
      const isOnline = chkRoomOnline(rooms)
      const masterRooms =
        response.bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM
          ? resultMasterRooms[index]
          : resultMasterRooms

      if (isOnline) {
        masterRooms.push({
          value: 'ONLINE',
          label: 'Online',
          uuid: 'online',
        })
      }

      return {
        ...item,
        masterRooms: masterRooms,
      }
    },
  )

  dispatch(setBookingInformation(bookingInformation))
}

export const handleSuccess =
  (msg = '') =>
  (dispatch) => {
    const { uuid } = store.getState().roomManagement.roomDetails

    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: msg,
        handleConfirm: () => {
          dispatch(closeDialog())
          dispatch(fetchMeetingRoomFilter())
          dispatch(fetchRoomRequestFilter())
          dispatch(fetchDataRoomManagement())
          dispatch(fetchBookingRoomWeekly())
          dispatch(handleResetValue())
          if (!_.isUndefined(uuid)) {
            dispatch(fetchRoomDetails(uuid))
          }
          if (window.location.pathname.includes('room-management/view')) {
            dispatch(fetchRoomDetailCalendar())
          } else {
            dispatch(fetchCalendar())
          }
        },
      }),
    )
  }

export const handleFail =
  ({ title = 'ผิดพลาด', msg }) =>
  (dispatch) => {
    dispatch(
      openDialog({
        type: 'fail',
        title: title,
        message: msg,
        handleConfirm: () => {
          dispatch(closeDialog())
        },
      }),
    )
  }

export const getBookingListByPeriod = ({
  bookingPeriod,
  roomBookingList,
  idxChange,
}) => {
  if (bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
    return roomBookingList.map((_, idx) => idx)
  }
  return [idxChange]
}

export const validateHhMm = (value) => {
  return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
}

export function handleEditTrainingTime(bookingItem, key, listenerApi) {
  if (
    [PERIOD_TYPE.OTHER].includes(bookingItem.period) &&
    key !== 'beforeTraining' &&
    key !== 'afterTraining'
  ) {
    let overTimeBeforeOptions = overTimeOptions
    let overTimeAfterOptions = overTimeOptions

    const { startTimeTraining, endTimeTraining, bookingDate } = bookingItem

    if (validateHhMm(startTimeTraining)) {
      const startDate = new Date(`${bookingDate} 00:00`)
      const startTraining = new Date(`${bookingDate} ${startTimeTraining}`)

      overTimeBeforeOptions = handleOverTimeOptions(startDate, startTraining)
    }
    if (validateHhMm(endTimeTraining)) {
      const endDate = new Date(`${bookingDate} 23:59`)
      const endTraining = new Date(`${bookingDate} ${endTimeTraining}`)

      overTimeAfterOptions = handleOverTimeOptions(endDate, endTraining)
    }

    listenerApi.dispatch(
      setOverTimeOption({
        bookingDate,
        overTimeAfterOptions,
        overTimeBeforeOptions,
      }),
    )
  }
}

export async function shouldSetRoomTime(
  state,
  startTimeTraining,
  endTimeTraining,
  roomBookingItem,
  listenerApi,
) {
  if (validateHhMm(startTimeTraining) && validateHhMm(endTimeTraining)) {
    const startTimeRoom = handleStartTimeRoom(
      roomBookingItem.beforeTraining,
      roomBookingItem.startTimeTraining,
      roomBookingItem.bookingDate,
    )

    listenerApi.dispatch(
      setRoomBookingListByDate({
        key: 'startTimeRoom',
        value: startTimeRoom,
        bookingDate: roomBookingItem.bookingDate,
      }),
    )

    const endTimeRoom = handleEndTimeRoom(
      roomBookingItem.afterTraining,
      roomBookingItem.endTimeTraining,
      roomBookingItem.bookingDate,
    )

    listenerApi.dispatch(
      setRoomBookingListByDate({
        key: 'endTimeRoom',
        value: endTimeRoom,
        bookingDate: roomBookingItem.bookingDate,
      }),
    )

    const resultMasterRoom = await FetchMasterRoomsByDay({
      bookingInformation: state.bookingInformation,
      stationUuid: state.bookingInformation.stationUuid,
      roomBookingItem: roomBookingItem,
      startTimeRoom,
      endTimeRoom,
    })

    listenerApi.dispatch(
      setRoomBookingListByDate({
        bookingDate: roomBookingItem.bookingDate,
        key: 'masterRooms',
        value: resultMasterRoom,
      }),
    )
  }
}

export const initialDrawerForm = async (bookingUuid) => {
  store.dispatch(startLoadingDrawer())

  const { bookingInformation } = store.getState().roomManagementDrawer
  const step = _.get(bookingInformation, 'step', 0)
  let newBookingInformation = { ...bookingInformation }
  const { bookingPeriod, roomBookingList } = newBookingInformation
  if (bookingPeriod && roomBookingList.length > 0) {
    const resultMasterRooms = await handleFetchMasterRooms(
      bookingPeriod,
      roomBookingList,
      newBookingInformation,
    )

    newBookingInformation.roomBookingList =
      newBookingInformation.roomBookingList.map((item, index) => {
        const rooms = _.get(item, 'rooms', [])
        const isOnline = chkRoomOnline(rooms)
        const masterRooms =
          bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM
            ? resultMasterRooms[index]
            : resultMasterRooms

        if (isOnline) {
          masterRooms.push({
            value: 'ONLINE',
            label: 'Online',
            uuid: 'online',
          })
        }

        return {
          ...item,
          isLoadingMasterRooms: false,
          masterRooms: masterRooms,
        }
      })

    store.dispatch(setBookingInformation(newBookingInformation))
  }

  const apiList = []

  apiList.push(store.dispatch(fetchStaffList()))

  if (!_.isEmpty(bookingUuid) && step === 0) {
    apiList.push(store.dispatch(fetchBookingRoomById(bookingUuid)))
  }
  await Promise.all(apiList)

  store.dispatch(stopLoadingDrawer())
}

export const initialPreviewForm = ({ bookingUuid }) => {
  store.dispatch(startLoadingDrawer())
  store
    .dispatch(fetchBookingRoomById(bookingUuid))
    .finally(() => store.dispatch(stopLoadingDrawer()))
}

export const getErrorMessage = (formError, path) => {
  const errorItem = formError.find((item) => item.path === path)
  return _.isEmpty(errorItem) ? '' : errorItem.errors[0]
}

export const getIndexByBookingDate = (bookingDate) => {
  const { roomBookingList } =
    store.getState().roomManagementDrawer.bookingInformation

  return roomBookingList.findIndex((item) => item.bookingDate === bookingDate)
}

export const chkRoomOnline = (rooms) => {
  return _.some(rooms, (room) => room.isOnline)
}
