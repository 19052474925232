export const filterItems = [
  {
    label: 'วันที่',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: 'เลือกช่วงเวลา',
    type: 'daterange',
  },
  {
    label: 'คลังทรัพย์สิน',
    nameCheckBox: 'inventoryChecked',
    nameInput: 'inventory',
    placeholder: 'เลือกคลังทรัพย์สิน',
    type: 'select_dropdown',
  },

  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: 'เลือก Station',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'หลักสูตร',
    nameCheckBox: 'courseChecked',
    nameInput: 'course',
    placeholder: 'เลือกหลักสูตร',
    type: 'select_dropdown',
  },
]

export const filterReqItems = [
  {
    label: 'ชื่อหลักสูตร',
    nameCheckBox: 'courseChecked',
    nameInput: 'course',
    placeholder: 'เลือกชื่อหลักสูตร',
    type: 'select_dropdown',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: 'เลือก Station',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'วันที่สร้าง',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: 'เลือกช่วงเวลา',
    type: 'daterange',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: [
      { label: 'รอคืน', name: 'waitingReturn' },
      { label: 'อนุมัติรอคืน', name: 'approve' },
    ],
  },
  {
    label: 'ประเภท Ticket',
    nameCheckBox: 'ticketTypeChecked',
    nameInput: 'ticketType',
    type: 'checkbox',
    list: [
      { label: 'Monthly Plan', name: 'monthlyPlan' },
      { label: 'Other', name: 'other' },
    ],
  },
  {
    label: 'จำนวนวันที่กำหนดคืน',
    nameCheckBox: 'returnDateChecked',
    nameInput: 'returnDate',
    inputFrom: 'returnDateFrom',
    inputTo: 'returnDateTo',
    placeholder: 'ระบุจำนวน',
    type: 'number_between',
  },
]
