import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../crudConfig'
import { store } from '../../../../../App'
import {
  INVENTORY_SETTING,
  PRODUCT_TYPE_TEXT_HERF,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

export const headCellsStock = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
    disableTruncate: false,
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
    minWidth: '80px',
    width: '80px',
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
    minWidth: '80px',
    width: '80px',
  },
  {
    id: 'category.name',
    label: 'หมวดหมู่',
    disablePadding: false,
  },
  {
    id: 'subCategory.name',
    label: 'หมวดหมู่ย่อย',
    disablePadding: false,
  },
]

export const headCellsAsset = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
    disableTruncate: false,
  },
  {
    id: 'category.name',
    label: 'หมวดหมู่',
    disablePadding: false,
  },
  {
    id: 'subCategory.name',
    label: 'หมวดหมู่ย่อย',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
    minWidth: '150px',
    width: '150px',
  },
]

export const headCellsLicense = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อซอฟต์แวร์',
    disablePadding: false,
    disableTruncate: false,
  },
  {
    id: 'category.name',
    label: 'หมวดหมู่',
    disablePadding: false,
  },
  {
    id: 'subCategory.name',
    label: 'หมวดหมู่ย่อย',
    disablePadding: false,
  },
  {
    id: 'vendor',
    label: 'Vendor',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
    minWidth: '150px',
    width: '150px',
  },
]

export const headerCells = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const { tabActive } = store.getState().crud

  let headCells = []
  let hrefType = PRODUCT_TYPE_TEXT_HERF.STOCK
  switch (tabActive) {
    case 0:
      headCells = headCellsStock
      break
    case 1:
      headCells = headCellsAsset
      hrefType = PRODUCT_TYPE_TEXT_HERF.ASSET
      break
    case 2:
      headCells = headCellsLicense
      hrefType = PRODUCT_TYPE_TEXT_HERF.LICENSE
      break
  }
  return [
    ...headCells,
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/${hrefType}/view`,
      editPath: `${crudConfig.modulePath}/${hrefType}/form`,
      hideDelete: hasPermissionCreate ? false : true,
      hideEdit: hasPermissionCreate ? false : true,
    },
  ]
}
