export const headCells = [
  {
    id: 'product.status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
    isStatus: true,
  },
  {
    id: 'product.productType',
    label: 'ประเภททรัพย์สิน',
    disablePadding: false,
    minWidth: '120px',
    width: '120px',
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'quantityAlert',
    label: 'แจ้งเตือนทรัพย์สินเหลือน้อย',
    disablePadding: false,
    minWidth: '60px',
    width: '60px',
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
    minWidth: '60px',
    width: '60px',
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
    minWidth: '60px',
    width: '60px',
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
    minWidth: '60px',
    width: '60px',
  },
  {
    id: 'totalStatus',
    label: 'สถานะคงเหลือ',
    disablePadding: false,
    minWidth: '60px',
    width: '60px',
  },
]

export const headerCells = () => {
  return [
    ...headCells,
    {
      id: 'ActionStockDropown',
      label: 'คำสั่ง',
      disablePadding: false,
      hideSortIcon: true,
      isInventoryStock: true,
      minWidth: '80px',
      width: '80px',
    },
  ]
}
