/* eslint-disable react/jsx-key */
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import Select from '../../../../components/Input/Select'
import TextInput from '../../../../components/Input/TextInput'
import { LIST_TYPE_TRAINING } from '../../Text'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import { convertFormatDateTime } from '../../../../utils/lib'
import Autocomplete from '../../../../components/Input/Autocomplete'
import {
  handleChangeFormDates,
  handleChangeFormSpeakers,
  handleChangeStaffList,
  handleSubmitDialog,
  checkTrainingPlatform,
} from './Handle'
import { convertTime, convertTimeToApi } from '../View/Index'

import { cancelSpeakerBorrow } from '../../api/Index'

import DialogForm from '../../Dialog/DialogForm'
import FormListBorrow from '../../Dialog/FormListBorrow'
import FormLocation from '../../Dialog/FormLocation'
import AlertDialog from '../../../../components/AlertDialog'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import BorrowDrawer from '../BorrowDrawer'
import { approveStatus } from '../../../../constants/monthlyPlan/speaker'

export default function TrainingOther({
  validateForm,
  stateForm,
  options,
  setLoading,
  handleChangeForm,
  course,
  setStateForm,
  type,
  setValidateForm,
  setOptionClassroom,
}) {
  setLoading(false)
  return (
    <Box>
      <Select
        dataTestid="select-distributionChannel"
        id="distributionChannel"
        name="distributionChannel"
        type="basic"
        required
        options={_.get(options, 'distributionChannel', [])}
        placeholder={'ช่องทางการจำหน่าย'}
        labelText="ช่องทางการจำหน่าย"
        handleChange={(event) =>
          handleChangeForm({
            key: 'distributionChannel',
            value: event.target.value,
            course,
            options,
            stateForm,
            setStateForm,
            type,
            setValidateForm,
          })
        }
        textError={_.get(validateForm, 'distributionChannel', '')}
        value={_.get(stateForm, 'distributionChannel.uuid', [])}
        showTextError={
          _.get(validateForm, 'distributionChannel', '') ? true : false
        }
      />
      <SelectGroupChip
        boxSx={{ mt: 2 }}
        id="trainingPlatform"
        name="trainingPlatform"
        required
        placeholder={'รูปแบบการสอน'}
        labelText="รูปแบบการสอน"
        options={_.get(options, 'trainingPlatform', [])}
        onChange={(event) => {
          handleChangeForm({
            key: 'trainingPlatform',
            value: event,
            course,
            options,
            stateForm,
            setStateForm,
            type,
            setValidateForm,
          })
          checkTrainingPlatform({
            stateForm,
            trainingPlatform: event,
            options: _.get(options, 'classroom', []),
            setOptionClassroom,
          })
        }}
        value={_.get(stateForm, 'trainingPlatform', '')}
        textError={_.get(validateForm, 'trainingPlatform', '')}
        showTextError={
          _.get(validateForm, 'distributionChannel', '') ? true : false
        }
      />
      {_.find(
        stateForm.trainingPlatform,
        (e) => e.trainingPlatform === 'Classroom',
      ) && (
        <FormControlLabel
          label="ใช้ห้องประชุม"
          control={
            <Checkbox
              color="primary"
              name="isUseRoom"
              checked={_.get(stateForm, 'isUseRoom', true)}
              onChange={(event) =>
                handleChangeForm({
                  key: 'isUseRoom',
                  value: event.target.checked,
                  course,
                  options,
                  stateForm,
                  setStateForm,
                  type,
                  setValidateForm,
                })
              }
            />
          }
        />
      )}
      <TextInput
        boxSx={{ mt: 2 }}
        id="courseOther"
        name="courseOther"
        type="basic"
        labelText="รายละเอียด"
        placeholder="รายละเอียด"
        multiline={true}
        rows={3}
        onChange={(event) =>
          handleChangeForm({
            key: 'courseOther',
            value: event.target.value,
            course,
            options,
            stateForm,
            setStateForm,
            type,
            setValidateForm,
          })
        }
        value={_.get(stateForm, 'courseOther', '')}
        textError={_.get(validateForm, 'courseOther', '')}
        isShowTextError={_.get(validateForm, 'courseOther', '') ? true : false}
      />
      <TextInput
        boxSx={{ mt: 2 }}
        id="remark"
        name="remark"
        type="basic"
        labelText="หมายเหตุ"
        placeholder="หมายเหตุ"
        multiline={true}
        rows={3}
        onChange={(event) =>
          handleChangeForm({
            key: 'remark',
            value: event.target.value,
            course,
            options,
            stateForm,
            setStateForm,
            type,
            setValidateForm,
          })
        }
        value={_.get(stateForm, 'remark', '')}
        textError={_.get(validateForm, 'remark', '')}
        isShowTextError={_.get(validateForm, 'remark', '') ? true : false}
      />
    </Box>
  )
}

export const DataList = ({
  type,
  state,
  options,
  course,
  loading,
  setLoading,
  validateForm,
  setValidateForm,
  optionSubWorkType,
  stateForm,
  setStateForm,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [stateFormDialog, setStateFormDialog] = useState({})
  const [typeDialog, setTypeDialog] = useState(null)
  const [stateRowIndex, setStateRowIndex] = useState(0)
  const [dialog, setDialog] = useState({
    variant: 'delete',
    title: 'ยืนยันลบข้อมูล',
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    open: false,
  })

  const listExpandDate = _.get(stateForm, 'dates', [])

  const handleClickOpen = (value, type, rowIndex) => {
    setStateFormDialog(value)
    setTypeDialog(type)
    setStateRowIndex(rowIndex)

    if (type === 'cancel') {
      setDialog({
        ...dialog,
        title: 'ยืนยันยกเลิกคำขอ',
        content: 'คุณต้องการยกเลิกคำขอยืมวิทยากรนี้หรือไม่',
        agreeText: 'บันทึก',
        open: true,
        onConfirmClick: () => handleCancelBorrow(value, rowIndex),
      })
    } else if (type === 'delete') {
      setDialog({
        ...dialog,
        open: true,
        onConfirmClick: () => handleDelete(value, rowIndex),
      })
    } else {
      setOpenDialog(true)
    }
  }
  const handleClose = () => {
    setOpenDialog(false)
    setDialog({ ...dialog, open: false })
  }

  const handleSubmitForm = (value) => {
    handleSubmitDialog(
      listExpandDate,
      value,
      typeDialog,
      stateRowIndex,
      stateForm.typeDate,
      setStateForm,
    )
    handleClose()
  }

  const handleDelete = (value, rowIndex) => {
    handleSubmitDialog(
      listExpandDate,
      value,
      'delete',
      rowIndex,
      stateForm.typeDate,
      setStateForm,
    )
    handleClose()
  }

  const handleCancelBorrow = async (value, rowIndex) => {
    setLoading(true)
    handleSubmitDialog(
      listExpandDate,
      value,
      'CANCEL',
      rowIndex,
      stateForm.typeDate,
      setStateForm,
    )
    await cancelSpeakerBorrow(value.uuid || '')
    handleClose()
    setLoading(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
          }}
        >
          <Box sx={{ width: '50%' }}>
            <Typography variant="body1">วันที่</Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ display: 'flex' }}>
              ช่วงเวลา
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ display: 'flex' }}>
              เวลาเริ่ม
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ display: 'flex' }}>
              เวลาสิ้นสุด
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
          </Box>
        </Box>
        <ShowData
          type={type}
          state={state}
          list={
            stateForm.typeDate === 'CUSTOM'
              ? stateForm.dates
              : [stateForm.dates[0]]
          }
          options={options}
          course={course}
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          optionSubWorkType={optionSubWorkType}
          stateForm={stateForm}
          setStateForm={setStateForm}
          typeDialog={typeDialog}
          handleClickOpen={handleClickOpen}
          loading={loading}
          setLoading={setLoading}
        />

        <DialogForm
          state={state}
          open={openDialog}
          handleClose={handleClose}
          setOpenDialog={setOpenDialog}
          stateFormDialog={stateFormDialog}
          handleSubmitDialog={handleSubmitForm}
          listResponsibility={_.get(options, 'responsibility', [])}
        />

        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={() => setDialog({ ...dialog, open: false })}
          title={dialog.title}
          variant={dialog.variant}
          onCancelClick={() => setDialog({ ...dialog, open: false })}
          onConfirmClick={dialog.onConfirmClick}
          content={dialog.content}
          agreeText={dialog.agreeText}
        />
      </Box>
    </Box>
  )
}

export const ShowData = ({
  type,
  state,
  list,
  options,
  course,
  validateForm,
  setValidateForm,
  optionSubWorkType,
  stateForm,
  setStateForm,
  handleClickOpen,
  loading,
  setLoading,
}) => {
  const [openBorrowDialog, setOpenBorrowDialog] = useState({})
  const isDateCustom = stateForm.typeDate === 'CUSTOM'

  useState(() => {
    const openObj = {}
    _.forEach(list, (e, i) => (openObj[i] = false))
    setOpenBorrowDialog(openObj)
  }, [list])

  return (
    <Box>
      {list.map((item, index) => {
        const valueDate =
          !_.get(stateForm, 'startDate', null) ||
          !_.get(stateForm, 'finishDate', null)
            ? true
            : false

        const findRequireFile = _.get(validateForm, 'dates', []).find(
          (itemExpand) => {
            return (
              _.get(itemExpand, 'date', '') ===
              dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB)
            )
          },
        )

        const borrowData = {
          startDate: isDateCustom ? item.date : stateForm.startDate,
          finishDate: isDateCustom ? item.date : stateForm.finishDate,
          startTime: convertTime(_.get(item, 'startTime', '')),
          endTime: convertTime(_.get(item, 'endTime', '')),
        }
        const speakerBorrows = _.get(item, 'speakerBorrow', []).filter(
          (speaker) => speaker.status !== approveStatus.DELETED,
        )
        let speakers = []
        if (isDateCustom) {
          speakers = _.get(stateForm, `dates[${index}].speakers`, [])
        } else {
          _.forEach(stateForm.dates, (date) => {
            speakers = _.concat(speakers, date.speakers)
          })
          speakers = _.uniqBy(speakers, 'staff.uuid')
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mb: 2,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 3,
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <Typography variant="body1">
                    {valueDate
                      ? '-'
                      : isDateCustom
                      ? convertFormatDateTime({
                          value: _.get(item, 'date', null),
                          type: 'date',
                        })
                      : _.get(stateForm, 'dateBetween', []).length > 1
                      ? `${convertFormatDateTime({
                          value: _.get(stateForm, 'startDate', null),
                          type: 'dayMonth',
                        })} - ${convertFormatDateTime({
                          value: _.get(stateForm, 'finishDate', null),
                          type: 'dayMonth',
                        })}`
                      : convertFormatDateTime({
                          value: _.get(item, 'date', null),
                          type: 'date',
                        })}
                  </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Select
                    dataTestid="select-typeTime"
                    id="typeTime"
                    name="typeTime"
                    type="basic"
                    options={LIST_TYPE_TRAINING}
                    handleChange={(event) =>
                      handleChangeFormDates({
                        index,
                        key: 'typeTime',
                        value: event.target.value,
                        stateForm,
                        setStateForm,
                      })
                    }
                    value={_.get(item, 'typeTime', '')}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <TimePicker
                    disabled={
                      _.get(item, 'typeTime', '') === 'OTHER' ? false : true
                    }
                    id="startTime"
                    onChange={(event) =>
                      handleChangeFormDates({
                        index,
                        key: 'startTime',
                        value: convertTimeToApi(event.target.value),
                        stateForm,
                        setStateForm,
                      })
                    }
                    value={convertTime(_.get(item, 'startTime', ''))}
                    disabledStartTime={convertTime(
                      _.get(item, 'startTime', ''),
                    )}
                    disabledEndTime={convertTime(_.get(item, 'endTime', ''))}
                    helperText={_.get(findRequireFile, 'startTime', '')}
                  />
                </Box>

                <Box sx={{ width: '100%' }}>
                  <TimePicker
                    disabled={
                      _.get(stateForm, `dates[${index}].typeTime`, '') ===
                      'OTHER'
                        ? false
                        : true
                    }
                    id="endTime"
                    onChange={(event) =>
                      handleChangeFormDates({
                        index,
                        key: 'endTime',
                        value: convertTimeToApi(event.target.value),
                        stateForm,
                        setStateForm,
                      })
                    }
                    value={convertTime(_.get(item, 'endTime', ''))}
                    disabledStartTime={convertTime(
                      _.get(item, 'startTime', ''),
                    )}
                    disabledEndTime={convertTime(_.get(item, 'endTime', ''))}
                    helperText={_.get(findRequireFile, 'endTime', '')}
                  />
                </Box>
              </Box>
              {_.get(stateForm, 'startDate', null) &&
                _.get(stateForm, 'finishDate', null) && (
                  <FormLocation
                    typeStatus={type}
                    rowIndex={index}
                    stateForm={stateForm}
                    setStateForm={setStateForm}
                    validateForm={validateForm}
                    setValidateForm={setValidateForm}
                    type={_.get(stateForm, 'workType.workType', '')}
                    course={course}
                    options={options}
                  />
                )}

              <StaffList
                loading={loading}
                options={options}
                validateForm={validateForm}
                setValidateForm={setValidateForm}
                optionSubWorkType={optionSubWorkType}
                stateForm={stateForm}
                setStateForm={setStateForm}
                index={index}
                speakers={speakers}
              />

              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 3,
                  paddingLeft: '16%',
                }}
              >
                <Button
                  sx={{ minWidth: 100, height: '30px' }}
                  variant="contained"
                  onClick={() => {
                    handleChangeStaffList({
                      type: 'add',
                      index,
                      stateForm,
                      setStateForm,
                    })
                  }}
                  color={'primary'}
                >
                  เพิ่มวิทยากร
                </Button>
                <Button
                  disabled={
                    _.get(stateForm, 'dateBetween', []).length ? false : true
                  }
                  sx={{
                    minWidth: 100,
                    height: '30px',
                    border: _.get(stateForm, 'dateBetween', []).length
                      ? ''
                      : '1px solid  !important',
                  }}
                  variant="outlined"
                  color={'primary'}
                  onClick={() =>
                    setOpenBorrowDialog((prev) => ({ ...prev, [index]: true }))
                  }
                >
                  ขอยืมวิทยากรโซนอื่น
                </Button>
              </Box>
              <BorrowDrawer
                field="training"
                open={openBorrowDialog[index]}
                onClose={() =>
                  setOpenBorrowDialog((prev) => ({ ...prev, [index]: false }))
                }
                data={borrowData}
                areaUuid={_.get(state, 'area.value', '')}
                listResponsibility={_.get(options, 'responsibility', [])}
                setStateForm={setStateForm}
                speakerBorrows={speakerBorrows}
              />

              {speakerBorrows.length > 0 && (
                <Box>
                  <Typography variant="body2b">
                    รายการขอยืมวิทยากรโซนอื่น
                  </Typography>

                  <FormListBorrow
                    stateForm={stateForm}
                    borrowData={borrowData}
                    listSpeakerBorrow={item.speakerBorrow}
                    handleClickOpen={handleClickOpen}
                    setLoadingDrawer={setLoading}
                    areaUuid={_.get(state, 'area.value', '')}
                    listResponsibility={_.get(options, 'responsibility', [])}
                    setStateForm={setStateForm}
                    field="training"
                    speakerBorrows={speakerBorrows}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export const addOptionStaff = (listOption, listDate, key) => {
  let newOption = []
  if (listOption.length) newOption = [...listOption]

  if (listDate.length) {
    let newSpeaker = []
    listDate.map((item) => {
      const speaker = _.get(item, 'speakers', [])

      if (speaker.length) {
        speaker.map((itemSK) => {
          if (!_.isEmpty(_.get(itemSK, `${key}`, {}))) {
            newSpeaker.push({
              id: _.get(itemSK, `${key}.id`),
              label: itemSK[key]
                ? _.get(itemSK, `${key}.area[0].station.station`, '') +
                  ' ' +
                  _.get(itemSK, `${key}.firstNameTH`, '') +
                  ' ' +
                  _.get(itemSK, `${key}.lastNameTH`, '')
                : '',
              value: _.get(itemSK, `${key}.uuid`, ''),
            })
          }
        })
      }
    })

    if (newSpeaker.length) newOption = [...listOption, ...newSpeaker]
  }
  return _.uniqBy(newOption, 'value')
}

export const StaffList = ({
  loading,
  validateForm,
  options,
  stateForm,
  setStateForm,
  setValidateForm,
  speakers,
  index,
}) => {
  const newOptionStaff = addOptionStaff(
    _.get(options, 'staff', []),
    _.get(stateForm, 'dates', []),
    'staff',
  )
  return (
    <Box>
      {!loading &&
        speakers.map((item, indexStaff) => {
          let borrowForm
          const isBorrow = _.get(item, 'staff.speakerBorrow', false)
          if (isBorrow) {
            borrowForm = {
              id: _.get(item, `staff.id`),
              label:
                _.get(item, `staff.area[0].station.station`, '') +
                ' ' +
                _.get(item, `staff.firstNameTH`, '') +
                ' ' +
                _.get(item, `staff.lastNameTH`, ''),
              value: _.get(item, `staff.uuid`, ''),
            }
          }
          const validateDate = _.get(
            validateForm,
            `dates[${index}].speakers`,
            [],
          )
          let objValidate = {}
          const staffUuid = _.get(item, 'staff.uuid', '')
          const defaultStaff = !isBorrow
            ? newOptionStaff.find((staff) => staff.value == staffUuid)
            : borrowForm
          const newSpeakers = speakers.map((speaker) => {
            return { uuid: _.get(speaker, 'staff.uuid', '') }
          })
          const diffOptions = !isBorrow
            ? _.differenceBy(newOptionStaff, newSpeakers, 'uuid')
            : [borrowForm]
          if (validateDate.length > 0) {
            objValidate = validateDate.find((itemVLD) => {
              return itemVLD?.indexSK === indexStaff
            })
          }
          return (
            <Box
              key={`${indexStaff}-${staffUuid}`}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
              }}
            >
              <Box sx={{ width: '50%' }}></Box>
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  id="staff"
                  name="staff"
                  required
                  labelText="วิทยากร"
                  placeholder="เลือกวิทยากร"
                  type="basic"
                  options={diffOptions}
                  disabled={
                    _.get(stateForm, 'dateBetween', []).length && !isBorrow
                      ? false
                      : true
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="กรุณาเลือก"
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-staff',
                      }}
                    />
                  )}
                  defaultValue={defaultStaff}
                  handleChange={(event, element) => {
                    handleChangeFormSpeakers({
                      index,
                      indexStaff,
                      key: 'staff',
                      value: _.get(element, 'uuid', ''),
                      stateForm,
                      setStateForm,
                      validateForm,
                      setValidateForm,
                      options,
                    })
                  }}
                  value={staffUuid}
                  textError={_.get(objValidate, 'staff', '')}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Select
                  dataTestid="select-responsibility"
                  required
                  disabled={
                    _.get(stateForm, 'dateBetween', []).length && !isBorrow
                      ? false
                      : true
                  }
                  labelText="หน้าที่"
                  placeholder="เลือกหน้าที่"
                  id="responsibility"
                  name="responsibility"
                  type="basic"
                  textError={_.get(objValidate, 'responsibility', '')}
                  options={_.get(options, 'responsibility', [])}
                  handleChange={(event) =>
                    handleChangeFormSpeakers({
                      index,
                      indexStaff,
                      key: 'responsibility',
                      value: event.target.value,
                      stateForm,
                      setStateForm,
                      validateForm,
                      setValidateForm,
                      options,
                    })
                  }
                  value={_.get(item, 'responsibility.uuid', '')}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 3,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    mt: checkMarginTime(
                      _.get(objValidate, 'staff', ''),
                      _.get(objValidate, 'responsibility', ''),
                      _.get(objValidate, 'startTime', ''),
                    ),
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="body2" color={'text.secondary'}>
                        เวลาเริ่ม
                      </Typography>
                      <Typography
                        sx={{ lineHeight: 1, ml: 0.5 }}
                        color={'error'}
                      >
                        *
                      </Typography>
                    </Box>
                  </Box>
                  <TimePicker
                    id="startTime"
                    disabled={
                      _.get(stateForm, 'dateBetween', []).length ? false : true
                    }
                    value={convertTime(_.get(item, 'startTime', ''))}
                    disabledStartTime={convertTime(
                      _.get(item, 'startTime', ''),
                    )}
                    disabledEndTime={convertTime(_.get(item, 'endTime', ''))}
                    onChange={(event) =>
                      handleChangeFormSpeakers({
                        index,
                        indexStaff,
                        key: 'startTime',
                        value: convertTimeToApi(event.target.value),
                        stateForm,
                        setStateForm,
                        validateForm,
                        setValidateForm,
                      })
                    }
                    helperText={_.get(objValidate, 'startTime', '')}
                  />
                </Box>
                <Box
                  sx={{
                    mt: checkMarginTime(
                      _.get(objValidate, 'staff', ''),
                      _.get(objValidate, 'responsibility', ''),
                      _.get(objValidate, 'endTime', ''),
                    ),
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="body2" color={'text.secondary'}>
                        เวลาสิ้นสุด
                      </Typography>
                      <Typography
                        sx={{ lineHeight: 1, ml: 0.5 }}
                        color={'error'}
                      >
                        *
                      </Typography>
                    </Box>
                  </Box>
                  <TimePicker
                    id="endTime"
                    disabled={
                      _.get(stateForm, 'dateBetween', []).length ? false : true
                    }
                    onChange={(event) =>
                      handleChangeFormSpeakers({
                        index,
                        indexStaff,
                        key: 'endTime',
                        value: convertTimeToApi(event.target.value),
                        stateForm,
                        setStateForm,
                        validateForm,
                        setValidateForm,
                      })
                    }
                    value={convertTime(_.get(item, 'endTime', ''))}
                    disabledStartTime={convertTime(
                      _.get(item, 'startTime', ''),
                    )}
                    disabledEndTime={convertTime(_.get(item, 'endTime', ''))}
                    helperText={_.get(objValidate, 'endTime', '')}
                  />
                </Box>
                {!isBorrow ? (
                  <IconButton
                    data-test-id="delete-staff"
                    onClick={() =>
                      handleChangeStaffList({
                        type: 'delete',
                        index,
                        indexStaff,
                        stateForm,
                        setStateForm,
                      })
                    }
                  >
                    <DeleteTwoToneIcon />
                  </IconButton>
                ) : (
                  <Box sx={{ mr: 3 }}></Box>
                )}
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

export const ShowTrainingPlatform = ({
  value,
  course,
  handleChangeForm,
  breakMealBudget,
  options,
  stateForm,
  setStateForm,
  type,
  setValidateForm,
}) => {
  const date = course.filter((a) => a.value === value)
  const trainingPlatform = _.get(date, '[0].trainingPlatform', [])

  if (breakMealBudget !== _.get(date, '[0].breakMealBudget', 0)) {
    handleChangeForm({
      key: 'course.breakMealBudget',
      value: _.get(date, '[0].breakMealBudget', 0),
      course,
      options,
      stateForm,
      setStateForm,
      type,
      setValidateForm,
    })
  }

  useEffect(() => {
    setStateForm((prev) => ({
      ...prev,
      training: {
        ...stateForm,
        trainingPlatform,
      },
    }))
  }, [value])

  return (
    <Box sx={{ width: '50%' }}>
      <Typography variant="body2">รูปแบบการสอน</Typography>
      <Typography variant="body1">
        {trainingPlatform.length
          ? trainingPlatform.map(
              (d, i) => `${i === 0 ? '' : ', '}${d.trainingPlatform}`,
            )
          : '-'}
      </Typography>
    </Box>
  )
}

export const checkMarginTime = (errorStaff, erorrRole, error) => {
  let result = '-24px'
  if ((!_.isEmpty(errorStaff) || !_.isEmpty(erorrRole)) && _.isEmpty(error))
    result = '-50px'
  if (_.isEmpty(errorStaff) && _.isEmpty(erorrRole) && !_.isEmpty(error))
    result = 0
  return result
}
