import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'

import React, { useRef, useState } from 'react'
import {
  handleClose,
  getMoreOptions,
  onOptionClick,
  getOptionsIcon,
} from './events'
import { StyledPopper } from './Styled'
import IconButton from '@mui/material/IconButton'
import MoreVert from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { validateEPermission } from '../../../../../utils/lib'
import {
  EXAMINATION_SETTING,
  PERMISSION,
} from '../../../../../constants/examination'
import { SCHEDULE_STATUS } from '../../../../../constants/eExamination'

const DropdownButton = ({ examUuid, announce, scheduleStatus }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const getMoreOptionsList = getMoreOptions()
  const hasViewPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.VIEW,
  })
  const hasCreatePermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.CREATE,
  })
  const hasDcPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.DC,
  })
  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList autoFocusItem sx={{ py: 0 }}>
                  {getMoreOptionsList.map((option, index) => {
                    return (
                      <label key={index} htmlFor={`menu-${option.method}`}>
                        <MenuItem
                          key={index}
                          component="span"
                          data-testid={`menu-${index}`}
                          onClick={() => {
                            dispatch(onOptionClick(option.method, examUuid))
                          }}
                          disabled={
                            (option.method === 'add' && announce > 0) ||
                            (['setting', 'import', 'add'].includes(
                              option.method,
                            ) &&
                              [
                                SCHEDULE_STATUS.CANCEL,
                                SCHEDULE_STATUS.COMPLETED,
                              ].includes(scheduleStatus)) ||
                            (['add', 'import'].includes(option.method) &&
                              !hasDcPermission) ||
                            (['setting'].includes(option.method) &&
                              !hasCreatePermission) ||
                            (option.method === 'history' && !hasViewPermission)
                              ? true
                              : false
                          }
                          sx={{
                            display: 'flex',
                            gap: 1,
                            borderBottom: '1px solid rgba(204, 204, 204, 0.25)',
                          }}
                        >
                          {getOptionsIcon(option.method)}
                          <Typography variant="body1" component="span">
                            {option.text}
                          </Typography>
                        </MenuItem>
                      </label>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
