export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: `ชื่อเรื่อง`,
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: `กรุณาใส่ชื่อเรื่อง`,
    type: 'text',
  },
  {
    label: `หมวดหมู่`,
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    placeholder: `กรุณาใส่ หมวดหมู่`,
    type: 'text',
  },
  {
    label: `หมวดหมู่ย่อย`,
    nameCheckBox: 'subCategoryChecked',
    nameInput: 'subCategory',
    placeholder: `กรุณาใส่ หมวดหมู่ย่อย`,
    type: 'text',
  },
  {
    label: `Tag`,
    nameCheckBox: 'tagNameChecked',
    nameInput: 'tagName',
    placeholder: `กรุณาใส่ Tag`,
    type: 'text',
  },
  {
    label: `วันที่เผยแพร่`,
    nameCheckBox: 'publishDateChecked',
    nameInput: 'publishDate',
    placeholder: `กรุณาใส่ วันที่เผยแพร่`,
    dateStateStart: 'startPublishDate',
    dateStateFinish: 'endPublishDate',
    dateStateDisplay: 'publishDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `วันที่สร้าง`,
    nameCheckBox: 'createDateChecked',
    nameInput: 'createDate',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'createStartDate',
    dateStateFinish: 'createFinishDate',
    dateStateDisplay: 'createDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `สร้างโดย`,
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: `กรุณาใส่ชื่อพนักงาน`,
    type: 'text',
  },
  {
    label: `วันที่แก้ไข`,
    nameCheckBox: 'updateDateChecked',
    nameInput: 'updateDate',
    placeholder: `กรุณาใส่ วันที่แก้ไข`,
    dateStateStart: 'updateStartDate',
    dateStateFinish: 'updateFinishDate',
    dateStateDisplay: 'updateDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: `กรุณาใส่ชื่อพนักงาน`,
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
