import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const COLUMN_NAME = {
  NO: 'no',
  COST_CENTER: 'costCenter',
  COST_CENTER_NAME: 'costCenterName',
  STAFF_ID: 'staffId',
  STAFF_NAME: 'staffName',
  OLD_ACCOUNT_CODE: 'oldAccountCode',
  NEW_ACCOUNT_CODE: 'newAccountCode',
  DESCRIPTION: 'description',
  CATEGORY: 'category',
  BUDGET: 'budget',
  REVISED: 'revised',
  ACTUAL: 'actual',
  COURSE_CODE: 'courseCode',
  COURSE_VERSION: 'courseVersion',
  COURSE_NAME_EN: 'courseNameEN',
  COURSE_NAME_TH: 'courseNameTH',
  COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
  START_TRAINING_DATE: 'startTrainingDate',
  END_TRAINING_DATE: 'endTrainingDate',
  STATION: 'station',
  TRAINING_PLATFORM: 'trainingPlatform',
  DISTRIBUTION: 'distribution',
  ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
  WORK_TYPE: 'workType',
  SUB_WORK_TYPE: 'subWorkType',
  SUMMARY_BUDGET: 'summaryBudget',
  SUMMARY_REVISED: 'summaryRevised',
  SUMMARY_ACTUAL: 'summaryActual',
  EXPENSE_DATE: 'expenseDate',
  TRAINING_DATE: 'trainingDate',
  STAFF_TYPE: 'staffType',
  EXPENSE_CATEGORY: 'expenseCategory',
  ACCOUNT_CODE: 'accountCode',
}

export const COLUMN_NAME_TH = {
  NO: 'No',
  COST_CENTER: 'Cost Center',
  COST_CENTER_NAME: 'Cost Center Name',
  STAFF_ID: 'Staff ID',
  STAFF_NAME: 'Staff Name',
  OLD_ACCOUNT_CODE: 'Old Account Code',
  NEW_ACCOUNT_CODE: 'New Account Code',
  DESCRIPTION: 'Description',
  CATEGORY: 'Category',
  BUDGET: 'Budget',
  REVISED: 'Revised',
  ACTUAL: 'Actual',
  COURSE_CODE: 'รหัสหลักสูตร',
  COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
  COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
  COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
  COURSE_VERSION: 'เวอร์ชัน',
  START_TRAINING_DATE: 'วันเริ่มต้น (Training Date)',
  END_TRAINING_DATE: 'วันสิ้นสุด (Training Date)',
  STATION: 'Station',
  TRAINING_PLATFORM: 'รูปแบบของการสอน',
  DISTRIBUTION: 'ช่องทางการจำหน่าย',
  ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
  WORK_TYPE: 'Work Type',
  SUB_WORK_TYPE: 'Sub Work Type',
  SUMMARY_BUDGET: 'Summary Budget',
  SUMMARY_REVISED: 'Summary Revised',
  SUMMARY_ACTUAL: 'Summary Actual',
  EXPENSE_DATE: 'วันที่ทำรายการเบิก',
  TRAINING_DATE: 'วันที่จัดอบรม',
  STAFF_TYPE: 'Staff Type',
}

export const TYPE_OF_VALUE = {
  DATE_RANGE: 'DATE_RANGE',
  INPUT_NUMBER: 'INPUT_NUMBER',
  INPUT_TEXT: 'INPUT_TEXT',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
}

export const defaultColumns = [
  {
    id: 1,
    name: COLUMN_NAME.NO,
    value: COLUMN_NAME.NO,
    label: COLUMN_NAME_TH.NO,
    minWidth: 60,
    align: 'center',
  },
  {
    id: 2,
    name: COLUMN_NAME.COST_CENTER,
    value: COLUMN_NAME.COST_CENTER,
    label: COLUMN_NAME_TH.COST_CENTER,
    minWidth: 120,
  },
  {
    id: 3,
    name: COLUMN_NAME.COST_CENTER_NAME,
    value: COLUMN_NAME.COST_CENTER_NAME,
    label: COLUMN_NAME_TH.COST_CENTER_NAME,
    minWidth: 120,
  },
  {
    id: 4,
    name: COLUMN_NAME.STAFF_ID,
    value: COLUMN_NAME.STAFF_ID,
    label: COLUMN_NAME_TH.STAFF_ID,
    minWidth: 100,
  },
  {
    id: 5,
    name: COLUMN_NAME.STAFF_NAME,
    value: COLUMN_NAME.STAFF_NAME,
    label: COLUMN_NAME_TH.STAFF_NAME,
    minWidth: 160,
  },
  {
    id: 6,
    name: COLUMN_NAME.SUMMARY_BUDGET,
    value: COLUMN_NAME.SUMMARY_BUDGET,
    label: COLUMN_NAME_TH.SUMMARY_BUDGET,
    minWidth: 160,
  },
  {
    id: 7,
    name: COLUMN_NAME.SUMMARY_REVISED,
    value: COLUMN_NAME.SUMMARY_REVISED,
    label: COLUMN_NAME_TH.SUMMARY_REVISED,
    minWidth: 160,
  },
  {
    id: 8,
    name: COLUMN_NAME.SUMMARY_ACTUAL,
    value: COLUMN_NAME.SUMMARY_ACTUAL,
    label: COLUMN_NAME_TH.SUMMARY_ACTUAL,
    minWidth: 160,
  },
  {
    id: 9,
    name: COLUMN_NAME.COURSE_CODE,
    value: COLUMN_NAME.COURSE_CODE,
    label: COLUMN_NAME_TH.COURSE_CODE,
    minWidth: 160,
  },
  {
    id: 10,
    name: COLUMN_NAME.COURSE_VERSION,
    value: COLUMN_NAME.COURSE_VERSION,
    label: COLUMN_NAME_TH.COURSE_VERSION,
    minWidth: 160,
  },
  {
    id: 11,
    name: COLUMN_NAME.COURSE_NAME_EN,
    value: COLUMN_NAME.COURSE_NAME_EN,
    label: COLUMN_NAME_TH.COURSE_NAME_EN,
    minWidth: 200,
  },
  {
    id: 12,
    name: COLUMN_NAME.COURSE_NAME_TH,
    value: COLUMN_NAME.COURSE_NAME_TH,
    label: COLUMN_NAME_TH.COURSE_NAME_TH,
    minWidth: 200,
  },
  {
    id: 13,
    name: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    value: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    label: COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
    minWidth: 200,
  },
  {
    id: 14,
    name: COLUMN_NAME.START_TRAINING_DATE,
    value: COLUMN_NAME.START_TRAINING_DATE,
    label: COLUMN_NAME_TH.START_TRAINING_DATE,
    minWidth: 160,
  },
  {
    id: 15,
    name: COLUMN_NAME.END_TRAINING_DATE,
    value: COLUMN_NAME.END_TRAINING_DATE,
    label: COLUMN_NAME_TH.END_TRAINING_DATE,
    minWidth: 160,
  },
  {
    id: 16,
    name: COLUMN_NAME.STATION,
    value: COLUMN_NAME.STATION,
    label: COLUMN_NAME_TH.STATION,
    minWidth: 160,
  },
  {
    id: 17,
    name: COLUMN_NAME.TRAINING_PLATFORM,
    value: COLUMN_NAME.TRAINING_PLATFORM,
    label: COLUMN_NAME_TH.TRAINING_PLATFORM,
    minWidth: 160,
  },
  {
    id: 18,
    name: COLUMN_NAME.DISTRIBUTION,
    value: COLUMN_NAME.DISTRIBUTION,
    label: COLUMN_NAME_TH.DISTRIBUTION,
    minWidth: 160,
  },
  {
    id: 19,
    name: COLUMN_NAME.WORK_TYPE,
    value: COLUMN_NAME.WORK_TYPE,
    label: COLUMN_NAME_TH.WORK_TYPE,
    minWidth: 160,
  },
  {
    id: 20,
    name: COLUMN_NAME.SUB_WORK_TYPE,
    value: COLUMN_NAME.SUB_WORK_TYPE,
    label: COLUMN_NAME_TH.SUB_WORK_TYPE,
    minWidth: 160,
  },
]

export const filterColumnOptions = [
  {
    id: 1,
    name: COLUMN_NAME.EXPENSE_DATE,
    value: COLUMN_NAME.EXPENSE_DATE,
    label: COLUMN_NAME_TH.EXPENSE_DATE,
    minWidth: 160,
    type: TYPE_OF_VALUE.DATE_RANGE,
  },
  {
    id: 2,
    name: COLUMN_NAME.COST_CENTER,
    value: COLUMN_NAME.COST_CENTER,
    label: COLUMN_NAME_TH.COST_CENTER,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.COST_CENTER,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 3,
    name: COLUMN_NAME.STAFF_TYPE,
    value: COLUMN_NAME.STAFF_TYPE,
    label: COLUMN_NAME_TH.STAFF_TYPE,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 4,
    name: COLUMN_NAME.STAFF_ID,
    value: COLUMN_NAME.STAFF_ID,
    label: COLUMN_NAME_TH.STAFF_ID,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 5,
    name: COLUMN_NAME.OLD_ACCOUNT_CODE,
    value: COLUMN_NAME.OLD_ACCOUNT_CODE,
    label: COLUMN_NAME_TH.OLD_ACCOUNT_CODE,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.ACCOUNT_CODE,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 6,
    name: COLUMN_NAME.NEW_ACCOUNT_CODE,
    value: COLUMN_NAME.NEW_ACCOUNT_CODE,
    label: COLUMN_NAME_TH.NEW_ACCOUNT_CODE,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.ACCOUNT_CODE,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 7,
    name: COLUMN_NAME.DESCRIPTION,
    value: COLUMN_NAME.DESCRIPTION,
    label: COLUMN_NAME_TH.DESCRIPTION,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.ACCOUNT_CODE,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 8,
    name: COLUMN_NAME.CATEGORY,
    value: COLUMN_NAME.CATEGORY,
    label: COLUMN_NAME_TH.CATEGORY,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.EXPENSE_CATEGORY,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 9,
    name: COLUMN_NAME.COURSE_CODE,
    value: COLUMN_NAME.COURSE_CODE,
    label: COLUMN_NAME_TH.COURSE_CODE,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 10,
    name: COLUMN_NAME.COURSE_VERSION,
    value: COLUMN_NAME.COURSE_VERSION,
    label: COLUMN_NAME_TH.COURSE_VERSION,
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_NUMBER,
  },
  {
    id: 11,
    name: COLUMN_NAME.COURSE_NAME_EN,
    value: COLUMN_NAME.COURSE_NAME_EN,
    label: COLUMN_NAME_TH.COURSE_NAME_EN,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 12,
    name: COLUMN_NAME.COURSE_NAME_TH,
    value: COLUMN_NAME.COURSE_NAME_TH,
    label: COLUMN_NAME_TH.COURSE_NAME_TH,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 13,
    name: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    value: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    label: COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 14,
    name: COLUMN_NAME.TRAINING_DATE,
    value: COLUMN_NAME.TRAINING_DATE,
    label: COLUMN_NAME_TH.TRAINING_DATE,
    minWidth: 160,
    type: TYPE_OF_VALUE.DATE_RANGE,
  },
  {
    id: 15,
    name: COLUMN_NAME.STATION,
    value: COLUMN_NAME.STATION,
    label: COLUMN_NAME_TH.STATION,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.STATION,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 16,
    name: COLUMN_NAME.TRAINING_PLATFORM,
    value: COLUMN_NAME.TRAINING_PLATFORM,
    label: COLUMN_NAME_TH.TRAINING_PLATFORM,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.TRAINING_PLATFORM,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 17,
    name: COLUMN_NAME.DISTRIBUTION,
    value: COLUMN_NAME.DISTRIBUTION,
    label: COLUMN_NAME_TH.DISTRIBUTION,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.DISTRIBUTION,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 18,
    name: COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
    value: COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
    label: COLUMN_NAME_TH.ABBREVIATION_DISTRIBUTION,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.DISTRIBUTION,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 19,
    name: COLUMN_NAME.STAFF_NAME,
    value: COLUMN_NAME.STAFF_NAME,
    label: COLUMN_NAME_TH.STAFF_NAME,
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
]
