import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import Switch from '@mui/material/Switch'

import InputTags from './InputTags'
import TextInput from '../../../../components/Input/TextInput'
import { StyledCard, StyledFormWrapper } from '../../Styled'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../utils/lib'
import { EXAM_UPLOAD_DOCUMENT_STATUS } from '../../../../constants/eConfig'
import { clearLeadingZeros } from '../event'

const EExamForm = ({ formik, body, setInputValue, isHide }) => {
  return (
    <StyledFormWrapper isHide={isHide}>
      <StyledCard>
        <Box m={3}>
          <Typography variant="h5" mb={2}>
            การประกาศผู้มีสิทธิ์สอบ
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography color={'text.secondary'} variant="body2">
              อีเมลผู้ที่เกี่ยวข้อง
            </Typography>
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
              *
            </Typography>
          </Box>
          <Box>
            <InputTags
              values={body.CC_EMAIL_ANNOUNCEMENT_EXAMINATION}
              textError={formik?.errors?.CC_EMAIL_ANNOUNCEMENT_EXAMINATION}
              setInputValue={setInputValue}
              keyValue="CC_EMAIL_ANNOUNCEMENT_EXAMINATION"
            />
          </Box>
        </Box>
      </StyledCard>
      <StyledCard>
        <Box m={3}>
          <Typography variant="h5" mb={2}>
            ค่าสมัครสอบ
          </Typography>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '12px',
                width: '40%',
              }}
            >
              <TextInput
                id="EXAMINATION_APPLICATION_FEE"
                name="EXAMINATION_APPLICATION_FEE"
                labelText="จำนวนเงิน"
                placeholder={'Ex. 0 - 999999'}
                type="basic"
                inputProps={{
                  'data-testid': 'EXAMINATION_APPLICATION_FEE',
                  type: 'number',
                  onInput: handleNumberInput,
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, [])
                  },
                  onPaste: handlePasteFormat,
                  maxLength: 2,
                }}
                textError={formik?.errors?.EXAMINATION_APPLICATION_FEE}
                required
                // disabled={!hasPermissionCreate}
                value={body.EXAMINATION_APPLICATION_FEE}
                onChange={(e) => {
                  if (String(e.target.value).length >= 6) {
                    e.target.value = e.target.value.substring(0, 6)
                  }
                  setInputValue(
                    'EXAMINATION_APPLICATION_FEE',
                    clearLeadingZeros(e.target.value),
                  )
                }}
                onBlur={formik.handleBlur}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              />
              <Typography>บาท</Typography>
            </Box>
            <Typography>
              *การเปลี่ยนค่าใน eConfig จะมีผลกับรอบสอบที่ยังไม่ได้เปิดรอบรับสมัครเท่านั้น
            </Typography>
          </Box>
        </Box>
      </StyledCard>
      <StyledCard>
        <Box m={3}>
          <Typography variant="h5" mb={2}>
            ประเภทไฟล์อื่นๆที่ต้องการให้ผู้สมัครแนบเพิ่ม
          </Typography>
          <Box>
            <Box>
              <Switch
                name="status"
                data-testId="switch-status"
                checked={
                  body.EXAMINATION_UPLOAD_OTHER_DOCUMENT.status ===
                  EXAM_UPLOAD_DOCUMENT_STATUS.ACTIVE
                }
                onChange={(e) => {
                  setInputValue(
                    'EXAMINATION_UPLOAD_OTHER_DOCUMENT.status',
                    e.target.checked
                      ? EXAM_UPLOAD_DOCUMENT_STATUS.ACTIVE
                      : EXAM_UPLOAD_DOCUMENT_STATUS.INACTIVE,
                  )
                }}
              />
              เปิดเอกสารเพิ่มเติม
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '12px',
                width: '40%',
              }}
              mb={3}
            >
              <TextInput
                id="EXAMINATION_UPLOAD_OTHER_DOCUMENT"
                name="EXAMINATION_UPLOAD_OTHER_DOCUMENT"
                labelText="ชื่อเอกสาร"
                placeholder={'ชื่อเอกสารเพิ่มเติม'}
                type="basic"
                textError={
                  formik?.errors?.EXAMINATION_UPLOAD_OTHER_DOCUMENT?.label
                }
                required
                value={body.EXAMINATION_UPLOAD_OTHER_DOCUMENT.label}
                onChange={(e) => {
                  if (String(e.target.value).length >= 255) {
                    e.target.value = e.target.value.substring(0, 255)
                  }
                  setInputValue(
                    'EXAMINATION_UPLOAD_OTHER_DOCUMENT.label',
                    e.target.value,
                  )
                }}
                onBlur={formik.handleBlur}
              />
            </Box>
          </Box>
        </Box>
      </StyledCard>
    </StyledFormWrapper>
  )
}

export default EExamForm
