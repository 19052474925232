import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { drawerDataType, drawerType } from '../enums/drawerEnum'

export const handleClickOpenDrawer = (key = '') => {
  let classCount = 0
  let type = key.includes('classDrawer')
    ? drawerType.ALL_CLASS
    : drawerType.TOP_LIST
  let distributionSelected = drawerDataType.ALL
  if (key === 'classDrawerAll') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด`)
  } else if (key === 'classDrawerAgency') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (Agency)`)
    distributionSelected = drawerDataType.AGENCY
  } else if (key === 'classDrawerBanca') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (BANCA)`)
    distributionSelected = drawerDataType.BANCA
  } else if (key === 'classDrawerKtb') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (KTB)`)
    distributionSelected = drawerDataType.KTB
  } else if (key === 'classDrawerOther') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (Other)`)
    distributionSelected = drawerDataType.OTHER
  } else if (key === 'rankingCourse') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (รวม)`)
  } else if (key === 'rankingBudget') {
    setDetailDrawerTitle(`หลักสูตรค่าใช้จ่ายสูงสุด`)
    type = drawerType.TOP_EXPESNE_LIST
  } else if (key === 'rankingCourseAgency') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (Agency)`)
    distributionSelected = drawerDataType.AGENCY
  } else if (key === 'rankingCourseBanca') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (BANCA)`)
    distributionSelected = drawerDataType.BANCA
  } else if (key === 'rankingCourseKtb') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (KTB)`)
    distributionSelected = drawerDataType.KTB
  } else if (key === 'rankingCourseOther') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (Other)`)
    distributionSelected = drawerDataType.OTHER
  }

  togglDetailDrawer(true)
  setDetailDrawerType(type)
  setDetailDrawerDistribution(distributionSelected)
  setDetailDrawerClassCount(classCount)
}

export const toggleFiltersDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.filtersDrawer.isOpen`,
      value: value,
    }),
  )
}

export const togglDetailDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.isOpen`,
      value: value,
    }),
  )
}

export const setDetailDrawerTitle = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.title`,
      value: value,
    }),
  )
}

export const setDetailDrawerType = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.type`,
      value: value,
    }),
  )
}

export const setDetailDrawerClassCount = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.classCount`,
      value: value,
    }),
  )
}

export const setDetailDrawerDistribution = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.distributionSelected`,
      value: value,
    }),
  )
}
