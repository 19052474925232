import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
  setReportConfig,
  setFiltersData,
  setReduxValue,
} from '../../redux/slices/reportComp'
import Report from '../../components/Report'
import { reportConfigBackend, reportConfigLearner } from './reportConfig'
import { handleCheckStatusReportUpdated } from '../../components/Report/handler/handleCheckStatusReportUpdated'
import { openDialog, closeDialog } from '../../redux/slices/dialog'
import { tabList } from '../../constants/report/AuditLog'

const Index = () => {
  const dispatch = useDispatch()
  const { tab } = useSelector(
    (state) => ({
      tab: state.reportComp.tab,
    }),
    shallowEqual,
  )
  useEffect(async () => {
    dispatch(
      setReportConfig({
        reportConfig: reportConfigBackend,
      }),
    )
    const res = await handleCheckStatusReportUpdated('AUDIT_LOG')
    dispatch(setReduxValue({ key: 'latestUpdated', value: res.latestUpdated }))
    if (res?.status === 'INPROGRESS') {
      dispatch(
        openDialog({
          type: 'confirm',
          title: 'ระบบกำลังอัปเดตข้อมูล',
          message: 'ข้อมูลอาจไม่ถูกต้อง กรุณารอสักครู่',
          hideCancel: true,
          handleConfirm: () => dispatch(closeDialog()),
          handleCancel: () => dispatch(closeDialog()),
        }),
      )
    } else if (res?.status === 'FAILED') {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ระบบอัปเดตข้อมูลผิดพลาด',
          message: 'ข้อมูลอาจไม่ถูกต้อง กรุณาอัปเดตข้อมูลอีกครั้ง',
          hideCancel: true,
          handleConfirm: () => dispatch(closeDialog()),
          handleCancel: () => dispatch(closeDialog()),
        }),
      )
    }
  }, [])
  useEffect(() => {
    dispatch(
      setReportConfig({
        reportConfig:
          tab === tabList.LEARNER ? reportConfigLearner : reportConfigBackend,
      }),
    )
    dispatch(setFiltersData([]))
    dispatch(setReduxValue({ key: 'reportsData', value: [] }))
    dispatch(setReduxValue({ key: 'allCount', value: 0 }))
  }, [tab])
  return (
    <>
      <Report />
    </>
  )
}
export default Index
