import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import SelectGroupChip from '../../../../../../components/SelectGroupChip'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledColumnFlex, StyledRespRow, StyledRowFlex } from '../../Styled'
import { StyledInputBox } from './Styled'
import {
  handleAmountChange,
  handleDefaultAdmin,
  handleDelete,
  handleInputChange,
  handleMultiSelectChange,
  handleNumberInput,
} from './events'
import { isMediaWidthMoreThan } from '../../../../../../utils/lib'

const OnlineRoomForm = (props) => {
  const { onlineRoom, oIndex, dIndex } = props
  const { adminStaffOption, isDisable } = useSelector(
    (state) => ({
      adminStaffOption: state.manageClassForm.adminStaffOption,
      isDisable: state.manageClassForm.isDisable,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const defaultOnlineRoom = useMemo(
    () => handleDefaultAdmin(onlineRoom),
    [onlineRoom],
  )
  const { errors } = useFormikContext()
  const errorOnlineRoom = `monthlyPlan.dates[${dIndex}].onlineRoom`
  const errorField = `${errorOnlineRoom}[${oIndex}]`
  const errorLearner = _.get(errors, errorOnlineRoom, '')
  const errorLearnerText =
    errorLearner == 'expectNumber'
      ? 'ทุกห้องรวมกันต้องเท่ากับจำนวนที่คาดหวัง'
      : ''
  const errorLink = _.get(errors, `${errorField}.link`, '')
  const isNotSm = isMediaWidthMoreThan('sm')

  return (
    <StyledColumnFlex>
      <StyledRowFlex sx={{ justifyContent: 'space-between' }}>
        <Typography variant="body1b" color="text.secondary">
          ห้องที่ {_.get(onlineRoom, 'no', 0)}
        </Typography>
        <IconButton
          data-testid="btn-delete"
          onClick={() => dispatch(handleDelete(props))}
          disabled={isDisable}
        >
          <DeleteTwoTone />
        </IconButton>
      </StyledRowFlex>
      <StyledInputBox>
        <StyledRespRow isInput>
          <TextInput
            name="link"
            type="basic"
            required
            labelText="Link ห้องเรียน"
            placeholder="URL"
            id="input-link"
            defaultValue={_.get(onlineRoom, 'link', '')}
            textError={typeof errorLink == 'string' ? errorLink : ''}
            onChange={(e) => dispatch(handleInputChange(e, props))}
            disabled={isDisable}
          />
          <TextInput
            boxSx={{ width: isNotSm ? '30%' : '100%' }}
            name="learnerNumber"
            type="basic"
            required
            labelText="จำนวนผู้เรียนต่อห้อง"
            id="input-learnerNumber"
            inputProps={{
              type: 'number',
              onInput: handleNumberInput,
              min: 0,
            }}
            defaultValue={_.get(onlineRoom, 'learnerNumber', '')}
            textError={_.get(
              errors,
              `${errorField}.learnerNumber`,
              errorLearnerText,
            )}
            onChange={(e) => dispatch(handleAmountChange(e, props))}
            disabled={isDisable}
          />
        </StyledRespRow>
        <TextInput
          boxSx={{ mb: 3 }}
          type="basic"
          multiline
          labelText="ช่องทางติดต่อ"
          rows={2}
          maxlength={3000}
          isShowTextError={false}
          name="contact"
          id="contact"
          defaultValue={_.get(onlineRoom, 'contact', '')}
          onBlur={(e) => dispatch(handleInputChange(e, props))}
          disabled={isDisable}
        />
        <StyledRowFlex>
          <SelectGroupChip
            name="admin2"
            labelText="แอดมินรอง"
            placeholder="กรุณาเลือก"
            options={adminStaffOption}
            value={defaultOnlineRoom}
            isMenuPosition
            onChange={(list) =>
              dispatch(handleMultiSelectChange(list, 'adminStaffPlan', props))
            }
            disabled={isDisable}
          />
        </StyledRowFlex>
      </StyledInputBox>
    </StyledColumnFlex>
  )
}

export default OnlineRoomForm
