import { setChangeInformation } from '../../../../../redux/slices/manage/agentProfile'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
import callAxios from '../../../../../utils/baseService'
import _ from 'lodash'
import { convertFormatDateTime } from '../../../../../utils/lib'

export const handleSwitch = (props) => (dispatch) => {
  dispatch(
    setChangeInformation({
      key: 'isSwitch',
      value: props.isSwitch,
    }),
  )
  dispatch(fetchCourse(props))
}

export const fetchCourse =
  (url, body, header, fieldName) => async (dispatch) => {
    const loadingKey =
      fieldName === 'waitingTable.data'
        ? 'waitingTable.isLoading'
        : 'dueTable.isLoading'
    dispatch(setFieldValue({ key: loadingKey, value: true }))
    let response = await callAxios.post(url, body, header)
    const responseData = _.get(response, 'data.result', []).map(
      (row, index) => {
        return {
          ...row,
          rowIndex: index + 1,
          createdAt: convertFormatDateTime({
            value: row.createdAt,
            type: 'date',
          }),
          trainingPlanCourse: _.get(row, 'trainingPlan.label') ?? '-',
          trainingPlanDate: _.get(row, 'trainingPlan.startDate') ?? '-',
          ticketType:
            _.get(row, 'monthlyPlan') === null ? 'Other' : 'Monthly Plan',
        }
      },
    )
    response = { ...response, data: { ...response.data, result: responseData } }
    dispatch(setFieldValue({ key: fieldName, value: response?.data }))
    dispatch(setFieldValue({ key: loadingKey, value: false }))
  }
