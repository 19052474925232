import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'
import appHistory from '../../../../../appHistory'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@mui/system'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { useDispatch } from 'react-redux'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
  PRODUCT_TYPE_TRANSFER,
} from '../../../../../constants/stock'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

export function ActionComponent({ uuid, formData }) {
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const dispatch = useDispatch()

  const { productType } = useSelector((state) => state.crud.formData)
  let productPath = 'stock'
  if (
    [PRODUCT_TYPE_TRANSFER.stock, PRODUCT_TYPE_TRANSFER.sku].includes(
      productType,
    )
  ) {
    productPath = 'stock'
  } else if ([PRODUCT_TYPE_TRANSFER.asset].includes(productType)) {
    productPath = 'asset'
  } else if ([PRODUCT_TYPE_TRANSFER.license].includes(productType)) {
    productPath = 'license'
  }
  return (
    <Box display="flex" justifyContent="space-between" mt={3}>
      <Box>
        <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={3}>
        <Button
          startIcon={<RestoreTwoToneIcon />}
          variant="outlined"
          onClick={() =>
            dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: true }))
          }
        >
          ประวัติ
        </Button>
        {formData.status !== 'DELETED' && hasPermissionCreate && (
          <Button
            startIcon={<EditIcon />}
            variant="contained"
            onClick={() =>
              appHistory.push(
                '/inventory/product/' + productPath + '/form/' + uuid,
              )
            }
            data-testid="btn-edit"
          >
            แก้ไข
          </Button>
        )}
      </Box>
    </Box>
  )
}
