import { putManageClass } from 'src/services/manageClass/form'
import { store } from '../../../../App'
import _ from 'lodash'
import {
  setIsOpenManageAdminDrawer,
  startLoadingManageAdminDrawer,
  stopLoadingManageAdminDrawer,
} from 'src/redux/slices/manageClass'
import { fetchDataList } from '../Table/event'
import dayjs from 'dayjs'

export const handleUpdateManageClass = async () => {
  const { data, isDraft } = store.getState().manageClassForm
  const {
    table,
    manageClass: { station },
  } = store.getState()
  store.dispatch(startLoadingManageAdminDrawer())
  await store.dispatch(
    putManageClass({
      ...data,
      startRegisterDateTime: dayjs(data.startRegisterDateTime).isValid()
        ? dayjs(data.startRegisterDateTime).format('DD/MM/YYYY HH:mm')
        : '',
      endRegisterDateTime: dayjs(data.endRegisterDateTime).isValid()
        ? dayjs(data.endRegisterDateTime).format('DD/MM/YYYY HH:mm')
        : '',
      isDraft: isDraft,
      monthlyPlan: {
        ...data.monthlyPlan,
        dates: _.map(_.get(data, 'monthlyPlan.dates', []), (date) => ({
          ...date,
          onlineRoom: _.get(date, 'onlineRoom', []).map((online) => ({
            ...online,
            learnerNumber:
              online.learnerNumber === '' ? 0 : online.learnerNumber,
          })),
        })),
      },
    }),
  )
  store.dispatch(stopLoadingManageAdminDrawer())

  store.dispatch(setIsOpenManageAdminDrawer(false))
  store.dispatch(fetchDataList(table, station))
}
