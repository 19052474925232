import _ from 'lodash'
import { tabTypeEnum } from '../enum/requisitionTypeEnum'
import { store } from '../../../../../App'
import { setFieldUploadValue } from '../../../../../redux/slices/uploadFile'
import { uniqId } from '../../../../../services/util'

export const getBodyMutation = (data) => {
  const monthlyPlan = _.get(data, 'monthlyPlan', '')
  const trainingPlan = _.get(data, 'trainingPlan', '')
  const responsibility = _.get(data, 'responsibility', '')
  const station = trainingPlan
    ? { label: trainingPlan?.label, value: trainingPlan?.value }
    : ''
  const responsibleUser = responsibility
    ? {
        label: `${responsibility?.firstNameTH} ${responsibility?.lastNameTH}`,
        value: responsibility?.uuid,
      }
    : ''
  const courseSelected = trainingPlan
    ? {
        value: trainingPlan?.value,
        label: trainingPlan?.label,
        startDate: trainingPlan?.startDate,
        finishDate: trainingPlan?.finishDate,
        monthlyPlanUuid: monthlyPlan.uuid
          ? monthlyPlan.uuid
          : trainingPlan?.monthlyPlanUuid,
      }
    : ''
  let requisitionType = _.get(data, 'requisitionType', tabTypeEnum.OTHER)
  if (data?.uuid) {
    requisitionType = monthlyPlan ? tabTypeEnum.MONTHLY : tabTypeEnum.OTHER
  }
  let assets = _.get(data, 'assets', [
    {
      label: '',
      value: '',
      assetCode: '',
      assetCodeOption: [],
      qty: '',
      availableQty: '',
      totalQty: '',
      status: '',
    },
  ])
  if (_.get(data, 'uuid', undefined)) {
    assets = _.defaultTo(assets, []).map((item) => {
      return {
        ...item,
        availableQty:
          Number(_.get(item, 'availableQty', 0)) +
          Number(_.get(item, 'qty', 0)), //return qty if edit
      }
    })
  }
  let attachFiles = []
  _.get(data, 'attachFiles', []).map((item, index) => {
    attachFiles.push({
      ...item,
      displayName: item.fileName,
      percent: 100,
      _id: uniqId(),
      id: index,
    })
  })
  store.dispatch(
    setFieldUploadValue({
      key: `formData.attachFiles`,
      value: { files: attachFiles, isUploading: false },
    }),
  )
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    requisitionType: requisitionType,
    trainingDate: trainingPlan?.startDate ?? '',
    station: station,
    responsibleUser: responsibleUser,
    warehouse: _.get(data, 'warehouse', ''),
    annotation: _.get(data, 'remark', ''),
    status: _.get(data, 'status', ''),
    assets: assets,
    trainingPlan: _.get(data, 'trainingPlan', ''),
    monthlyPlan: monthlyPlan,
    responsibility: _.get(data, 'responsibility', ''),
    courseSelected: courseSelected,
    attachFiles: attachFiles,
  }
}
