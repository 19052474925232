// import { validatePermission } from '../../../../../utils/lib'

export const headerCells = () => {
  //const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'MASTER_DATA',
  //   permission: ['DELETE'],
  // })

  return [
    {
      id: 'index',
      label: 'ลำดับ',
      disablePadding: false,
      width: '10px',
    },
    {
      id: 'id',
      label: 'Ticket ID',
      disablePadding: false,
    },
    {
      id: 'ticketType',
      label: 'ประเภท Ticket',
      disablePadding: false,
    },
    {
      id: 'trainingPlanCourse',
      label: 'ชื่อหลักสูตร',
      disablePadding: false,
    },
    {
      id: 'trainingPlanDate',
      label: 'วันที่อบรม',
      disablePadding: false,
    },
    {
      id: 'createdByName',
      label: 'สร้างโดย',
      disablePadding: false,
    },
    {
      id: 'createdAt',
      label: 'วันที่สร้าง',
      disablePadding: false,
    },
    {
      id: 'requisitionStatus',
      label: 'สถานะ',
      disablePadding: false,
    },
    {
      id: 'returnDate',
      label: 'กำหนดคืน',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: '',
      width: '90px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: `/inventory/requisition/view`,
    },
  ]
}
