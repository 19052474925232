import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const CustomCarousel = (props) => {
  const {
    children,
    ariaLabel,
    selectedItem = 0,
    infiniteLoop = true,
    autoPlay = true,
    swipeable = true,
    emulateTouch = true,
    showArrows = false,
    showStatus = false,
    showThumbs = false,
    stopOnHover = false,
    interval = 3000,
    swipeScroll = 50,
    renderIndicator,
    renderArrowNext,
    renderArrowPrev,
    onChange,
    sx,
    showIndicators = true,
  } = props
  return (
    <Carousel
      selectedItem={selectedItem}
      ariaLabel={ariaLabel}
      infiniteLoop={infiniteLoop}
      autoPlay={autoPlay}
      swipeable={swipeable}
      emulateTouch={emulateTouch}
      showArrows={showArrows}
      showStatus={showStatus}
      showThumbs={showThumbs}
      stopOnHover={stopOnHover}
      interval={interval}
      swipeScrollTolerance={swipeScroll}
      preventMovementUntilSwipeScrollTolerance
      renderIndicator={renderIndicator}
      onChange={onChange}
      renderArrowNext={renderArrowNext}
      renderArrowPrev={renderArrowPrev}
      sx={sx}
      showIndicators={showIndicators}
    >
      {children}
    </Carousel>
  )
}

export default CustomCarousel
