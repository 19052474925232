import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportExportByLearnerConst = {
  COLUMN_NAME: {
    NO: 'no',
    FULL_AGENT_CODE: 'fullAgentCode',
    AGENT_CODE: 'agentCode',
    TITLE_NAME_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    TITLE_NAME_EN: 'prefix',
    FIRST_NAME_EN: 'firstName',
    LAST_NAME_EN: 'lastName',
    LICENSE_NO: 'licenseNo',
    REG_RENEWAL_DATE: 'regRenewalDate',
    ID_CARD: 'idCard',
    UNIT_CODE: 'unitCode',
    UNIT_NAME: 'unitName',
    RAM_CODE: 'ramCode',
    RAM_NAME: 'ramName',
    SRAM: 'sRam',
    ACADEMY_ZONE: 'academyZone',
    GA: 'ga',
    STATUS_AGENT: 'statusAgent',
    POSITION: 'position',
    PHONE_NUMBER: 'mobileNo',
    EMAIL: 'email',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
    TRAININGPLATFORM: 'trainingPlatform',
    RESULT: 'testResult',
    HOUR: 'hours',
    MINUTE: 'minutes',
    STATUS_CLASS: 'statusClass',
    START_CLASS_DATETIME: 'startClassDateTime',
    END_CLASS_DATETIME: 'endClassDateTime',
    CLOSED_CLASS_DATETIME: 'closeClassDateTime',
    DIFF_DATETIME: 'diffClassDateTime',
    STATION: 'station',
    TRAINER_ID_1: 'trainerId1',
    TRAINER_ID_2: 'trainerId2',
    TRAINER_ID_3: 'trainerId3',
    TRAINER_ID_4: 'trainerId4',
    TRAINER_ID_5: 'trainerId5',
    TRAINER_ID_6: 'trainerId6',
    TRAINER_ID_7: 'trainerId7',
    TRAINER_ID_8: 'trainerId8',
    TRAINER_ID_9: 'trainerId9',
    TRAINER_ID_10: 'trainerId10',
    TRAINER_ID_11: 'trainerId11',
    TRAINER_ID_12: 'trainerId12',
    TRAINER_ID_13: 'trainerId13',
    TRAINER_ID_14: 'trainerId14',
    TRAINER_ID_15: 'trainerId15',
    TRAINER_ID_16: 'trainerId16',
    TRAINER_ID_17: 'trainerId17',
    TRAINER_ID_18: 'trainerId18',
    TRAINER_ID_19: 'trainerId19',
    TRAINER_ID_20: 'trainerId20',
    ROLE_TRAINER_ID_1: 'trainerRole1',
    ROLE_TRAINER_ID_2: 'trainerRole2',
    ROLE_TRAINER_ID_3: 'trainerRole3',
    ROLE_TRAINER_ID_4: 'trainerRole4',
    ROLE_TRAINER_ID_5: 'trainerRole5',
    ROLE_TRAINER_ID_6: 'trainerRole6',
    ROLE_TRAINER_ID_7: 'trainerRole7',
    ROLE_TRAINER_ID_8: 'trainerRole8',
    ROLE_TRAINER_ID_9: 'trainerRole9',
    ROLE_TRAINER_ID_10: 'trainerRole10',
    ROLE_TRAINER_ID_11: 'trainerRole11',
    ROLE_TRAINER_ID_12: 'trainerRole12',
    ROLE_TRAINER_ID_13: 'trainerRole13',
    ROLE_TRAINER_ID_14: 'trainerRole14',
    ROLE_TRAINER_ID_15: 'trainerRole15',
    ROLE_TRAINER_ID_16: 'trainerRole16',
    ROLE_TRAINER_ID_17: 'trainerRole17',
    ROLE_TRAINER_ID_18: 'trainerRole18',
    ROLE_TRAINER_ID_19: 'trainerRole19',
    ROLE_TRAINER_ID_20: 'trainerRole20',
    TRAINER_NAME_1: 'trainerName1',
    TRAINER_NAME_2: 'trainerName2',
    TRAINER_NAME_3: 'trainerName3',
    TRAINER_NAME_4: 'trainerName4',
    TRAINER_NAME_5: 'trainerName5',
    TRAINER_NAME_6: 'trainerName6',
    TRAINER_NAME_7: 'trainerName7',
    TRAINER_NAME_8: 'trainerName8',
    TRAINER_NAME_9: 'trainerName9',
    TRAINER_NAME_10: 'trainerName10',
    TRAINER_NAME_11: 'trainerName11',
    TRAINER_NAME_12: 'trainerName12',
    TRAINER_NAME_13: 'trainerName13',
    TRAINER_NAME_14: 'trainerName14',
    TRAINER_NAME_15: 'trainerName15',
    TRAINER_NAME_16: 'trainerName16',
    TRAINER_NAME_17: 'trainerName17',
    TRAINER_NAME_18: 'trainerName18',
    TRAINER_NAME_19: 'trainerName19',
    TRAINER_NAME_20: 'trainerName20',
    STATUS_LEARNER: 'statusClassLeaner',
    MANAGER_AGENT_CODE: 'managerAgentCode',
    DISTRIBUTION: 'distribution',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    REQUIRED_DOCUMENT: 'requiredDocument',
    ACQUIRED_SKILL: 'acquiredSkill',
    TRAINER_STAFF_ID: 'trainerStaffID',
    TRAINER_NAME: 'trainerName',
    VERSION_NUMBER: 'courseVersion',
    REQUIRE_DOCUMENT: 'requireDocument',
    MANAGER_NAME: 'managerName',
    DEPARTMENT: 'department',
    ATTENDANCE: 'attendance',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    FULL_AGENT_CODE: 'Full Agent Code',
    AGENT_CODE: 'Agent Code',
    TITLE_NAME_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    TITLE_NAME_EN: 'Title Name EN',
    FIRST_NAME_EN: 'First Name EN',
    LAST_NAME_EN: 'Last Name EN',
    LICENSE_NO: 'License No.',
    REG_RENEWAL_DATE: 'Reg. Renewal Date',
    ID_CARD: 'ID Card',
    UNIT_CODE: 'Unit Code',
    UNIT_NAME: 'Unit Name',
    RAM_CODE: 'Ram Code',
    RAM_NAME: 'Ram Name',
    SRAM: 'SRAM',
    ACADEMY_ZONE: 'Academy Zone',
    GA: 'GA',
    STATUS_AGENT: 'Status Agent',
    POSITION: 'Position',
    PHONE_NUMBER: 'Phone Number',
    EMAIL: 'Email',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตรสำหรับ Learner',
    TRAININGPLATFORM: 'รูปแบบการสอน',
    RESULT: 'ผลอบรม',
    HOUR: 'เวลาจัดสอนทั้งหมด',
    MINUTE: 'นาที',
    STATUS_CLASS: 'สถานะคลาส',
    START_CLASS_DATETIME: 'วันเวลาเริ่มคลาส',
    END_CLASS_DATETIME: 'วันเวลาสิ้นสุดคลาส',
    CLOSED_CLASS_DATETIME: 'วันเวลาปิดคลาส',
    DIFF_DATETIME: 'วันเวลา Diff คลาส',
    STATION: 'Station',
    TRAINER_ID_1: 'Trainer ID (1)',
    TRAINER_ID_2: 'Trainer ID (2)',
    TRAINER_ID_3: 'Trainer ID (3)',
    TRAINER_ID_4: 'Trainer ID (4)',
    TRAINER_ID_5: 'Trainer ID (5)',
    TRAINER_ID_6: 'Trainer ID (6)',
    TRAINER_ID_7: 'Trainer ID (7)',
    TRAINER_ID_8: 'Trainer ID (8)',
    TRAINER_ID_9: 'Trainer ID (9)',
    TRAINER_ID_10: 'Trainer ID (10)',
    TRAINER_ID_11: 'Trainer ID (11)',
    TRAINER_ID_12: 'Trainer ID (12)',
    TRAINER_ID_13: 'Trainer ID (13)',
    TRAINER_ID_14: 'Trainer ID (14)',
    TRAINER_ID_15: 'Trainer ID (15)',
    TRAINER_ID_16: 'Trainer ID (16)',
    TRAINER_ID_17: 'Trainer ID (17)',
    TRAINER_ID_18: 'Trainer ID (18)',
    TRAINER_ID_19: 'Trainer ID (19)',
    TRAINER_ID_20: 'Trainer ID (20)',
    ROLE_TRAINER_ID_1: 'Trainer Role (1)',
    ROLE_TRAINER_ID_2: 'Trainer Role (2)',
    ROLE_TRAINER_ID_3: 'Trainer Role (3)',
    ROLE_TRAINER_ID_4: 'Trainer Role (4)',
    ROLE_TRAINER_ID_5: 'Trainer Role (5)',
    ROLE_TRAINER_ID_6: 'Trainer Role (6)',
    ROLE_TRAINER_ID_7: 'Trainer Role (7)',
    ROLE_TRAINER_ID_8: 'Trainer Role (8)',
    ROLE_TRAINER_ID_9: 'Trainer Role (9)',
    ROLE_TRAINER_ID_10: 'Trainer Role (10)',
    ROLE_TRAINER_ID_11: 'Trainer Role (11)',
    ROLE_TRAINER_ID_12: 'Trainer Role (12)',
    ROLE_TRAINER_ID_13: 'Trainer Role (13)',
    ROLE_TRAINER_ID_14: 'Trainer Role (14)',
    ROLE_TRAINER_ID_15: 'Trainer Role (15)',
    ROLE_TRAINER_ID_16: 'Trainer Role (16)',
    ROLE_TRAINER_ID_17: 'Trainer Role (17)',
    ROLE_TRAINER_ID_18: 'Trainer Role (18)',
    ROLE_TRAINER_ID_19: 'Trainer Role (19)',
    ROLE_TRAINER_ID_20: 'Trainer Role (20)',
    TRAINER_NAME_1: 'Trainer Name (1)',
    TRAINER_NAME_2: 'Trainer Name (2)',
    TRAINER_NAME_3: 'Trainer Name (3)',
    TRAINER_NAME_4: 'Trainer Name (4)',
    TRAINER_NAME_5: 'Trainer Name (5)',
    TRAINER_NAME_6: 'Trainer Name (6)',
    TRAINER_NAME_7: 'Trainer Name (7)',
    TRAINER_NAME_8: 'Trainer Name (8)',
    TRAINER_NAME_9: 'Trainer Name (9)',
    TRAINER_NAME_10: 'Trainer Name (10)',
    TRAINER_NAME_11: 'Trainer Name (11)',
    TRAINER_NAME_12: 'Trainer Name (12)',
    TRAINER_NAME_13: 'Trainer Name (13)',
    TRAINER_NAME_14: 'Trainer Name (14)',
    TRAINER_NAME_15: 'Trainer Name (15)',
    TRAINER_NAME_16: 'Trainer Name (16)',
    TRAINER_NAME_17: 'Trainer Name (17)',
    TRAINER_NAME_18: 'Trainer Name (18)',
    TRAINER_NAME_19: 'Trainer Name (19)',
    TRAINER_NAME_20: 'Trainer Name (20)',
    STATUS_LEARNER: 'สถานะของผู้เรียน',
    DISTRIBUTION: 'ช่องทางการจำหน่าย',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนชองทางการจำหน่ายอื่น',
    DEPARTMENT: 'Department',
    REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    TRAINER_STAFF_ID: 'Trainer Staff ID',
    TRAINER_NAME: 'Trainer Name',
    VERSION_NUMBER: 'เลขเวอร์ชันของหลักสูตร',
    MANAGER_NAME: 'ชื่อ Manager',
    ATTENDANCE: 'เข้าอบรม',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },

  courseLevelControlOptions: [
    { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
    { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
    { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
  ],

  approvalOptions: [
    {
      label: 'มีการอนุมัติ',
      value: true,
    },
    {
      label: 'ไม่มีการอนุมัติ',
      value: false,
    },
  ],

  isAvailableOptions: [
    {
      label: 'มี',
      value: true,
    },
    {
      label: 'ไม่มี',
      value: false,
    },
  ],

  requireOptions: [
    {
      label: 'ต้องการ',
      value: true,
    },
    {
      label: 'ไม่ต้องการ',
      value: false,
    },
  ],

  isAttendanceOptions: [
    {
      label: 'กำหนด',
      value: true,
    },
    {
      label: 'ไม่กำหนด',
      value: false,
    },
  ],

  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportExportByLearnerDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportExportByLearnerConst.COLUMN_NAME.NO,
      value: reportExportByLearnerConst.COLUMN_NAME.NO,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportExportByLearnerConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportExportByLearnerConst.COLUMN_NAME.AGENT_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.AGENT_CODE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 'fit-content',
    },
    {
      id: 4,
      name: reportExportByLearnerConst.COLUMN_NAME.TITLE_NAME_TH,
      value: reportExportByLearnerConst.COLUMN_NAME.TITLE_NAME_TH,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TITLE_NAME_TH,
      minWidth: 'fit-content',
    },
    {
      id: 5,
      name: reportExportByLearnerConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportExportByLearnerConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportExportByLearnerConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportExportByLearnerConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportExportByLearnerConst.COLUMN_NAME.TITLE_NAME_EN,
      value: reportExportByLearnerConst.COLUMN_NAME.TITLE_NAME_EN,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TITLE_NAME_EN,
      minWidth: 'fit-content',
    },
    {
      id: 73,
      name: reportExportByLearnerConst.COLUMN_NAME.FIRST_NAME_EN,
      value: reportExportByLearnerConst.COLUMN_NAME.FIRST_NAME_EN,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.FIRST_NAME_EN,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportExportByLearnerConst.COLUMN_NAME.LAST_NAME_EN,
      value: reportExportByLearnerConst.COLUMN_NAME.LAST_NAME_EN,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.LAST_NAME_EN,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportExportByLearnerConst.COLUMN_NAME.LICENSE_NO,
      value: reportExportByLearnerConst.COLUMN_NAME.LICENSE_NO,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 'fit-content',
    },
    {
      id: 10,
      name: reportExportByLearnerConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportExportByLearnerConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 'fit-content',
    },
    {
      id: 11,
      name: reportExportByLearnerConst.COLUMN_NAME.ID_CARD,
      value: reportExportByLearnerConst.COLUMN_NAME.ID_CARD,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ID_CARD,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportExportByLearnerConst.COLUMN_NAME.UNIT_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.UNIT_CODE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 'fit-content',
    },
    {
      id: 13,
      name: reportExportByLearnerConst.COLUMN_NAME.UNIT_NAME,
      value: reportExportByLearnerConst.COLUMN_NAME.UNIT_NAME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportExportByLearnerConst.COLUMN_NAME.RAM_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.RAM_CODE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 'fit-content',
    },
    {
      id: 15,
      name: reportExportByLearnerConst.COLUMN_NAME.RAM_NAME,
      value: reportExportByLearnerConst.COLUMN_NAME.RAM_NAME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 'fit-content',
    },
    {
      id: 16,
      name: reportExportByLearnerConst.COLUMN_NAME.SRAM,
      value: reportExportByLearnerConst.COLUMN_NAME.SRAM,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.SRAM,
      minWidth: 'fit-content',
    },
    {
      id: 18,
      name: reportExportByLearnerConst.COLUMN_NAME.GA,
      value: reportExportByLearnerConst.COLUMN_NAME.GA,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.GA,
      minWidth: 'fit-content',
    },
    {
      id: 19,
      name: reportExportByLearnerConst.COLUMN_NAME.STATUS_AGENT,
      value: reportExportByLearnerConst.COLUMN_NAME.STATUS_AGENT,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.STATUS_AGENT,
      minWidth: 'fit-content',
    },
    {
      id: 20,
      name: reportExportByLearnerConst.COLUMN_NAME.POSITION,
      value: reportExportByLearnerConst.COLUMN_NAME.POSITION,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.POSITION,
      minWidth: 'fit-content',
    },
    {
      id: 21,
      name: reportExportByLearnerConst.COLUMN_NAME.PHONE_NUMBER,
      value: reportExportByLearnerConst.COLUMN_NAME.PHONE_NUMBER,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.PHONE_NUMBER,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportExportByLearnerConst.COLUMN_NAME.EMAIL,
      value: reportExportByLearnerConst.COLUMN_NAME.EMAIL,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportExportByLearnerConst.COLUMN_NAME.ACADEMY_ZONE,
      value: reportExportByLearnerConst.COLUMN_NAME.ACADEMY_ZONE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ACADEMY_ZONE,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_CODE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
    },
    {
      id: 74,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
    },
    {
      id: 100,
      name: reportExportByLearnerConst.COLUMN_NAME.ATTENDANCE,
      value: reportExportByLearnerConst.COLUMN_NAME.ATTENDANCE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ATTENDANCE,
      minWidth: 'fit-content',
    },
    {
      id: 26,
      name: reportExportByLearnerConst.COLUMN_NAME.RESULT,
      value: reportExportByLearnerConst.COLUMN_NAME.RESULT,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.RESULT,
      minWidth: 'fit-content',
    },
    {
      id: 75,
      name: reportExportByLearnerConst.COLUMN_NAME.HOUR,
      value: reportExportByLearnerConst.COLUMN_NAME.HOUR,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.HOUR,
      minWidth: 160,
    },
    {
      id: 77,
      name: reportExportByLearnerConst.COLUMN_NAME.STATUS_CLASS,
      value: reportExportByLearnerConst.COLUMN_NAME.STATUS_CLASS,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.STATUS_CLASS,
      minWidth: 160,
    },
    {
      id: 78,
      name: reportExportByLearnerConst.COLUMN_NAME.START_CLASS_DATETIME,
      value: reportExportByLearnerConst.COLUMN_NAME.START_CLASS_DATETIME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.START_CLASS_DATETIME,
      minWidth: 160,
    },
    {
      id: 79,
      name: reportExportByLearnerConst.COLUMN_NAME.END_CLASS_DATETIME,
      value: reportExportByLearnerConst.COLUMN_NAME.END_CLASS_DATETIME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.END_CLASS_DATETIME,
      minWidth: 160,
    },
    {
      id: 80,
      name: reportExportByLearnerConst.COLUMN_NAME.CLOSED_CLASS_DATETIME,
      value: reportExportByLearnerConst.COLUMN_NAME.CLOSED_CLASS_DATETIME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.CLOSED_CLASS_DATETIME,
      minWidth: 160,
    },
    {
      id: 81,
      name: reportExportByLearnerConst.COLUMN_NAME.DIFF_DATETIME,
      value: reportExportByLearnerConst.COLUMN_NAME.DIFF_DATETIME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.DIFF_DATETIME,
      minWidth: 160,
    },
    {
      id: 82,
      name: reportExportByLearnerConst.COLUMN_NAME.STATION,
      value: reportExportByLearnerConst.COLUMN_NAME.STATION,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.STATION,
      minWidth: 100,
    },
    {
      id: 28,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_1,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_1,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_1,
      minWidth: 'fit-content',
    },
    {
      id: 29,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_2,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_2,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_2,
      minWidth: 'fit-content',
    },
    {
      id: 30,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_3,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_3,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_3,
      minWidth: 'fit-content',
    },
    {
      id: 31,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_4,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_4,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_4,
      minWidth: 'fit-content',
    },
    {
      id: 32,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_5,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_5,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_5,
      minWidth: 'fit-content',
    },
    {
      id: 33,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_6,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_6,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_6,
      minWidth: 'fit-content',
    },
    {
      id: 34,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_7,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_7,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_7,
      minWidth: 'fit-content',
    },
    {
      id: 35,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_8,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_8,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_8,
      minWidth: 'fit-content',
    },
    {
      id: 36,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_9,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_9,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_9,
      minWidth: 'fit-content',
    },
    {
      id: 37,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_10,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_10,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_10,
      minWidth: 'fit-content',
    },
    {
      id: 38,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_11,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_11,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_11,
      minWidth: 'fit-content',
    },
    {
      id: 39,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_12,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_12,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_12,
      minWidth: 'fit-content',
    },
    {
      id: 40,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_13,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_13,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_13,
      minWidth: 'fit-content',
    },
    {
      id: 41,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_14,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_14,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_14,
      minWidth: 'fit-content',
    },
    {
      id: 42,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_15,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_15,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_15,
      minWidth: 'fit-content',
    },
    {
      id: 43,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_16,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_16,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_16,
      minWidth: 'fit-content',
    },
    {
      id: 44,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_17,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_17,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_17,
      minWidth: 'fit-content',
    },
    {
      id: 45,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_18,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_18,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_18,
      minWidth: 'fit-content',
    },
    {
      id: 46,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_19,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_19,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_19,
      minWidth: 'fit-content',
    },
    {
      id: 47,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_20,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_ID_20,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_ID_20,
      minWidth: 'fit-content',
    },

    {
      id: 68,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_1,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_1,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_1,
      minWidth: 160,
    },
    {
      id: 69,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_2,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_2,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_2,
      minWidth: 160,
    },
    {
      id: 70,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_3,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_3,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_3,
      minWidth: 160,
    },
    {
      id: 71,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_4,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_4,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_4,
      minWidth: 160,
    },
    {
      id: 72,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_5,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_5,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_5,
      minWidth: 160,
    },
    {
      id: 83,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_6,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_6,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_6,
      minWidth: 160,
    },
    {
      id: 84,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_7,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_7,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_7,
      minWidth: 160,
    },
    {
      id: 85,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_8,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_8,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_8,
      minWidth: 160,
    },
    {
      id: 86,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_9,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_9,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_9,
      minWidth: 160,
    },
    {
      id: 87,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_10,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_10,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_10,
      minWidth: 160,
    },
    {
      id: 88,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_11,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_11,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_11,
      minWidth: 160,
    },
    {
      id: 89,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_12,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_12,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_12,
      minWidth: 160,
    },
    {
      id: 90,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_13,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_13,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_13,
      minWidth: 160,
    },
    {
      id: 91,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_14,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_14,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_14,
      minWidth: 160,
    },
    {
      id: 92,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_15,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_15,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_15,
      minWidth: 160,
    },
    {
      id: 93,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_16,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_16,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_16,
      minWidth: 160,
    },
    {
      id: 94,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_17,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_17,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_17,
      minWidth: 160,
    },
    {
      id: 95,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_18,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_18,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_18,
      minWidth: 160,
    },
    {
      id: 96,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_19,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_19,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_19,
      minWidth: 160,
    },
    {
      id: 97,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_20,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME_20,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.TRAINER_NAME_20,
      minWidth: 160,
    },
    {
      id: 48,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_1,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_1,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_1,
      minWidth: 160,
    },
    {
      id: 49,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_2,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_2,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_2,
      minWidth: 160,
    },
    {
      id: 50,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_3,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_3,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_3,
      minWidth: 160,
    },
    {
      id: 51,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_4,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_4,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_4,
      minWidth: 160,
    },
    {
      id: 52,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_5,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_5,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_5,
      minWidth: 160,
    },
    {
      id: 53,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_6,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_6,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_6,
      minWidth: 160,
    },
    {
      id: 54,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_7,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_7,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_7,
      minWidth: 160,
    },
    {
      id: 55,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_8,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_8,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_8,
      minWidth: 160,
    },
    {
      id: 56,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_9,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_9,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_9,
      minWidth: 160,
    },
    {
      id: 57,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_10,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_10,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_10,
      minWidth: 160,
    },
    {
      id: 58,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_11,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_11,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_11,
      minWidth: 160,
    },
    {
      id: 59,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_12,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_12,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_12,
      minWidth: 160,
    },
    {
      id: 60,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_13,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_13,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_13,
      minWidth: 160,
    },
    {
      id: 61,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_14,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_14,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_14,
      minWidth: 160,
    },
    {
      id: 62,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_15,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_15,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_15,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_16,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_16,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_16,
      minWidth: 160,
    },
    {
      id: 64,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_17,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_17,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_17,
      minWidth: 160,
    },
    {
      id: 65,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_18,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_18,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_18,
      minWidth: 160,
    },
    {
      id: 66,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_19,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_19,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_19,
      minWidth: 160,
    },
    {
      id: 67,
      name: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_20,
      value: reportExportByLearnerConst.COLUMN_NAME.ROLE_TRAINER_ID_20,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.ROLE_TRAINER_ID_20,
      minWidth: 160,
    },
    {
      id: 98,
      name: reportExportByLearnerConst.COLUMN_NAME.STATUS_LEARNER,
      value: reportExportByLearnerConst.COLUMN_NAME.STATUS_LEARNER,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.STATUS_LEARNER,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_CODE,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 20,
      align: 'center',
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value: reportExportByLearnerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAININGPLATFORM,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAININGPLATFORM,
      label: 'รูปแบบการสอน',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.TRAININGPLATFORM,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 7,
      name: reportExportByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      value: reportExportByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      label: 'ช่องทางการจำหน่าย',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 9,
      name: reportExportByLearnerConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      value: reportExportByLearnerConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      label: 'ชื่อย่อของช่องทางการจำหน่าย',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportExportByLearnerConst.COLUMN_NAME.ROADMAP,
      value: reportExportByLearnerConst.COLUMN_NAME.ROADMAP,
      label: 'กลุ่มหลักสูตร',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.ROADMAP,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportExportByLearnerConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      value: reportExportByLearnerConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      label: 'ชื่อย่อของกลุ่มหลักสูตร',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.ROADMAP,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportExportByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      value: reportExportByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      label: 'ระดับของผู้เรียน',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 13,
      name: reportExportByLearnerConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      value: reportExportByLearnerConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      label: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 14,
      name: reportExportByLearnerConst.COLUMN_NAME.ACQUIRED_SKILL,
      value: reportExportByLearnerConst.COLUMN_NAME.ACQUIRED_SKILL,
      label: 'ระดับหลักสูตร',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.ACQUIRED_SKILL,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 15,
      name: reportExportByLearnerConst.COLUMN_NAME.REQUIRE_DOCUMENT,
      value: reportExportByLearnerConst.COLUMN_NAME.REQUIRE_DOCUMENT,
      label: 'เอกสารการสมัคร',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportExportByLearnerConst.requireOptions,
    },
    {
      id: 16,
      name: reportExportByLearnerConst.COLUMN_NAME.MANAGER_NAME,
      value: reportExportByLearnerConst.COLUMN_NAME.MANAGER_NAME,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.MANAGER_NAME,
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.POSITION,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 22,
      name: reportExportByLearnerConst.COLUMN_NAME.STATION,
      value: reportExportByLearnerConst.COLUMN_NAME.STATION,
      label: reportExportByLearnerConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 17,
      name: reportExportByLearnerConst.COLUMN_NAME.DEPARTMENT,
      value: reportExportByLearnerConst.COLUMN_NAME.DEPARTMENT,
      label: 'Department',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.DEPARTMENT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_STAFF_ID,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_STAFF_ID,
      label: 'Trainer Staff ID',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.TRAINER_STAFF_ID,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      value: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      label: 'Trainer Name',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportExportByLearnerConst.COLUMN_NAME.TRAINER_NAME,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportExportByLearnerConst.COLUMN_NAME.STATUS_CLASS,
      value: reportExportByLearnerConst.COLUMN_NAME.STATUS_CLASS,
      label: 'Status Class',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportExportByLearnerConst.statusOptions,
    },
    {
      id: 21,
      name: reportExportByLearnerConst.COLUMN_NAME.VERSION_NUMBER,
      value: reportExportByLearnerConst.COLUMN_NAME.VERSION_NUMBER,
      label: 'เลขเวอร์ชันของหลักสูตร',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 22,
      name: reportExportByLearnerConst.COLUMN_NAME.START_CLASS_DATETIME,
      value: reportExportByLearnerConst.COLUMN_NAME.START_CLASS_DATETIME,
      label: 'วันเริ่มต้นคลาส',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 23,
      name: reportExportByLearnerConst.COLUMN_NAME.END_CLASS_DATETIME,
      value: reportExportByLearnerConst.COLUMN_NAME.END_CLASS_DATETIME,
      label: 'วันสิ้นสุดคลาส',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 24,
      name: reportExportByLearnerConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      value: reportExportByLearnerConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      label: 'Agent Code ของ Manager',
      minWidth: 160,
      type: reportExportByLearnerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}
