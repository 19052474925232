import React from 'react'
import { putManageClass } from '../../../services/manageClass/form'
import { loadingDialog, openDialog } from '../../../redux/slices/dialog'
import ConfirmDialog from './ConfirmDialog'
import {
  schema,
  schemaClosed,
  schemaActivitySupport,
  schemaOnline,
} from './schema'
import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { replaceAll } from '../../../utils/lib'
import { setReduxValue } from 'src/redux/slices/manageClassForm'

dayjs.extend(customParseFormat)
export const getSchema = (manageClassData, expectNumberPeople) => {
  const workType = _.get(manageClassData, 'monthlyPlan.workType.workType', '')
  const classType = _.get(
    manageClassData,
    'monthlyPlan.classroom.classroom',
    'Classroom',
  )
  if (workType === 'Activity Support') {
    return schemaActivitySupport(expectNumberPeople)
  } else if (manageClassData.status === 'CLOSED') {
    return schemaClosed(expectNumberPeople)
  } else {
    if (classType === 'Online') {
      return schemaOnline(classType, expectNumberPeople)
    } else {
      return schema(classType, expectNumberPeople)
    }
  }
}
export const handleSubmit = (manageClassData, isFirstOrDraft) => (dispatch) => {
  const isDraft = _.get(manageClassData, 'isDraft', true)
  const mapObj = {
    https: 'AMS_HTTPS',
    http: 'AMS_HTTP',
  }
  if (isFirstOrDraft) {
    const title = isDraft ? 'ยืนยันบันทึกฉบับร่าง' : 'ยืนยันบันทึกและเผยแพร่'
    const message = isDraft
      ? 'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่'
      : 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่'
    const monthlyPlan = _.get(manageClassData, 'monthlyPlan', {})

    let payload = {
      ...manageClassData,
      monthlyPlan: {
        ...monthlyPlan,
        dates: _.get(monthlyPlan, 'dates', []).map((date) => ({
          ...date,
          onlineRoom: _.get(date, 'onlineRoom', []).map((online) => ({
            ...online,
            learnerNumber:
              online.learnerNumber === '' ? 0 : online.learnerNumber,
          })),
        })),
      },
      detail: replaceAll(String(manageClassData?.detail ?? ''), mapObj),
    }

    if (!_.isEmpty(manageClassData?.roomManagement)) {
      payload['roomManagement'] = {
        ...manageClassData?.roomManagement,
        trainingPlan: null,
      }
    }
    dispatch(
      openDialog({
        title,
        message,
        handleConfirm: async () => {
          dispatch(loadingDialog())
          await dispatch(putManageClass(payload))
          dispatch(setReduxValue({ key: 'isSuccess', value: true }))
        },
      }),
    )
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกและเผยแพร่',
        type: 'content',
        content: <ConfirmDialog />,
        dataField: 'manageClassForm.data',
        handleConfirm: async (manageClassData) => {
          dispatch(loadingDialog())

          let payload = {
            ...manageClassData,
            isDraft,
            detail: replaceAll(String(manageClassData?.detail ?? ''), mapObj),
          }

          if (!_.isEmpty(manageClassData?.roomManagement)) {
            payload['roomManagement'] = {
              ...manageClassData?.roomManagement,
              trainingPlan: null,
            }
          }

          await dispatch(putManageClass(payload))
          dispatch(setReduxValue({ key: 'isSuccess', value: true }))
        },
      }),
    )
  }
}
export const checkEndRegisterDateTime = (value, parent) => {
  const valueStartDate = _.get(parent, 'startRegisterDateTime', '')
  const startDateMP = _.get(parent, 'monthlyPlan.startDate', '')
  if (_.isEmpty(value) || _.isEmpty(valueStartDate)) return false

  const now = dayjs().subtract(10, 'year').startOf('year')

  const startDateRegister = dayjs(valueStartDate, 'DD/MM/YYYY')
  const endDateRegister = dayjs(value, 'DD/MM/YYYY')
  const startMP = dayjs(startDateMP)

  const startDateMs = startDateRegister.diff(now, 'ms')
  const endDateMs = endDateRegister.diff(now, 'ms')
  const startMPMs = startMP.diff(now, 'ms')

  //วันปิดรับสมัคร ห้ามมากกว่า วันที่จัดอบรบ
  if (endDateMs > startMPMs) return false
  //วันปิดรับสมัคร ห้ามมากกว่า วันเปิดรับสมัคร
  return startDateMs <= endDateMs
}

export const checkStartRegisterDateTime = (value, parent) => {
  const valueEndDate = _.get(parent, 'endRegisterDateTime', '')
  const startDateMP = _.get(parent, 'monthlyPlan.startDate', '')
  if (_.isEmpty(value) || _.isEmpty(valueEndDate)) return false

  const now = dayjs().subtract(10, 'year').startOf('year')

  const startDateRegister = dayjs(value, 'DD/MM/YYYY')
  const endDateRegister = dayjs(valueEndDate, 'DD/MM/YYYY')
  const startMP = dayjs(startDateMP)

  const startDateMs = startDateRegister.diff(now, 'ms')
  const endDateMs = endDateRegister.diff(now, 'ms')
  const startMPMs = startMP.diff(now, 'ms')

  //วันเปิดรับสมัคร ห้ามมากกว่าหรือเท่ากับ วันที่จัดอบรม
  if (startDateMs >= startMPMs) return false
  //วันเปิดรับสมัคร ห้ามมากกว่า วันปิดรับสมัคร
  return startDateMs <= endDateMs
}
