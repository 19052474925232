import {
  setInitialTable,
  startLoading,
  stopLoading,
  clearFilter,
  resetPage,
  resetTablePage,
  setSearchText,
  setStatusFilter,
  setSelected,
  setDefaultSort,
  setOrder,
  setHandleChange,
} from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import { headerCells } from '../model/headerCells'
import { crudConfig } from '../../crudConfig'
import { convertFormatDateTime } from '../../../../../utils/lib'
import _ from 'lodash'
import { ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import callAxios from '../../../../../utils/baseService'
import { handleQuickSearch } from '../../../../../components/CRUD/handler/handleQuickSearch'
import { onDownload } from '../../../../../components/CRUD/handler/onDownload'
import { handleDeleteClick } from '../../../../../components/CRUD/handler/handleDeleteClick'
import { tableTypeEnum } from '../enum/tableTypeEnum'
import { tabTypeEnum } from '../enum/tabTypeEnum'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { defaultFilter } from '../model/defaultFilter'

export const handleChangeHeadCells = async (tabActive, tabTypeActive) => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'INVENTORY_PRODUCT',
  //   permission: ['DELETE'],
  // })
  const urlParams = new URLSearchParams(window.location.search)
  const createdByName = urlParams.get('createdBy')
  resetTable(createdByName)
  const { table, search, filter: filterState } = store.getState().table
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  let headCells = await headerCells()
  let transferBy = [tableTypeEnum.ALL, tableTypeEnum.APPROVE]
  switch (tabActive) {
    case 0:
      transferBy = [tableTypeEnum.ALL]
      break
    case 1:
      transferBy = [tableTypeEnum.APPROVE]
      break
  }

  let requisitionType = [tabTypeEnum.MONTHLY, tabTypeEnum.OTHER]
  switch (tabTypeActive) {
    case 0:
      requisitionType = [tabTypeEnum.MONTHLY]
      break
    case 1:
      requisitionType = [tabTypeEnum.OTHER]
      break
  }

  // headCells = [
  //   ...headCells,
  //   {
  //     id: 'action',
  //     disablePadding: false,
  //     label: 'คำสั่ง',
  //     width: '90px',
  //     hideSortIcon: true,
  //     viewPath: `${crudConfig.modulePath}/view`,
  //     editPath: `${crudConfig.modulePath}/form`,
  //     hideDelete: hasPermissionDelete ? false : true,
  //   },
  // ]

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    quickSearch: searchText,
    ...mutateFilterPropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
    search: '',
    status: [],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    transferBy,
    requisitionType,
  }

  store.dispatch(startLoading())
  const isCancel = await callAxios
    .post(crudConfig.filterPath, body, { 'x-type': crudConfig.moduleId })
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        categoryName: _.get(item, 'category.name', ''),
        stockCategoryName: _.get(item, 'stockCategory.name', ''),
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      store.dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCells,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) =>
            store.dispatch(
              handleQuickSearch(tableProps, text, mutateFilterPropToBody),
            ),
          onDownload: (selected, sort, order) =>
            store.dispatch(onDownload(selected, sort, order)),
          onDelete: (selected) => {
            handleDeleteClick(selected.uuid, mutateFilterPropToBody)
          },
        }),
      )

      const requisitionCount = _.get(res, 'data.requestCount', 0)
      store.dispatch(
        setFieldValue({
          key: 'requisitionCount',
          value: requisitionCount,
        }),
      )
    })
    .catch((e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: crudConfig.headerCells(),
        }),
      )
      return e.message?.includes('method')
    })
  if (!isCancel) store.dispatch(stopLoading())
}

const resetTable = (createdByName) => {
  store.dispatch(setSearchText(''))
  store.dispatch(clearFilter())
  store.dispatch(resetTablePage())
  store.dispatch(resetPage())
  store.dispatch(setStatusFilter([]))
  store.dispatch(setSelected([]))
  store.dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  store.dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  if (createdByName) {
    const newFilter = {
      ...defaultFilter,
      createdByChecked: true,
      createdBy: createdByName,
    }
    store.dispatch(
      setHandleChange({
        key: 'filter.filterProp.createdBy',
        value: createdByName,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState',
        value: newFilter,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterStateMemo',
        value: newFilter,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filter.filterTotal',
        value: 1,
      }),
    )
  }
}
