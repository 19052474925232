import dayjs from 'dayjs'

export const initialState = {
  isLoading: false,
  isOpenFilter: false,
  error: '',
  filterMemo: {},
  filter: {
    startDate: dayjs(new Date()).startOf('Month').format('YYYY-MM-DD'),
    endDate: dayjs(new Date()).format('YYYY-MM-DD'),
    course: '',
    station: '',
    inventory: '',
  },
  overview: {},
  tableDrawer: {
    isOpen: false,
    header: '',
    drawerType: '',
    courseType: '',
  },
  enrollDrawer: {
    isOpen: false,
    header: '',
    uuid: '',
  },
  waitingTable: {
    isOpen: false,
    isLoading: false,
    sort: 'updatedAt',
    order: 'DESC',
    data: {},
  },
  dueTable: {
    isOpen: false,
    isLoading: false,
    sort: 'updatedAt',
    order: 'DESC',
    data: {},
  },
}
