import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'
import { StyledButton } from '../Styled'
import { ChevronRight } from '@mui/icons-material'

const AllContent = () => {
  const { total, academy, vendor } = useSelector(
    (state) => ({
      total: state.inventoryDashboard?.overview?.inventoryStock?.total,
      academy: state.inventoryDashboard?.overview?.inventoryStock?.academy,
      vendor: state.inventoryDashboard?.overview?.inventoryStock?.vendor,
    }),
    shallowEqual,
  )

  return (
    <Card
      sx={{
        minHeight: '224px',
        width: 'fit-content',
        padding: '8px 16px 12px 16px',
        display: 'grid',
        gap: '16px',
      }}
    >
      <Row sx={{ gap: '16px' }}>
        <Typography variant="h6">คลังสินค้า</Typography>
        <StyledButton
          color="text.lightGray"
          size="small"
          marginLeft=""
          background="#4976BA14"
          onClick={() => {
            window.location = '/inventory/stock'
          }}
        >
          <ChevronRight fontSize="small" />
        </StyledButton>
      </Row>
      <Typography>ทั้งหมด</Typography>
      <Row sx={{ marginTop: '-12px' }}>
        <Typography variant="h4" mr={0.5} sx={{ lineHeight: '20px' }}>
          {total ?? 0}
        </Typography>
        <Typography
          variant="body2"
          color="text.lightGray"
          sx={{ lineHeight: '20px', paddingTop: '8px' }}
        >
          คลัง
        </Typography>
      </Row>
      <Row sx={{ gap: '24px' }}>
        <Card
          sx={{
            minHeight: '76px',
            minWidth: '158px',
            padding: '8px 16px 12px 16px',
          }}
        >
          <Typography>คลัง Academy</Typography>
          <Row>
            <Typography variant="h4" mr={0.5} sx={{ lineHeight: '20px' }}>
              {academy ?? 0}
            </Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '20px', paddingTop: '8px' }}
            >
              คลัง
            </Typography>
          </Row>
        </Card>
        <Card
          sx={{
            minHeight: '76px',
            minWidth: '158px',
            padding: '8px 16px 12px 16px',
          }}
        >
          <Typography>คลัง Vendor</Typography>
          <Row>
            <Typography variant="h4" mr={0.5} sx={{ lineHeight: '20px' }}>
              {vendor ?? 0}
            </Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '20px', paddingTop: '8px' }}
            >
              คลัง
            </Typography>
          </Row>
        </Card>
      </Row>
    </Card>
  )
}

export default AllContent
