import { useEffect, useState } from 'react'
import { StyledCard } from '../../Styled'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setSelected,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table'
import callAxios from '../../../../../../utils/baseService'
import _ from 'lodash'
import { initialState } from '../../../../../../redux/slices/table/model'
import { store } from '../../../../../../App'
import { downloadFile } from '../../../../../../services/util'
import FixedTable from '../../../components/FixedTable'
import { reportFilter } from '../../../../../../utils/apiPath'
import dayjs from 'dayjs'
import { setDetailDrawerClassCount } from '../../../handler/handleClickOpenDrawer'

const AllClassList = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { filterProp } = filter
  const { searchText } = search
  useEffect(() => {
    dispatch(
      fetchDataList('initial', table, page, filterProp, searchText, setInitial),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(fetchDataList('fetch', table, page, filterProp, searchText))
    }
  }, [limit, order, page, sort])

  return (
    <>
      <StyledCard id="topList-table" isLoading={isLoading}>
        <FixedTable />
      </StyledCard>
    </>
  )
}

export const headCells = [
  {
    id: 'no',
    label: 'ลำดับ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'courseNameEN',
    label: 'ชื่อหลักสูตร EN',
    disablePadding: false,
    minWidth: '220px',
  },
  {
    id: 'distribution',
    label: 'ช่องทางการจัดจำหน่าย',
    disablePadding: false,
  },
  {
    id: 'station',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'trainingDate',
    label: 'Training Date',
    disablePadding: false,
    minWidth: '200px',
  },
  {
    id: 'openRegisterA',
    label: 'จำนวนคนเปิดรับสมัคร (A) (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'openRegisterB',
    label: 'จำนวนคนเปิดรับสมัคร (B) (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'B_A_percent',
    label: 'B/A(%)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'attendeeC',
    label: 'เข้าอบรม (C) (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'C_B_percent',
    label: 'C/B (%)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'notAttendCount',
    label: 'ไม่เข้าอบรม (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'passCount',
    label: 'ผ่านการอบรม (คน)',
    disablePadding: false,
    isNumber: true,
    headCellBgColor: '#1CC54E80',
  },
  {
    id: 'notPassCount',
    label: 'ไม่ผ่านการอบรม (คน)',
    disablePadding: false,
    isNumber: true,
    headCellBgColor: '#C9143280',
  },
  {
    id: 'expenseSummary',
    label: 'Expense Summary by Class (บาท)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'costHead',
    label: 'Cost/Head (บาท)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '40px',
    minWidth: '40px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: ``,
  },
]

export const fetchDataList =
  (method, table, page, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    const { filtersDrawer, detailDrawer } =
      store.getState().crud.mixModuleDashboard
    dispatch(startLoading())
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const realPage = page <= 0 ? 1 : +page
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const sortBy = tableProps.sort
    const distributionSelected = detailDrawer?.distributionSelected ?? ''
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      name: method == 'filter' ? keyword : title,
      startDate:
        filtersDrawer?.dateFilterStartDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
      endDate:
        filtersDrawer?.dateFilterFinishDate ?? dayjs().format('YYYY-MM-DD'),
      ramCode: _.defaultTo(filtersDrawer?.ram, []),
      sRam: _.defaultTo(filtersDrawer?.sram, []),
      station: _.defaultTo(filtersDrawer?.station, []).map(
        (item) => item.value,
      ),
      acquiredSkill: _.defaultTo(filtersDrawer?.skill, []),
      distribution: _.defaultTo(filtersDrawer?.distribution, []),
      type: distributionSelected,
    }

    const isCancel = await callAxios
      .post(`${reportFilter}/mix-module-dashboard/all-class`, body)
      .then(({ data }) => {
        const rows = data.result.map((item) => ({
          ...item,
          no: _.get(item, 'item', '-'),
          courseNameEN: _.get(item, 'courseNameEN', '-'),
          distribution: _.get(item, 'distribution', '-'),
          station: _.get(item, 'station.station', '-'),
          trainingDate: _.get(item, 'trainingDate', '-'),
          openRegisterA: _.get(item, 'expectNumberPeople', 0),
          openRegisterB: _.get(item, 'enrollNumber', 0),
          B_A_percent: _.get(item, 'ba', 0),
          attendeeC: _.get(item, 'attendees', 0),
          C_B_percent: _.get(item, 'cb', 0),
          notAttendCount: _.get(item, 'notAttendee', 0),
          passCount: _.get(item, 'pass', 0),
          notPassCount: _.get(item, 'notPass', 0),
          expenseSummary: _.get(item, 'expenseSummary', 0),
          costHead: _.get(item, 'costHead', 0),
          viewUrl:
            item?.type == 'CLASS'
              ? `/manage-class/detail/${item?.uuid}`
              : `/e-learning/course/view/${item?.uuid}`,
        }))
        dispatch(
          setInitialTable({
            rows: rows,
            allCount: data.count,
            headCells: headCells,
            placeholder: 'ค้นหา',
            searchKey: 'courseNameEN',
            canFilter: false,
            hideFilter: true,
            isCheckBox: false,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
          }),
        )
        dispatch(setSelected(rows.map((item) => item.no)))
        setDetailDrawerClassCount(data.count)
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCells,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const { mixModuleDashboard } = store.getState().crud
  const title = `${mixModuleDashboard?.detailDrawer?.title ?? ''}`
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    list: selected,
    type: mixModuleDashboard?.detailDrawer?.type ?? '',
  }
  await dispatch(
    downloadFile({
      url: ``,
      body: body,
      fileName: `รายการ${title}.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, 1, null, text))
}

export default AllClassList
