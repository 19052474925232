import * as yup from 'yup'
import dayjs from 'dayjs'
import { assetTypeEnum } from '../enum/assetTypEnum'
import { paymentMethodEnum } from '../enum/paymentMethod'

export const formValidationSchema = yup.object({
  from: yup
    .object({
      value: yup.string().nullable().required('กรุณากรอกข้อมูลในช่องนี้'),
    })
    .nullable()
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  to: yup
    .object({
      value: yup.string().nullable().required('กรุณากรอกข้อมูลในช่องนี้'),
    })
    .nullable()
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  type: yup.string().nullable().required('กรุณาเลือกข้อมูล'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
})

export const drawerFormValidationSchema = yup.object({
  product: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
  quantity: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  unitPrice: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  stockValue: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณากรอกข้อมูลในช่องนี้'),
})

export const validateStockNotImport = yup
  .object({
    product: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
    quantity: yup
      .number()
      .typeError('ต้องเป็นตัวเลขเท่านั้น')
      .transform((value) => (isNaN(value) ? undefined : value))
      .test(
        'quantity',
        'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
        (val) => val && val.toString().length <= 20,
      )
      .nullable()
      .required('กรุณากรอกข้อมูลในช่องนี้'),
  })
  .test('quantity-available', 'Custom validation error', function (value) {
    const { quantity, productAvailable } = value
    if (quantity && quantity > productAvailable) {
      return this.createError({
        path: `quantity`,
        message: 'กรุณากรอกจำนวนให้ถูกต้อง',
      })
    }
    return true
  })

export const drawerFormValidationSchemaSKU = yup.object({
  productStockAsset: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
  skuNo: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  costCenter: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPrice: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
})

export const drawerFormValidationSchemaAsset = yup.object({
  productAsset: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
  skuNo: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  assetNo: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ Item Name'),
  costCenter: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPrice: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
  contractStartDate: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  contractEndDate: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const dayAfter = new Date(contractStartDate.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewContractDate: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const startDateDate = new Date(contractStartDate)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  holder: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
})

export const drawerFormValidationSchemaLicense = yup.object({
  productLicense: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
  skuNo: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  costCenter: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPrice: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
  contractStartDate: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  contractEndDate: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const dayAfter = new Date(contractStartDate.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewContractDate: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const startDateDate = new Date(contractStartDate)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  holder: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
  payer: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
  paymentType: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
  paymentDesc: yup
    .string()
    .nullable()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .when('paymentType', (paymentType, schema) => {
      if ([paymentMethodEnum.CREDIT_CARD].includes(paymentType?.value)) {
        return schema.required('กรุณากรอกชื่อบนบัตร')
      } else if ([paymentMethodEnum.OTHER].includes(paymentType?.value)) {
        return schema.required('กรุณากรอกรายละเอียด')
      }
      return schema.nullable()
    }),
  paymentFile: yup
    .object()
    .nullable()
    .when('paymentType', (paymentType, schema) => {
      if ([paymentMethodEnum.CASH_INVOICE].includes(paymentType?.value)) {
        return schema.required('กรุณาอัปโหลดรูปภาพ')
      }
      return schema.nullable()
    }),
})

export const drawerFormValidationSkuNotImport = yup.object({
  type: yup.string().nullable().required('กรุณาเลือกข้อมูล'),
  skuNoOption: yup.object().nullable().required('กรุณาเลือก รหัสทรัพย์สิน'),
  productStockAsset: yup
    .object()
    .nullable()
    .when('type', {
      is: assetTypeEnum.SKU,
      then: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
      otherwise: yup.object().nullable(),
    }),
  productAsset: yup
    .object()
    .nullable()
    .when('type', {
      is: assetTypeEnum.ASSET,
      then: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
      otherwise: yup.object().nullable(),
    }),
  productLicense: yup
    .object()
    .nullable()
    .when('type', {
      is: assetTypeEnum.LICENSE,
      then: yup.object().nullable().required('กรุณาระบุชื่อทรัพย์สิน'),
      otherwise: yup.object().nullable(),
    }),
})

export const rejectValidationSchema = yup.object({
  rejectTransferReason: yup
    .string()
    .nullable()
    .max(3000, 'กรุณาระบุภายใน 3,000 ตัวอักษร')
    .required('กรุณาระบุเหตุผลการปฏิเสธการโอนย้าย'),
})