import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  validatePermission,
} from '../../../../../../utils/lib'
import {
  INVENTORY_SETTING,
  PRODUCT_TYPE,
  STOCK_PERMISSION,
  STOCK_STATUS,
} from '../../../../../../constants/stock'
import { openAssetListDrawer, openHistoryDrawer, openQuantityAlertDialog, openReduceDialog } from './events'

const DropdownButton = (props) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  // const { isHistory, ticketId, assetId } = props
  const { row, isInventoryStock } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const history = useHistory()
  let menuOptions = createOptions
  let product = _.get(row, 'product', '')
  let productType = _.get(row, 'product.productType', '')
  let warehouse = _.get(row, 'warehouse', '')
  if (row?.productWarehouse) {
    productType = _.get(row, 'productWarehouse.product.productType', '')
    warehouse = _.get(row, 'productWarehouse.warehouse', '')
    product = _.get(row, 'productWarehouse.product', '')
  }

  if (productType === PRODUCT_TYPE.STOCK && !isInventoryStock) {
    menuOptions = stockOptions
  } else if (
    [
      PRODUCT_TYPE.STOCK_ASSET,
      PRODUCT_TYPE.ASSET,
      PRODUCT_TYPE.LICENSE,
    ].includes(productType) &&
    !isInventoryStock
  ) {
    menuOptions = stockSKUOptions
  }

  if (
    isInventoryStock &&
    [PRODUCT_TYPE.STOCK, PRODUCT_TYPE.STOCK_ASSET].includes(productType)
  ) {
    menuOptions = [
      ...menuOptions,
      { text: 'ปรับการแจ้งเตือนทรัพย์สินเหลือน้อย', value: 'quantity_alert' },
    ]
  }

  if (
    (warehouse &&
      [STOCK_STATUS.INACTIVE, STOCK_STATUS.DELETED].includes(
        warehouse?.status,
      )) ||
    (isInventoryStock &&
      [STOCK_STATUS.INACTIVE, STOCK_STATUS.DELETED].includes(product?.status))
  ) {
    menuOptions = menuOptions.filter(
      (item) => !['transfer', 'reduce'].includes(item.value),
    )
  }
  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {menuOptions.map((option, index) => {
          let isHide = false
          if (option.value === 'reduce' && productType !== 'STOCK') {
            isHide = true
          } else if (
            option.value === 'asset_list' &&
            ![
              PRODUCT_TYPE.STOCK_ASSET,
              PRODUCT_TYPE.ASSET,
              PRODUCT_TYPE.LICENSE,
            ].includes(productType)
          ) {
            isHide = true
          }
          let disabled = false
          if (option.value === 'asset_detail' && !hasPermission) {
            disabled = true
          }

          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                if (option.value === 'transfer') {
                  history.push(
                    `${productPath.TICKET}/?warehouse=${warehouse?.uuid}&productType=${productType}`,
                  )
                } else if (option.value === 'reduce') {
                  openReduceDialog(row)
                } else if (option.value === 'asset_list') {
                  openAssetListDrawer(row)
                } else if (option.value === 'asset_detail') {
                  history.push(`${productPath?.[productType]}/${product?.uuid}`)
                } else if (option.value === 'history') {
                  openHistoryDrawer(row)
                } else if (option.value === 'warehouse_detail') {
                  history.push(`${productPath.WAREHOUSE}/${warehouse?.uuid}`)
                } else if (option.value === 'quantity_alert') {
                  openQuantityAlertDialog(row)
                }
                setAnchorEl(null)
              }}
              sx={{ display: isHide ? 'none' : '' }}
              disabled={disabled}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = [
  { text: 'โอนย้าย', value: 'transfer' },
  { text: 'ปรับลด', value: 'reduce' },
  { text: 'รายการ รหัสทรัพย์สิน ของทรัพย์สินนี้ในคลัง', value: 'asset_list' },
  { text: 'รายละเอียดทรัพย์สิน', value: 'asset_detail' },
]

export const stockOptions = [
  { text: 'โอนย้าย', value: 'transfer' },
  { text: 'ปรับลด', value: 'reduce' },
  { text: 'ประวัติการเคลื่อนไหว', value: 'history' },
  { text: 'รายละเอียด คลังทรัพย์สิน', value: 'warehouse_detail' },
]

export const stockSKUOptions = [
  { text: 'โอนย้าย', value: 'transfer' },
  { text: 'ประวัติการเคลื่อนไหว', value: 'history' },
  { text: 'รายละเอียด คลังทรัพย์สิน', value: 'warehouse_detail' },
  { text: 'ดูรายการ รหัสทรัพย์สิน ของทรัพย์สินนี้ในคลัง', value: 'asset_list' },
]

export const productPath = {
  STOCK: '/inventory/product/stock/view',
  STOCK_ASSET: '/inventory/product/stock/view',
  ASSET: '/inventory/product/asset/view',
  LICENSE: '/inventory/product/license/view',
  WAREHOUSE: '/inventory/stock/detail',
  TICKET: '/inventory/transfer/form',
}