import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { setHandleChange } from '../../../../../redux/slices/table2'
import { handleQuickSearch } from '../TableDrawer/events'
import { store } from '../../../../../App'

const SearchTable = ({ placeholder = 'ค้นหาจากชื่อคอร์ส' }) => {
  const dispatch = useDispatch()
  const [searchTable, setSearchTable] = useState('')
  const { table } = store.getState().table2
  const { courseType, header } = store.getState().inventoryDashboard.tableDrawer

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          setHandleChange({
            key: 'search.seachText',
            value: searchTable,
          }),
        ),
      500,
    )
    return () => clearTimeout(timeOutId)
  }, [searchTable])

  return (
    <BoxSearch>
      <SearchInput
        data-testid="search-input"
        placeholder={placeholder}
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          setSearchTable(value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const value = _.get(e, 'target.value', '')
            dispatch(
              handleQuickSearch(table, value, courseType, header, {
                limit: 100,
              }),
            )
          }
        }}
        value={searchTable}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={() => {
          dispatch(
            handleQuickSearch(table, searchTable, courseType, header, {
              limit: 100,
            }),
          )
        }}
      />
    </BoxSearch>
  )
}

export default SearchTable
