import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import { getExaminationScheduleEvent } from '../../../services/eExamination/form'
import { setStudentHistoryList } from '../../../redux/slices/eExamination/list'
import {
  EXAMINER_EVENT_STATUS_TH,
  EXAMINER_EVENT_STATUS,
} from '../../../constants/eExamination'
import { convertFormatDateTime } from '../../../utils/lib'

export const handleFiltering = () => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
}

export const headCells = [
  {
    id: 'author',
    label: 'ผู้ดำเนินการ',
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    hideSortIcon: true,
  },
]

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    toDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const fetchStudentHistory = (uuid, page, events) => async (dispatch) => {
  if (uuid) {
    const body = {
      contentId: uuid,
      author: '',
      limit: _.get(events, 'limit', 100),
      order: 'DESC',
      page: 1,
      sort: 'createdAt',
      event: [
        EXAMINER_EVENT_STATUS.CREATED,
        EXAMINER_EVENT_STATUS.UPDATED,
        EXAMINER_EVENT_STATUS.CREATED_STUDENT,
        EXAMINER_EVENT_STATUS.UPLOAD_PERMISSION,
        EXAMINER_EVENT_STATUS.ANNOUNCE,
        EXAMINER_EVENT_STATUS.DELETED_STUDENT,
        EXAMINER_EVENT_STATUS.UPLOAD_RESULT,
        EXAMINER_EVENT_STATUS.CANCEL_STUDENT,
        EXAMINER_EVENT_STATUS.CANCEL_BY_ADMIN,
        EXAMINER_EVENT_STATUS.CANCEL_BY_MANAGER,
        EXAMINER_EVENT_STATUS.CANCEL_BY_SELF,
        EXAMINER_EVENT_STATUS.CANCEL_EXAMINATION,
        EXAMINER_EVENT_STATUS.UPDATED_STUDENT,
        EXAMINER_EVENT_STATUS.COMPLETED,
      ],
    }
    const response = await dispatch(getExaminationScheduleEvent(body))
    const totalCount = _.get(response, 'payload.data.totalCount', 0)
    let result = _.get(response, 'payload.data.result', [])
    result = _.map(result, (item) => {
      return {
        ...item,
        event: EXAMINER_EVENT_STATUS_TH[item.event],
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'dateTime',
        }),
      }
    })

    dispatch(
      setStudentHistoryList({
        result,
        totalCount,
      }),
    )
  }
}
