import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../../components/FilterDrawer/Styled'
import { arrayCheckbox, defaultFilter } from './model'
import * as events from './event'
import _ from 'lodash'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../utils/lib'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { useSelector } from 'react-redux'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
// import { setFieldValue } from '../../../../../redux/slices/crud'
import SelectGroupChip from '../../../../../components/SelectGroupChip'
import dayjs from 'dayjs'

export const ListCheckBox = ({
  listArray,
  stateFilter,
  setFilter,
  station,
  region,
  association,
}) => {
  return listArray.map((item, index) => (
    <StyledGroupBox key={index}>
      <FormGroup>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              inputProps={{
                'data-testid': `check-${item.nameCheckBox}`,
              }}
              name={item.nameCheckBox}
              checked={stateFilter[item.nameCheckBox]}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter: stateFilter,
                  setFilter,
                })
              }}
            />
          }
          sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
        />

        {stateFilter[item.nameCheckBox] && (
          <>
            {item.type == 'checkbox' && (
              <StyledList>
                <ListResult
                  filter={stateFilter}
                  setFilter={setFilter}
                  arrayResult={
                    item.nameInput == 'station' ? station : item.list
                  }
                  listKeys={item.nameInput}
                />
              </StyledList>
            )}

            {item.type == 'text' && (
              <InputBase
                sx={{ borderRadius: 0 }}
                type="basic"
                inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                name={item.nameInput}
                disabled={!stateFilter[item.nameCheckBox]}
                placeholder={item.placeholder}
                value={stateFilter[item.nameInput]}
                onChange={(e) => {
                  events.handleChange({
                    key: e.target.name,
                    value: e.target.value,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            )}

            {item.type == 'number' && (
              <InputBase
                sx={{ borderRadius: 0 }}
                type="basic"
                inputProps={{
                  type: 'number',
                  onInput: handleNumberInput,
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                name={item.nameInput}
                disabled={!stateFilter[item.nameCheckBox]}
                placeholder={item.placeholder}
                value={stateFilter[item.nameInput]}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                onChange={(e) => {
                  events.handleChange({
                    key: e.target.name,
                    value: e.target.value,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            )}

            {item.type === 'daterange' && (
              <DateRangeInput
                placeholder={item.placeholder}
                dateState={{
                  startDate: stateFilter.startDate || new Date(),
                  endDate: stateFilter.endDate || new Date(),
                  display: stateFilter.displayDate,
                  key: 'selection',
                }}
                onChange={(selectedDates) => {
                  events.handleSelectDate(selectedDates, stateFilter, setFilter)
                }}
              />
            )}

            {item.type === 'DROPDOWN_MULTI' && (
              <SelectGroupChip
                boxSx={{ mb: 3 }}
                id={item.nameInput}
                name={item.nameInput}
                labelText={''}
                placeholder="กรุณาเลือก"
                options={
                  item.nameInput == 'station'
                    ? station
                    : item.nameInput == 'region'
                    ? region
                    : item.nameInput == 'association'
                    ? association
                    : item.list
                }
                value={stateFilter[`${item.nameInput}List`]}
                onChange={(list) => {
                  events.handleSelectMultiple(
                    list,
                    stateFilter,
                    setFilter,
                    item.nameInput,
                  )
                }}
                // textError={error}
              />
            )}

            {item.type === 'REGION_PROVINCE' && (
              <>
                <FormGroup>
                  <FormControlLabel
                    label={'ภาค'}
                    control={
                      <Checkbox
                        inputProps={{
                          'data-testid': `check-${item.nameRegCheckBox}`,
                        }}
                        name={item.nameRegCheckBox}
                        checked={stateFilter[item.nameRegCheckBox]}
                        onChange={(e) => {
                          events.handleChange({
                            value: e.target.checked,
                            key: e.target.name,
                            filter: stateFilter,
                            setFilter,
                          })
                        }}
                        sx={{ ml: '16px' }}
                      />
                    }
                    sx={
                      !stateFilter[item.nameRegCheckBox]
                        ? { marginBottom: -1 }
                        : {}
                    }
                  />

                  {stateFilter[item.nameRegCheckBox] && (
                    <SelectGroupChip
                      boxSx={{ mb: 3, px: 2 }}
                      id={item.nameInputReg}
                      name={item.nameInputReg}
                      labelText={''}
                      placeholder="กรุณาเลือก"
                      options={region ?? item.list}
                      value={stateFilter[`${item.nameInputReg}List`]}
                      onChange={(list) => {
                        events.handleSelectMultiple(
                          list,
                          stateFilter,
                          setFilter,
                          item.nameInputReg,
                        )
                      }}
                    />
                  )}
                  <FormControlLabel
                    label={'จังหวัด'}
                    control={
                      <Checkbox
                        inputProps={{
                          'data-testid': `check-${item.nameProvCheckBox}`,
                        }}
                        name={item.nameProvCheckBox}
                        checked={stateFilter[item.nameProvCheckBox]}
                        onChange={(e) => {
                          events.handleChange({
                            value: e.target.checked,
                            key: e.target.name,
                            filter: stateFilter,
                            setFilter,
                          })
                        }}
                        sx={{ ml: '16px' }}
                      />
                    }
                    sx={
                      !stateFilter[item.nameProvCheckBox]
                        ? { marginBottom: -1 }
                        : {}
                    }
                  />

                  {stateFilter[item.nameProvCheckBox] && (
                    <SelectGroupChip
                      boxSx={{ mb: 3, px: 2 }}
                      id={item.nameInputProv}
                      name={item.nameInputProv}
                      labelText={''}
                      placeholder="กรุณาเลือก"
                      options={association.filter((option) => {
                        const selectedRegion = stateFilter[
                          `${item.nameInputReg}List`
                        ].map((reg) => reg.uuid)
                        return selectedRegion.includes(option.regionUuid)
                      })}
                      value={stateFilter[`${item.nameInputProv}List`]}
                      onChange={(list) => {
                        events.handleSelectMultiple(
                          list,
                          stateFilter,
                          setFilter,
                          item.nameInputProv,
                        )
                      }}
                    />
                  )}
                </FormGroup>
              </>
            )}
          </>
        )}
      </FormGroup>
    </StyledGroupBox>
  ))
}

export const ListResult = ({ filter, setFilter, arrayResult, listKeys }) => {
  return arrayResult.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={_.get(filter[listKeys], item.name, false)}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKeys,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = () => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const [station, setStation] = useState([])
  const [region, setRegion] = useState([])
  const [association, setAssociation] = useState([])
  const dispatch = useDispatch()
  const { open, clearFilter } = useSelector(
    (state) => ({
      open: state.crud.eExamDashboard?.filtersDrawer?.isOpen,
      clearFilter: state.crud.eExamDashboard?.filtersDrawer?.clearFilter,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  useEffect(() => {
    let newFilter = { ...filter }
    switch (clearFilter) {
      case 'date':
        newFilter = {
          ...filter,
          dateChecked: true,
          startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
          endDate: new Date(dayjs().format('YYYY-MM-DD')),
          displayDate: true,
        }
        break
      case 'station':
        newFilter = { ...filter, stationChecked: false, station: [] }
        break
      case 'associationRegion':
        newFilter = {
          ...filter,
          associationChecked: false,
          associationRegionChecked: false,
          associationRegionList: [],
          associationProvinceChecked: false,
          associationProvinceList: [],
        }
        break
      case 'associationProvince':
        newFilter = {
          ...filter,
          associationProvinceChecked: false,
          associationProvinceList: [],
        }
        break
      case 'otherRegion':
        newFilter = {
          ...filter,
          otherFilterChecked: false,
          otherFilterRegionChecked: false,
          otherFilterRegionList: [],
          otherFilterProvinceChecked: false,
          otherFilterProvinceList: [],
        }
        break
      case 'otherProvince':
        newFilter = {
          ...filter,
          otherFilterProvinceChecked: false,
          otherFilterProvinceList: [],
        }
        break
      default:
        break
    }
    setFilter(newFilter)
    setFilterMemo(newFilter)
  }, [clearFilter])

  useEffect(() => {
    events.handleFetchStation(setStation)
    events.fetchRegion(setRegion)
    events.fetchAssociation(setAssociation)
  }, [])

  return (
    <StyledDrawer open={open} onClose={() => toggleFiltersDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleFiltersDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckBox
        listArray={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
        station={station}
        region={region}
        association={association}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={async () => {
            await events.handleChange({
              value: false,
              key: 'dateChecked',
              filter,
              setFilter,
              isCheck: true,
            })
            setFilter(defaultFilter)
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(filter))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
